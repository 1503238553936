.ex-homepageSidebarContent {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	padding: 0.5em;
	text-decoration: none;
	color: map-get($COLORS, primarytext);

	&:hover {
		text-decoration: none;
		color: darken(map-get($COLORS, primarytext), 10%);
	}

	@include bp('small') {
		flex-basis: 100%;
	}

	&_copy {
		flex-basis: 100%;

		&_with-icon {
			max-width: calc(100% - 5rem);
			margin-left: 1rem;
		}
	}

	&_image {
		flex-basis: 100%;
		background-size: contain;
		background-repeat: no-repeat;
		min-height: 13rem;
		background-position: center;

		@include bp('small') {
			order: 1;
			flex-basis: 100%;
			min-height: 10rem;
			margin-bottom: 1em;
		}
	}

	&_icon {
		flex-basis: 3.5rem;
		background-size: cover;
		height: 3.5rem;
		align-self: center;

		@include bp('small') {
			flex-basis: 2.5rem;
			height: 2.5rem;
			margin-bottom: 1em;
		}
	}

	&_title {
		margin-top: 0.5rem;
		font-size: 1rem;
		line-height: 1.125;
	}

	&_subtext {
		font-size: 0.75rem;
		line-height: 1;
		margin: 0.5em 0 1em 0;
		font-style: italic;
	}

	&_description {
		font-size: 0.875rem;
		line-height: 0.875rem;
		margin: 1em 0;

		&_normal {
			font-size: 1.0rem;
			line-height: 1.0rem;
		}
	}

	&_button {
		min-width: 10em;

		&_right {
			float: right;
		}
	}
}
