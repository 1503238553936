.mapshared-collection {

	&-row {
		min-height: 25px;

		@include bp('small') {
			flex-wrap: wrap;
			padding: 0 0.5em;
		}

		&.disabled {
			cursor: not-allowed;
		}

		&:hover {
			background: #e9ecef;
			cursor: pointer;
		}
	}

	&-row-container {
		max-height: 300px;
		overflow-x: hidden;
		overflow-y: auto;

		&-short {
			max-height: 200px;
			overflow-x: hidden;
			overflow-y: auto;
		}
	}

	&-content-container {
		text-align: left;
		margin-top: 3px;
	}

	&-confirmation-container {
		margin-top: 5px;
		margin-bottom: 10px;
	}

	&-confirmation-button-container {
		margin-top: 5px;
	}

	&-confirmation-cancel-button {
		margin-right: 5px;
	}

	&-search {
		width: 98%;
	}

	&-checkbox {
		margin-right: 5px !important;
		margin-top: 5px !important;

		&.disabled {
			cursor: not-allowed;
		}

		&:hover {
			cursor: pointer;
		}
	}

	&-explanation {
		padding: 5px 0px;
	}
}

body[theme='flatGreen'] .mapshared-collection-row:hover {
	background: #FFD68D;
}


.delete-collection-modal-warning-icon {
	margin-right: 15px;
	font-size: 25px;
	color: red;
}

.locked-mapshare-collection-icon {
	width: 13px;
	height: 20px;
	margin-right: 5px;
}
