﻿@mixin footer() {
	.ex-footer,
	#inreach-footer {
		position: relative;
		margin-bottom: 1rem;

		@include bp('(min-width: 950px)') {
			#inreach-footer {
				text-align: center;
				width: 950px;
				margin: auto;
				padding: 10px 0px 10px 0px;
				position: relative;
				top: 0;
				left: 0;
			}
		}

		@include bp('small') {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			padding: 1em;
		}

		&_links {
			text-align: center;
			align-content: center;
			color: black;

			@include bp('small') {
				order: 2;
				flex-basis: 100%;
			}
		}

		&_logo {
			position: absolute;
			top: -5px;
			right: 15px;

			@include bp('small') {
				position: static;
				order: 1;
				margin: 0 auto 1em;
			}

			.ex-page_mapsharePassword & {
				top: 5px;
			}
		}
	}
}
