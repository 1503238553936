.ex-homepageBilling {
	width: 100%;
	overflow: hidden;

	&_container {
		width: 100%;
	}

	&_section {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 2em 0;
		border-bottom: 2px solid map-get($COLORS, bordersdark);
		transition: max-height 0.3s ease-in-out;
		max-height: 20em;

		&s {
			margin-top: 3em;
		}

		&:last-child {
			border: none;
			padding-bottom: 0;
		}

		&-hidden {
			max-height: 0;
			padding: 0;
		}

		&-content {
			flex-basis: calc(100% / 3 - 1em);

			&_50 {
				flex-basis: calc(100% / 2 - 1em);
			}

			@include bp('small') {
				margin-bottom: 1em;

				&_full-sm {
					flex-basis: 100% !important;
				}
			}

			&_label {
				font-size: 0.875rem;
				line-height: 1.125;
				color: map-get($COLORS, labelsmall);

				&-large {
					color: map-get($COLORS, dark);
					font-size: 1.25rem;
					line-height: 1.25;
				}
			}

			&_value {
				font-size: 1rem;
				line-height: 1.25;
			}

			&_button {
				display: inline-block;
				min-width: 11rem;

				&-sm {
					min-width: 0;

					@include bp('small') {
						margin-top: 1em;
					}
				}
			}

			&_link {
				display: inline-block;
				border: none;
				background: none;
				color: map-get($COLORS, link);
			}
		}
	}

	&_subtitle {
		font-size: 0.875rem;
		line-height: 1.125;
	}

	&_spinner {
		position: absolute;
		left: 50%;
		top: 1em;
		transform: translateX(-50%);

		&-makingPayment {
			> .spinner-border {
				margin-right: 1em;
				margin-left: 0.5em;
			}
		}
	}
}


.ex-homepageContentArea_wrapper {
	&.billing {
		&-prioritize {
			order: -2;
		}

		&-normal {
			order: 4
		}
	}
}