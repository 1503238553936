﻿.ex-addCollection {
    &_btn {
        align-self: flex-start;
        margin-right: 0.25rem;

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }
}
