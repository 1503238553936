@mixin mobile-embed() {
	.mobile-embed {
		&-flow {
			background-color: var(--bodybackground);
			color: var(--primarytext);
			width: 100vw;
			height: 100vh;
			overflow: hidden;

			&-slider {
				position: relative;
				display: flex;
				transition: left ease-in-out .25s;
			}

			&-page {
				background-color: var(--bodybackground);
				color: var(--primarytext);
				width: 100vw;
				height: 100vh;
				overflow-y: auto;

				&-input {
					padding: 1rem 0 8rem 0;
					margin: 0 1rem;

					& h1 {
						font-size: 1.5rem;
						font-weight: 600;
					}

					& h2 {
						margin-top: 1rem;
						font-size: 1rem;
					}

					.inline-input {
						margin-top: 1rem;

						&-row {
							display: flex;
							justify-content: space-between;
						}
					}
				}

				&-popup {
					&-backdrop {
						width: 100vw;
						height: 100vh;
						position: fixed;
						top: 0;
						background-color: #00000000;
						transition: background-color ease-in-out 0.15s;
						pointer-events: none;

						&.popup-open {
							background-color: var(--modalbackdrop);
							pointer-events: auto;
						}
					}

					&-content {
						transition: transform ease-in-out 0.15s;
						transform: translateY(100%);
						width: 100vw;
						position: absolute;
						bottom: 0;
						background-color: var(--primarybackground);
						padding: 1rem;

						&.popup-open {
							transform: translateY(0);
						}
					}
				}

				&-popdown {
					&-backdrop {
						width: 100vw;
						height: 100vh;
						position: fixed;
						top: 0;
						background-color: #00000000;
						transition: background-color ease-in-out 0.15s;
						pointer-events: none;

						&.popdown-open {
							background-color: var(--modalbackdrop);
							pointer-events: auto;
						}
					}

					&-content {
						transition: transform ease-in-out 0.15s;
						transform: translateY(-100%);
						width: 100vw;
						position: absolute;
						top: -100%;
						background-color: var(--primarybackground);
						padding: 1rem;

						&.popdown-open {
							transform: translateY(0);
							top: 0;
						}
					}
				}
			}

			.card-label {
				@include secondary-body-text();
				color: var(--secondarytext);
				margin-left: 2rem !important;
				font-size: 0.7rem;
			}

			.card > *:first-child {
				border-top-right-radius: 0.5rem;
			}

			.card > *:last-child {
				border-bottom-right-radius: 0.5rem;
			}

			.card {
				margin: 1rem;
				border-radius: 0.5rem;
				background-color: var(--primarybackground);

				h1 {
					font-size: 1.75rem;
				}

				&-title {
					line-height: 1.3;
				}

				&-subtitle {
					@include secondary-body-text();
					color: var(--secondarytext);
					line-height: 1.2;

					& + * {
						margin-top: 0.5rem;
					}
				}

				.list-group-item {
					background-color: var(--primarybackground);
					border-color: var(--listitemborder);
					padding-left: 0;
					margin-left: 1.25rem;

					&.list-nav-button {
						display: flex;
						justify-content: space-between;

						&:after {
							content: ">";
							color: var(--tertiarytext);
							font-weight: bold;
						}
					}
				}

				& > .list-group:last-child .list-group-item:last-child {
					border-bottom-right-radius: 0.5rem;
					border-bottom-left-radius: 0.5rem;
				}

				& > .list-group:first-child .list-group-item:first-child {
					border-top-left-radius: 0.5rem;
					border-top-right-radius: 0.5rem;
				}

				&-description {
					@include secondary-body-text();
					color: var(--secondarytext);
					margin: 0.25rem 1.5rem;
				}

				.input-group-text {
					background-color: var(--formlabelbackground);
					border-color: var(--formborder);
					color: var(--formtext);
				}

				.chevron {
					color: var(--primarytext);
				}
			}

			label {
				margin-left: 0;
			}
		}

		&-list-item {
			background-color: var(--primarybackground);
			align-items: center;
			border: 0;

			&:not(:first-child) {
				border-top: 1px solid #545458A6;
			}

			.wrap {
				overflow-wrap: break-word;
				max-width: 90%;
			}

			.gray {
				color: #929292;
			}
		}

		&-bottom-button {
			display: flex;
			flex-direction: column;
			position: absolute;
			width: 100vw;
			bottom: 0;
			padding: 1rem 0;
			background-color: var(--bodybackground);
			align-items: center;

			.error-message {
				color: var(--danger);
			}

			.bottom-button {
				align-self: stretch;
				margin: 0 1rem;
			}
		}

		&-toggle {
			display: flex;
			justify-content: space-between;
			align-items: center;

			&-label {
				display: flex;
				flex-direction: column;

				&-value {
					transition: color linear 0.15s;
					@include secondary-body-text();

					&.on {
						color: var(--explorange)
					}

					&.off {
						color: var(--secondarytext);
					}
				}
			}

			&-control {
				&-back {
					height: 1.6rem;
					width: 2.64rem;
					border-radius: 0.8rem;
					padding: 0.15rem;
					transition: background-color linear 0.15s;

					&.on {
						background-color: var(--explorange)
					}

					&.off {
						background-color: gray;
					}
				}

				&-front {
					height: 1.3rem;
					width: 1.3rem;
					border-radius: 50%;
					background-color: white;
					transition: transform ease-in-out 0.15s;

					&.on {
						transform: translateX(80%);
					}

					&.off {
					}
				}
			}
		}

		&-text-field {
			display: flex;
			flex-direction: column;

			* + & {
				margin-top: 1rem;
			}

			&-label {
				@include secondary-body-text();
				color: var(--secondarytext);
				margin-bottom: 0;

				&-highlight {
					color: var(--explorange);
				}
			}

			&-description {
				@include secondary-body-text();
				color: var(--secondarytext);
				line-height: 1.25;
			}

			&-validation-message {
				color: var(--danger);
				margin-top: 0.5em;
				font-size: 0.9em;
			}

			& input, select {
				margin-top: 0.25rem;
				margin-bottom: 0.25rem;
			}
		}

		&-link {
			color: var(--explorange);
		}
		&-link:hover {
			color:var(--explorange);
			text-decoration: none; /*Remove the default of underlining*/
		}
		
		&-content-editable-div {
			.textarea {
				min-height: 10rem;
				height: fit-content;
				max-height: 20rem;
				overflow: scroll;
			}

			.highlight {
				color: red;
				background-color: lightcoral
			}
		}

		&-checkbox {
			display: flex;
			align-items: center;

			&-container {
				display: flex;
				flex-direction: column;

				* + & {
					margin-top: 1rem;
				}
			}

			&-label {
				margin-bottom: 0;
				align-self: center;
				line-height: 1.25;
			}

			&-description {
				@include secondary-body-text();
				color: var(--secondarytext);
				padding-left: 2rem;
			}

			&-description-aligned {
				@include secondary-body-text();
				color: var(--secondarytext);
				padding-left: 35px; /*A slightly different padding-left for the mobileCheckbox2 that lines it up with the label*/
			}

			input[type=checkbox] {
				display: inline-block;
				padding: 0.625rem;
				margin-right: 0.5rem;
				margin-top: 0.25rem;
				border: 2px solid var(--formborder);
				appearance: none;
				border-radius: 0.25rem;
				position: relative;

				&:checked {
					outline: none;
					border: 2px solid var(--explorange);
					background: var(--explorange);
				}

				&:checked:before {
					content: 'L';
					transform: rotateZ(45deg) scaleX(-1);
					position: absolute;
					top: -0.5rem;
					left: 0.34rem;
					font-size: 1.3rem;
					font-weight: bold;
					color: var(--primarybackground);
				}
			}
		}

		&-datepicker {
			&-header {
				display: flex;
				justify-content: space-between;
			}

			&-days {
				display: flex;
				margin-top: 0.25rem;

				&-cell {
					color: var(--secondarytext);
					text-align: center;
					width: calc(100% / 7);
				}
			}

			&-calendar {
				display: flex;
				flex-wrap: wrap;

				&-cell {
					text-align: center;
					margin: 0.125rem calc(calc(100% / 14) - 1rem);
					width: 2rem;
					height: 1.6rem;

					&.selected-day {
						border-radius: 0.25rem;
						color: white;
						background-color: var(--explorange);
						color: var(--contentbackground);
					}
				}
			}
		}

		&-timepicker {
			display: flex;
			justify-content: center;
			@include subheader-text();

			&-column {
				display: flex;
				flex-direction: column;
				justify-content: center;

				i {
					text-align: center;
				}
			}

			&-hour, &-minute, &ampm {
				padding: 0 0.75rem;
			}

			&-constant {
				min-width: 0.5rem;
			}
		}

		&-btn {
			&-primary {
				width: 100%;
				background-color: var(--explorange);
				border-color: var(--explorange);
				color: var(--contentbackground);
				font-weight: bold;
				min-height: 2.625rem;

				&:disabled {
					opacity: 0.7 !important;
					background-color: var(--explorange) !important;
					border-color: var(--explorange) !important;
					color: var(--contentbackground) !important;
				}
			}

			&-secondary {
				width: 100%;
				color: var(--explorange);
				background: none;
				border: none;
				font-weight: bold;

				&:disabled {
					opacity: 0.7 !important;
					color: var(--explorange) !important;
					background: none !important;
					border: none !important;
				}

				.btn + & {
					margin-top: 0.5rem;
				}
			}

			&-secondary-normal {
				width: 100%;
				color: var(--explorange);
				background: none;
				border: solid;
				border-width: thin;
				border-radius: 0.25rem;
				font-weight: normal;
				margin-top: 0.5rem;
			}

			&-normal-right {
				margin-top: 10px;
				padding: 10px;
				border-style: solid;
				border-radius: 0.25rem;
				border-width: thin;
				color: var(--primarytext);
				border-color: var(--buttonoutline);
				background-color: var(--primarybackground);
				font-weight: normal;
				float: right;
			}

			&-loading {
				&:disabled:before {
					content: '';
					display: inline-block;
					width: 1.25rem;
					height: 1.25rem;
					margin-right: 0.5rem;
					vertical-align: text-bottom;
					border-radius: 50%;
					border: 0.25rem solid var(--contentbackground);
					border-right-color: transparent;
					animation: spinner-border .75s linear infinite;
				}
			}

			&-normal {
				margin-top: 10px;
				padding: 10px;
				border-style: solid;
				border-radius: 5px;
				border-color: black;
				background-color: white;
				font-weight: normal;
			}

			&-normal-highlight {
				margin-top: 10px;
				padding: 10px;
				border-style: solid;
				border-radius: 5px;
				font-weight: normal;
				background-color: var(--explorange);
				border-color: var(--explorange);
				color: var(--contentbackground);
			}
		}

		&-error {
			color: var(--danger);
			padding: 0.25rem;
		}
	}

	.ex-mobile_browser {
		#content {
			min-height: auto;
		}

		.mobile-embed {
			&-flow {
				height: calc(100vh - 3.75rem);

				&-page {
					height: calc(100vh - 3.75rem);
				}
			}
		}
	}

	.form-control {
		border-color: var(--formborder);
		background-color: var(--formbackground);
		color: var(--formtext);

		&:focus {
			border-color: var(--formborder);
			background-color: var(--formbackground);
			color: var(--formtext);
		}

		&:disabled {
			background-color: var(--disabledinputbackground);
		}

		&:placeholder {
			opacity: 1;
			color: var(--formplaceholder);
		}
	}

	.explorange {
		color: var(--explorange);
	}

	.opacity-75 {
		opacity: 0.75;
	}
}
