.ex-select {
	&_xs {
		width: 6em;
	}

	&_sm {
		width: 8em;
	}

	&_md {
		width: 12em;
	}

	&_lg {
		width: 16em;
	}
}

.ex-input {
	&_xs {
		width: 6em;
	}

	&_sm {
		width: 8em;
	}

	&_md {
		width: 12em;
	}

	&_lg {
		width: 16em;
	}
}