.ex-pagination {
	display: flex;

	@media(max-width: 950px) {
		display: grid;
	}

	.page-selector {
		background: url('/Content/images/open_expander.svg') no-repeat right 10px center;
		width: 76px;
		padding-left: 16px;
		padding-top: 0px;
		padding-bottom: 0px;
		height: 33px;
		border: 1px solid #212529;
		font-size: 16px;
	}

	.page-options {
		option:hover {
			box-shadow: 0 0 10px 100px #1882A8 inset;
		}

		font-size: 16px;
	}

	.vertical-line {
		border-left: 1px solid #AAAAAA;
		height: auto;
	}

	.btn.paginator-button:disabled {
		background-color: transparent !important;
		border-color: transparent !important;
		color: transparent !important;
	}
}
