﻿.modalBackground {
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;

	.modalContainer {
		border-radius: 0.5rem;
		background-color: var(--formbackground);
		display: flex;
		flex-direction: column;
		padding: 0.75rem;
		margin: 1rem;

		.modalTitle {
			display: flex;
			justify-content: flex-start;
			margin-top: 0.5rem;

			.modalTitleText {
				@include subheader-text();
			}
		}

		.modalBody {
			@include title-text();
			margin-bottom: 1.5rem;

			#ValidateAddressDialog_Prompt {
				font-size: 1rem;
			}
		}

		.modalFooter {
			display: flex;
			justify-content: flex-end;

			button {
				margin: 0.25rem;
			}
		}
	}
}

.modalBackground.fade {
	transition: opacity .15s linear;
	opacity: 0;

	.modalContainer {
		transition: transform .3s ease-out;
		transform: translate(0, -25%);
	}
}


.modalBackground.show {
	opacity: 1;

	.modalContainer {
		transform: translate(0,0);
	}
}