﻿@mixin manage-contact-mobile() {
	.ex-manage-contact-mobile {
		.form-body {
			margin: 1rem;
		}

		.form-label {
			font-size: 1rem !important;
		}

		.area-code-select {
			height: 2.25rem;
			margin-bottom: 1rem;
		}

		.invalid-input {
			color: var(--danger);
		}

		li {
			font-size: .90rem;
			margin-left: -.3rem;
		}

		.save {
			font-weight: normal;
			margin-top: 1rem;
			font-size: 1.2rem;
		}

		.disabled-submit {
			background: var(--buttondisabled) !important;
			border-color: var(--buttondisabled) !important;
		}

		.required:after {
			content: " *";
			color: var(--danger);
		}

		.delete {
			margin-top: 1rem;
			background-color: transparent !important;
			color: var(--danger) !important;
			border-color: transparent !important;
			font-size: 1.2rem;
			font-weight: normal;
		}

		.delete-label {
			display: inline-block;
		}

		.trash-can {
			margin-top: .3rem;
			margin-right: 1rem;
		}
	}
}
