﻿@mixin generateRandomBackgroundImageCss($from, $to, $size) {
    @for $i from $from to $to {
        .random-background-image-#{$i} {
            background-image: url(/content/images/Background/LogOn/background#{$i}-#{$size}.jpg);
        }
    }
}

@mixin RandomBackgroundImages() {
    $logonBgdFrom: 4;
    $logonBgdTo: 9;
    @include generateRandomBackgroundImageCss($logonBgdFrom, $logonBgdTo, 'huge');

    @include bp('(max-width: 2000px)') {
        @include generateRandomBackgroundImageCss($logonBgdFrom, $logonBgdTo, 'large');
    }

    @include bp('(max-width: 1000px)') {
        @include generateRandomBackgroundImageCss($logonBgdFrom, $logonBgdTo, 'medium');
    }
}
