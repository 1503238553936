.ex-trackingForm {
	text-align: left;

	&_data {
		padding-right: 50%;
		position: relative;
		min-height: 19.5rem;

		@include bp('small') {
			padding-right: 0;
			min-height: 0;
		}
	}

	&_image {
		width: 100%;
		display: block;

		&-wrapper {
			position: absolute;
			right: 2rem;
			top: 50%;
			transform: translateY(-50%);

			@include bp('small') {
				position: static;
				transform: none;
				margin-bottom: 1rem;
			}
		}
	}

	&_complete {
		display: flex;
		flex-wrap: wrap;

		&-section {
			padding: 0.5rem;

			&:last-child {
				margin-left: auto;
				line-height: 2;
			}
		}

		&-label {
			font-size: 1.25rem;
		}

		&-value {
			font-size: 1.25rem;
			font-weight: bold;
			margin-left: 0.5rem;
		}
	}
}
