@mixin selection-bar() {
	*:not(.selected) > .selected:before {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 7px;
		height: 100%;
		content: "";
		background-color: map-get($COLORS, explorange);
		border-bottom-left-radius: inherit;
		border-top-left-radius: inherit;
	}
}
