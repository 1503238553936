@mixin garminUser() {
	.ex-page_garUser {
		.ex-page_garWelcome-mainContent {
			flex-basis: 100%;
		}

		&-title {
		}

		&-subtitle {
			padding: 0 0 0.625em 0;
		}

		&-grid {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding-top: 2.25em;

			&-item {
				align-self: flex-end;
				margin-bottom: 2.5em;
				flex-basis: calc(100% / 3 - 3em);
				text-align: center;

				@include bp('small') {
					flex-basis: calc(100% / 2 - 1em);
				}

				&_image {
					width: 100%;
					max-width: 7.5em;

					&-wide {
						max-width: 9.875em;
					}

					&-fill {
						max-width: 15.875em;
					}
				}

				&_desc {
					text-align: center;
					padding: 0 0 0.625em 0;
				}

				&_links {
					display: flex;
					justify-content: center;
					flex-wrap: wrap;
					padding: 0 0 0.625em 0;
				}

				&_link {
					@include bp('small') {
						width: 100%;
						max-width: 12rem;
						margin: 0;
						margin-bottom: 0.5rem;
					}
				}
			}
		}

		&-footer {
			text-align: center;
			padding-top: 2.25em;
		}
	}
}
