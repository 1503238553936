.ex-deviceSidebar {
	position: absolute;
	left: 0;
	top: 3rem;
	width: 24rem;
	padding: 1rem;

	&_image {
		width: 100%;
		max-width: 11rem;
		margin-bottom: 2em;
	}
}
