.ex-page_textMessage-full {
    height: 100%;
    width: 100%;
    margin: 0;

    @include bp('medium') {
        #header {
            & > .header {
                display: flex;
                justify-content: center;
            }
        }
    }

    @include bp('(max-width: 560px)') {
        #header {
            height: 60px;
        }
    }

    #replyContainer {
        position: absolute;
        top: 70px;
        right: 10px;
        background-color: white;
        max-height: 600px;
        width: 400px;
        z-index: 10002;
        border-radius: 5px;
        -webkit-box-shadow: 2px 2px 3px 3px rgba(1, 1, 1, .2);
        box-shadow: 2px 2px 3px 3px rgba(1, 1, 1, .2);
    }

    .grayBg {
        border-bottom: 1px solid #727272;
        background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#e7e9eb), to(#c4c4c4));
        background-image: -moz-linear-gradient(0% 100% 90deg,#c4c4c4, #e7e9eb);
        background-color: #d5d5d5;
    }

    #header > img {
        padding: 2px;
    }

    #replyContainerHeader {
        cursor: all-scroll;
    }

    .replyHeader {
        padding: 10px;
        color: black;
        text-align: left;
    }

    .replyBody {
        padding: 0 10px 10px 10px;
        width: 400px;
        box-sizing: border-box;
    }

    #replyContainer {
        text-align: left;
    }

    .headerText {
        font-weight: bold;
        padding-top: 20px;
        text-align: center;
        font-size: 13pt;
        top: 0px;
        left: 0px;
        color: #636363
    }

    .red {
        color: red;
    }

    #nopoints {
        -webkit-opacity: 0.7;
        -moz-opacity: 0.7;
        opacity: 0.7;
        filter: alpha(opacity=50);
        background-color: black;
        width: 100%;
        height: 100%;
        z-index: 4000;
        position: absolute;
        color: white;
        text-align: center;
    }

    #nopoints > div {
        width: 300px;
        height: 100px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -50px 0 0 -150px;
    }

    .profileWarning {
        width: 70%;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .field-validation-error {
        @include small-body-text();
    }

    .device-message label{
        margin: 0;
        font-size: 14px;

        &.form-check-label{
            font-size: 16px;
        }
    }

    #canned-messages-holder {
        & label{
            margin-top: 10px;
        }
        & textarea.form-control{
            margin-top: 0;
        }
    }

	.message-text {
		max-height: 8rem;
		overflow: auto;
	}
}
