.ex-chat,
#chat-container {
	position: absolute;
	bottom: -100%;
	width: 100%;
	height: 400px;
	text-align: left;
	z-index: 1030;
	background-color: map-get($COLORS, contentbackground);
	overflow: hidden;
	border-left: 1px solid map-get($COLORS, borders);
	max-height: 60vh;

	@include bp('small') {
		display: none !important;
	}

	&_open {
		bottom: 0 !important;
	}

	.ex-chatControls {
		@include bp('small') {
			height: auto !important;
			padding: 0.25em;
		}

		&_form {
			position: absolute;
			top: 8px;
			left: 8px;
			width: 600px;

			@include bp('small') {
				width: 100%;
				position: static;

				.token-input-list-facebook {
					position: static;
					float: none;
				}
			}
		}

		&_actions {
			position: absolute;
			left: 625px;
			top: 10px;

			@include bp('small') {
				position: relative;
				top: auto;
				left: auto;
				float: none;
			}
		}
	}

	&Expander {
		display: block;
		position: absolute;
		bottom: 0px;
		cursor: s-resize;
		width: 100%;
		background-color: map-get($COLORS, dark);
		height: 5px;
		opacity: .4;
		z-index: 1000;
		overflow: visible;

		@include bp('small') {
			transition: opacity .3s ease-in-out;
			opacity: 0.7;
			display: none !important;
		}
	}

	#chat-events {
		width: 100%;
		height: calc(100% - 120px);
		overflow-y: scroll;
		overflow-x: hidden;

		@include bp('small') {
			height: calc(100% - 180px) !important;
		}
	}

	.chat-event {
		border-top: 1px solid #cbcbcb;
		padding: 10px;
		position: relative;
		cursor: pointer;

		&-time {
			float: right;
			padding-right: 3px;
			color: rgba(0,0,0,0.75);
		}
	}

	#chat-header {
		background-color: black;
		color: white;
		height: 25px;
		border-left: 1px solid #cecece;
		border-top: 1px solid #cecece;
		padding-right: 10px;

		a {
			color: white;
			font-weight: bold;
		}
	}

	.chat-svr-event {
		color: #676767;
	}

	#chat-controls {
		height: 150px;
		overflow-y: auto;
		overflow-x: hidden;
		background-color: #e6e6e6;

		input:focus {
			border: none !important;
			box-shadow: none !important;
		}
	}

	.chat-name {
		padding: 3px;
		display: inline-block;
		font-weight: bold;
		border: 1px solid white;
		border-radius: 3px;

		&:hover {
			padding: 3px;
			display: inline-block;
			font-weight: bold;
			border: 1px solid black;
		}
	}

	.chat-self-event {
		background-color: #DEEEF8;
	}

	.chat-note {
		background-color: #FCF9DD;
	}

	.popupButtonText {
		position: absolute;
		bottom: 4px;
		left: 0px;
		width: 100%;
		font-size: 10px;
		color: black;
	}

	.chat-new-messages-alert {
		display: none;
		position: absolute;
		border: 1px solid #a19e9e;
		padding-left: 10px;
		padding-top: 10px;
		padding-right: 10px;
		padding-bottom: 5px;
		background-color: white;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		z-index: 1031;
		width: 150px;
		left: calc((50%) - (150px / 2));
		bottom: 120px;
		text-align: center;
		box-shadow: 1px 1px 8px 1px rgba(0,0,0,0.5);
	}

	#chat-filter {
		background-color: #e6e6e6;
		height: 38px;
		padding-top: 4px;
		padding-right: 15px;
		padding-left: 15px;
		border-bottom: 1px solid #cbcbcb;
		position: relative;
	}

	#chat-filter {
		button, input {
			margin-top: 0px !important;
			margin-bottom: 0px !important;
			height: 30px;
		}

		&-settings {
			position: absolute;
			top: 38px;
			right: 28px;
			width: 350px;
			background-color: #e6e6e6;
			box-shadow: 1px 1px 8px 1px rgba(0,0,0,0.5);
			z-index: 1031;
			padding: 15px;
		}
	}
}

#expand-collapse-chat {
	position: absolute;
	left: 50%;
	top: -15px;
	padding: 2px;
	z-index: 7;
	background-color: black;
	border-radius: 3px 3px 3px 3px;
	transform: rotate(90deg);

	.ex-chatContainer_open & {
		transform: rotate(-90deg);
	}

	@include bp('small') {
		left: auto;
		right: 1em;
		width: 1.5rem;
		height: 2.5rem;
		top: -1.75em;
	}
}
