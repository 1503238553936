﻿.ex-page_login {
	#submitBtn, #login-btn-signin, #login-btn-reset, #login-btn-cancel {
		display: inline-block;
		*display: inline;
		/* IE7 inline-block hack */
		*zoom: 1;
		padding: 4px 10px 4px;
		margin-bottom: 0;
		font-size: 13px;
		line-height: 18px;
		color: #333333;
		text-align: center;
		text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
		vertical-align: middle;
		background-color: #f5f5f5;
		background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
		background-image: -ms-linear-gradient(top, #ffffff, #e6e6e6);
		background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
		background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
		background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
		background-image: linear-gradient(top, #ffffff, #e6e6e6);
		background-repeat: repeat-x;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e6e6e6', GradientType=0);
		border-color: #e6e6e6 #e6e6e6 #bfbfbf;
		border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
		filter: progid:dximagetransform.microsoft.gradient(enabled=false);
		border: 1px solid #cccccc;
		border-bottom-color: #b3b3b3;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
		-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
		cursor: pointer;
		*margin-left: .3em;
		font-weight: normal;
		height: auto;
		font-family: inherit;
		margin-top: 35px;
		margin-left: 3px
	}


	#password {
		width: 210px !important;
		background-color: white;
		margin-left:3px
	}
	#password:focus {
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
		-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
		-webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
		-moz-transition: border linear 0.2s, box-shadow linear 0.2s;
		-ms-transition: border linear 0.2s, box-shadow linear 0.2s;
		-o-transition: border linear 0.2s, box-shadow linear 0.2s;
		transition: border linear 0.2s, box-shadow linear 0.2s;
		border-color: rgba(82, 168, 236, 0.8)
	}
	input:focus{
		outline:0;
	}
	#create-account-component h2{
		font-family: Arial
	}
	#login-state-default h2{
		font-family: inherit;
		font-size: 24px;
		font-weight: normal;
		margin-top:0px;
		margin-bottom:0px;
		margin-left:3px
	}

	#login-state-default p {
		margin-top:0px;
		margin-left:3px
	}

	label{
	font-weight:normal !important;
	margin-left:3px
	}

	.textfield p {
		color: #000000 !important;
		margin-left:3px
	}
}