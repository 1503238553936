﻿.ex-collectionPane {
	&_section {
		&-name {
			@include bp('small') {
				flex-wrap: wrap;
			}
		}

		&-form {
			@include bp('small') {
				flex-wrap: wrap;
			}
		}
	}

	&_footer,
	#edit-collection-pane-footer {
		flex: none;
		margin-bottom: 15px;
		margin-top: 5px;
		padding-left: 10px;
		padding-right: 10px;

		@include bp('small') {
			flex-wrap: wrap;
			width: 100vw;
			justify-content: flex-end;
		}

		&-buttonContainer {
			@include bp('small') {
				flex-wrap: wrap;
			}
		}

		&-button {
			@include bp('small') {
				margin-left: 0 !important;
				margin-right: 0.5em !important;
				margin-bottom: 0.5em;
			}
		}
	}
}
