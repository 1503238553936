﻿.ex-activationSection {
	transform: scaleX(0) scaleY(0) translateX(400%);
	transform-origin: top right;
	opacity: 0;
	width: 64%;
	transition: all 0.4s ease-in-out;
	background: map-get($COLORS, light);
	height: 0;

	.btn-outline-explore {
		color: var(--dark);
		border-color: var(--dark);

		&:hover {
			color: var(--dark);
			background-color: #fff;
			border-color: var(--dark);
		}
	}

	.centered-image {
		display: block;
		margin: 3em auto 2em auto;
		width: 120px;
		height: 120px;
	}

	input.form-control.is-invalid {
		background-image: none;
		border-color: #dc3545;
	}

	@media screen and (max-width: 1280px) {
		width: 85%;
	}

	@media screen and (max-width: 767px) {
		width: 100%;
	}

	&_active {
		display: block;
		transform: scaleX(1) scaleY(1) translateX(29%);
		opacity: 1;
		height: initial;

		@media screen and (max-width: 1280px) {
			transform: scaleX(1) scaleY(1) translateX(9%);
		}

		@media screen and (max-width: 767px) {
			transform: scaleX(1) scaleY(1) translateX(0);
		}
	}

	.btn {
		font-weight: bold;
		min-width: 10rem;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;

		&.btn-loading {
			padding-top: 0.36rem;
			padding-bottom: 0.36rem;
		}

		&.align-left {
			margin-right: auto;
		}
	}

	.spinner-border.centered {
		margin-left: 48%;
	}

	&_btnWrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin-bottom: 1em;
		bottom: 0;
		width: 100%;

		&-hasForm {
			bottom: -5rem;
		}

		&-form {
			flex-basis: 100%;
			display: flex;
			justify-content: flex-end;
		}

		&.with-border {
			border-bottom: 2px solid #dedede;
			margin-bottom: 1rem;
			padding-bottom: 1rem;
		}
	}
}
