@mixin page-home() {
	#content {
		min-height: 85vh;
	}

	.random-pro-home-background-image-1 {
		background-image: url('../../../Content/images/ProHome/Background-1.jpg');
	}

	.random-pro-home-background-image-2 {
		background-image: url('../../../Content/images/ProHome/Background-2.jpg');
	}

	.random-pro-home-background-image-3 {
		background-image: url('../../../Content/images/ProHome/Background-3.jpg');
	}

	.random-pro-home-background-image-4 {
		background-image: url('../../../Content/images/ProHome/Background-4.jpg');
	}

	.random-pro-home-background-image-1,
	.random-pro-home-background-image-2,
	.random-pro-home-background-image-3,
	.random-pro-home-background-image-4 {
		background-position-y: bottom;
		background-repeat: no-repeat;
		background-size: contain;
		min-height: 100vh !important;

		@media (max-width: 767px) {
			background-image: none;
		}
	}

	.footer-component {
		margin: 51px auto 0 auto;
		background-color: #fff;
		padding: 6px 30px;
		width: max-content !important;
		border-top-left-radius: 14px;
		border-top-right-radius: 14px;

		@media (max-width: 767px) {
			width: 100% !important;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			padding: 1em;

			p {
				order: 2;
				flex-basis: 100%;
			}

			img {
				position: static !important;
				order: 1;
				margin: 0 auto 1em;
				margin-left: inherit !important;
			}
		}

		a {
			color: #d33e01 !important;
		}

		p {
			display: inline-block;
			margin: 0;
		}

		img {
			position: unset;
			vertical-align: baseline;
			width: 130px;
			height: 19px;
		}
	}

	.ex-team_tracking_tour {
		background-color: #3b3b3b !important;

		.ex-team_tracking_tour-header, .ex-team_tracking_tour-content {
			max-width: 80em !important;
			padding: 0;

			@media (max-width: 1190px) {
				max-width: 62em !important;
			}

			@media (max-width: 1024px) {
				max-width: 100% !important;
				padding: 0 1rem;
			}
		}

		.ex-button-nav {
			padding-bottom: 0.3rem !important;
		}
	}

	.ex-page_home {
		.columns-2 {
			columns: 2;
		}

		.decorativeImageHome {
			border-radius: 0.25rem;
			max-width: 100%;
		}

		.centerContent {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-content: stretch;
		}

		.menu-ellipses {
			padding: 4px 3px 8px 3px;
			cursor: pointer;
			position: relative;

			&-inner {
				font-weight: 900;
				@include title-text();
				line-height: 10px;
				position: relative;
				top: -8px;
				user-select: none;
				pointer-events: none;
			}

			&.menu-ellipses-disabled {
				cursor: not-allowed;
			}

			&.menu-ellipses-visual {
				cursor: default
			}

			&:hover {
				color: #808080;

				&.menu-ellipses-disabled, &.menu-ellipses-visual {
					color: unset;
				}
			}
		}

		&.pro {
			column-count: 3;
			column-gap: 1.6em;
			max-width: $default-max-width;
			width: 80% !important;
			min-height: 86.3vh;

			.spinner-small {
				width: 1em;
				height: 1em;
			}

			input[type="checkbox"] {
				accent-color: #d33e01;
				appearance: none;
				border: 1px solid #d33e01;
				height: 1em;
				width: 1em;
				border-radius: 0.2em;
				margin-top: 0.3em !important;
				vertical-align: top;

				&:disabled, &:checked {
					appearance: checkbox;
				}
			}

			a {
				color: #d33e01;
			}

			.spinner-border {
				border: 0.25em solid #d33e01;
				border-right-color: transparent;
			}

			.tablet {
				height: 0;
				opacity: 0;
				overflow: hidden;
				margin-bottom: 0 !important;
				display: block;
			}

			.mobile {
				height: 0;
				opacity: 0;
				overflow: hidden;
				margin-bottom: 0 !important;
				display: block;
			}

			@media (max-width: 1190px) {
				column-count: 2;
				width: 56em !important;

				.desktop {
					height: 0 !important;
					opacity: 0;
					overflow: hidden;
					margin: 0 !important;
					display: block;
				}

				.mobile {
					height: 0 !important;
					opacity: 0;
					overflow: hidden;
					margin: 0 !important;
					display: block;
				}

				.tablet {
					height: initial;
					opacity: initial;
					overflow: initial;
					margin-bottom: 1.6em !important;
					display: inline-block;
				}
			}

			@media (max-width: 1024px) {
				width: 100% !important;

				.card {
					width: 100%;
				}
			}

			@media (max-width: 660px) {
				column-count: 1;

				.desktop {
					height: 0 !important;
					opacity: 0;
					overflow: hidden;
					margin: 0 !important;
					display: block;
					border: none;
				}

				.tablet {
					height: 0 !important;
					opacity: 0;
					overflow: hidden;
					margin: 0 !important;
					display: block;
					border: none;
				}

				.mobile {
					height: initial !important;
					opacity: initial;
					overflow: initial;
					margin-bottom: 1.6em !important;
					display: inline-block;
				}
			}


			#contentwrapper {
				background-position-y: bottom;
				background-repeat: no-repeat;
				background-size: contain;

				@media (max-width: 660px) {
					background-image: none;
				}

				&.random-pro-home-background-image-1 {
					background-image: url('../../../Content/images/ProHome/Background-1.jpg');
				}

				&.random-pro-home-background-image-2 {
					background-image: url('../../../Content/images/ProHome/Background-2.jpg');
				}

				&.random-pro-home-background-image-3 {
					background-image: url('../../../Content/images/ProHome/Background-3.jpg');
				}

				&.random-pro-home-background-image-4 {
					background-image: url('../../../Content/images/ProHome/Background-4.jpg');
				}
			}

			#content {
				#inreach-footer {
					margin: 51px auto 0 auto;
					background-color: #fff;
					padding: 6px 30px;
					width: max-content;
					border-top-left-radius: 14px;
					border-top-right-radius: 14px;

					@include bp('medium') {
						width: 100%;
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						padding: 1em;

						p {
							order: 2;
							flex-basis: 100%;
						}

						img {
							position: static !important;
							order: 1;
							margin: 0 auto 1em;
							margin-left: inherit !important;
						}
					}

					p {
						display: inline-block;
						margin: 0;
					}

					img {
						position: unset;
						vertical-align: baseline;
						margin-left: 20px;
						width: 130px;
						height: 19px;
					}
				}

				a {
					text-decoration: underline;
				}

				a.btn, a.with-image {
					text-decoration: none;
				}

				@media (max-width: 1024px) {
					padding: 0 1em;
					width: 100%;
				}
			}

			.card {
				border-radius: 0.25rem 1rem 1rem 1rem;
				width: 100%;
				margin-bottom: 1.6em;
				transition: all 0.3s;
				box-shadow: 0px 2px 6px 2px rgb(0 0 0 / 15%), 0px 1px 2px rgb(0 0 0 / 30%);
				border: none;

				.download-icon {
					width: 1em !important;
					padding-bottom: 0.2em;
				}

				.btn {
					min-width: 145px;
				}

				nav.d-flex {
					flex-wrap: wrap;
					column-gap: 0.4em;
					row-gap: 0.4em;
					width: 100%;
				}

				.btn-outline-danger {
					color: #d33e01 !important;
					border-color: #d33e01 !important;

					&:hover {
						color: #fff !important;
						background-color: #d33e01 !important;
					}
				}

				.simple-grid {
					display: grid;
					grid-template-columns: 1em 1fr;
					padding-left: 0;
					column-gap: 0.4em;
					align-items: start;

					.spinner-border {
						width: 1em;
						height: 1em;
					}

					input {
						margin-top: 0.4em;
					}
				}

				&.spinner-card {
					.card-body {
						height: 10em;
						align-items: center;
					}
				}

				&.invalid-billing {
					nav {
						.btn {
							background: #D33E01 !important;
							border-color: #D33E01 !important;
							color: #fff !important;
						}
					}
				}

				&.volume-discounts {
					.card-header {
						background-color: #fff;
						color: #000;
						border-bottom: none;

						h3 {
							font-size: 1.25rem;
							font-weight: bold;
						}

						h4 {
							font-size: 1rem;
						}
					}

					div.current-discount {
						span {
							color: #0074E0;
							font-weight: bold;
						}
					}

					nav {
						.btn {
							background: #D33E01 !important;
							border-color: #D33E01 !important;
							color: #fff !important;
						}
					}
				}

				&#AddDeviceContainer {
					span {
						margin-bottom: 1em;
					}
				}

				&.full-width {
					display: block;
					column-span: all;
				}

				&.full-image {
					img {
						border-radius: 0.20rem 0.95rem 0.95rem 0.95rem;
						height: 100%;
						width: 100%;
						object-fit: cover;
					}

					img.advertisement-sar {
						min-height: 12em;
					}

					section {
						position: absolute;
						display: grid;
						color: #FFF;

						h5, h3, span {
							font-weight: bold;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}

					section.advertisement {
						justify-items: right;
						bottom: 2em;
						right: 1em;

						h3 {
							margin: 0;
							font-size: 1.4em;
						}

						.btn {
							font-weight: bold;
							width: max-content;
							background: #D33E01;
							border-color: #D33E01;
							color: #FFF;
						}
					}

					section.advertisement-sar {
						justify-items: right;
						bottom: 2em;
						right: 1em;
						position: absolute;
						top: 0;
						left: 0;
						min-height: 10em;

						h3 {
							margin: 0;
							font-size: 1.4em;
							position: absolute;
							top: 1em;
							left: 1em;
							color: var(--primarytext);
							white-space: break-spaces;
						}

						.btn {
							font-weight: bold;
							width: max-content;
							position: absolute;
							bottom: -1em;
							right: 0.28em;
							background: #D33E01;
							border-color: #D33E01;
							color: #FFF;
						}
					}

					section.welcome {
						top: 0px;
						left: 0px;
						right: 0px;
						grid-row-gap: 0;
						background-color: #fffa;
						color: #000;
						padding: 0.4em 1em;
						max-width: 100%;
						border-top-left-radius: 0.2rem;
						border-top-right-radius: 0.95rem;

						h5 {
							margin: 0;
							font-weight: bold;
							height: 1.25em;
						}

						span {
							font-weight: normal;
						}
					}
				}

				.card-header {
					font-size: 1.1em;
					border-radius: 0.25rem 1rem 0 0;
					margin: 0;
					background-color: #3b3b3b;
					color: #fff;
					border-color: map-get($COLORS, probordercolor);

					&.alert-danger {
						background-color: #f8d7da;
						border-color: #f5c6cb;
						color: #000;
					}
				}

				.card-body {
					display: grid;
					grid-row-gap: 1em;

					img.add-device {
						width: 7em;
						position: absolute;
						bottom: 1em;
						opacity: 0.5;
					}

					.btn {
						padding: 0.2em 1em;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					ol {
						margin: 0;
					}

					ul {
						list-style: none;
						padding: 0;

						li {
							padding: 0.5em 0;

							h5 {
								font-size: 1em;
								padding: 0;
								margin: 0;
								font-weight: bold;
							}

							a.with-image {
								display: grid;
								grid-template-columns: 1em 1fr;
								grid-column-gap: 0.4em;
								text-decoration: none;
								align-items: center;
							}
						}

						&.articles {
							line-height: 1.3em;
							margin-bottom: 0;

							li {
								display: grid;
								grid-template-columns: 1fr 2fr;
								grid-column-gap: 1em;

								span {
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 3;
									-webkit-box-orient: vertical;
								}

								img {
									grid-row-end: span 2;
									width: 100%;
									height: 100%;
									object-fit: cover;
									max-height: 5em;
									border-radius: 0.5em;
								}
							}
						}
					}

					nav {
						display: grid;
						grid-template-columns: max-content;
						justify-content: center;
						align-self: end;
					}

					hr {
						width: 100%;
						margin: 0;
						border-color: map-get($COLORS, probordercolor);
					}
				}

				section {
					display: grid;
					grid-row-gap: 0.8em;

					&.billing-data {
						.account-balance {
							display: grid;
							grid-template-columns: max-content 1em;

							.balance-total {
								margin-right: 0.4em;

								&.red {
									color: red;
								}

								&.green {
									color: green;
								}
							}

							img {
								vertical-align: initial;
							}
						}
					}

					&.user-survey {
						div {
							grid-row-gap: 0.4em;
						}
					}

					&.invoices {
						div {
							display: grid;
							grid-template-columns: 2.4em 1fr;
							grid-column-gap: 1em;

							img {
								width: 100%;
								grid-row-end: span 2;
							}

							nav {
								grid-template-columns: max-content 1fr;
								grid-column-gap: 1em;
							}
						}
					}

					&.funds {
						nav {
							grid-template-columns: max-content;
							justify-self: right;
						}
					}

					&.user-survey {
						div {
							display: grid;
						}
					}

					&.inreach-in-use {
						nav {
							grid-template-columns: 1fr 1fr;
							grid-column-gap: 1em;
							padding: 0 1em;
						}
					}
				}
			}
		}
	}

	.tracking-missconfig {
		background-color: #FBEDD3;
		padding: 10px;
	}

	.bold-text {
		font-weight: bold;
	}

	.custom-padding {
		padding-right: 10px;
	}

	.volume-discounts-parent-minimized {
		display: flex;
		column-gap: 0.5em;
		align-items: center;
		justify-content: center;
		background: #fff;
		padding: 0em 2em;
		margin-bottom: 1em;
		margin-top: 4px;
		border: solid 1px rgba(0, 0, 0, 0.125);
		margin-left: auto;
		margin-right: auto;

		.volume-discount-header {
			padding: 0.8em 0;
			text-align: center;

			h2 {
				font-size: 1.2em;
				text-transform: uppercase;
				margin-bottom: 0;
				color: #0074E0;
				font-weight: bold;

				img {
					display: none;
					float: right;

					@media (max-width: 1000px) {
						display: initial;
					}
				}
			}

			a {
				width: max-content;
				font-weight: bold;
			}
		}

		.volume-discount-actions {
			img {
				width: 2em;

				#Vector {
					fill: #0074E0;
				}
			}

			@media (max-width: 1000px) {
				display: none;
			}
		}

		@media (max-width: 1000px) {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr;
		}
	}

	.volume-discounts-parent {
		display: grid;
		grid-template-columns: 0.8fr 1fr 2em;
		column-gap: 1.5em;
		align-items: center;
		background: #fff;
		padding: 0em 2em;
		margin-bottom: 1em;
		margin-top: 4px;
		border: solid 1px rgba(0, 0, 0, 0.125);
		margin-left: auto;
		margin-right: auto;

		@media (max-width: 1000px) {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr;
		}

		.volume-discount-header {
			display: grid;
			grid-template-rows: 1fr 1fr 1fr;
			padding: 2em 0;
			text-align: left;

			h2 {
				font-size: 1.2em;
				text-transform: uppercase;
				margin-bottom: 0;
				color: #0074E0;
				font-weight: bold;

				img {
					display: none;
					float: right;
					transform: rotate(180deg);

					@media (max-width: 1000px) {
						display: initial;
					}
				}
			}

			span.current-discount {
				color: #0074E0;
				font-weight: bold;
			}

			a {
				width: max-content;
				font-weight: bold;
			}
		}

		.volume-discounts {
			.discount-tiers {
				margin: 0;
			}
		}

		.volume-discount-actions {
			img {
				width: 2em;
				transform: rotate(180deg);

				#Vector {
					fill: #0074E0;
				}
			}

			@media (max-width: 1000px) {
				display: none;
			}
		}
	}

	.volume-discounts {
		.discount-tiers {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1.2fr 1fr;
			height: 2em;
			text-align: center;
			margin-bottom: 2em;
			margin-top: 1.2em;
			position: relative;

			.tier {
				padding: 0.2em;
				display: grid;
				align-content: center;
				justify-content: center;
				padding-bottom: 7px;

				div {
					font-size: 0.75em;
					color: #fff;
					margin-bottom: -4px;

					.font-weight-bold {
						font-size: 0.9em !important;
					}
				}
			}

			.selector {
				position: absolute;
				display: flex;
				left: 20px;

				.pin {
					position: relative;

					img {
						position: absolute;
						width: 2.8em;
					}

					span.value {
						position: absolute;
						font-size: 0.75em;
						width: 3.67em;
					}

					span.description {
						font-size: 0.9em;
						position: absolute;
						white-space: nowrap;
					}

					span.description-right {
						left: 3.4em;
					}

					span.description-left {
						right: 0.4em;
						display: none;
					}
				}
			}

			.selector.switch-labels {
				.pin {
					span.description-right {
						display: none;
					}

					span.description-left {
						display: inline;
					}
				}
			}

			.tier-selector {
				top: -41px;

				.pin {
					span.value {
						top: 11px;
					}

					span.description-right {
						margin-top: 0.6em;
					}

					span.description-left {
						margin-top: 0.6em;
					}
				}
			}

			.plan-selector {
				bottom: 0px;

				.pin {
					span.value {
						top: 18px;
					}

					span.description-right {
						margin-top: 1em;
					}

					span.description-left {
						margin-top: 1em;
					}
				}
			}

			.tier-1 {
				background: #62B4FF;
				border-top-left-radius: 20px 50%;
				border-bottom-left-radius: 20px 50%;
			}

			.tier-2 {
				background: #40A3FF;
			}

			.tier-3 {
				background: #2496FF;
			}

			.tier-4 {
				background: #0084FF;
			}

			.tier-5 {
				background: #0074E0;
				border-top-right-radius: 20px 50%;
				border-bottom-right-radius: 20px 50%;
			}
		}
	}
}
