#AlertContainer {
	position: fixed;
	top: 3rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 100000;
	text-align: left;
	width: max-content;
}

.alert-container {
	max-width: 75vw;
	max-height: 50vh;
	overflow-x: hidden;
	overflow-y: auto;
}

.alert-btn-footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: .5rem 0;
}

.alert-btn-footer  > :not(:last-child) {
	margin-right: .5rem;
}
