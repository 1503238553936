﻿@mixin inbox() {

	body.ex-page_inbox {
		background-color: #F9F9FC !important;
	}

	.ex-page_inbox {

		@include selection-bar();

		#InboxContainer {
			display: flex;
			justify-content: center;
			margin: 1.5rem 0;

			@include bp('small') {
				display: block;
			}
		}

		.card {
			width: 50%;
		}

		.list-group-item {
			display: flex;
			justify-content: space-between;
			flex-shrink: 0;

			&:first-child:before {
				border-top-left-radius: 0.25rem;
			}

			&:last-child:before {
				border-bottom-left-radius: 0.25rem;
			}
		}

		.list-group-item.selected {
			background-color: #FFF2EE;
		}

		.card {
			height: fit-content;
		}

		#InboxMessageList {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			// The inner calc is the height of the #content div
			// The outter calc subtracts the margins and header of the container
			max-height: calc(calc(100vh - 121px) - 10rem);
			overflow-y: auto;
		}

		#InboxContactList {
			max-height: calc(calc(100vh - 121px) - 10rem);
			overflow-y: auto;
		}

		.inbox {
			&-conversation {
				&-name {
					font-weight: 700;
					font-size: 16px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					width: 95%;
				}

				&-delete {
					align-self: center;
					font-size: 14px;
					color: #666;
					cursor: pointer;

					&:hover {
						color: #000;
					}
				}
			}

			&-message {
				position: relative;
				max-width: 75%;

				&-row {
					display: flex;
				}

				&-content {
					font-size: 14px;
					padding: 7px 10px;
					border-radius: 18.5px;
					border: 1px solid rgba(0, 0, 0, 0.125);
					width: fit-content;
				}

				&-delete {
					align-self: center;
					font-size: 16px;
					margin: 3px;
					color: #666;
					cursor: pointer;

					&:hover {
						color: #000;
					}
				}

				&-date {
					font-size: 12px;
					color: #666666;
				}
			}
		}

		.mt-message {
			align-self: flex-start;

			&-content {
				background-color: #FFF2EE;
				order: 1;
			}

			&-delete {
				order: 2;
			}
		}

		.mo-message {
			align-self: flex-end;
			margin-right: 5px;

			&-row {
				align-items: flex-end;
			}

			&-content {
				background-color: #F9F9FC;
				order: 3;
			}

			&-date {
				text-align: right;
			}

			&-delete {
				order: 2;
			}

			&-map-link {
				display: block;
				align-self: center;
				font-size: 16px;
				margin: 3px;
				color: #666;
				cursor: pointer;
				order: 1;

				&:hover {
					color: #000;
				}
			}
		}

		.no-messages-text {
			font-size: 16px;
			color: #666;
			text-align: center;
		}

		.error-text {
			font-size: 16px;
			color: red;
		}

		.nav.ex-nav_secondary ul {
			background-color: #ffffff;
			background-clip: border-box;
			border: 1px solid rgba(0, 0, 0, 0.125);
			border-radius: 0.25rem;
		}
	}
}
