﻿@mixin activityPreferencesSelector() {
	.ex-activity_prefs_selector {
		position: relative;

		&-list {
			display: flex;
			flex-wrap: wrap;
			align-items: start;
			justify-content: center;
		}

		&-icon_selector {
			margin-top: 1rem;
			width: 6.25rem;
		}

		&-loading {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: #FFFFFF80;
			display: flex;
			justify-content:center;
			align-items: center;
		}
	}
}
