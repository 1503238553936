@mixin garminWelcome() {
    .ex-page_garWelcome {

        &-wrapper {
            display: flex;
            align-items: stretch;
            min-height: calc(100vh - 3.8125em);
        }

        &-title {
        }

        &-item {
            padding: 0 0 0.625em 0;
        }

        &-image {
        }

        &-mainContent {
            flex-basis: 58%;
            padding: 3.125em;
        }

        &-sidebar {
            background-color: map-get($COLORS, secondarybackground);
            flex-basis: 42%;
            padding: 3.125em 1.25em 0px 1.25em;
        }
    }
}
