﻿@mixin changePlanDialog() {
	#modal_change_plan {

		.modal-body {

			.changePlan_planList {
				position: relative;
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 1em;

				.priceChangeWarning {
					margin-bottom: 10px;
				}

				.reactivateRetiredPlanMessage {
					margin-bottom: 10px;
				}

				.availablePlan {


					&Title {
						font-weight: bold;
						margin-bottom: 1em;
					}

					.planServices {
						&Title {
							font-style: italic;
						}
					}
				}

				.planDescription {
					ul {
						margin-bottom: 0px;

						br {
							display: none;
						}
					}
				}

				.planChange {


					#ScheduledDate {
						margin-bottom: 10px;
					}

					.countText {
						font-size: 7pt;
					}
				}

				.enterpriseAssign {
					clear: both;

					.showAdmin {
						margin-bottom: 10px;
						color: red;
					}

					.assignedDevice {
						margin-bottom: 10px;
						color: black;

						.alreadyAssignedText {
							font-weight: bold;
						}
					}
				}
			}

			.switchFreedom {
				.earlyTerminationFee {
					font-weight: bold;
					color: red;
					margin-bottom: 5px;
				}
			}

			.changeHelp {
				.selectPlanButton {
					text-align: center;
				}
			}

			#changePlanDialog-promoCodeForm {
				margin-bottom: 1em;

				.promoCodeForm-title {
					font-weight: bold;
					margin-bottom: 1em;
				}
			}
		}

		.modal-footer {
			.metaPlanType {
				float: left;
				width: 300px;
				text-align: left;
				cursor: pointer;
			}
		}
	}
}
