@media screen {
    .dl-horizontal dt {
        width: 175px;
        padding-top: 5px;
        padding-right: 10px;
    }

    .dl-horizontal dd {
        padding-top: 5px;
        padding-left: 5px;
    }

    dd {
        margin-left: 0px;
        margin-bottom: 10px;
    }

    .dl-horizontal dd input, .dl-horizontal dd select {
        margin-top: 0px;
        margin-left: 0px;
        margin-bottom: 0px;
    }

    .validationMessage {
        color: map-get($COLORS, danger);
        font-size: smaller;
        padding-left: 2px;
    }

    .activity-header button {
        margin-bottom: 10px;
    }

    .inline label, .inline input[type=radio] {
        display: inline;
    }

    .row-fluid dt {
        color: #888;
    }

    select {
        font-size: larger;
    }

    .params-label label {
        font-weight: bold;
        margin-bottom: 0px;
    }
}

@media print {
    input {
        display: none !important;
    }

    #header, .nav, button, select, .noprint {
        display: none !important;
    }

    .params-label label {
        font-weight: bold;
        margin-bottom: 0px;
    }
}

.profiletd {
    height: 28px;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    vertical-align: middle !important;
}

.thintd {
    width: 100px !important;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cryptoinput {
    width: 60px;
    margin-bottom: 0px;
}

#cryptoModalInput {
    width: 520px;
}

.profiletd div {
    max-height: 35px;
    overflow: hidden;
    width: 90px;
}

.profileselect {
    width: 60px;
    margin-bottom: 0px;
}

.profileinput {
    width: 38px;
    margin-bottom: 0px;
}

.row-selected {
    background-color: #8fbed3 !important;
}

.row-selected:hover {
    background-color: #8fbed3 !important;
}

.row-selected:hover td {
    background-color: #8fbed3 !important;
}

.clicky {
    cursor: pointer;
}
