﻿@mixin deactivateServiceDialog() {

	.deactivateSurveyRoadpost {
		width: 550px;
		margin-left: -275px;
		height: 80%;
		max-height: 500px !important;
		margin-top: -22%;
	}

	.ex-deactivate-service-dialog {
		margin-top: auto;

		&.deactivateSurveyDelorme {
			position: absolute;
			max-height: 850px !important;
			box-sizing: border-box;
			margin: 5% 0 0 0;
		}

		#surveyDelorme * {
			max-width: none; /* Survey Monkey always sets a max-width which prevents the survey from being reactive */
		}
		/* Fail safe to force the height of the survey if it gets changed by anyone with access to Survey Monkey */
		#surveyDelorme .smcx-widget {
			height: 500px;
		}

		#surveyDelorme .smcx-widget .smcx-iframe-container {
			height: 465px; /* Survey Monkey footer branding is 35px */
		}
	}
}
