﻿.ex-page_setupEnterprise {

	#example {
		position: relative;
		margin-left: 0;
	}

	.tooltip {
		@include bp('small') {
			display: none;
		}
	}

	.tooltip-inner {
		max-width: 300px;
	}

	.navEnterprise {
		max-width: 100%;
		border-bottom: none !important;
		margin-top: 8rem;
		margin-bottom: 8rem;

		@include bp('small') {
			margin-top: 0rem;
			margin-bottom: 1rem;
		}
	}

	.highlightTab {
		-moz-border-radius: 10px;
		-webkit-border-radius: 10px;
		border-radius: 10rem;
		background-color: white;
		border-color: map-get($COLORS, explorange);
		border-width: .15rem;
		border-style: dashed;
		border-bottom: .15rem dashed map-get($COLORS, explorange) !important;
	}

	.ex-nav > .highlightTab {
		margin-right: 1rem !important;

		@include bp('small') {
			margin-bottom: .30rem !important;
			margin-top: .30rem !important;
		}
	}

	.ex-nav > .highlightTab ~ .highlightTab {
		margin: 0 -5px -1px 0 !important;
	}

	.mobileExplanation {
		display: none;

		@include bp('small') {
			display: block;
		}
	}

	.bs-tooltip-top {
		top: -5px !important;
	}

	.bs-tooltip-bottom {
		top: 5px !important;
	}

	.ex-nav {
		margin-bottom: 0;
		list-style: none;
		display: flex;
		padding-left: 0;
		white-space: normal !important;

		@include bp('medium') {
			width: unset;
			max-width: unset;
			flex-wrap: unset;
			text-align: left;
			padding: unset;
			margin: 0;
		}

		@include bp('small') {
			width: 75vw;
			max-width: 20em;
			display: flex;
			flex-wrap: wrap;
			text-align: left;
			padding: 4.5em 0 0;
			margin: 0;
		}

		&_tabs {
			width: auto;
			margin: 0 auto 1em !important;
			min-height: 0 !important;
		}

		&_wrapper {
			justify-content: center;
			margin-left: 180px;
			margin-right: 180px;

			&.info-hidden {
				margin-right: 65px;
			}

			@include bp('large') {
				margin-left: 55px;
			}

			@include bp('medium') {
				margin: 0;
				align-items: flex-start;
				position: absolute;
				left: -100%;
				top: 0;
				z-index: 10001;
				background: map-get($COLORS, primarybackground);
				transition: left 0.3s ease-in-out;

				.ex-nav_active & {
					left: 0;
				}
			}

			&-secondary {
				align-items: flex-end;
				padding-right: 10em;
				margin-right: 0 !important;
				margin-left: auto;

				@include bp('medium') {
					padding-right: 0;
				}
			}
		}

		&_item {
			position: relative;
			height: auto;
			padding: 0.75rem 0 0.75rem !important;
			margin: 0 -5px -1px 0;
			padding: 0.5em 0;
			max-width: 190px;
			text-align: center;


			&.empty {
				@include bp('small') {
					margin-bottom: .30rem !important;
					padding: 0 !important;
				}
			}

			&.empty ~ .empty {
				@include bp('small') {
					margin-top: .30rem !important;
					margin-bottom: 0 !important;
				}
			}

			@include bp('medium') {
				position: relative;
				height: auto;
				padding: 0.75rem 0 0.75rem !important;
				margin: 0 -5px -1px 0;
				padding: 0.5em 0;
				max-width: 190px;
				flex-basis: unset;
				border-bottom: none;
			}

			@include bp('small') {
				display: block;
				flex-basis: 100%;
				border-bottom: 1px solid map-get($COLORS, borders);
				margin-right: 2em;
				text-align: initial;
				padding-left: 1rem !important;

				&:last-child {
					border-bottom: none;
				}
			}

			@include bp('large-only') {
				margin: 0 -10px -1px 0;
			}

			&-secondary {
				max-width: none;
			}

			a {
				color: map-get($COLORS, dark);
				width: 100%;
				padding: 0.5em 0.875em;
				text-transform: uppercase;
				text-decoration: none;
				display: block;
				line-height: 100%;

				.ex-nav_wrapper-secondary & {
					color: map-get($COLORS, tertiarytext);
					text-decoration: underline;
					text-transform: none;
					transition: color 0.3s ease-in-out;

					&:hover {
						color: darken(map-get($COLORS, tertiarytext), 20%);
					}

					@include bp('medium') {
						text-decoration: none;
					}
				}

				@include bp('medium') {
					display: block;
				}

				@include bp('huge-only') {
					&:first-of-type {
						padding-right: 0;
						padding-left: 1.75em;
					}
				}
			}
		}
	}
}
