#login-form {
	display: flex;
	flex-direction: column;
}

#submitBtn, #login-btn-signin, #login-btn-reset, #login-btn-cancel, #mfa-verification-code-submit {
	all: none;
	background-image: none;
	text-shadow: none;
	display: inline-block;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	line-height: 1.5;
	border-radius: 10px;
	font-size: 18px !important;
	font-weight: bold;
	padding: 18px 24px;
	text-decoration: none;
	margin: 10px 0 6px 0;
	height: 100%;
	color: map-get($COLORS, buttonprimary);
	background-color: #fff;
	border-color: map-get($COLORS, buttonprimary);
	align-self: center;
}

#submitBtn:hover, #login-btn-signin:hover, #login-btn-reset:hover, #login-btn-cancel:hover, #mfa-verification-code-submit:hover {
	color: #fff;
	background-color: map-get($COLORS, buttonprimary);
	border-color: map-get($COLORS, buttonprimary);
}

#submitBtn:focus, #login-btn-signin:focus, #login-btn-reset:focus, #login-btn-cancel:focus, #mfa-verification-code-submit:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-explore:not(:disabled):not(.disabled):active, .btn-explore:not(:disabled):not(.disabled).active, .show > .btn-explore.dropdown-toggle {
	color: #fff;
	background-color: map-get($COLORS, buttonprimary);
	border-color: map-get($COLORS, buttonprimary);
}

#submitBtn:disabled, #login-btn-signin:disabled, #login-btn-reset:disabled, #login-btn-cancel:disabled, #mfa-verification-code-submit:disabled {
	color: #fff;
	background-color: map-get($COLORS, buttondisabled);
	border-color: map-get($COLORS, buttondisabled);
}

#login-btn-cancel, #login-btn-reset {
	padding: 0.375rem 0.75rem;
	border-radius: 0.25rem;
	font-size: 1rem !important;
	font-weight: 400;
}

#password, #mfa-code {
	all: initial;
	margin: 10px 0;
	width: 95% !important;
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
	border-bottom: 2px solid #0090ff;
	font-size: 18px;
	padding-top: 10px;
	padding-left: 10px;
	box-shadow: none;
	font-family: Arial,Helvetica,sans-serif;
}


#login-state-default h2 {
	display: none !important;
}

input:focus {
	outline: 0;
}

#create-account-component h2 {
	font-family: Arial
}

#login-state-default h2 {
	all: initial;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 22px;
	font-weight: bold;
}

#login-state-default p {
	margin-top: 0px;
	margin-left: 3px
}

label {
	font-weight: normal !important;
	margin-left: 3px
}

.textfield p {
	color: #000000 !important;
	margin-left: 3px
}

.gauth-loading {
	display: none;
}

label[for=username] {
	display: none !important;
}

label[for=password] {
	font-weight: bold !important;
}

.mfaFormLabel {
	span, br {
		display: none;
	}
}

.blueForm-basic .textfield input {
	background: none;
}


#gauth-widget-frame-logInComponentStepTwoSSO {
	order: 1 !important;
}

// This mostly centers the MFA next button. There isn't really a better way to do it.
#mfa-verification-code-submit, #choose-mfa-method-form #submitBtn {
	position: relative;
	left: 120px;
	transform: translateX(-50%);
}

#choose-mfa-method-form {
	#login-btn-cancel {
		display: none;
	}

	.mfaFormLabel {
		span, br {
			display: initial;
		}
	}
}

input[type=number] {
	appearance: textfield !important;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
