.ex-controlContainer,
#control-container {
	overflow-y: auto;
	overflow-x: hidden;
	display: inline-block;
	float: left;
	width: 0;
	transition: width 0.2s ease-out;
	position: relative;
	z-index: 10000;

	@include bp('small') {
		width: 0;

		&:after {
			content: '';
			background: map-get($COLORS, dark);
			position: fixed;
			top: $default-header-height;
			left: 0;
			bottom: 0;
			z-index: -2;
			opacity: 0;
			transition: opacity .3s ease-in-out;
		}
	}

	.modal-open & {
		z-index: 100;
	}

	&_open {
		width: 19.375rem !important;

		@include bp('small') {
			width: 19.375rem !important;

			& ~ #map .ex-chat {
				@include bp('small') {
					width: auto;
					min-width: 100vw;
				}
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: map-get($COLORS, contentbackground);
				z-index: -1;
			}

			&:after {
				opacity: 0.7 !important;
				right: 0;
			}
		}

		&.wider-control-container {
			width: 510px !important;
		}
	}

	.ex-nav_active & {
		@include bp('small') {
			z-index: 1000;
		}
	}
}

#loading-users-pane {
	left: 0 !important;
	right: 0 !important;
	background: map-get($COLORS, contentbackground) !important;
	z-index: 100020 !important;

	.lds-spinner {
		margin: auto;
	}
}