﻿#responseBox {
	margin-top: 0px;
	width: 722px;
	margin-left: auto;
	margin-right: auto;
	padding: 10px;
	background-color: white;
	height: auto !important;
}

.boldText {
	font-weight: bold;
}

a {
	cursor: pointer;
}