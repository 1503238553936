@mixin headline-text() {
    font-size: 2.5rem;
}

.headline-text-font-size {
	@include headline-text();
}

@mixin title-text() {
    font-size: 1.875rem;
}

.title-text-font-size {
	@include title-text();
}

@mixin subheader-text() {
    font-size: 1.25rem;
}

.subheader-text-font-size {
	@include subheader-text();
}

@mixin body-text() {
    font-size: 1rem;
}

.body-text-font-size {
	@include body-text();
}

@mixin secondary-body-text() {
    font-size: 0.875rem;
}

.secondary-body-font-size {
	@include secondary-body-text();
}

@mixin small-body-text() {
    font-size: 0.75rem;
}

.small-body-font-size {
	@include small-body-text();
}

// Useful for getting rid of the spacing between static images
.fs-0 {
	font-size: 0;
}