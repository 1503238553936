﻿.ui-widget-content {
    background: #ffffff /*{bgColorContent}*/ url("/content/themes/base/images/ui-bg_flat_75_ffffff_40x100.png") /*{bgImgUrlContent}*/ 50% /*{bgContentXPos}*/ 50% /*{bgContentYPos}*/ repeat-x /*{bgContentRepeat}*/;
}

.ui-widget-header {
    background: #cccccc /*{bgColorHeader}*/ url("/content/themes/base/images/ui-bg_highlight-soft_75_cccccc_1x100.png") /*{bgImgUrlHeader}*/ 50% /*{bgHeaderXPos}*/ 50% /*{bgHeaderYPos}*/ repeat-x /*{bgHeaderRepeat}*/;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    background: #e6e6e6 /*{bgColorDefault}*/ url("/content/themes/base/images/ui-bg_glass_75_e6e6e6_1x400.png") /*{bgImgUrlDefault}*/ 50% /*{bgDefaultXPos}*/ 50% /*{bgDefaultYPos}*/ repeat-x /*{bgDefaultRepeat}*/;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
    background: #dadada /*{bgColorHover}*/ url("/content/themes/base/images/ui-bg_glass_75_dadada_1x400.png") /*{bgImgUrlHover}*/ 50% /*{bgHoverXPos}*/ 50% /*{bgHoverYPos}*/ repeat-x /*{bgHoverRepeat}*/;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
    background: #ffffff /*{bgColorActive}*/ url("/content/themes/base/images/ui-bg_glass_65_ffffff_1x400.png") /*{bgImgUrlActive}*/ 50% /*{bgActiveXPos}*/ 50% /*{bgActiveYPos}*/ repeat-x /*{bgActiveRepeat}*/;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    background: #fbf9ee /*{bgColorHighlight}*/ url("/content/themes/base/images/ui-bg_glass_55_fbf9ee_1x400.png") /*{bgImgUrlHighlight}*/ 50% /*{bgHighlightXPos}*/ 50% /*{bgHighlightYPos}*/ repeat-x /*{bgHighlightRepeat}*/;
}

.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
    color: #363636 /*{fcHighlight}*/;
}

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
    background: #fef1ec /*{bgColorError}*/ url("/content/themes/base/images/ui-bg_glass_95_fef1ec_1x400.png") /*{bgImgUrlError}*/ 50% /*{bgErrorXPos}*/ 50% /*{bgErrorYPos}*/ repeat-x /*{bgErrorRepeat}*/;
}

.ui-icon,
.ui-widget-content .ui-icon {
    background-image: url("/content/themes/base/images/ui-icons_222222_256x240.png") /*{iconsContent}*/;
}

.ui-widget-header .ui-icon {
    background-image: url("/content/themes/base/images/ui-icons_222222_256x240.png") /*{iconsHeader}*/;
}

.ui-state-default .ui-icon {
    background-image: url("/content/themes/base/images/ui-icons_888888_256x240.png") /*{iconsDefault}*/;
}

.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon {
    background-image: url("/content/themes/base/images/ui-icons_454545_256x240.png") /*{iconsHover}*/;
}

.ui-state-active .ui-icon {
    background-image: url("/content/themes/base/images/ui-icons_454545_256x240.png") /*{iconsActive}*/;
}

.ui-state-highlight .ui-icon {
    background-image: url("/content/themes/base/images/ui-icons_2e83ff_256x240.png") /*{iconsHighlight}*/;
}

.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
    background-image: url("/content/themes/base/images/ui-icons_cd0a0a_256x240.png") /*{iconsError}*/;
}

.ui-widget-overlay {
    background: #aaaaaa /*{bgColorOverlay}*/ url("/content/themes/base/images/ui-bg_flat_0_aaaaaa_40x100.png") /*{bgImgUrlOverlay}*/ 50% /*{bgOverlayXPos}*/ 50% /*{bgOverlayYPos}*/ repeat-x /*{bgOverlayRepeat}*/;
}

.ui-widget-shadow {
    background: #aaaaaa /*{bgColorShadow}*/ url("/content/themes/base/images/ui-bg_flat_0_aaaaaa_40x100.png") /*{bgImgUrlShadow}*/ 50% /*{bgShadowXPos}*/ 50% /*{bgShadowYPos}*/ repeat-x /*{bgShadowRepeat}*/;
}