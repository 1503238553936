﻿.ex-home {
	&_row {
		display: flex;
		flex-direction: row;

		&-padded {
			padding: 1em;
		}

		@include bp('small') {
			flex-wrap: wrap;
			margin: auto;
		}
	}

	&_col {
		@include bp('small') {
			width: 100%;
			max-width: 100%;
			flex-basis: 100% !important;
			height: auto !important;
			padding: 1em;

			& > div {
				margin: auto !important;
			}
		}

		&-left {
		}

		&-center {
		}

		&-right {
		}
	}

	&_callout {
		text-align: center;
		border: 1px solid #0085f5;
		border-radius: 10px;
		padding: 0.675rem;
		margin: 1em auto;

		&-copy {
			margin-left: 0.5rem;

			@include bp('small') {
				line-height: 1.25;
				display: inline-block;
				margin: 1em;
			}
		}
	}
}
