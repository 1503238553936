@mixin boostrapOverrides() {

	[class^="icon-"],
	[class*=" icon-"] {
		background-image: url("../content/img/glyphicons-halflings.png");
	}

	.icon-white {
		background-image: url("../content/img/glyphicons-halflings-white.png");
	}

	.btn-success {
		background-color: #1E7D34;
		border-color: #1E7D34;
	}

	[class*="btn-outline-"] {
		background: transparent;

		&.btn-explore {
			background: var(--explorange);

			&:disabled {
				background-color: var(--buttondisabled);
				color: var(--contentbackground);
			}
		}
	}

	.btn-outline-primary {
		color: #0072ec;
		border-color: #0072ec;

		&:hover {
			background-color: #0072ec;
		}
	}

	.btn-outline-danger {
		color: map-get($COLORS, danger) !important;
		border-color: map-get($COLORS, danger) !important;

		&:hover {
			background-color: map-get($COLORS, danger);
			color: map-get($COLORS, contentbackground) !important;
		}
	}

	.btn-outline-dark {
		color: var(--btndark) !important;
		border-color: var(--btndark) !important;

		&:hover {
			background-color: var(--btndark);
			color: var(--contentbackground) !important;
		}
	}

	.btn:disabled, .btn.disabled {
		background-color: map-get($COLORS, buttondisabled) !important;
		border-color: map-get($COLORS, buttondisabled) !important;
		color: map-get($COLORS, contentbackground) !important;
		opacity: 1 !important;
	}

	.modal {
		overflow: auto !important;

		.modal-content {
			background-color: var(--primarybackground);
			color: var(--primarytext);
		}

		&.fade {
			&.in {
				opacity: 1;

				.modal-dialog {
					transform: none;
				}
			}
		}

		&-backdrop {
			background-color: map-get($COLORS, modalbackdrop);

			&.fade {
				&.in {
					opacity: 0.7;
				}
			}
		}

		&-full {
			width: calc(100vw - 2em);
			max-width: 100%;
			margin-left: 0.5%;

			@include bp('small') {
				margin-left: auto;
				margin-right: auto;
			}
		}

		&-dialog {
			z-index: 1049;
		}
	}

	.alert {
		&-error {
			@extend .alert-danger;
		}
	}

	.popover.tour {
		.popover-header {
			background: map-get($COLORS, contentbackground);
		}

		.popover-body {
			background: map-get($COLORS, contentbackground);
		}
	}
}

.modal {
	&.fade {
		&.in {
			opacity: 1;

			.modal-dialog {
				transform: none;
			}
		}
	}

	&-backdrop {
		background-color: map-get($COLORS, modalbackdrop);

		&.fade {
			&.in {
				opacity: 0.7;
			}
		}
	}
}

@media (prefers-reduced-motion: reduce) {
	.fade {
		transition: opacity 0.15s linear !important;
	}
}
