.ex-table {
	width: 100%;

	@include bp('small') {
		min-width: 30em;
	}

	&_wrapper {
		margin: 1em 0;

		@include bp('small') {
			width: 100%;
			overflow-x: scroll;
			border: 1px solid map-get($COLORS, borders);
		}
	}

	&_wide {
		@include bp('small') {
			min-width: 60em;
		}
	}
}
