﻿@mixin scrollContainer() {
	.ex-scrollContainer {
		& > * {
			width: 100%;
		}
	}

	.ex-scrolls {
		overflow: scroll;

		&_x {
			overflow: scroll auto;
		}

		&_y {
			overflow: auto scroll;
		}

		&_hidden {
			overflow: hidden;
		}
	}
}
