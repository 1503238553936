.ex-subscriptionMgmtInfo {
	width: 90%;
	display: flex;
	justify-content: center;
	align-items: start;
	padding: 0.5rem;
	text-decoration: none;
	border: 2px solid var(--bordersdark);
	border-radius: 5px;
	overflow: hidden;

	@include bp("small") {
		flex-wrap: wrap;
	}

	&.borderless {
		border: none;
		width: 100%;
	}

	span {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		min-width: 50%;
		max-width: 70%;
		font-size: 0.9rem;

		@include bp("small") {
			margin-bottom: 1rem;
		}

		&.compact {
			max-width: 60%;
		}
	}

	&_image {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		width: 3rem;
		height: 3rem;

		&.hideAtMedium {
			@include bp("medium") {
				display: none;
			}
	
			@include bp("small") {
				display: revert;
			}
		}
	}

	&_button {
		padding: 0.8rem 2.2rem;
		font-size: 0.85rem;
		white-space: nowrap;

		@include bp("medium") {
			white-space: auto;
		}

		&.compact {
			padding: 0.4rem 1.2rem;
		}
	}
}
