// values for small, medium, large and so on are stored in _variables.scss

// Enable/disable media queries
// @type Bool
$MQs: true !default;

// Output a media query named after `$point`.
// @param {String} $point
// @requires $breakpoints
// @requires $MQs
@mixin bp($point) {
    $query: map-get($breakpoints, $point);
  
    @if not $query {
        $query: $point;
    }
  
    @if $MQs {
        @media #{$query} {
            @content;
        }
    }
}