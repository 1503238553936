﻿.ex-contactBox {
	position: relative;
	padding: 1em;
	width: calc(100% / 3 - 1em);
	border: 1px solid map-get($COLORS, borders);
	margin: 1em 1em 1em 0;
	display: inline-block;
	vertical-align: top;

	@include bp('medium') {
		width: calc(100% / 2 - 1em);
	}

	@include bp('small') {
		width: 100%;
	}

	&_actions {
		position: absolute;
		top: 0.5em;
		right: 0.5em;
	}

	&_action {
		margin-right: 1em;
	}


	&_button {
		i {
			margin-left: 0.5em;
		}
	}
}
