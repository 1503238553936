// Colors
$COLORS: (
    explorange: #d33e01,
    explorangebackground: rgba(211,62,1, 0.08),
    explorangeunderline: #ff5723,
    primarytext: #2e2e2e,
    secondarytext: #636363,
    tertiarytext: #666666,
    dark: #000000,
	light: #ffffff,
    textandbuttonshadow: #766a46,
    primaryaccent: #d33e01,
    secondaryaccent: #0069de,
    tertiaryaccent: #2e2e2e,
    quaternaryaccent: #35ac00,
    danger: #d60000,
    primarybackground: #ffffff,
    secondarybackground: #efefef,
	tertiarybackground: #f5f5f5,
	bodybackground: #f9f9fc,
    pagebackground: #fcfcff,
    contentbackground: #ffffff,
    inputbackground: #ffffff,
	disabledinputbackground: #e9ecef,
	bordercolor: #ced4da,
    tabbackground: #e3e4e6,
    tabbackgroundhover: #b0b0b0,
    tabbackgroundsecondary: #ffffff,
    listitembackground: #f7f7fc,
    listitemhover: #e9ecef,
	listitemborder: #00000020,
    disabled: #dfdfdf,
    ads: #ff9d00,
    link: #315bb1,
	buttonprimary: #0069f0,
	buttonprimaryactive: #e6e6e6,
	buttonexplorehover: #b53500,
	buttonsuccess: #1e7d34,
	buttonoutline: #343a40,
    borders: #dedede,
    borderslight: #dedede,
	probordercolor: #bebebe,
	buttondisabled: #76767e,
	bordersdark: #e3e3e3,
	modalbackdrop: rgba(0, 0, 0, 0.7),
	statboxshadow: #4d3f3b22,
	labelsmall: #525252,
	dropdownhover: #343a40,
	gray: #808080,
	alertdangertext: #721c24,
	cardheader: #f4f4f4,
	formborder: #ced4da,
	formbackground: #ffffff,
	formplaceholder: #000000,
	formtext: #495057,
	formlabelbackground: #e9ecef,
	btndark: #343a40,
	bluelink: #315bb1,
	filledIcon: #6c6c6c,
	bannerBackground: #434343,
	cardHeaderNoTheme: #ff8862,
	cardBodyNoTheme: #ffc8b7,
	landingpagesecondarybackground: #eeeeee,
	sarimagebackgroundcolor: #20306c,

	// homepage stat colors named by Ben
	cranberry: #891340,
	grape: #67034C,
	raspberry: #7A0A4D,
	apple: #A52328,
	tangerine: #BD3114,
	pomegranate: #981C33,

	// Other brands' colors
	facebook: #0076FB,
	twitter: #1FA0F0,

	togglegreen: #30D158,
);

$COLORSDARKMODE: (
    explorange: #FF825A,
    explorangebackground: rgba(211,62,1, 0.08),
    explorangeunderline: #ff5723,
    primarytext: #ffffff,
    secondarytext: #BABABA,
    tertiarytext: #666666,
    dark: #000000,
	light: #ffffff,
    textandbuttonshadow: #766a46,
    primaryaccent: #d33e01,
    secondaryaccent: #0069de,
    tertiaryaccent: #2e2e2e,
    quaternaryaccent: #35ac00,
    danger: #ff5555,
    primarybackground: #1d1d1d,
    secondarybackground: #000000,
	tertiarybackground: #f5f5f5,
	bodybackground: #000000,
    pagebackground: #fcfcff,
    contentbackground: #050505,
	inputbackground: #1d1d1d,
	disabledinputbackground: #a0a1a7,
	bordercolor: #a6a6a6,
    tabbackground: #e3e4e6,
    tabbackgroundhover: #b0b0b0,
    tabbackgroundsecondary: #ffffff,
    listitembackground: #f7f7fc,
    listitemhover: #e9ecef,
	listitemborder: #404040,
    disabled: #dfdfdf,
    ads: #ff9d00,
    link: #315bb1,
	buttonprimary: #0069f0,
	buttonprimaryactive: #e6e6e6,
	buttonexplorehover: #b53500,
	buttonsuccess: #1e7d34,
	buttonoutline: #f8f9fa,
    borders: #dedede,
    borderslight: #dedede,
	probordercolor: #bebebe,
	buttondisabled: #76767e,
	bordersdark: #e3e3e3,
	modalbackdrop: rgba(0, 0, 0, 0.7),
	statboxshadow: #4d3f3b22,
	labelsmall: #525252,
	dropdownhover: #343a40,
	alertdangertext: #721c24,
	cardheader: #f4f4f4,
	formborder: #929292,
	formbackground: #2b2b2b,
	formplaceholder: #5a595e,
	formtext: #ffffff,
	formlabelbackground: #2b2b2b,
	btndark: #ffffff,
	bluelink: #ff825a,
	filledIcon: #a6a6a6,
	bannerBackground: #434343,
	cardHeaderNoTheme: #ff8862,
	cardBodyNoTheme: #ffc8b7,
	landingpagesecondarybackground: #eeeeee,
	sarimagebackgroundcolor: #20306c,
	
	// homepage stat colors named by Ben
	cranberry: #891340,
	grape: #67034C,
	raspberry: #7A0A4D,
	apple: #A52328,
	tangerine: #BD3114,
	pomegranate: #981C33,

	// Other brands' colors
	facebook: #0076FB,
	twitter: #1FA0F0,

	togglegreen: #30D158,
);

$COLORSEARTHMATE: (
    explorange: #23b57c,
    explorangebackground: rgba(35, 181, 124, 0.08),
    explorangeunderline: #23b57c,
    primarytext: #2e2e2e,
    secondarytext: #636363,
    tertiarytext: #666666,
    dark: #000000,
	light: #ffffff,
    textandbuttonshadow: #766a46,
    primaryaccent: #d33e01,
    secondaryaccent: #0069de,
    tertiaryaccent: #2e2e2e,
    quaternaryaccent: #35ac00,
    danger: #d60000,
    primarybackground: #ffffff,
    secondarybackground: #efefef,
	tertiarybackground: #f5f5f5,
	bodybackground: #f9f9fc,
    pagebackground: #fcfcff,
    contentbackground: #ffffff,
    inputbackground: #ffffff,
	disabledinputbackground: #e9ecef,
	bordercolor: #ced4da,
    tabbackground: #e3e4e6,
    tabbackgroundhover: #b0b0b0,
    tabbackgroundsecondary: #ffffff,
    listitembackground: #f7f7fc,
    listitemhover: #e9ecef,
	listitemborder: #00000020,
    disabled: #dfdfdf,
    ads: #ff9d00,
    link: #315bb1,
	buttonprimary: #0069f0,
	buttonprimaryactive: #e6e6e6,
	buttonexplorehover: #167b54,
	buttonsuccess: #1e7d34,
	buttonoutline: #343a40,
    borders: #dedede,
    borderslight: #dedede,
	probordercolor: #bebebe,
	buttondisabled: #76767e,
	bordersdark: #e3e3e3,
	modalbackdrop: rgba(0, 0, 0, 0.7),
	statboxshadow: #4d3f3b22,
	labelsmall: #525252,
	dropdownhover: #343a40,
	gray: #808080,
	alertdangertext: #721c24,
	cardheader: #f4f4f4,
	formborder: #ced4da,
	formbackground: #ffffff,
	formplaceholder: #000000,
	formtext: #495057,
	formlabelbackground: #e9ecef,
	btndark: #343a40,
	bluelink: #315bb1,
	filledIcon: #6c6c6c,
	cardHeaderNoTheme: #23b57c,
	cardBodyNoTheme: #d7eddc,
	landingpagesecondarybackground: #eeeeee,

	// homepage stat colors named by Ben
	cranberry: #891340,
	grape: #67034C,
	raspberry: #7A0A4D,
	apple: #A52328,
	tangerine: #BD3114,
	pomegranate: #981C33,

	// Other brands' colors
	facebook: #0076FB,
	twitter: #1FA0F0,

	togglegreen: #30D158,
);

$COLORSEARTHMATEDARKMODE: (
    explorange: #23b57c,
    explorangebackground: rgba(35, 181, 124, 0.08),
    explorangeunderline: #23b57c,
    primarytext: #ffffff,
    secondarytext: #BABABA,
    tertiarytext: #666666,
    dark: #000000,
	light: #ffffff,
    textandbuttonshadow: #766a46,
    primaryaccent: #d33e01,
    secondaryaccent: #0069de,
    tertiaryaccent: #2e2e2e,
    quaternaryaccent: #35ac00,
    danger: #ff5555,
    primarybackground: #1d1d1d,
    secondarybackground: #000000,
	tertiarybackground: #f5f5f5,
	bodybackground: #000000,
    pagebackground: #fcfcff,
    contentbackground: #050505,
	inputbackground: #1d1d1d,
	disabledinputbackground: #a0a1a7,
	bordercolor: #a6a6a6,
    tabbackground: #e3e4e6,
    tabbackgroundhover: #b0b0b0,
    tabbackgroundsecondary: #ffffff,
    listitembackground: #f7f7fc,
    listitemhover: #e9ecef,
	listitemborder: #404040,
    disabled: #dfdfdf,
    ads: #ff9d00,
    link: #315bb1,
	buttonprimary: #0069f0,
	buttonprimaryactive: #e6e6e6,
	buttonexplorehover: #167b54,
	buttonsuccess: #1e7d34,
	buttonoutline: #f8f9fa,
    borders: #dedede,
    borderslight: #dedede,
	probordercolor: #bebebe,
	buttondisabled: #76767e,
	bordersdark: #e3e3e3,
	modalbackdrop: rgba(0, 0, 0, 0.7),
	statboxshadow: #4d3f3b22,
	labelsmall: #525252,
	dropdownhover: #343a40,
	alertdangertext: #721c24,
	cardheader: #f4f4f4,
	formborder: #929292,
	formbackground: #2b2b2b,
	formplaceholder: #5a595e,
	formtext: #ffffff,
	formlabelbackground: #2b2b2b,
	btndark: #ffffff,
	bluelink: #ff825a,
	filledIcon: #a6a6a6,
	cardHeaderNoTheme: #23b57c,
	cardBodyNoTheme: #d7eddc,
	landingpagesecondarybackground: #eeeeee,

	// homepage stat colors named by Ben
	cranberry: #891340,
	grape: #67034C,
	raspberry: #7A0A4D,
	apple: #A52328,
	tangerine: #BD3114,
	pomegranate: #981C33,

	// Other brands' colors
	facebook: #0076FB,
	twitter: #1FA0F0,

	togglegreen: #30D158,
);


// Load colors maps into vanilla css vars
// Sass vars are pre-compiled, this allows dynamic control
body:not([data-theme=dark]):not([data-theme=earthmate]):not([data-theme=earthmatedark]) {
	@each $name, $value in $COLORS {
		--#{$name}: #{$value};
	}
}
body[data-theme=dark] {
	@each $name, $value in $COLORSDARKMODE {
		--#{$name}: #{$value};
	}
}
body[data-theme=earthmate] {
	@each $name, $value in $COLORSEARTHMATE {
		--#{$name}: #{$value};
	}
}
body[data-theme=earthmatedark] {
	@each $name, $value in $COLORSEARTHMATEDARKMODE {
		--#{$name}: #{$value};
	}
}


$gradient: linear-gradient(map-get($COLORS, ads) 0%, map-get($COLORS, ads) 100%);
$gradienthover: linear-gradient(map-get($COLORS, ads) 0%, map-get($COLORS, primaryaccent) 100%);
// FONTS
$FONT-default: "Sarabun";
$FONT-heading: "Sarabun"; // not used yet
$FONT-secondary: "Sarabun"; // not used yet


$WEIGHTS: (
    light: 300,
    book: 400,
    regular: 400,
    normal: 400,
    medium: 500,
    heavier: 600,
    bold: 700
);

$map-header-height-mobile: 4.5em;

$hr: map-get($COLORS, primarytext);

$default-header-height: 60px;

$default-max-width: 1350px;

// Responsive
$screen-mobile-sm: 320px !default;
$screen-mobile-md: 375px !default;
$screen-mobile-lg: 425px !default;
$screen-sm: 768px !default;
$screen-md: 1025px !default;
$screen-tread: 1101px !default;
$screen-lg: 1281px !default;
$screen-xl: 1600px !default;

// These don't work unless viewport meta tag exists
$breakpoints: (
	"mobile-sm"       : "(max-width: #{$screen-mobile-sm - 1})",
	"mobile-md"       : "(max-width: #{$screen-mobile-md - 1})",
	"mobile-lg"       : "(max-width: #{$screen-mobile-lg - 1})",
    "small"       : "(max-width: #{$screen-sm - 1})",
    "medium"      : "(max-width: #{$screen-md - 1})",
	"tread"       : "(max-width: #{$screen-tread - 1})",
    "large"       : "(max-width: #{$screen-lg - 1})",
    "huge"        : "(max-width: #{$screen-xl - 1})",
    "widest"      : "(min-width: #{$screen-xl})",
    "medium-only" : "(min-width: #{$screen-sm}) and (max-width: #{$screen-md - 1})",
    "large-only"  : "(min-width: #{$screen-md}) and (max-width: #{$screen-lg - 1})",
	"huge-only"   : "(min-width: #{$screen-lg}) and (max-width: #{$screen-xl - 1})"
) !default;
