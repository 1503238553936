.ex-page_addDevice {
	width: 100%;
	overflow-x: hidden;
}

.ex-addDevice {
	margin: auto;

	&_goBack {
		position: absolute;
		right: 1rem;
		top: 1rem;
	}

	&_promoCode-wrapper {
		text-align: left;
	}

	&_getConfirmation {
		align-self: flex-end;
	}

	&_terms {
		margin-bottom: 1rem;
	}

	#ex-addDevice_promoCodeForm {
		margin: 0;
		padding: 0;

		.promoCodeForm-header {
			margin-bottom: 0.5rem;
		}
	}

	.title {
		font-size: 30px;
	}

	.subtitle {
		margin-left: 5px;
	}

	.centerButton {
		margin: 0px auto 0px auto;
		text-align: center;
		clear: both;
	}

	.faded {
		opacity: 0.5;
	}

	.cf:before,
	.cf:after {
		content: " ";
		display: table;
	}

	.cf:after {
		clear: both;
	}

	.cf {
		*zoom: 1;
	}

	.field-validation-error {
		margin-top: 5px;
		margin-bottom: 5px;
		display: inline-block;
	}
}
