@mixin social() {
	.ex-page_social {

		#content {
			@include bp('small') {
				margin-top: 1em;
			}
		}

		#social-wrapper {
			margin-left: 20px !important;
		}

		.row {
			background-color: white;
			margin-top: 1.5rem;
			margin-left: 8px;
			margin-right: 8px;
			padding: 1.5rem;
			flex-direction: row;

			@include bp('small') {
				margin: 1em 0;
			}
		}

		.lowerBar {
			border-bottom: 1px solid #dedede;
			width: 100%;
			margin-bottom: 1rem;
			padding-bottom: 1rem;
		}

		.upperBar {
			border-top: 1px solid #dedede;
			width: 100%;
			margin-top: 1rem;
			padding-top: 1rem;
		}

		.leftBar {
			float: left;
			border-left: 1px solid #d1d2d6;
			height: 141px;
			margin-top: 10px;
		}

		#mapshare-configure-options-button {
			margin-bottom: 7px;
		}

		#mapshare-configure-options-button-disabled {
			margin-bottom: 7px;
		}

		#mapshare-data-filter-button {
			margin-bottom: 7px;
		}

		#mapshare-data-filter-button-disabled {
			margin-bottom: 7px;
		}

		#mapShare {
			&Url {
				margin-top: 5px;
				margin-right: 5px;
				margin-bottom: 35px;
			}

			&EditIcon {
				margin-top: 5px;
				margin-right: 5px;
				margin-bottom: 5px;
				color: var(--explorange)
			}
		}

		#optionsBox {
			min-height: 115px;
			margin-top: 27px;
		}

		#btn-social-mapshare-toggle {
			&.toggle {
				width: 3rem;
				height: 1.875rem;
				background-color: var(--gray);
				border-radius: 1rem;
				display: flex;
				align-items: center;
				cursor: pointer;

				.toggle-switch {
					width: calc(1.875rem - 6px);
					height: calc(1.875rem - 6px);
					border-radius: 50%;
					background-color: white;
					margin-left: 3px;
					transition: margin-left ease-in 0.15s;
				}

				&.toggled-on {
					background-color: var(--togglegreen);

					.toggle-switch {
						margin-left: calc(1.125rem + 3px);
					}
				}

				&.toggled-disabled {
					background-color: black;
					pointer-events: none;
					cursor: auto;
				}
			}
		}

		#shareBox {
			min-height: 115px;
			margin-top: 10px;
		}

		#topRow {
			display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
			display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
			display: -ms-flexbox; /* TWEENER - IE 10 */
			display: -webkit-flex; /* NEW - Chrome */
			display: flex;
			justify-content: flex-start;
			align-items: stretch;
		}

		.map-share-consent {
			&-container {
				padding-top: 10px;

				input[type=checkbox] {
					margin: 0px 5px 0px 0px;
				}
			}

			&-text {
				line-height: 1.1em;
			}
		}

		#linkBox {
			width: 610px;
			float: left;
		}

		.checkbox {
			float: left;
			padding-left: 28px;
		}

		#firstCheckbox {
			margin-left: -9px;
		}

		#mapShareImage {
			margin: 2em auto;
			position: relative;
			bottom: 0;
			left: 0;
			width: 99.1%;
			height: 158px;
			background-image: url("/Content/images/MapShareImage.png");
			background-repeat: no-repeat;
			background-size: cover;
			overflow: hidden;
		}

		.linkBtn {
			margin-top: 7px;
			margin-right: 8px;
			margin-left: 2px;
		}

		.rowImg {
			margin-bottom: 5px;
		}

		.linkedUserList {
			margin-top: 8px;
			margin-left: auto;
			margin-right: auto;
			max-height: 500px;
			overflow-y: auto;
		}

		.linkedUserList a {
			text-decoration: underline;
			@include small-body-text();
			cursor: pointer;
		}

		#addLinkDialog {
			text-align: left;
			width: 320px;
		}

		#manageLinksDialog {
			text-align: left;
			width: 315px;
		}


		.fb-like {
			margin-top: 3px;
		}

		#btn-send-twit {
			margin-top: 3px;
		}

		#addLinkDialog .boldText {
			margin-top: 10px;
			display: block;
			font-weight: bold;
		}

		.sub-toolbar {
			width: 950px;
			margin-left: -20px;
			padding-left: 20px;
			margin-top: -18px;
		}

		.msOptionsSection + .msOptionsSection {
			margin-top: 15px;
		}

		.msOptionsSection label {
			margin-bottom: 0px;
		}

		.mapShareDataLabel {
			margin-top: 1em;
		}

		.msTitle {
			font-weight: bold;
			@include secondary-body-text();
			text-align: left;
			margin-bottom: 5px;
		}

		.msTip {
			font-style: italic;
		}

		#msNameInput {
			margin-top: 3px;
			margin-bottom: 5px;
		}

		#msDescriptionInput {
			width: 98%;
			margin-bottom: -3px;
			padding-bottom: 0px;
		}

		#msPasswordInputContainer {
			margin-top: 3px;
		}

		#msPasswordInputContainer input {
			width: 190px;
			margin-bottom: 0px;
		}

		#msPasswordError {
			color: red;
			padding-bottom: 0px;
		}

		#msHideBeforeDateInputContainer {
			margin-top: 3px;
			position: relative;
		}

		#msHideBeforeDateInputContainer input {
			margin-bottom: 0px;
		}

		#msViewControlsContainer div {
			margin-bottom: 5px;
		}

		#msSendMessages, #msLocate, #primaryCollectionLabel {
			margin-right: 5px;
		}

		#primaryCollectionSelect {
			margin-bottom: 3px;
		}

		#btnFeeds {
			margin-left: 10px;
			@include small-body-text();
			cursor: pointer;
			color: #315bb1;
			margin-top: 5px;
			margin-right: 5px;
			margin-bottom: 35px;
		}

		.social-media-subheader {
			@include subheader-text();
		}

		.social-media-expirtaion {
			@include small-body-text();
			padding-top: 10px;
		}

		.modal-body {
			& h6 {
				font-weight: bold;
			}

			& a {
				word-wrap: break-word;
			}
		}

		.ex-social {
			&_mainContent {
				border-right: 1px solid map-get($COLORS, borders);

				@include bp('small') {
					width: 100%;
					max-width: 100%;
					flex-basis: 100%;
					padding: 0 1em;
				}
			}

			&_sideContent {

				@include bp('small') {
					width: 100%;
					max-width: 100%;
					flex-basis: 100%;
					padding: 0 1em;
				}
			}

			&_divider {
				margin-left: 1.25rem;
				margin-right: 1.25rem;

				@include bp('small') {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}

		#mapShareOptions {
			.msSelectableCheckbox {
				cursor: pointer;
			}
		}

		.globalMapShare {
			margin-bottom: 1em;
			margin-top: -1em;
		}

		#mapShareVisibility {
			margin-bottom: 1em;
		}

		.mapshare {
			&-share-menu {
				display: flex;
				flex-direction: column;
				border: 1px solid var(--borders);
				position: absolute;
				background-color: var(--contentbackground);
			}
		}

		.livetrack {

			&-highlighted-row {
				padding: 1rem 0;
				margin: 1rem 0;
				border-top: 1px solid var(--borders);
				border-bottom: 1px solid var(--borders);
			}

			&-privacy {
				&-container {
					flex-basis: 50%;

					&:not(&:first-child) {
						margin-left: 2rem;
					}
				}

				&-details {
					@include small-body-text();
				}
			}

			&-edit-url-button {
				color: var(--explorange);
				cursor: pointer;
			}

			&-share-menu {
				display: flex;
				flex-direction: column;
				border: 1px solid var(--borders);
				position: absolute;
				background-color: var(--contentbackground);
			}

			&-privacy_notification {
				position: fixed;
				top: 45px;
				left: 0;
				right: 0;
				margin: 0 auto;
				z-index: -1;
				width: max-content;
				text-align: left;
				transition: opacity 0.3s ease-in-out, z-index 0.3s ease-in-out;

				&-open {
					z-index: 10010 !important;
					opacity: 1 !important;
				}
			}

			&-url-edit-linktext {
				overflow-wrap: break-word
			}
		}
	}
}
