.ex-logonBanner {
	background: map-get($COLORS, tertiarybackground);
	position: absolute;
	top: 3.5rem;
	left: 0;
	right: 0;

	@include bp('small') {
		position: relative;
		top: 0;
	}

	&_content {
		padding: 1em;
		max-width: 40rem;
		margin: auto;
		text-align: center;
		background: map-get($COLORS, tertiarybackground); // IE hack
	}

	&_title {
		color: map-get($COLORS, explorange);
		font-size: 1.5rem;
		font-weight: bold;
		line-height: 1.25;
	}

	&_message {
		color: map-get($COLORS, primarytext);
		font-size: 1rem;
		line-height: 1.125;
	}

	&_active {
		.innerLogOnContainer {
			width: 100% !important;
			max-width: 33em !important;
		}

		#LogOnFlexContainerRight {
			padding: 25px 0 !important;
		}

		#LogOnFlexContainerLeft {
			padding: 25px 0 !important;
		}
	}
}
