.ex-homepageStats {
	margin-bottom: 2em;
	padding: 0.5em;
	order: 1;

	@include bp('small') {
		order: 5;
	}

	&_title {
		font-size: 1.5rem;

		@include bp('small') {
			font-size: 1.25rem;
		}
	}

	&_slider {

		&-wrapper {
			.ex-homepageStats_loading & {
				display: flex;
				justify-content: space-around;
			}

			@include bp('(min-width: 1192px)') {
				flex-wrap: wrap;
			}
		}
	}
}
