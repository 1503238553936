﻿@mixin logonPartial() {

	#logindisplay,
	.ex-logonPartial {
		.ex-page_mapsharePassword & {
			@include bp('small') {
				left: 0;
				right: auto;
				top: 4em;
				width: 100%;
				font-size: 10px;
			}
		}

		#sign-in-text {
			pointer-events: all !important;
			display: none;
			position: absolute;
			right: calc(100vw - 5rem);
			top: 0;
			padding: 1em;
			align-self: center;
			font-size: 1rem;
			white-space: nowrap;
			color: var(--buttonprimary) !important;

			@include bp('medium') {
				display: block;
				padding: 1em;
				margin-right: -1rem;
			}
		}

		&_container {
			pointer-events: none;

			#profile-picture, #header-menu, #ex-languagePicker {
				pointer-events: all;
			}

			#preferences-menu {
				pointer-events: all !important;
				cursor: pointer;
				padding: 15px 15px 20px 15px !important;
			}

			#sign-in-button-wrapper {
				margin: 0 1em 0 1em;
				display: block;
				align-self: center;
				padding-bottom: 5px;

				@include bp('medium') {
					display: none;
				}
			}

			#sign-in-button {
				color: var(--light);
				white-space: nowrap;
				align-self: center;
				width: 8rem;
				font-size: .875rem;
				pointer-events: all !important;
			}

			.status-page-link:not(.invisible) {
				pointer-events: all;
			}

			.ex-page_mapsharePassword & {
				@include bp('small') {
					position: relative;
					right: auto;
					left: 0;
				}
			}
		}

		&_row,
		#logon-icon-row {
			.ex-page_mapsharePassword & {
				@include bp('small') {
					justify-content: center !important;
				}
			}
		}

		#lone-lang-picker-container {
			.ex-page_mapsharePassword & {
				@include bp('small') {
					justify-content: flex-end;
				}
			}
		}
	}
}
