﻿@mixin sideNav() {

    #waypoints {
        display: none;
        position: relative !important;
    }

    #routes {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    }

    .waypoints-header {
        height: 60px;
    }

    .hiddenEye {
        float: left;
        width: 20px;
        cursor: pointer;
    }

    .listButton {
        float: right;
    }

    .toggleSync {
        width: 30px;
        margin-right: 4px;
        padding-top: 5px;
        text-align: center;
    }

    .deleteButton {
        width: 15px;
        padding-top: 4px;
        margin-top: 2px;
    }

    .warningButton {
        width: 15px;
        padding-top: 7px;
    }

    .mapShare {
        width: 15px;
        padding-top: 4px;
        margin-top: 3px;
        margin-right: 3px;
        margin-left: 9px;
    }

    .navigating {
        width: 15px;
        padding-top: 7px;
        margin-right: 2px;
    }

    .backgroundDiv {
        float: left;
        width: 20px;
        margin-top: 5px;
    }

    .backgroundColor {
        width: 10px;
        height: 10px;
        display: inline-block;
        border: 1px solid gray;
        margin-left: 10px;
        margin-right: 10px;
    }

    .waypointLabel {
        width: 270px;
        margin-left: 30px;
    }

    .routeLabel {
        width: 270px;
        margin-left: 30px;
    }

    .labelName {
        font-weight: bold;
        padding-left: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 170px;
    }

    .labelName.route {
        margin-left: 18px;
    }

    .updateText {
        padding-top: 9px;
    }

    .routeUpdated {
        padding-top: 2px;
        position: absolute;
        top: 22px;
        width: 220px;
    }

    #filterContainer {
        margin: 0 5px 0 7px;
        padding-top: 4px;
    }

    .icon {
        float: left;
        height: 33px;
        width: 27px;
        cursor: pointer;
    }

    .toggleVisible {
        float: left;
        width: 20px;
        padding: 2px;
    }

    .listIcon {
        width: 24px;
        height: 24px;
        margin-right: 2px;
        float: left;
    }
}
