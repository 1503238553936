.ex-planInfo, .ex-planTypeInfo {
	text-align: left;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	&_hasImage {
		padding-right: 16rem;
	}

	&_section {
		display: flex;
		flex-wrap: wrap;
		flex-basis: 100%;
		margin-bottom: 1rem;

		&-full {
			padding-top: 1rem;
		}

		.ex-button {
			font-size: 1.25rem;
		}
	}

	&_container {
		margin: auto;
		height: auto;
	}

	&_title {
		font-size: 1.5rem;
		font-weight: bold;
		flex-basis: 100%;

		&-secondary {
			font-weight: normal;
		}
	}

	&_subtitle {
		font-size: 1.25rem;
		color: map-get($COLORS, secondarytext);
		width: 100%;

		&-secondary {
			font-size: 1.125rem;
		}

		&-tertiary {
			font-weight: bold;
			font-size: 1.25rem;
		}
	}

	&_description {
		width: 100%;
	}

	&_img {
		width: 100%;
		max-width: 15rem;
		border-radius: 1rem;

		&Wrapper {
			position: absolute;
			right: 1rem;
			top: 1rem;
			width: 15rem;
			max-width: 100%;

			&-static {
				position: static;
				width: calc(100% - 2rem);
				margin: auto;

				img {
					max-width: none;
				}
			}
		}
	}

	.recurringFee {
		font-size: 0.875rem;
	}

	.annualOrOneTimeFee {
		text-align: center;
	}

	.detailsLnk {
		text-align: center;
		padding-top: 2px;
		margin-top: auto;
		color: map-get($COLORS, explorange);
	}

	.detailstable {
		background-color: inherit;
		border: none;
		text-align: left;
		width: 100%;

		tr {
			border-bottom: 1px solid map-get($COLORS, borders);
		}

		tr:last-child {
			border: none;
		}
	}

	.noPlanBox {
		border-left: 1px solid #808080;
		border-bottom: 1px solid #808080;
		border-right: 1px solid #808080;
		display: table;
		overflow: hidden;
		width: 100%;
	}

	.suspendPlanFirstColumn {
		display: table-cell;
		vertical-align: middle;
		padding-left: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
		width: 18%;
	}

	.suspendPlanRemainingColumn {
		display: table-cell;
		vertical-align: middle;
		padding-left: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
	}


	.metaBottom {
		clear: both;
		text-align: center;
		border-top: 1px solid black;
		margin-left: 25%;
		margin-right: 25%;
		padding-bottom: 10px;
	}

	.plansTop {
		text-align: center;
		padding-bottom: 5px;
	}

	&_button {
		max-width: 16rem;
		min-width: 10rem;
		width: 80%;

		&.btn:disabled {
			background-color: lighten(map-get($COLORS, explorange), 20%) !important;
			border-color: lighten(map-get($COLORS, explorange), 20%) !important;
		}
	}

	.ex-price {
		margin-top: 1em;
		margin-bottom: 1em;
	}

	&_list {
		margin-top: 1em;

		tr:first-child {
			display: none;
		}

		li {
			&::marker {
				color: #d33e01;
			}
		}

		&-item {
			display: flex;

			&_icon {
				position: absolute;
				left: 0.25rem;
				top: 0;

				svg {
				}
			}

			&_copy {
				font-size: 1.125rem;
				padding-left: 2rem;

				&-secondary {
					color: map-get($COLORS, secondarytext);
				}
			}
		}
	}

	&_callout {
		display: flex;
		width: 100%;

		&-icon {
			svg {
				height: 2.675em;
				width: 2.675em;
			}
		}

		&-copy {
			display: flex;
			flex-wrap: wrap;
			line-height: 1.25;

			&_secondary {
				flex-basis: auto;
			}
		}

		&-value {
			font-size: 2rem;
			font-weight: bold;
			color: map-get($COLORS, primarytext);
			flex-basis: 100%;
			line-height: 1;
		}

		&-key {
			font-size: 1.5rem;
			font-weight: bold;
			color: map-get($COLORS, primarytext);
			flex-basis: 100%;
		}

		&-subkey {
			font-size: 1rem;
			color: map-get($COLORS, secondarytext);
			flex-basis: 100%;
		}
	}
}
