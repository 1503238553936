﻿.ex-ipc {
	text-align: left;
	padding: 0.5em;

	.border-bottom-sections {
		border-bottom: 2px solid #c3c3c3 !important;
		padding-bottom: 5px;
	}

	#OutboundSettings {
		table {
			input, button {
				width: 100%;
			}
		}
	}

	.warning-message {
		display: grid;
		grid-template-columns: 1em 1fr;
		column-gap: 0.4em;
		align-items: center;

		img {
			width: 1em;
		}
	}

	.opaque {
		opacity: .5;
		pointer-events: none;
	}

	.greenText {
		color: green;
	}

	.redText {
		color: red;
	}

	.slim {
		max-width: 200px;
		word-wrap: break-word;
	}

	.wide-content {
		border-top: 1px solid #dee2e6;
	}

	&_toggle {
		display: inline-block;
		vertical-align: text-top;
		margin-left: 2em;
	}

	&_settings {
		&-description {
			font-size: 14px;
			padding-bottom: 4px;
		}

		&-heading {
			display: inline-block;
			@include subheader-text();
			line-height: 27px;
			font-weight: bold;
		}

		&_container {
			padding: 0.5em;
		}
	}

	#ipc-title {
		@include title-text();
		font-weight: normal;
		vertical-align: middle;
		margin-right: 10px;
	}
}

.no-wide-content {
	margin-top: 16px;
	width: max-content;
}

.ex-sos {
	h2 {
		font-size: 1.875rem;
	}

	h3 {
		font-size: 1.25rem;
	}

	textarea {
		height: 10em;
	}

	.ex-light {
		color: var(--buttondisabled);
	}

	.text-truncate {
		white-space: break-spaces;
	}

	.ex-test-image {
		width: 1.2em;
		margin-right: 0.4em;
	}

	.ex-question-grid {
		display: grid;
		grid-template-columns: 0.8fr 1.5fr 0.8fr 1.5fr;
		grid-row-gap: 1em;
		grid-column-gap: 1em;
	}

	.ex-email-grid {
		display: grid;
		grid-template-columns: 1fr 1fr 5em;
		grid-column-gap: 1em;
	}

	.ex-test-message-grid {
		display: grid;
		grid-template-columns: 1fr auto;
	}

	.is-valid {
		padding-right: calc(1.5em + .75rem) !important;
		background-image: url(/Content/images/Check-SVG.svg);
		background-repeat: no-repeat;
		background-position: right 0.45em;
		background-size: 1.5em 1.5em;
	}
}

.ex-sar {
	.bg-active {
		background-color: #fbefed !important;
	}

	div.p-3 p {
		min-height: 3em;
	}

	.auto-renew-grid {
		display: grid;
		grid-template-columns: 1fr 2.3em;

		.toggle {
			width: 100%;
			height: 1.2em;
			bottom: -0.4em;
		}

		.toggle-switch-slider {
			&::before {
				height: 0.95em;
				width: 0.95em;
			}

			&.checked {
				&::before {
					transform: translateX(1.1em);
				}
			}
		}
	}

	.plan-description {
		display: grid;
		grid-template-columns: 1.2fr 1fr;

		img {
			border-top-right-radius: 0.5em !important;
			border-bottom-right-radius: 0.5em !important;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		@include bp('small') {
			grid-template-columns: 1fr;

			img {
				padding: 1rem;
				max-height: 25em;
				width: 100%;
				object-fit: cover;
				object-position: bottom;
			}
		}
	}

	.list-padding {
		padding-left: 1.25rem !important;
	}

	.plans {
		display: grid;
		grid-auto-columns: minmax(0, 1fr);
		grid-auto-flow: column;
		grid-column-gap: 1em;

		@include bp('small') {
			grid-template-columns: 1fr;
			grid-row-gap: 1em;
			grid-auto-flow: row;
		}
	}

	/* Individual SAR Card */
	.plan {
		/* Align the content within each SAR card */
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}
	/* SAR Plan Title */
	.plan h3 {
		font-size: max(1.7cqw, 1rem);

		@include bp('small') {
			font-size: 1.75rem;
		}
	}
	/* SAR Plan Price */
	.plan h5 {
		font-size: max(1.2cqw, .76rem);

		@include bp('small') {
			font-size: 1.25rem;
		}
	}
	/* SAR Plan description */
	.plan p {
		font-size: min(16px, 1.8cqw);

		@include bp('small') {
			font-size: 16px
		}
	}

	.center-mobile {
		@include bp('small') {
			text-align: center;
		}
	}

	.details {
		display: grid;
		grid-template-columns: 4em 1fr;

		h4 {
			line-height: 35px;
		}
	}

	.details-error {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-column-gap: 1em;
	}

	.rounded {
		border-radius: 0.5em !important;
	}

	.modal-dialog {
		max-width: 620px;
		margin-top: 5em;

		.modal-footer {
			border: none;
		}

		.modal-header {
			border: none;
		}
	}

	button {
		.spinner-border {
			margin-bottom: 0.1em;
		}
	}
}

.ex-ipc {
	h2 {
		font-size: 1.875rem;
	}

	h3 {
		font-size: 1.25rem;
	}

	.toggle {
		width: 1.5em;
		height: 0.75em;
		bottom: -0.3em;
	}

	.ex-question-grid {
		display: grid;
		grid-template-columns: 1fr 1.2fr 1fr 1.2fr;
		grid-row-gap: 1em;
		grid-column-gap: 1em;

		@media (max-width: 940px) {
			grid-template-columns: 1fr 1.6fr;
		}
	}

	.toggle-switch-slider {
		&::before {
			height: 0.55em;
			width: 0.55em;
			bottom: 0.1em;
			left: 0.1em;
		}

		&.checked {
			background-color: #2b6709;

			&::before {
				transform: translateX(0.75em);
			}
		}
	}

	.notification-emails {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 1em;
		grid-row-gap: 1em;

		@media (max-width: 590px) {
			grid-template-columns: 1fr;
		}
	}
}

.ex-billing {
	#paymentFrameContainer {
		grid-column-end: span 4;
		display: flex;
	}

	.no-card {
		grid-column-end: span 2;
	}

	@media (max-width: 940px) {
		.ex-question-grid {
			grid-template-columns: 1.2fr 1.5fr !important;
		}
	}

	.user-color {
		border-radius: 4px;
		width: 50%;
		height: 1em;
		display: inline-block;
		margin-bottom: -0.2em;
	}
}

.sync-nav, .settings-nav, .groups-and-users-nav {
	margin-left: auto !important;
	margin-right: auto !important;
	width: fit-content !important;

	@media (max-width: 767px) {
		display: contents;
		column-gap: 1em;
	}
}

.ex-nav_secondary {
	margin: 0 !important;
}

.ex-tt {

	.toggle-switch-slider {

		&.checked {
			background-color: #D33E02 !important;
		}
	}

	.tt-info-panel {
		list-style: none;
		padding: 0;
		margin: 13px;

		li {
			position: relative;
		}

		li::before {
			content: "\0025CF";
			margin-right: 0.5em;
			color: #D33E02;
			position: absolute;
			left: -15px;
		}
	}

	.underlined-orange {
		text-decoration: underline;
		color: #D33E02
	}

	@media (max-width: 950px) {
		.column-orientation {
			display: flex;
			flex-direction: column !important;
		}
	}
}

.ex-sos, .ex-sar, .ex-ipc, .ex-preferences, .ex-billing, .ex-tt, .ex-users, .ex-new-group {
	max-width: $default-max-width;
	width: 80% !important;

	@media (max-width: 950px) {
		width: 100% !important;
	}

	input[type=color] {
		padding: 0.2em 0.5em;
	}

	.btn-large {
		width: 10em;
	}

	.password-label {
		font-size: 2em;
		height: 1em;
		line-height: 1em;
	}

	.info-box-img {
		vertical-align: text-bottom;
	}

	.btn-outline-danger, .text-danger {
		color: #d33e01 !important;
	}

	.c-pointer {
		cursor: pointer;
	}

	.c-default {
		cursor: default;
	}

	.w-fit {
		width: fit-content !important;
	}

	.ex-question-grid {
		display: grid;
		grid-template-columns: 0.8fr 1.5fr 0.8fr 1.5fr;
		row-gap: 1em;
		column-gap: 1em;
	}

	a.text-danger:hover {
		color: #d33e01 !important;
	}

	.border-danger {
		border-color: #d33e01 !important;
	}

	.btn-danger {
		background-color: #d33e01 !important;
		border-color: #d33e01 !important;
	}

	.btn-outline-danger:hover {
		background-color: #d33e01 !important;
		border-color: #d33e01 !important;
	}

	input[type="checkbox"] {
		accent-color: #d33e01;
		appearance: none;
		border: 1px solid #d33e01;
		height: 1em;
		width: 1em;
		border-radius: 0.2em;
		margin-top: 0.3em !important;
		vertical-align: top;

		&:disabled, &:checked {
			appearance: checkbox;
		}
	}

	button.btn-outline-danger.active {
		color: #fff !important;
		background-color: #d33e01 !important;
		border-color: #d33e01 !important;
		box-shadow: none !important;
	}

	.toggle {
		width: 1.5em;
		height: 0.75em;
		bottom: -0.3em;
	}

	.toggle-switch-slider {
		&::before {
			height: 0.55em;
			width: 0.55em;
			bottom: 0.1em;
			left: 0.1em;
		}

		&.checked {
			background-color: #2b6709;

			&::before {
				transform: translateX(0.75em);
			}
		}
	}

	.spinner-border {
		width: 1em;
		height: 1em;
	}

	.small-img {
		width: 1em;
		height: 1em;
	}

	.spinner-border-large {
		width: 2em !important;
		height: 2em !important;
	}

	.span-2d {
		grid-column-end: span 2;

		@media (max-width: 940px) {
			grid-column-end: span 1;
		}
	}

	.col-2m {
		@media (max-width: 940px) {
			grid-column-start: 2;
		}
	}

	.d-none-m {
		@media (max-width: 940px) {
			display: none;
		}
	}

	.w-fit {
		width: 14em !important;

		@media (max-width: 940px) {
			width: 25em;
		}

		@media (max-width: 747px) {
			width: 20em;
		}

		@media (max-width: 590px) {
			width: 14em;
		}

		@media (max-width: 440px) {
			width: 10em;
		}
	}
}

.ex-preferences {
	.w-fit {
		width: fit-content !important;
	}

	@media (max-width: 940px) {
		.ex-question-grid {
			grid-template-columns: 1.2fr 1.5fr 0.8fr 0.8fr !important;
		}
	}

	@media (max-width: 640px) {
		.ex-question-grid {
			grid-template-columns: 1.2fr 1.5fr 0.2fr 0.2fr !important;
		}
	}
}

.ex-users {
	.column-gap-1 {
		column-gap: 1em;
	}

	.column-gap-04 {
		column-gap: 0.4em;
	}

	h2 {
		font-size: 1.875rem;
	}

	h3 {
		font-size: 1.25rem;
	}

	.ex-light {
		color: var(--buttondisabled);
	}

	.text-truncate {
		white-space: break-spaces;
	}

	.ex-test-image {
		width: 1.2em;
		margin-right: 0.4em;
	}

	.ex-question-grid {
		display: grid;
		grid-template-columns: 0.8fr 1.5fr 0.8fr 1.5fr;
		grid-row-gap: 1em;
		grid-column-gap: 1em;
	}

	.ex-email-grid {
		display: grid;
		grid-template-columns: 1fr 1fr 5em;
		grid-column-gap: 1em;
	}

	.ex-test-message-grid {
		display: grid;
		grid-template-columns: 1fr auto;
	}

	.is-valid {
		padding-right: calc(1.5em + .75rem) !important;
		background-image: url(/Content/images/Check-SVG.svg);
		background-repeat: no-repeat;
		background-position: right 0.45em;
		background-size: 1.5em 1.5em;
	}

	.modal-dialog {
		max-width: 620px;

		.modal-content {
			padding: 24px;
		}

		.modal-footer {
			border: none;
			padding: 0px;
		}

		.modal-header {
			border: none;
			padding-top: 0px;
			padding-bottom: 24px;
			padding-left: 0px;
			padding-right: 0px;
		}

		.modal-body {
			padding-top: 0px;
			padding-bottom: 24px;
			padding-left: 0px;
			padding-right: 0px;
		}
	}

	.destructive {
		.btn-danger {
			background-color: #D90A1B !important;
			border-color: #D90A1B !important;
		}
	}

	.search-bar {
		position: relative;
	}

	.search-bar-input {
		width: 50%;
		padding-left: 3.38rem;
		padding-bottom: 16px;
		border: transparent;
		border-radius: 0px;
		border-bottom: 1px solid #6C6C6C;
		background: url('/Content/images/leading-icon.svg') no-repeat left center;
		background-size: 1.5rem 1.5rem;
		background-position: 0.88rem 2px;

		@media(max-width: 950px) {
			width: 100%;
		}
	}

	.search-bar-button {
		width: 50%;
		display: flex;
		flex-direction: row;
	}

	input[type="submit"] {
		margin-left: -50px;
		height: 25px;
		width: 50px;
		background: blue;
		color: white;
		border: 0;
		-webkit-appearance: none;
	}

	.filter-buttons {
		align-self: flex-end;
	}

	.checkboxCol {
		width: 0.5rem;
	}

	.contentCol {
		width: 16.5%;
	}

	.dropdown-panel {
		position: absolute;
		left: 0em;
		width: max-content;
		min-width: 140px;
		background: #fff;
		z-index: 1;
		margin-top: 0.5rem;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			max-height: 300px;
			overflow-x: hidden;
			overflow-y: auto;
		}

		li:hover {
			background: #FCECE6;
		}
	}

	.original-list {
		font-size: 12px;

		ul {
			list-style-type: disc;
			display: block;
			list-style-type: disc;
			margin-block-start: 1em;
			margin-block-end: 1em;
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			padding-inline-start: 40px;
			margin-top: 0px;
		}

		li:hover {
			background: none;
		}
	}

	.add-users-dropdown {
		margin-right: 9.2rem;
	}

	.import-users-dropdown {
		margin-right: auto;
	}

	.group-filter-dropdown {
		margin-right: 13.3rem
	}

	.role-filter-dropdown {
		margin-right: 7.3rem
	}

	summary {
		list-style: none
	}

	summary::-webkit-details-marker {
		display: none;
	}

	details summary::before {
		content: url('/Content/images/navigate_next.svg');
	}

	details[open] summary::before {
		content: url('/Content/images/open_expander.svg');
	}

	details[open] .users-table-grid {
		border-top-color: #D33E01;
		background-color: rgb(255, 230, 216);
	}

	.users-table-grid {
		border-top: 0.5px solid;
		border-top-color: #AAAAAA;
		display: grid;
		grid-template-columns: 2em 2em repeat(6, 1fr) 2em;
		grid-row-gap: 0.5em;
		grid-column-gap: 0.5em;
		padding-top: 14px;
		padding-bottom: 14px;
	}

	.spinner-separator {
		border-top: 0.5px solid;
		border-top-color: #AAAAAA;
	}

	div.mobile-only {
		display: none;
	}

	@media(max-width: 950px) {

		div.mobile-only {
			display: grid;
			grid-template-columns: 0.8fr 1fr;
			width: auto;
			grid-column-gap: 0.5em;
			padding-bottom: 1em;
		}
	}

	.assigned-devices-grid {
		/*justify-content: center;*/
		padding-right: 1em;
		display: grid;
		grid-template-columns: 32px 32px 1fr;
		grid-column-gap: 0.5em;
		grid-row-gap: 0.5em;
		font-size: 14px;
		background: #FCECE666;
		padding-top: 14px;
		padding-bottom: 16px;
	}

	.devices-spinner {
		background: #FCECE666;
		padding-top: 14px;
		padding-bottom: 16px;
	}

	.devices-list-grid {
		padding-right: 1em;
		display: grid;
		grid-template-columns: 32px 32px 1fr 1fr 2fr 2fr 2em;
		grid-column-gap: 0.5em;
		padding-bottom: 0.5em;
		font-size: 14px;
		background: #FCECE666;
	}

	.filter-pill {
		background-color: #F6C6B6B2;
		padding: 6px 12px;
		text-align: center;
		display: inline-block;
		border-radius: 16px;
		font-size: 14px;
		margin-bottom: 6px;
	}

	.filter-count {
		background-color: #D33E02;
		text-align: center;
		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		padding: 3px 6px;
		display: flex;
		border-radius: 16px;
		height: 15px;
		line-height: 8px;
		width: 26.14px;
	}

	.group-selector {
		background: url('/Content/images/open_expander.svg') no-repeat right 10px center;
	}

	.btn.filter-button:disabled {
		background-color: transparent !important;
		border-color: transparent !important;
		color: #212529 !important;
	}

	.span-3d {
		grid-column-end: span 3;

		@media (max-width: 940px) {
			grid-column-end: span 1;
		}
	}

	input[type="checkbox"] {
		accent-color: #d33e01;
		appearance: none;
		border: 1px solid #d33e01;
		height: 1em;
		width: 1em;
		border-radius: 0.2em;
		margin-top: 0.3em !important;
		vertical-align: top;

		&:disabled, &:checked {
			appearance: checkbox;
		}

		&.card-checkbox {
			width: 1.5em !important;
			height: 1.5em !important;
			min-width: 1.5em !important;
			min-height: 1.5em !important;
			border-radius: 50%;
			margin-top: 0em !important;

			&:checked {
				appearance: none;
				background-image: url('../Content/images/checkbox-enabled.svg');
				background-position: inherit;
				background-repeat: no-repeat;
				background-size: cover;
			}

			&:disabled {
				appearance: none;
				background-color: #e9e9e9;
				border: 1px solid #ccc;
				cursor: not-allowed;
				opacity: 0.6;
			}
		}
	}

	.user-img {
		margin-top: 1px;
		height: 1em;
	}

	.vertical {
		width: 10px;
	}

	.card-color {
		border-left: 7px solid gray;
	}
}

.ex-ipc-inbound-authorization {
	.ex-inbound-authorization-grid {
		display: grid;
		grid-template-columns: 0.8fr 1.5fr 0.8fr 1.5fr;
		grid-row-gap: 1em;
		grid-column-gap: 1em;

		@media (max-width: 950px) {
			grid-template-columns: 1fr 1.6fr;
		}



		.ex-inbound-api-keys-grid {
			display: grid;

			.ex-inbound-api-keys-row {
				@media (max-width: 950px) {
					grid-template-columns: 0.25fr 0.75fr;
				}

				display: grid;
				grid-template-columns: 0.3fr 0.20fr 0.46fr 0.04fr;
				grid-row-gap: 1em;
				grid-column-gap: 1em;
				border-top: 1px solid #dee2e6 !important;
			}

			.ex-inbound-api-keys-header {
				display: grid;
				grid-template-columns: 0.3fr 0.20fr 0.46fr 0.04fr;

				@media (max-width: 950px) {
					grid-template-columns: 1fr;
				}
			}
		}
	}

	.span-2-mobile {
		grid-column-end: span 1;

		@media (max-width: 950px) {
			grid-column-end: span 2;
		}
	}

	.span-2-desktop {
		grid-column-end: span 2;

		@media (max-width: 950px) {
			grid-column-end: span 1;
		}
	}

	.span-3-desktop {
		grid-column-end: span 3;

		@media (max-width: 950px) {
			grid-column-end: span 1;
		}
	}

	.btn.img-button:disabled {
		background-color: transparent !important;
		border-color: transparent !important;
		color: #212529 !important;
		opacity: 0.65 !important
	}

	.span-2 {
		grid-column-end: span 2;
	}

	.span-row {
		grid-column-end: span 4;

		@media (max-width: 950px) {
			grid-column-end: span 2;
		}
	}

	.medium-icon {
		width: 1.6em;
		height: 1.6em;
	}

	.medium-icon.button-icon {
		cursor: pointer;
	}

	.mobile-only {

		@media (min-width: 950px) {
			display: none !important;
		}
	}

	.desktop-only {
		@media (max-width: 950px) {
			display: none !important;
		}
	}
}

.ex-ipc-inbound-logs {
	.ex-inbound-logs-controls {
		display: grid;
		grid-template-columns: 0.8fr 1.5fr 0.8fr 1.5fr;
		grid-row-gap: 1em;
		grid-column-gap: 1em;

		@media (max-width: 950px) {
			grid-template-columns: 1fr 1.6fr;
		}
	}

	.table-icon {
		height: 1.5em;
		width: 1.5em;
	}

	.mobile-only {

		@media (min-width: 950px) {
			display: none;
		}
	}
}

.ex-new-group-header {
	border-bottom: 1px solid #DEDEDE;

	.stepper-container {
		list-style: none;
	}

	.step {
		display: grid;
		grid-template-columns: 1fr 30px 1fr;
		align-items: center;
	}

	.step-index {
		width: 30px;
		height: 30px;
		line-height: 28px;
		border: 1px solid #D33E01;
		display: block;
		text-align: center;
		margin: 0 auto 1px auto;
		border-radius: 50%;
		background-color: #fff;
		color: #D33E01;
	}

	.step-index.current-step {
		background-color: #D33E01;
		border-color: #D33E01;
		color: #fff;
	}

	.line {
		width: 100%;
		height: 1px;
		background-color: #D33E01;
	}

	.step-label.current-step {
		color: #D33E01;
		font-weight: 600 !important;
	}

	@media (min-width: 950px) and (max-width: 1450px) {
		.logo {
			&.ex-header_logo {
				display: none
			}

			&.ex-header_logo_small {
				display: block;
				top: 10px;
			}
		}
	}

	@media (max-width: 1250px) {
		.stepper-container li {
			display: none;
			width: 0px;
		}

		.stepper-container li.current {
			display: block;
			width: max-content;
		}

		.stepper-container li.current div.line {
			visibility: hidden;
			width: 0px;
		}
	}

	@media (max-width: 950px) {
		.stepper-container {
			margin-top: 40px;
			padding-left: 0px;
		}
	}
}

.ex-users, .ex-new-group {
	textarea {
		height: 10em;
	}

	.resize-none {
		resize: none;
	}

	.reset-buttons {
		align-self: flex-end;
	}

	.card-text-light-grey {
		white-space: nowrap;
		color: #79747E;
	}

	.card-text-dark-grey {
		color: #49454F;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.card-title {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		flex-grow: 1;
		min-width: 0;
	}

	.empty-users {
		border-top: 0.5px solid;
		border-top-color: #AAAAAA;
	}

	.users-paginator {
		display: inline-flex;
	}

	.btn.paginator-button:disabled {
		background-color: transparent !important;
		border-color: transparent !important;
		color: transparent !important;
	}

	.page-selector {
		background: url('/Content/images/open_expander.svg') no-repeat right 10px center;
		width: 76px;
		padding-left: 16px;
		padding-top: 0px;
		padding-bottom: 0px;
		height: 33px;
		border: 1px solid #212529;
		font-size: 16px;
	}

	.page-options {
		option:hover {
			box-shadow: 0 0 10px 100px #1882A8 inset;
		}

		font-size: 16px;
	}

	div.pagination-mobile-only {
		display: none;
	}

	@media(max-width: 950px) {

		div.pagination-mobile-only {
			display: grid;
			grid-template-columns: 1fr;
			width: auto;
			grid-column-gap: 0.5em;
			padding-bottom: 1em;
		}
	}

	.vertical-line {
		border-left: 1px solid #AAAAAA;
		height: auto;
	}

	.users-rows {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.group-pill {
		background-color: #D33E02;
		padding: 2px 8px;
		text-align: center;
		display: inline-block;
		border-radius: 8em;
		font-size: 12px;
		color: white;
		height: min-content;
		width: max-content;

		.hide-if-small-container {
			@container (width < 300px) {
				display: none;
			}
		}
	}
}

.ex-new-group {
	.toggle {
		width: 1.5em;
		height: 0.75em;
		margin-top: -18px;
	}

	.toggle-switch-slider {
		&::before {
			height: 0.55em;
			width: 0.55em;
			bottom: 0.1em;
			left: 0.1em;
		}

		&.checked {
			background-color: #D33E01;

			&::before {
				transform: translateX(0.75em);
			}
		}
	}

	h2 {
		font-size: 1.875rem;
	}

	h3 {
		font-size: 1.25rem;
	}

	.text-truncate {
		white-space: break-spaces;
	}

	@media(max-width: 950px) {

		div.mobile-only {
			display: grid;
			grid-template-columns: 0.8fr 1fr;
			width: auto;
			grid-column-gap: 0.5em;
			padding-bottom: 1em;
		}
	}

	.btn-outline-secondary:hover {
		background-color: inherit !important;
		border-color: #ced4da !important;
	}

	.btn-outline-secondary:focus, btn-outline-secondary:active {
		outline: none;
		box-shadow: none;
	}

	input[type="checkbox"] {
		accent-color: #d33e01;
		appearance: none;
		border: 1px solid #d33e01;
		height: 1em;
		width: 1em;
		border-radius: 0.2em;
		margin-top: 0.3em !important;
		vertical-align: top;

		&:disabled, &:checked {
			appearance: checkbox;
		}

		&.card-checkbox {
			width: 1.5em !important;
			height: 1.5em !important;
			min-width: 1.5em !important;
			min-height: 1.5em !important;
			border-radius: 50%;
			margin-top: 0em !important;

			&:checked {
				appearance: none;
				background-image: url('../Content/images/checkbox-enabled.svg');
				background-position: inherit;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
	}

	.search-bar-input {
		padding-left: 3.38rem;
		padding-bottom: 16px;
		border: transparent;
		border-radius: 0px;
		border-bottom: 1px solid #6C6C6C;
		background: url('/Content/images/leading-icon.svg') no-repeat left center;
		background-size: 1.5rem 1.5rem;
		background-position: 0.88rem 2px;
	}

	.dropdown-panel {
		position: absolute;
		left: 0em;
		width: max-content;
		min-width: 140px;
		background: #fff;
		z-index: 1;
		margin-top: 0.5rem;

		option:hover {
			background: #FCECE6;
		}
	}

	.filter-pill {
		background-color: #F6C6B6B2;
		padding: 6px 12px;
		text-align: center;
		display: inline-block;
		border-radius: 16px;
		font-size: 14px;
		margin-bottom: 6px;
	}

	.custom-dropdown {
		max-height: 200px; /* Limit height */
		overflow-y: auto; /* Add scroll for overflow */
		border: 1px solid #ccc;
		border-radius: 4px;
		background-color: white;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		margin-top: 0.25rem;
		padding: 0;
		position: absolute;
		width: 100%;
		z-index: 1050;
	}

	.custom-dropdown .dropdown-item {
		padding: 0.5rem 1rem;
		cursor: pointer;
		white-space: nowrap;
	}

	.custom-dropdown .dropdown-item:hover {
		background-color: #f8f9fa;
		color: #343a40;
	}

	.account-contacts-grid {
		display: grid;
		border-top: 0.5px solid;
		border-top-color: #AAAAAA;
		grid-template-columns: 2em repeat(4, 1fr);
		grid-row-gap: 0.5em;
		grid-column-gap: 20px;
		padding-top: 14px;
		padding-bottom: 14px;
	}

	.group-contacts-grid {
		display: grid;
		border-top: 0.5px solid;
		border-top-color: #AAAAAA;
		grid-template-columns: repeat(4, 1fr) 2em;
		grid-row-gap: 0.5em;
		grid-column-gap: 20px;
		padding-top: 14px;
		padding-bottom: 14px;
	}

	.team-tracking-participants-grid {
		display: grid;
		border-top: 0.5px solid;
		border-top-color: #AAAAAA;
		grid-template-columns: repeat(2, 3fr) repeat(2, 2fr);
		grid-row-gap: 0.5em;
		grid-column-gap: 20px;
		padding-top: 14px;
		padding-bottom: 14px;
		overflow-y: auto;
	}

	.max-height-100 {
		max-height: 100px;
	}

	.scrollbar-gutter {
		scrollbar-gutter: stable both-edges;
	}

	.quick-texts-view-box {
		border-radius: 3px 15px 15px;
	}

	.quick-texts-edit-box {
		border-radius: 3px 15px 15px;
		margin-left: -15px;
		margin-right: -15px;
		padding: 18px 36px;
	}

	.inreach-plus-cell {
		border: 1px solid #dfdfdf;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 75px;
		height: 65px;
	}

	.inreach-cell {
		border: 1px solid #dfdfdf;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 60px;
		height: 50px;
	}

	.removed-inreach-cells {
		display: grid;
		grid-template-columns: repeat(9, 0.1fr);
		grid-template-rows: repeat(3, 0.12fr);
	}

	.inreach-cells {
		display: grid;
		grid-template-columns: repeat(3, 0.12fr);
	}

	.inreach-screen {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.map-icons-nav {
		margin: 0px !important;
	}

	.disabled {
		pointer-events: none;
		cursor: not-allowed;
	}
}
