// @param {String} $duration
// @param {String} $bgSize
@mixin loading($duration:null, $bgSize:null) {
	@if not $duration {
		$duration: 2s;
	}

	@if not $bgSize {
		$bgSize: 10rem;
	}

	animation-duration: $duration;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: loadingContent;
	animation-timing-function: linear;
	background: map-get($COLORS, listitembackground);
	background: linear-gradient(to right, map-get($COLORS, tabbackground) 8%, map-get($COLORS, contentbackground) 18%, map-get($COLORS, tabbackground) 33%);
	background-size: $bgSize;
	position: relative;
	opacity: 0.4;

	@keyframes loadingContent {
		0% {
			background-position: -#{$bgSize} 0;
		}

		100% {
			background-position: #{$bgSize} 0;
		}
	}
}
