﻿.ex-card {
	margin: 0 0.5em;

	&_wrapper {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 1em;
	}

	&_50 {
		flex-basis: calc(50% - 1.25em);
	}

	&_sm {
		@include bp('small') {
			&-100 {
				flex-basis: 100%;
			}
		}
	}

	&_goBack {
		font-size: 0.75em;

		.ex-page_myinfo & {
			display: none !important;
		}

		&.card-title {
			font-size: 0.875rem;
		}
	}

	&_slider {
		@include bp('small') {
			width: 195vw;
			transform: translateX(0);
			transition: all 0.3s ease-in-out;

			&-active {
				transform: translateX(-50%);
			}
		}
	}

	&_slide {
		@include bp('small') {
		}
	}

	@include bp('small') {
		margin-bottom: 1em;
	}
}
