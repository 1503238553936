.collections-tutorial-step-container {
    width: 550px;
    max-width:550px;
}

.collections-tutorial-title {
    text-align: left;
    padding: 5px 15px;
    line-height: 2;
}

.collections-tutorial-content-container {
    padding: 0px;
}

.collections-tutorial-step-counter {
    float: right;
    font-weight: normal;
}

.collections-tutorial-content {
    text-align: left;
    border-bottom: 1px solid #eee;
    padding-left: 16px;
    padding-right: 14px;
    padding-bottom: 10px;
    padding-top: 14px;
}

.collections-tutorial-footer {
	text-align: right;
	padding: 10px 15px;
	background: map-get($COLORS, contentbackground);
	border-radius: 0 0 3px 3px;
}

// Override some styles from bootstrap tour4
.tour-backdrop {
    z-index: 11000 !important; // Our map lefthand pane is at z-index 10000
}

.popover[class*="tour-"] {
    z-index: 11002 !important; // Just needs to be 2 higher than .tour-backdrop
}