@mixin devicesGrid() {

	.ex-page_devicesGrid {

		&-banner {
			text-align: left;
			margin-left: -5px;
			margin-right: -5px;
		}

		&-validateAddress {

			&_prompt {
				text-align: center;

				&-text {
					display: inline-block;
				}
			}

			&_select {
				width: 28.125rem;
			}
		}

		&-wrapper {
		}

		&-container {
			text-align: left;

			&_inner {
				text-align: left;
			}
		}

		&-buttons {
			padding: 0.875em 9em 0.875em 0.875em;
			position: relative;
			display: flex;
			flex-wrap: wrap;
			max-width: 87.5rem;

			@include bp('large') {
			}

			&_section {
				margin-left: 1.25em;
				margin-bottom: 1em;

				&:first-child {
					margin-left: 0;
				}

				&-absolute {
					position: absolute;
					right: 1.5em;
					top: 1.5em;
				}

				&-text {
					display: block;
				}
			}
		}

		&-search {
			position: absolute;
			right: 1em;
			top: 5em;

			&_input {
				width: 9.375em;
			}
		}

		&-data {
			background-color: map-get($COLORS, secondarybackground);

			&_wrapper {
				margin-top: 3em;
				min-width: 87.5rem;
				width: 100vw;
			}

			&_cell {

				&-check {
					width: 1.75em;
				}
			}

			&_element {
				display: inline-block;
				height: 100%;
				padding-left: 0.325em;
				padding-right: 0.325em;
				text-align: left;
				overflow: hidden;
				float: left;
				text-overflow: ellipsis;

				&-xs {
					//width: 5%;
					width: 4.375rem !important;
				}

				&-sm {
					//width: 11%;
					width: 6.25rem !important;
				}

				&-mdsm {
					//width: 5%;
					width: 8.125rem !important;
				}

				&-md {
					//width: 13%;
					width: 8.75rem !important;
				}

				&-mdlg {
					//width: 10%;
					width: 9.375rem !important;
				}

				&-lg {
					//width: 9%;
					width: 10.3125rem !important;
				}

				&-xl {
					//width: 13%;
					width: 14.5rem !important;
				}

				&-select {
					max-width: 9.375em;
				}
			}
		}

		&-rows {
		}

		&-row {
			display: flex;
			height: auto;
			border: 1px solid rgb(212,212,212);
			overflow: hidden;

			&_element {


				a {
					display: block;
				}
			}
		}

		#contentwrapper {
			background-image: none !important;
			background-color: #ffffff !important;
		}

		.device-sync-required {
			height: 100%;
			background-color: #f5d3d3;
			font-weight: bold;
			color: red !important;
		}

		.red-bold {
			font-weight: bold;
			color: red !important;
		}

		.button-link {
			text-decoration: underline;
		}

		.bootstrap-select * {
			box-sizing: border-box;
		}

		.collection-option:hover {
			background-color: #CDCDCD !important;
		}

		.dropdown-menu .inner {
			overflow-x: hidden;
		}

		.dropdown-menu .text {
			text-overflow: ellipsis;
			max-width: 100%;
			white-space: nowrap;
			overflow: hidden;
		}

		.dropdown .filter-option-inner-inner {
			max-width: 190px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		.bootstrap-select .dropdown-menu {
			width: 220px;
		}

		.collection-mapshared-icon {
			position: relative;
			top: -1px;
			margin-right: 4px;
		}

		.orange {
			color: map-get($COLORS, explorange);
		}

		.dropdown-menu li {
			margin-left: 0px;
		}

		h4 {
			font-weight: 100;
			@include subheader-text();
			margin-bottom: 0.5em;
			text-align: left;
			flex-basis: 100%;
		}

		h5 {
			font-weight: bold;
			@include secondary-body-text();
			text-align: left;
			flex-basis: 100%;
		}

		h6 {
			font-weight: bold;
			@include small-body-text();
			color: black;
			text-align: left;
			flex-basis: 100%;
		}

		.btn {
			min-width: 70px;
		}

		.device-image-container {
			line-height: 100%;
			text-align: center;
			width: 190px;

			@include bp('small') {
				position: absolute;
				left: 0em;
				top: 1em;
				height: 7em;
				width: 6em;
			}
		}

		.device-image {
			align-self: center;
			max-width: 190px;
			max-height: 220px;

			@include bp('small') {
				max-width: none;
				max-height: none;
				width: auto;
				height: 7em;
				padding: 0.25em;
			}
		}

		.ex-widget_open {
			@include bp('small') {
				.device,
				.garmin-device {
					&-image {
						height: 10em;
						width: auto;

						&-container {
							position: static;
							height: 10em;
							width: auto;
						}
					}

					&-info-widget {
						padding: 0.125em 0 0;
					}

					&-widget {
						&_close {
							@include bp('small') {
								position: absolute;
								top: 1em;
								right: 1em;
							}
						}
					}
				}

				&.device-row {
					&-container {
						position: fixed;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						max-height: 90vh;
						overflow: scroll;
					}
				}

				& ~ #device-blackout {
					position: fixed;
					bottom: 0;
					top: 0;
					left: 0;
					right: 0;
				}
			}
		}

		.collection-checkbox {
			margin-right: 5px;
			margin-left: -1.2rem;

			&:disabled {
				display: none;

				& ~ .checkbox-lock {
					display: inline;
					min-width: 13px;
					min-height: 13px;
					margin: 1px 5px 5px 0px;
					background-image: url(../Content/images/collections_helper_locked_icon.svg);
					background-size: 13px 13px;
					background-repeat: no-repeat;
					margin-left: -1.25rem;
				}
			}

			& ~ .checkbox-lock {
				display: none;
			}
		}

		.collection-select {
			outline: none;
			margin: 1em 0;

			@include bp('small') {
				margin-bottom: 0;
			}

			& + .dropdown-toggle,
			&.show .dropdown-toggle {
				background: map-get($COLORS, primarybackground) !important;
				color: map-get($COLORS, primarytext) !important;

				&:hover {
					background: map-get($COLORS, primarybackground);
					color: map-get($COLORS, primarytext);
				}
			}

			.dropdown-item {
				&.active {
					background: map-get($COLORS, tabbackground);
					color: map-get($COLORS, primarytext);
				}
			}
		}

		.device-collections {
			width: 100%;
			height: 10em;
			padding: 1em;
			border: 1px solid map-get($COLORS, borders);
			overflow-y: auto;
			margin: 1em 0;

			@include bp('small') {
				margin-bottom: 0;
			}
		}

		.device-collections label {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.sync-link {
			text-decoration: underline;
		}

		.device-widget,
		.ex-deviceWidget {
			width: 220px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			transition: all 0.3s ease-in-out;

			&-header {
				text-align: left;

				@include bp('small') {
					width: 100%;
					padding-left: 1em;
				}
			}

			&_expanded {
				&.ex-deviceWidget_base {
					order: 3;

					@include bp('small') {
						order: 1;
					}
				}

				& ~ .device-subwidget,
				&.device-subwidget {
					@include bp('small') {
						order: 2;
						width: 100%;
						padding-left: 0.5em;

						& > div {
							width: 100%;
						}
					}
				}

				.device-widget-header {
					@include bp('small') {
						align-self: center;
						padding-left: 0.5em;
					}
				}

				.device-rollable-icon {
					@include bp('small') {
						vertical-align: middle;
					}
				}

				.device-widget_close {
					max-width: 70px;
				}

				&.sync-type-widget-override {
					width: 110px;

					@include bp('small') {
						width: 100%;
					}

					#sync-type-selection {
						flex-direction: column;

						@include bp('small') {
							flex-direction: unset;
						}

						button {
							cursor: pointer;
							width: 110px;
							margin-left: 0;

							@include bp('small') {
								height: 2.5rem;
							}

							&:not(:last-child) {
								border-radius: .25rem .25rem 0 0;

								@include bp('small') {
									border-radius: .25rem 0 0 .25rem;
								}
							}

							&:not(:first-child) {
								border-radius: 0 0 .25rem .25rem;

								@include bp('small') {
									border-radius: 0 .25rem .25rem 0;
								}
							}

							&:disabled {
								cursor: not-allowed;
							}

							img {
								display: block;
								margin: .25rem auto;
								height: 45px;
								width: 44px;

								@include bp('small') {
									display: none;
								}
							}

							span {
								max-width: 90%;
								display: inline-block;
								overflow: hidden;
								text-overflow: ellipsis;

								@include bp('small') {
									white-space: nowrap;
								}
							}
						}
					}
				}

				.collection-sync-explanation {
					position: relative;
					left: 3rem;

					@include bp('small') {
						position: static;
					}
				}

				.assign-btn {
					position: relative;
					left: 3rem;
					bottom: 3rem;

					@include bp('small') {
						position: static;
						margin-top: 1rem;
					}
				}

				&.collection-widget-override {
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;

					&.space-for-close-btn {
						margin-right: 2.75rem;

						@include bp('small') {
							margin-right: 0;
						}
					}

					h4 {
						flex-basis: 0;

						span {
							max-width: 85%;
						}

						.ex-icon_help {
							position: relative;
							top: -0.2rem;
						}
						// IE is having trouble with the margin on these so here we go
						@media all and ('-ms-high-contrast: none') {
							margin-bottom: 2em;
						}
					}
				}
			}

			@include bp('small') {
				width: 100%;
				justify-content: flex-start;

				&.no-content-mobile {
					.device-rollable-icon-container {
						display: none;
					}

					.device-widget-header {
						display: none;
					}
				}
			}
		}

		.device-info-widget {
			@include bp('small') {
				padding: 0 1.25em 0 4.5em;
			}
		}

		.device-sub-widget {

			@include bp('small') {
				width: 100%;
				margin: 1em 0;
			}
		}

		.device-subwidget,
		.ex-device-subwidget {
			width: 220px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;

			@include bp('small') {
				width: 100%;
				padding: 0 1.25em;
				margin: 1em auto;
			}

			&_container {
				width: 100%;
				margin-bottom: 1em;
			}

			&_warningMessage {
				color: map-get($COLORS, danger);
				font-weight: bold;
			}
		}

		.device-rollable-icon-container {
			width: 90px;
			height: 90px;
			line-height: 90px;
			text-align: center;
			margin-right: 1rem;

			@include bp('small') {
				margin-right: 0.5rem;
			}
		}

		.device-rollable-icon {
			cursor: pointer;
			vertical-align: middle;
		}

		.explore-device-image {
			max-width: 190px;
			max-height: 220px;
			align-self: center;

			@include bp('small') {
				max-width: none;
				max-height: none;
				width: auto;
				height: 6em;
				padding: 0.25em;
			}
		}

		.explore-device-beta-label {
			@include body-text();
			position: relative;
			color: white;
			background-color: #007FFF;
			border-radius: 15px;
			margin-left: 5px;
			bottom: 2px;
			padding: 6px 12px;
			cursor: help;
		}

		.device-widget-container {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			align-items: stretch;
			flex: 1 0 auto;

			@include bp('small') {
				flex-direction: column;
			}
		}

		.device-title {
			@include title-text();
			font-weight: normal;
			padding-bottom: 10px;

			@include bp('small') {
				font-size: 1.5rem;
			}
		}

		.device-row-container {
			padding: 20px;
			width: 100%;
			border-bottom: 1px solid #c3c3c3;
			position: relative;
			top: 0;
			left: 0;
			display: flex;
			flex-direction: column;

			@include bp('small') {
				padding: 1.25em 0;
			}
		}

		.device-pending-change {
			position: relative;
			top: -5px;
			left: 30px;
			@include secondary-body-text();
			display: inline-block;
			background-color: map-get($COLORS, explorange);
			color: white;
			font-weight: bold;
			padding: 2px 7px;

			@include bp('medium') {
				left: 10em;
				max-width: calc(100vw - 10em);
			}

			@include bp('small') {
				left: 7em;
				max-width: calc(100vw - 7em);
			}
		}

		.ex-widget_open .device-pending-change {
			left: auto;
			top: auto;
			margin: 3em 0 1em 0;
			max-width: 100%;

			@include bp('medium') {
				left: auto;
				top: auto;
				margin-right: 0;
				margin-top: 3em;
				max-width: 100%;
			}

			@include bp('small') {
				left: auto;
				top: auto;
				margin-right: 0;
				margin-top: 3em;
				max-width: 100%;
			}
		}

		.device-row {
			min-height: 300px;
			display: flex;
			justify-content: space-between;

			@include bp('small') {
				flex-direction: column;
				padding: 1em;
			}
		}

		#device-blackout {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			display: none;
			background-color: black;
			opacity: 0.5;
			z-index: 1000;
		}

		#device-inreach-add {
			width: calc(100% - 60px);
			padding: 30px;
			padding-left: 0px;
			position: relative;
			top: 0px;
			left: 0px;
		}

		#device-list {
			position: relative;
			top: 0;
			left: 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}

		.dlg-container {
			padding: 20px;
		}

		#devices-container {
			background-color: #ffffff;
			height: auto;
		}

		#contentwrapper > .container {
			background-color: #ffffff;
			height: calc(100vh - 106px);
		}

		.sub-toolbar {
			background-color: white;
		}

		.deviceInfoPair {
			margin-left: -40px;
			padding-bottom: 5px;
		}

		.deviceInfoLabel {
			color: gray !important;
			font-weight: bold;
			margin-bottom: -2px !important;
			padding-bottom: 0px !important;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.deviceInfoText {
			font-weight: bold !important;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.device-select {
			margin-bottom: 2px;
		}

		.addDeviceBtn {
			padding-top: 0px;
			margin-top: 0px;
		}

		.no {
			color: map-get($COLORS, danger);
			font-weight: bold;
		}

		.deviceCell {
			display: block;
			height: 38px;
			border: 1px solid rgb(212,212,212);
			overflow: hidden;
		}

		.deviceElement {
			display: inline-block;
			height: 100%;
			padding-left: 5px;
			padding-right: 5px;
			text-align: left;
			overflow: hidden;
			float: left;
			text-overflow: ellipsis;
		}

		.checkCell {
			width: 15px;
		}

		.sortClickable {
			cursor: pointer;
			margin: 2px;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}

		.sortClickable span {
			display: inline-block;
			height: 16px !important;
		}

		.whiteDeviceGrid {
			background-color: white;
		}

		.grayDeviceGrid {
			background-color: rgb(234, 234, 234);
		}

		.deviceRetry {
			font-weight: bold;
			cursor: pointer;
			display: block;
		}

		.garmin-syncable-name {
			@include body-text();
			font-weight: bold;
		}

		.pending-change {
			position: absolute;
			top: 48px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			background: rgba(0, 0, 0, 0.9);
			padding: 10px;
			color: white;
		}

		.pending-change h2 {
			color: red;
			margin-top: 0px;
		}

		.pending-change a {
			color: white !important;
			text-decoration: underline;
		}

		.pending-change-notification {
			float: right;
			padding: 3px;
			color: white;
			background-color: #ff6a00;
			@include small-body-text();
			line-height: 13px;
			margin-top: -8px;
		}

		.pending-change-notification a {
			color: white !important;
			text-decoration: underline;
		}

		.cancelLink {
			color: gray;
			@include small-body-text();
			text-decoration: underline !important;
			cursor: pointer;
		}

		.supportLink {
			text-decoration: underline;
			cursor: pointer;
		}

		.modal-body .nav {
			height: auto !important;
		}

		.modal-body .nav-tabs > li > a {
			padding-top: 2px !important;
			padding-bottom: 2px !important;
			border-color: #ddd;
			background-color: #ddd;
		}

		.modal-body .nav-tabs > li.active > a {
			border-bottom: 1px solid white;
			background-color: white;
		}

		.switch-bold {
			font-weight: bold;
		}

		.no-bottom-margin {
			margin-bottom: 0px;
		}

		.editName input {
			margin-bottom: 0px;
			margin-top: -5px;
		}

		.titleEdit * {
			margin-top: 5px;
		}

		.kgSorted {
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d9e5f3', endColorstr='#bbcee5') !important;
			background: -moz-linear-gradient(top, #d9e5f3, #bbcee5) !important;
			background: -webkit-gradient(linear, left top, left bottom, from(#d9e5f3), to(#bbcee5)) !important;
		}

		tr.odd {
			background-color: white !important;
		}

		tr.even {
			background-color: #F2F5F9 !important;
		}

		.odd .sorting_1 {
			background-color: #d2d9e1 !important
		}

		.even .sorting_1 {
			background-color: #e0e5ea !important
		}

		table.dataTable tbody td {
			padding: 4px 4px !important;
		}

		table.dataTable thead th,
		table.dataTable thead td {
			padding: 4px 4px !important;
			border-bottom: 1px solid #111111;
		}

		#devicesProGrid_wrapper {
			margin-left: -10px;
			margin-right: -10px !important;
		}

		#devicesProGrid {
			padding-top: 10px;
		}

		#devicesProGrid_info {
			margin-left: 10px;
		}

		.gridStyle {
			border: 1px solid rgb(212,212,212);
			width: 100%;
			margin-top: 10px;
		}

		.gridStyle select {
			width: auto !important;
		}

		.kgViewport {
			height: inherit !important;
			overflow: hidden !important;
		}

		.kgRow.selected {
			background-color: #c3cfdd !important;
		}

		#device_table_id_wrapper {
			width: 99%;
		}

		.primaryCollectionSelect {
			margin-top: 6px;
		}

		.device-edit-icon {
			position: relative;
			left: 6px;
			cursor: pointer;
			@include body-text();
		}

		#tableHead {
			@include secondary-body-text();
			font-weight: bold;
			height: 60px;
		}

		.addOrSwitchDlg {
			h5 {
				font-size: 20px;
			}

			.addOrSwitchDlgFooter {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				width: 100%;

				.addDeviceButtonContainer {
					align-self: flex-start;
					margin-bottom: 1em;

					.addDeviceButton {
						padding: 0;
						font-size: 0.8em;
						text-decoration: underline;
					}
				}

				.addOrSwitchDlgFooterButtonContainer {
					display: flex;
					justify-content: flex-end;
				}
			}
		}
	}

	.ex-page_devicesGridPro {
		background-color: #F9F9FC;

		#contentwrapper {
			background-color: transparent !important;
			display: flex;
			flex-direction: column;
			height: 0 !important;

			& > div:nth-child(3) {
				margin: 0 !important;
				flex-grow: 1;
				position: relative;
			}
		}

		#devicesGrid {
			position: absolute;
			height: 100%;
			width: 100%;
		}

		.modal {
			text-align: left;
		}

		.pop-menu-button {
			width: 100%;
			text-align: start;
			padding: 5px 10px;
			cursor: pointer;
			transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s;
			background: none;
			border: none;

			&:hover {
				background-color: rgb(52, 58, 64);
				color: white;
			}

			&:disabled {
				cursor: not-allowed;

				&:hover {
					background: none;
					color: graytext;
				}
			}
		}

		#pro-device {

			&-container {
				display: flex;
				justify-content: center;
				align-items: flex-start;
				width: 100%;
				height: 100%;
				background-color: transparent;
				padding-top: 20px;

				@include bp('large') {
					justify-content: flex-start;
					padding-top: 0;
				}

				&-padding {
					padding: 15px 25px;
					padding-bottom: 5px;
					border: solid 1px rgba(0, 0, 0, 0.125);
					background-color: white;

					@media screen and (max-width: 1100px) {
						width: 100%;
					}
				}
			}

			&-action-bar {
				display: flex;
				padding: 10px 0;
				position: sticky;
				top: -20px;
				background-color: white;
				z-index: 3;
				height: 60px;

				@include bp('small') {
					top: 0;
				}

				@media screen and (max-width: 700px) {
					display: block;
					height: auto;
				}

				span {
					margin: 0 10px;
					margin-bottom: 0;
					white-space: nowrap;

					@media screen and (max-width: 700px) {
						margin: 0;
					}

					& > button {
						flex-grow: 0;
						min-width: 200px;
						white-space: nowrap;

						@media screen and (max-width: 700px) {
							width: 48%;
						}

						@media screen and (max-width: 500px) {
							width: 100%;
						}

						&:last-of-type {
							margin-right: 0;
							position: relative;
						}

						& .small-chevron {
							font-size: 12px;
						}

						&#pro-device-bulk-actions {
							@media screen and (max-width: 700px) {
								margin-left: 4%;
							}

							@media screen and (max-width: 500px) {
								margin-left: 0;
								margin-top: 0.4em;
							}
						}
					}
				}

				& > input {
					flex-grow: 1;
					margin-right: 10px;
					max-width: 400px;

					@media screen and (max-width: 700px) {
						max-width: 100%;
						width: 100%;
						margin-bottom: 0.4em;
					}
				}

				&-menu {
					display: flex;
					flex-direction: column;
					min-width: 200px;
					right: -1px;
					top: 39px;
					border: 1px solid rgba(0, 0, 0, 0.125);
					background-color: white;
				}
			}

			&-table {
				border-collapse: separate;
				border-spacing: 0;

				@media screen and (max-width: 1100px) {
					width: 100%;
				}

				tr {
					td.table-checkbox {
						input {
							@media screen and (max-width: 800px) {
								margin-right: 1em;
							}
						}
					}

					&.filter-row, &.header-row {
						@media screen and (max-width: 1100px) {
							display: none;
						}
					}

					td.hide-on-tablet {
						@media screen and (max-width: 1100px) {
							display: none;
						}
					}

					td .tablet-row {
						display: flex;

						.row-label {
							font-weight: bold;
							margin-right: 1em;
							flex-basis: 40%;
						}
					}

					td .mobile-row {
						display: flex;

						.row-label {
							font-weight: bold;
							margin-right: 1em;
							flex-basis: 35%;
						}
					}

					td.tablet-only {
						display: none;
						width: 50%;

						@media screen and (max-width: 1100px) {
							display: table-cell;
						}

						@media screen and (max-width: 800px) {
							display: none;
						}
					}

					td.mobile-only {
						display: none;
						width: 100%;

						@media screen and (max-width: 800px) {
							display: table-cell;
						}
					}

					td.content-only {
						display: contents;
					}

					.color {
						&-deactivated {
							background-color: rgba(255, 0, 0, 0.05);
						}

						&-suspended {
							background-color: rgba(255, 128, 0, 0.05);
						}
					}

					td, th {
						padding: 10px 14px;
						text-align: left;

						@media screen and (max-width: 1100px) {
							padding: 0.2em;
						}
					}

					td:first-child, td:last-child, th:first-child, th:last-child {
						min-width: 0;
					}

					th {
						text-transform: uppercase;
						color: #767676;
						font-weight: normal;
						background-color: white;
						z-index: 2;
						position: sticky;
						height: 40px;
					}
				}

				thead {
					position: relative;

					tr {
						&:first-child {

							th {
								top: 40px;
								padding-bottom: 0;

								@include bp('small') {
									top: 60px;
								}
							}
						}

						&:last-child {
							th {
								top: 80px;
								padding-top: 0;
								border-bottom: solid 1px rgba(0, 0, 0, 0.125);

								@include bp('small') {
									top: 100px;
								}
							}
						}
					}

					.header-bottom-border {
						height: 1px;
						background-color: rgba(0, 0, 0, 0.125);
					}

					.table-header-details {
						font-size: 10px;
						max-width: 150px;
					}
				}

				tbody {

					tr {
						td {
							border-bottom: solid 1px rgba(0, 0, 0, 0.125);
						}

						&:last-child td {
							border-bottom: none;
						}
					}
				}

				.device-usage {
					padding: 0 20px;
					@include secondary-body-text();

					&-link {
						text-decoration: underline;
						color: #315bb1;
						cursor: pointer;
					}

					&-over {
						color: red !important;
						font-weight: bold;
					}
				}

				.inline-button {
					border: none;
					@include secondary-body-text();
					border-radius: 12px;
					padding: 0 20px;
					transition: background-color 0.15s ease-in-out;

					&.assign {
						background-color: #EAEAEA;

						&:hover {
							background-color: #CDCDCD;
						}
					}

					&:focus {
						outline: none;
						box-shadow: 0px 0px 2px #0066ff;
					}
				}

				.menu-ellipses {
					padding: 4px 3px 8px 3px;
					cursor: pointer;
					position: relative;

					&-inner {
						font-weight: 900;
						@include title-text();
						line-height: 10px;
						position: relative;
						top: -8px;
						user-select: none;
						pointer-events: none;
					}

					&:hover {
						color: #808080;
					}
				}

				.row-menu {
					display: flex;
					flex-direction: column;
					position: absolute;
					right: 0;
					top: 24px;
					min-width: 200px;
					border: 1px solid rgba(0, 0, 0, 0.125);
					background-color: white;
					z-index: 6;

					&-up {
						top: -4em;
						right: 3em;
					}
				}
			}
		}
	}
}
