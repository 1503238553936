﻿.ex-page_setup {

	#content {
		position: relative;
		margin-left: 0;
	}

	#contentwrapper {
		display: flex;
		flex-direction: column;
		justify-content: stretch;
	}

	.ex-content {
		margin: 0 !important;
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		flex-grow: 1;
	}

	&-container {
		width: 100%;

		&_inner {
			padding: 1em;
		}
	}

	&-buttonContainer {
		margin: 2em auto;
	}

	&-headline {
		margin-bottom: 30px;

		&_secondary {
			font-weight: bolder;
			line-height: 1.25;
			margin-bottom: 1em;
		}
	}

	&-copy {
		max-width: 42em;
	}

	&-background {
		flex-grow: 1;
		width: 100%;
		//height: 291px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
}
