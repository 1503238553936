﻿
.mobile-embed-flow {
	.emergency-information {
		.card {
			&-label {
				@include secondary-body-text();
				margin-top: 0.75rem;
				margin-bottom: 0;
				margin-left: 1.25rem !important;
			}

			&-value {
				@include body-text();
				margin-left: 1.25rem;
			}

			.list-group-item {
				border-top: 1px solid #6C6C6C;
				border-left: 0;
				border-bottom: 0;
				border-right: 0;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				margin-top: 0.75rem;
			}

			#emergency-contacts-info-container {
				display: flex;
				flex: 1 1 auto;
				justify-content: space-around;

				.circle {
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 1.5rem;
					border-radius: 50%;
					width: 3rem;
					height: 3rem;
					background: #929292;
					color: var(--bodybackground);
					margin-top: 1rem;
				}

				.emergency-contacts-info {
					display: flex;
					flex-direction: column;
					align-items: center;

					&-phone-number {
						color: var(--secondarytext);
					}
				}
			}
		}
	}
}
