.ex-bg {
	&_cranberry {
		background-color: map-get($COLORS, cranberry);
	}

	&_grape {
		background-color: map-get($COLORS, grape);
	}

	&_raspberry {
		background-color: map-get($COLORS, raspberry);
	}

	&_apple {
		background-color: map-get($COLORS, apple);
	}

	&_tangerine {
		background-color: map-get($COLORS, tangerine);
	}

	&_pomegranate {
		background-color: map-get($COLORS, pomegranate);
	}

	&_facebook {
		background-color: map-get($COLORS, facebook);
	}

	&_twitter {
		background-color: map-get($COLORS, twitter);
	}
}
