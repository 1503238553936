@mixin nav() {
	.ex-nav {
		margin-bottom: 0;
		list-style: none;
		display: flex;
		padding-left: 0;

		@include bp('medium') {
			width: 75vw;
			max-width: 20em;
			display: flex;
			flex-wrap: wrap;
			text-align: left;
			padding: 4.5em 0 0;
			margin: 0;
		}

		&_tabs {
			width: auto;
			margin: 0 auto 1em !important;
			min-height: 0 !important;
		}

		&_wrapper {
			justify-content: center;
			margin-left: 180px;
			margin-right: 180px;

			&.info-hidden {
				margin-right: 65px;
			}

			@include bp('large') {
				margin-left: 55px;
			}

			@include bp('medium') {
				margin: 0;
				align-items: flex-start;
				position: absolute;
				left: -100%;
				top: 0;
				z-index: 10001;
				background: map-get($COLORS, primarybackground);
				transition: left 0.3s ease-in-out;

				.ex-nav_active & {
					left: 0;
				}
			}

			&-secondary {
				align-items: flex-end;
				padding-right: 10em;
				margin-right: 0 !important;
				margin-left: auto;

				@include bp('medium') {
					padding-right: 0;
				}
			}
		}

		&_item {
			position: relative;
			height: auto;
			padding: 1rem 0 0.75rem !important;
			margin: 0 -5px -1px 0;
			padding: 0.5em 0;
			max-width: 190px;

			@include bp('medium') {
				display: block;
				flex-basis: 100%;
				border-bottom: 1px solid map-get($COLORS, borders);
				margin-right: 2em;

				&:last-child {
					border-bottom: none;
				}
			}

			@include bp('large-only') {
				margin: 0 -10px -1px 0;
			}

			&-secondary {
				max-width: none;
			}

			a {
				color: map-get($COLORS, dark);
				padding: 0.5em 0;
				margin: 0 0.875em;
				text-transform: uppercase;
				text-decoration: none;
				display: block;
				line-height: 100%;

				.ex-nav_wrapper-secondary & {
					color: map-get($COLORS, tertiarytext);
					text-decoration: underline;
					text-transform: none;
					transition: color 0.3s ease-in-out;

					&:hover {
						color: darken(map-get($COLORS, tertiarytext), 20%);
					}

					@include bp('medium') {
						text-decoration: none;
					}
				}

				@include bp('medium') {
					display: block;
				}
			}

			button {
				color: map-get($COLORS, dark);
				padding: 0.5em 0;
				margin: 0 0.875em;
				text-decoration: none;
				display: block;
				line-height: 100%;
				font-size: 14px;

				.ex-nav_wrapper-secondary & {
					color: map-get($COLORS, tertiarytext);
					text-decoration: underline;
					text-transform: none;
					transition: color 0.3s ease-in-out;

					&:hover {
						color: darken(map-get($COLORS, tertiarytext), 20%);
					}

					@include bp('medium') {
						text-decoration: none;
					}
				}

				@include bp('medium') {
					display: block;
				}
			}

			button:disabled {
				background-color: transparent !important;
				border-color: transparent !important;
				color: #212529 !important;
			}
		}
	}

	.responsive-header-element {
		@media (max-width: 1140px) {
			margin-right: 65px;

			&#header-info {
				display: none;
			}
		}

		@include bp('medium') {

			&#header-info {
				display: none;
			}

			&.nav {
				white-space: normal;
			}

			&#profile-picture {
				height: 100%;
			}

			&#menu-status-widget {
				display: block !important;
			}

			&#menu-support-link {
				display: block !important;
			}

			&#header-menu-username {
				display: block !important;
			}
		}
	}

	.announcement-banner {
		height: max-content;
		background-color: #434343;
		color: #FFFFFF;

		a {
			color: #FFFFFF;
			text-decoration: underline;
		}
	}
}
