.promoCodeForm {
    &-input {
        width: 15rem;

        @include bp('small') {
            margin-bottom: .25rem;
            width: 100%;
        }
    }
}

.ex-activationSection .promoCodeForm {
	&-input {
		width: 12rem;
		margin-right: 0 !important;
		margin-left: auto;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		height: 2.875rem;
		border: 1px solid map-get($COLORS, dark);

		@include bp('small') {
		}
	}

	&-form {
		margin-bottom: 1rem;
	}

	&-feedback{
		text-align:right;
	}
}