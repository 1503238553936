.ex-homepageVideo {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	&_container {
		position: relative;
		flex-basis: 100%;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
	}
}
