.ex-tooltip {
	display: inline-block;
	position: relative;

	.tooltip-text {
		padding: 8px;
		position: absolute;
		z-index: 9999;
		visibility: hidden;
		-webkit-box-shadow: 0 0 5px #aaa;
		box-shadow: 0 0 5px #aaa;
		background-color: black;
		color: #fff;
		text-align: center;
		font-size: 12px;
		white-space: nowrap;
	}

	&:hover .tooltip-text {
		visibility: visible;
	}

	.small-img {
		width: 1em;
		height: 1em;
	}
}
