.ex-userMessage {
	&_displayAddress {
		max-width: 19em;
	}

	&_buttonContainer {
		@include bp('small') {
			position: absolute;
			right: 1em;
			top: 0.625em;
		}
	}
}
