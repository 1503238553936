@mixin header() {
	.ex-header,
	#header {
		@include bp('medium') {
			max-width: 100vw;
			width: 100%;
			height: auto !important;
			min-height: 3.75em !important;

			&:after {
				content: '';
				position: fixed;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: transparent;
				z-index: 10000;
				transition: background 0.3s ease-in-out;
				pointer-events: none;
			}

			.ex-nav_active & {
				transition: background 0.3s ease-in-out;

				&:after {
					background: map-get($COLORS, modalbackdrop);
					pointer-events: all;
				}
			}
		}

		.ex-page_mapsharePassword & {
			@include bp('small') {
				height: 100px;
			}
		}

		&_logo {
			@include bp('medium') {
				top: 1em;
				left: 50%;
				transform: translateX(-50%);
			}

			@include bp('small') {
				.ex-page_mapShare & {
					top: 1em;
					left: 1em;
					transform: none;
					width: 8em;
				}
			}
		}
	}

	#contentwrapper.ex-nav_active {
		pointer-events: all;
		transition: background 0.3s ease-in-out;
		height: 100vh !important;
	}
}
