﻿@mixin headerMenu() {
	.ex-headerMenu {
		background-color: map-get($COLORS, contentbackground);
		border-left: 1px solid map-get($COLORS, borderslight);
		position: absolute;
		right: 0;
		bottom: 0;
		height: 0;
		overflow: hidden;
		min-width: 200px;
		transition: height ease 0.25s, bottom ease 0.25s;
		@include secondary-body-text();
		z-index: 10002;

		&_hasBeenOpened {
			display: block !important;
		}

		#inner-header-menu {
			min-height: 100%;
			position: absolute;
			bottom: 0;
			width: 100%;
		}

		.header-menu-row {
			padding: 4px;
			text-align: left;
			border-bottom: 1px solid map-get($COLORS, borderslight);
		}

		#lShowLegal:hover {
			text-decoration: underline !important;
		}

		#lShowAITSHelp:hover {
			text-decoration: underline !important;
		}
	}
}
