.ex-selectUser {
	text-align: left;
	margin-bottom: 1rem;

	.dropdown {
		width: 75%;
		padding: 0px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 5em;
		margin-top: 2em;

		@media (max-width: 767px) {
			width: 100%;
		}

		button {
			width: 100%;
			text-align: left;
			padding-left: 25px;
			color: #343a40;
			background-color: white;
			border-color: #343a40;

			&::after {
				position: absolute;
				right: 20px;
				top: 45%;
			}

			&:focus {
				box-shadow: none;
			}
		}

		.dropdown-menu {
			width: 100%;
			left: -1px !important;
			box-sizing: content-box;
		}

		.dropdown-image {
			width: 1.7%;
			margin-left: 20px;
		}
	}

	select {
		max-width: 33rem;
	}
}
