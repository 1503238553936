.flex-row-space-between-centered {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-row-start-centered {
    display: flex;
    justify-content: start;
    align-items: center;
}

.flex-row-center-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-row-start-align-start {
    display: flex;
    justify-content: start;
    align-items: start;
}

.flex-column-space-between {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.flex-column-start {
    display: flex;
    justify-content: start;
    flex-direction: column;
}

.flex-column-centered {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.flex-none {
    flex: none;
}

.text-overflow-ellipsis {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.multi-line-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
}

.visibility-hidden {
    visibility: hidden;
}

.display-none {
    display: none !important;
}

.float-left {
    float: left;
}

.common-bootstrap-modal {
    text-align: left;
}

.min-height-text-area {
    min-height: 3rem;
}
