@mixin enterpriseDeviceDialog() {
	.ex-page_enterprise {

		h3 {
			text-align: left;
		}

		label {
			text-align: left;
		}

		hr {
			margin-top: 0;
			margin-bottom: 0.625rem;
		}

		.deviceRowContent {
			display: flex;
		}

		.deviceRow, .selectUserDiv, .suspendHelp {
			transition: all ease-in-out 0.3s;
			max-height: 200px;
		}

		.suspendHelp {
			overflow:hidden;
		}

		.collapsed {
			max-height: 0px;
			overflow: hidden;
		}

		.imeiInput {
			display: block;
			width: 12em;
		}

		.authInput {
			display: block;
			width: 6em;
		}

		#promoInput {
			display: block;
			width: 10em;

			&:not(:disabled) {
				background: #d8edfe;
			}
		}

		#btnAddAnother {
			margin-bottom: 0.625em;
		}

		#cancelAdd {
			float: right;
		}

		#sumbitAdd {
			float: right
		}

		.dlgInput {
			float: left;
			margin-right: 1.25em;
			margin-bottom: 1em;
			text-align: left;
		}

		.termsDiv {
			clear: both;
		}

		.removeDeviceBtn {
			padding-top: 2.5em;
		}

		.addError {
			text-align: left;
			color: red;
			display: inherit;
			margin-bottom: 0.5em;
		}

		#planSelect {
			width: 100%;
		}

		#userSelect {
			width: 100%;
			margin-bottom: 0.625em;
		}

		#planLabel {
			display: inherit;
			text-align: left;
			margin: 0.5em 0 0.5em 0;
		}

		#userLabel {
			display: inherit;
			text-align: left;
			margin: 0.5em 0 0.5em 0;
		}

		.deactivateSurveyDelorme {
			position: absolute;
			max-height: 850px !important;
			margin: -20% 0 0 -22.5%;
			width: 45%;
			box-sizing: border-box;
		}

		.deactivateSurveyRoadpost {
			width: 550px;
			margin-left: -275px;
			height: 80%;
			max-height: 500px !important;
			margin-top: -22%;
		}

		#surveyDelorme {
			* {
				max-width: none; /* Survey Monkey always sets a max-width which prevents the survey from being reactive */
			}
			/* Fail safe to force the height of the survey if it gets changed by anyone with access to Survey Monkey */
			.smcx-widget {
				height: 500px;

				.smcx-iframe-container {
					height: 465px; /* Survey Monkey footer branding is 35px */
				}
			}
		}

		#modal_deactivate_service,
		.ex-deactivateModal {
			.modal {
				&-body {
					overflow-y: visible;
					max-height: 100%;

					.alert {
						&-error {
							clear: both;
						}
					}
				}
			}

			&_suspendStep {
				padding: 0.625em;
				background-color: map-get($COLORS, tabbackground);
				border: 1px solid map-get($COLORS, borders);
				margin-bottom: 0.625em;
				border-radius: 2px;
			}

			.changeServiceBtn {
				margin-right: 0.25em;
			}

			&_buttonContainer {
				text-align: right;
			}
		}
	}
}
