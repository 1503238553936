.ex-activationInProgress {
    &_hiker-container {
        height: 125px;
        width: 125px;
        background-color: var(--explorange);
        border-radius: 50%;
        display: inline-block;

        img {
            transform: scale(0.8, 0.8);
        }
    }
}