@mixin teamTrackingTour() {
	.ex-tour, .ex-tour-top {
		width: 100%;
	}

	#modal_new_group {
		.modal-dialog {
			top: 20%;
		}

		.ex-team_tracking_tour {
			position: absolute !important;
			width: 100%;
		}
	}

	.ex-page_groupsAndUsers .ex-tour-top {
		.ex-team_tracking_tour {
			@media (max-width: 1024px) {
				margin-bottom: 1em;
			}

			@media (max-width: 767px) {
				margin-bottom: 2px;
			}
		}
	}

	.ex-page_addDevice {
		.ex-team_tracking_tour {
			&-header {
				max-width: 64%;
				width: 64%;
				padding: 0 4px 0 20px;

				@media (max-width: 1280px) {
					max-width: 85%;
					width: 85%;
					padding-left: 16px;
					padding-right: 10px;
				}

				@media (max-width: 767px) {
					max-width: 100%;
					width: 100%;
					padding-left: 8px;
					padding-right: 8px;
				}
			}

			&-content {
				max-width: 64%;
				width: 64%;
				padding: 0;
				padding: 0 4px 0 20px;

				@media (max-width: 1280px) {
					max-width: 85%;
					width: 85%;
					padding-left: 16px;
					padding-right: 10px;
				}

				@media (max-width: 767px) {
					max-width: 100%;
					width: 100%;
					padding-left: 8px;
					padding-right: 8px;
				}
			}
		}
	}

	.ex-team_tracking_tour {
		position: unset !important;
		transform: none !important;
		max-width: 100%;
		margin-left: 0;
		margin-right: 0;
		background-color: #323e52;
		color: white;
		padding: 1rem 1rem 0.5rem 1rem;
		border-radius: 0;
		border: none;
		padding: 2px 0;

		@media (max-width: 767px) {
			margin-top: 2px;
		}

		@media (max-width: 767px) {
			margin-top: 2px;
		}

		&-header {
			display: flex;
			justify-content: space-between;
			max-width: 920px;
			margin: 0 auto;
			padding: 0 20px;

			&-title {
				@include subheader-text();
			}

			&-step_counter {
				margin-left: 1rem;
			}

			@media (max-width: 991px) {
				max-width: 720px;
			}
		}

		&-content {
			display: flex;
			max-width: 920px;
			margin: 0 auto;
			padding: 0 20px;

			@media (max-width: 991px) {
				max-width: 720px;
			}
		}

		.ex-actual-content {
			width: 100%;
		}

		.ex-nav {
			display: flex;
			justify-content: flex-end;
			padding: 0.5rem 0;
			align-content: center;

			& button {
				margin-left: 0.25rem;
				color: white;
				border-color: white;

				&:hover {
					color: map-get($COLORS, explorange);
					background-color: white;
				}

				&:focus {
					box-shadow: 0 0 0 1.5px white;
				}
			}
		}

		.ex-button-nav {
			display: flex;
			justify-content: flex-end;
			padding:0 0 0.5rem 0;
			align-content: center;

			& button {
				margin-left: 0.25rem;
				color: white;
				border-color: white;

				&:hover {
					color: map-get($COLORS, explorange);
					background-color: white;
				}

				&:focus {
					box-shadow: 0 0 0 1.5px white;
				}
			}
		}
	}
}
