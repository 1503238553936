.ex-progress {
	border: 1px solid map-get($COLORS, borders);
	background: map-get($COLORS, light);
	border-radius: 1rem;
	position: relative;
	height: 1rem;
	width: 50%;
	min-width: 20rem;
	margin: 1rem auto;

	&_inner {
		background: map-get($COLORS, explorange);
		border-radius: 1rem;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
	}

	@include bp('small') {
		width: initial;
		margin-left: 1em;
		margin-right: 1em;
	}
}
