.ex-homepageSubscriptionEdit {
	position: absolute;
	left: calc(33.3% + 2em);
	top: 6em;
	bottom: 2em;
	right: 2em;
	flex-basis: calc(100% / 3 * 2 - 4em);
	padding: 1em;
	transition: opacity 0.4s ease-in-out;
	opacity: 0;
	z-index: -1;

	.ex-homepageSubscriptions_past-due & {
		top: 2em;
	}

	.ex-homepageSubscriptions_loading & {
		display: none;
	}

	&.full-width {
		left: 2em;
	}

	@include bp('medium') {
		flex-basis: calc(100% / 3 - 0.25em);
	}

	@include bp('small') {
		flex-basis: 100%;
		margin-bottom: 1em;
		position: static;
		display: none;
	}

	&_editing {
		opacity: 1;
		z-index: 1;

		@include bp('small') {
			display: block;
		}
	}

	&_section {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0;
		margin-bottom: 1rem;

		.ex-homepageSubscriptions_loading & {
			margin-bottom: 0.5em;

			@include loading();
		}

		&-bottom {
			position: absolute;
			bottom: 1em;
			left: 0.25em;
			right: 0.25em;
			opacity: 0;
			z-index: -1;
			transition: opacity 0.3s ease-in-out;

			.ex-homepageSubscriptions_loading & {
				visibility: hidden;
			}

			.ex-homepageSubscription_section {
				margin-bottom: 1em;
			}

			.ex-homepageContentArea_expanded & {
				z-index: 0;
				opacity: 1;
			}

			.manage-plan-card & {
				z-index: 0;
				opacity: 1;
			}
		}
	}

	&_content {
		@include bp('small') {
			padding-bottom: 4em;
		}
	}

	&_title {
		font-size: 1.25rem;
		line-height: 1.25;
		font-weight: bold;
		flex-basis: 100%;
		margin-bottom: 1rem;
	}

	&_label {
		font-size: 1rem;
		line-height: 1.25;
	}

	&_button {
		font-weight: bold;
		font-size: 1em;
		min-width: 10em;
		margin: auto;

		@include bp('medium') {
			min-width: 0;
		}

		&-link {
			padding: 0.375rem 0.75rem;
			border: none;
			background: none;
			color: var(--explorange);
			max-width: 8em;

			&:hover {
				background: none;
				border: none;
				text-decoration: underline;
			}
		}
	}

	&_actions {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;

		@include bp('small') {
			margin-right: 1em;
			margin-bottom: 1em;
		}
	}

	&_action {

		@include bp('small') {
			flex-basis: auto;
			margin-left: 1em;
		}
	}

	&_priceDescription {
		.suspend-warning {
			margin-top: 1.5rem;
		}

		div {
			margin-bottom: 0.5rem;
		}

		.manage-plan-card & {
			&_confirmDeactivate {
				color: var(--bluelink);
			}
		}
	}
}
