#consent_blackbar {
	position: fixed;
	z-index: 110;
	width: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000;
	margin: 0 auto;

	@include bp('small') {
		top: 0;
		bottom: unset !important;
	}
}

// Weird hack until we fix tealium 
#truste-consent-text [id="truste-cookie-button"] {
	color: var(--buttonprimary) !important;
}

.ex-banner,
.site-notification-banner {
	min-height: 40px;
	width: 100%;
	position: absolute;
	left: 0;
	padding: 0.5em 1em;
	padding-right: 3em !important;
	z-index: 100010 !important;

	/* this class is filled in from the Site Notifcation model */
	.notification-content.has-moreInfo {
		padding-right: 10px;
		border-right: 2px solid #fff;
		margin-right: 10px;

		@include bp('small') {
			border-right: none;
			margin-right: 0;
			padding-right: 0;
		}
	}

	&-moreInfo {
		@include bp('small') {
			display: block;
		}
	}

	.notification-more-info {
		color: #fff;
		font-weight: bold;
		cursor: pointer;

		@include bp('small') {
			display: block;
		}

		&:hover {
			text-decoration: underline !important;
		}
	}

	.notification-placeholder {
		visibility: hidden;
		min-height: 40px;
	}

	&-closeBanner {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		display: flex;
		justify-content: center;

		a {
			color: white;
			border-radius: 50%;
			border: 1px solid white;
			width: 1em;
			height: 1em;
			display: block;
			text-decoration: none;
			font-weight: bold;
			@include subheader-text();
			line-height: 0.65;
			padding-left: 0.05em;

			@include bp('small') {
				padding-left: 0;
			}

			&:hover {
				background: map-get($COLORS, contentbackground);
				color: map-get($COLORS, explorange);
			}
		}
	}

	.btn:hover {
		color: #ff5723;
	}
}
