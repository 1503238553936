.ex-alertBanner {
	background: map-get($COLORS, bodybackground);
	position: relative;

	&_content {
		padding: 1em;
		max-width: 40rem;
		margin: auto;
		text-align: center;
	}

	&_title {
		color: map-get($COLORS, explorange);
		font-size: 1.5rem;
		font-weight: bold;
		line-height: 1.25;
	}

	&_message {
		color: map-get($COLORS, primarytext);
		font-size: 1rem;
		line-height: 1.125;
	}

	&_active {
		.innerLogOnContainer {
			width: 100% !important;
			max-width: 33em !important;
		}

		#LogOnFlexContainerRight {
			padding: 25px 50px !important;
		}

		#LogOnFlexContainerLeft {
			padding: 25px 50px !important;
		}
	}
}
