.ex-homepageStatSecondary {
	height: 8em;
	border: 2px solid map-get($COLORS, bordersdark);
	border-radius: 5px;
	padding: 1em;
	margin-top: 1em;
	margin-bottom: 1em;

	.ex-homepageStats_loading & {
		width: 11em;
		margin: 1em 0.5rem;
	}

	@include bp('small') {
		padding: 0.875em;
	}

	&_centered {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;

		& > * {
			flex-basis: 100%;
		}
	}

	&_title {
		font-weight: bold;
		margin-bottom: 0.75rem;
		font-size: 1.125rem;
		line-height: 1.25rem;
		text-align: center;

		@include bp('small') {
			font-size: 0.875rem;
		}

		.ex-homepageStats_loading & {
			@include loading();
			min-height: 2rem;
		}
	}

	&_value {
		font-weight: 400;
		font-size: 1rem;
		line-height: 1.25;
		text-align: center;

		& + & {
			margin-bottom: 0.5rem;
		}

		.ex-homepageStats_loading & {
			@include loading();
			min-height: 1rem;
		}

		@include bp('small') {
			font-size: 0.825rem;
		}
	}
}
