﻿
/* have to override this in the element's style itself, bootstrap not respecting this*/
form #CardNumber, .cc-entry-field {
    padding-left: 60px !important;
    width: 200px !important;
    height: 20px !important;
    border-radius: 5px !important;
}

form #CardNumber:before {
    content: "";
    background-position: -0px -0px;
}

form .CardNumberByClass:before {
    content: "";
    background-position: -0px -0px;
}

.check-gray, .check-green, .cc-gray, .cc-visa, .cc-mastercard, .cc-discover, .cc-amex {
    display: inline-block;
    background-image: url(/content/images/CCSprite.png), url(/content/images/CCSprite.png);
    background-repeat: no-repeat;
    background-position: -1px -68px, 0px -12px !important;
}

.check-gray {
    background-position: -0px -12px !important;
    height: 16px !important;
    width: 20px !important;
}

.check-green {
    background-position: -0px -3px !important;
    height: 16px !important;
    width: 20px !important;
}

.cc-gray {
    background-position: 0px -66px, 235px -3px !important;
}

.cc-visa {
    background-position: 0px -118px, 235px -3px !important;
}

.cc-mastercard {
    background-position: 0px -165px, 235px -3px !important;
}

.cc-discover {
    background-position: 0px -212px, 235px -3px !important;
}

.cc-amex {
    background-position: 0px -260px, 235px -3px !important;
}

.cc-valid.cc-gray {
    background-position: 0px -66px, 235px -32px !important;
}

.cc-valid.cc-visa {
    background-position: 0px -118px, 235px -32px !important;
}

.cc-valid.cc-mastercard {
    background-position: 0px -165px, 235px -32px !important;
}

.cc-valid.cc-discover {
    background-position: 0px -212px, 235px -32px !important;
}

.cc-valid.cc-amex {
    background-position: 0px -260px, 235px -32px !important;
}
