@mixin manage-mapshare() {
	.ex-page_manage_mapshare {

		a {
			color: var(--explorange);
		}

		.mapshare-url-edit {

			&-title {
				color: var(--secondarytext);
			}

			&-button {
				display: flex;
				justify-content: space-between;
				background-color: var(--primarybackground);
				border-color: var(--formborder);

				&-text {
					text-decoration: underline;
					color: var(--explorange);
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					transition: color linear 0.15s;

					&.mapshare-disabled {
						text-decoration: none;
						color: var(--secondarytext);
						pointer-events: none;
					}
				}

				.glyphicon {
					font-size: 1.25rem;
					color: var(--formborder);
				}
			}
		}

		.mapshare-social {
			&-title {
				color: var(--secondarytext);
			}

			&-pfp {
				height: 2rem;
				width: 2rem;
				margin-top: 0.25rem;
				margin-right: 0.5rem;
				border-radius: 0.25rem;
			}

			&-link {
				text-decoration: underline;
				color: var(--explorange);
			}

			&-info {
				color: var(--secondarytext);

				&-value {
					color: var(--primarytext);
				}
			}
		}

		.mapshare-title-icon {
			border-radius: 0.25rem;
			margin-right: 0.5rem;
			vertical-align: bottom;
		}

		.mapshare-floating-button-container {
			background: var(--primarybackground);
			padding: 1rem;
			border-top: 1px solid rgba(0, 0, 0, 0.125);
			margin-top: 0.25rem;
		}

		.livetrack-url-edit {

			&-infobox {
				border-top: solid;
				border-width: 1px;
				border-color: var(--borderslight);
				padding-top: 0.5rem;
				margin-top: 0.75rem;
				flex-wrap: wrap;
			}

			&-linktext {
				text-decoration: underline;
				color: var(--explorange);
				overflow-wrap: break-word
			}

			&-icon {
				color: var(--explorange);
				margin-left: 0.75rem;
				padding-top: 0.2rem;
			}
		}

		.livetrack-info-header {
			font-weight: bold;
			margin-top: 0.75rem;
		}

		.livetrack-button {
			width: 6rem;
			border-radius: 0.25rem;
			margin-top: 0.75rem;
			margin-left: 0.75rem;
		}

		.livetrack-button-link {
			padding-left: 0;
			border: none;
			background-color: var(--primarybackground);
			color: var(--explorange);
		}

		.livetrack-dropdown {
			width: 100%;
			border: solid;
			border-width: 2px;
			border-radius: 0.25rem;
			padding: 0.75rem;
			margin-top: 0.25rem;
			margin-bottom: 0.25rem;
		}

		.livetrack-nav-button {
			margin: 0;
			margin-top: 0.75rem;
			padding: 0;
			padding-bottom: 0.75rem;
			border: none;
			width: 100%;
			background-color: var(--primarybackground);
			text-align: left;
			color: var(--primarytext);
		}

		.livetrack-highlight {
			color: var(--explorange);
		}

		.spinner-border-large {
			width: 4rem !important;
			height: 4rem !important;
			border: 0.35em solid currentColor;
			border-right-color: transparent;
		}
	}
}
