@mixin site() {
	#content,
	#logonPageContent,
	.ex-content {
		.nav,
		.ex-nav {
			position: relative;
			min-height: 60px;
			margin: 0 13em 0 13em;
			align-items: center;
			white-space: nowrap;

			@include bp('small') {
				margin: auto;
				align-items: flex-start;
				top: auto;
			}

			&Sync {
				position: absolute;
				top: 30px;
				right: 225px;
				width: 20px;
			}

			ul {
				width: 100%;
				height: 100%;
				list-style: none;
				margin: 0;
				padding: 0;

				@include bp('small') {
					flex-wrap: wrap;
				}

				li {
					display: inline-block;
					margin: 0 -5px -1px 0;
					padding: 8px 0;
					height: 100%;

					a,
					a:link,
					a:visited {
						color: #000;
						width: 100%;
						height: 100%;
						padding: 8px 14px 8px 14px;
						text-transform: uppercase;
						font-weight: bolder;
						text-decoration: none;
						display: inline-block;
						line-height: 100%;

						#navbar & {
							text-transform: none;
						}

						&:before {
							content: '';
							display: inline-block;
							height: 100%;
							vertical-align: middle;
						}

						@include bp('(max-width: 560px)') {
							padding: 2px 4px;
						}
					}
				}
			}

			&_secondary {
				@include bp('small') {
					margin: auto;
					align-items: flex-start;
					top: auto;
				}

				ul {
					li {
						text-transform: capitalize !important;

						@include bp('small') {
							min-width: 33%;
							text-align: center;
						}

						a {
							text-transform: none !important;

							&:hover {
								color: map-get($COLORS, explorange) !important;
								border: none !important;
							}
						}
					}
				}

				.currenttab,
				.sub-toolbar-active {
					color: map-get($COLORS, explorange) !important;
					border: none !important;
				}
			}
		}


		.navmenu ul {
			cursor: pointer;
		}
	}

	#content.layoutnoimagefooter {
		.ex-nav {
			&_secondary {
				@include bp('small') {
					margin: 0 auto;
				}
			}
		}

		.wide-content {
			position: absolute;
			left: 10px;
			right: 10px;
			margin-top: 16px;
		}
	}

	ul.token-input-list-facebook {
		margin: 0;
	}

	html {
		height: 100%;
		font-size: 100%;
	}

	a.with-underline {
		text-decoration: underline;
	}

	body {
		margin: 0;
		height: 100%;
		background-color: #FFFFFF;
		font-family: $FONT-default, 'Arial', sans-serif;
		font-size: 16px;
	}

	body *:lang(zh-CN) {
		font-family: $FONT-default, Arial, Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Heiti SC', 'WenQuanYi Zen Hei', 'Microsoft YaHei', sans-serif;
	}

	body *:lang(zh-TW) {
		font-family: $FONT-default, Arial, Helvetica, 'PingFang TC', 'Heiti TC', 'Microsoft JhengHei', sans-serif;
	}

	body *:lang(ja) {
		font-family: $FONT-default, Arial, Helvetica, 'YuGothic', 'Hiragino Kaku Gothic ProN', 'Meiryo', sans-serif;
	}

	body.fullWidth {
		background-image: none !important;
	}

	.explore-orange {
		color: map-get($COLORS, explorange) !important;
	}
	/* Header---------------------- */
	#header {
		min-height: 60px;
		border-bottom: 1px solid #DEDEDE;
		background: map-get($COLORS, contentbackground);
	}

	#header-selector {
		height: 1px;
		position: absolute;
		transition: all ease-in-out 0.2s;
		background-color: #ff5723;
		z-index: 1;


		@include bp('medium') {
			display: none;
		}
	}

	.link-disabled {
		pointer-events: none;
  		cursor: default;
	}

	.logo {
		position: absolute;
		top: 15px;
		left: 10px;

		&.ex-header_logo {
			display: block;

			@include bp('large-only') {
				display: none;
			}
		}

		&.ex-header_logo_small {
			display: none;

			&.ex-header_logo_small_override {
				top: 10px;
				display: block;
			}

			@include bp('large-only') {
				top: 10px;
				display: block;
			}
		}
	}

	.logo-ad {
		position: absolute;
		top: 2px;
		left: 2px;
	}


	.dl-horizontal.deviceDetails dd {
		line-height: 17px;
	}

	#logindisplay {
		position: absolute;
		top: 0;
		right: 0;
		text-align: left;
		height: 60px;
		padding: 5px;
	}


	.dateDrop {
		width: 75px;
	}

	.invoicetable td {
		text-align: center;
		padding: 5px;
	}

	.invoicetable th {
		text-align: center;
		padding: 5px;
	}

	.devicetab-sync-icon {
		float: right;
		position: relative;
		right: 6px;
	}

	.syncnavbutton {
		-webkit-border-radius: 7px 7px 0 0;
		-moz-border-radius: 7px 7px 0 0;
		-o-border-radius: 7px 7px 0 0;
		border-radius: 7px 7px 0 0;
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#ffffff), to(#cdcdcd)) !important;
		background-image: -moz-linear-gradient(0% 100% 90deg,#cdcdcd, #ffffff) !important;
		background: linear-gradient(0deg, #cdcdcd, #ffffff) !important;
		background-color: #EEEEEE !important;
		border: 0;
		margin-left: 20px !important;
		-webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0);
		-moz-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0);
		box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0);
	}

	.syncnavbutton:hover {
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#e6e6e6), to(#ffffff)) !important;
		background-image: -moz-linear-gradient(0% 100% 90deg,#e6e6e6, #ffffff) !important;
		background: linear-gradient(0deg, #e6e6e6, #ffffff) !important;
		background-color: #FBFBFB !important;
	}

	.currenttab.syncnavbutton:hover {
		background-color: #FFFFFF !important;
		background-image: none !important;
	}

	.syncnavbutton a:link {
		background-color: transparent !important;
		color: #000000 !important;
		text-decoration: none;
	}


	.syncnavbutton a:visited {
		background-color: transparent !important;
		color: #000000 !important;
		text-decoration: none;
	}

	.syncnavbutton-danger {
		-webkit-border-radius: 7px 7px 0 0;
		-moz-border-radius: 7px 7px 0 0;
		-o-border-radius: 7px 7px 0 0;
		border-radius: 7px 7px 0 0;
		background-color: #FF0000 !important;
		filter: none !important;
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#FF8B8B), to(#FF0000)) !important;
		background-image: -moz-linear-gradient(0% 100% 90deg,#FF8B8B, #FF0000) !important;
		background: linear-gradient(180deg, #FF8B8B, #FF0000) !important;
		border: 0;
		margin-left: 20px !important;
	}

	.syncnavbutton-danger a {
		background-image: none;
	}

	.syncnavbutton-danger a:hover {
		background-image: none;
	}

	.syncnavbutton-danger:hover {
		background-color: #FF5959 !important;
		filter: none !important;
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#FFFFFF), to(#FF5959)) !important;
		background-image: -moz-linear-gradient(0% 100% 90deg,#FFFFFF, #FF5959) !important;
		background: linear-gradient(180deg, #FFFFFF, #FF5959) !important;
	}

	.syncnavbutton-danger a:link {
		color: #FFFFFF !important;
		text-decoration: none;
	}


	.syncnavbutton-danger a:visited {
		color: #FFFFFF !important;
		text-decoration: none;
	}

	.currenttab.syncnavbutton-danger:hover {
		background-color: #FFFFFF !important;
		background-image: none !important;
	}

	.currenttab.syncnavbutton-danger a:visited {
		color: #000000 !important;
	}

	.clickpic:hover {
		cursor: pointer;
	}


	.top-row {
		padding-top: 10px;
	}

	.allow-vertical-overflow {
		overflow-y: auto;
	}
	/* Body------------------------*/
	.ex-page_textMessage-full, .ex-page_contacts, .ex-page_social {
		background-color: #F9F9FC;

		.nav.ex-nav_secondary ul {
			background-color: #ffffff;
			background-clip: border-box;
			border: 1px solid rgba(0, 0, 0, 0.125);
			border-radius: 0.25rem;
		}
	}

	#contentwrapper {
		text-align: center;
		min-height: 100%;
		height: auto !important;
		height: 100%;
		margin: 0 auto -392px;
		width: 100%;
	}

	@media (max-width: 950px) {
		#content {
			text-align: left;
			border: 0;
			min-height: 100%;
			height: auto !important;
			height: 100%;
			padding: 0px 0px 0px 0px;
		}
	}

	@media(min-width: 950px) {
		#content {
			text-align: left;
			border: 0;
			min-height: 100%;
			height: auto !important;
			height: 100%;
			width: 950px;
			margin: auto;
			padding: 0px 0px 0px 0px;
		}
	}


	@media(max-width:560px) {
		.phonesizedialog {
			position: absolute;
			left: 10px;
			right: 10px;
			width: auto;
			margin: 0px;
			top: 25% !important;
		}

		#logindisplay {
			@include small-body-text();
		}

		#header {
			height: 100px;
		}

		.container {
			width: auto !important;
		}

		.ui-dialog {
			width: 97% !important;
		}
	}

	@media(max-width:767px) {
		.border-right-no-phone {
			border-right: hidden;
		}

		.password-padding-no-phone {
		}

		body {
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
	}

	@media(min-width:767px) {
		.border-right-no-phone {
			border-right: 1px solid #D9D9D9;
		}

		.password-padding-no-phone {
			padding-top: 40px;
		}
	}

	.plantitle {
		margin: 0;
		padding: 0;
		font-weight: normal;
		color: #0d3c7b;
		@include subheader-text();
	}

	.plantitleselected {
		margin: 0;
		padding: 0;
		font-weight: normal;
		color: #377312;
		@include subheader-text();
	}


	.label {
		color: #a4a4a4;
		@include small-body-text();
	}

	.smalltext {
		font-size: 10px;
	}

	.graytext {
		color: #696969 !important;
	}

	.successtext {
		color: green !important;
	}

	.failtext {
		color: map-get($COLORS, danger) !important;
	}

	.errortext {
		color: #e80202;
	}

	#billingInfoButtonLink {
		color: white !important;
		text-decoration: none !important;
	}

	.editor-label label {
		margin-bottom: 0px;
	}

	.dropdown {
		width: 220px;
		line-height: 28px;
		padding: 4px;
		vertical-align: middle;
		margin: 0px;
	}

	input[type=text].shortfield {
		width: 50px;
	}

	.button {
		padding: 5px 10px;
		@include small-body-text();
		line-height: 1;
		color: #FFF;
		background: #5b7599; /* Old browsers */
		background: -moz-linear-gradient(top, #5b7599 0%, #2e3542 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#5b7599), color-stop(100%,#2e3542)); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top, #5b7599 0%,#2e3542 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top, #5b7599 0%,#2e3542 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top, #5b7599 0%,#2e3542 100%); /* IE10+ */
		background: linear-gradient(to bottom, #5b7599 0%,#2e3542 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b7599', endColorstr='#2e3542',GradientType=0 ); /* IE6-9 */
		background-color: #e5e5e5;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-o-border-radius: 4px;
		border-radius: 4px;
		border: 1px solid #a6a6a6;
		width: auto;
		margin: 10px 10px 10px 0;
		cursor: pointer;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
	}

	.graybutton {
		padding: 5px 10px;
		@include small-body-text();
		line-height: 1;
		color: #b4b4b4;
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#fefefe), to(#cdcdcd));
		background-image: -moz-linear-gradient(0% 100% 90deg,#cdcdcd, #fefefe);
		background-color: #e5e5e5;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-o-border-radius: 4px;
		border-radius: 4px;
		border: 1px solid #a6a6a6;
		width: auto;
		margin: 10px 10px 10px 0;
		cursor: pointer;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fefefe', endColorstr='#cdcdcd');
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
	}

	.greenbutton {
		padding: 5px 10px;
		@include small-body-text();
		line-height: 1;
		color: #FFF;
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#74dd53), to(#2e8612));
		background-image: -moz-linear-gradient(0% 100% 90deg,#2e8612, #74dd53);
		background-color: #50b032;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-o-border-radius: 4px;
		border-radius: 4px;
		border: 1px solid #a0a0a0;
		float: left;
		width: auto;
		margin: 10px 10px 10px 0;
		cursor: pointer;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#74dd53', endColorstr='#2e8612');
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
	}

	.greensuccess {
		padding: 5px 10px;
		@include small-body-text();
		line-height: 1;
		color: #FFF;
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#74dd53), to(#2e8612));
		background-image: -moz-linear-gradient(0% 100% 90deg,#2e8612, #74dd53);
		background-color: #50b032;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-o-border-radius: 4px;
		border-radius: 4px;
		border: 1px solid #a0a0a0;
		margin: 10px 10px 10px 0;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#74dd53', endColorstr='#2e8612');
	}

	.detailstable {
		margin: 0;
		padding: 0;
		border: 1px solid #d3d3d3;
		background-color: #e7e7e7;
		width: 100%;
	}

	.detailstable tr {
		margin: 0;
	}

	.detailstable tr:first-child {
		background-color: #d8d8d8;
		padding: 0;
		margin: 0;
	}

	.detailstable tr td {
		margin: 0;
		padding: 5px;
	}

	.detailstable tr:first-child td:first-child {
		font-weight: bold;
	}

	.note {
		padding: 10px;
		background-color: #d2e0f1;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-o-border-radius: 4px;
		border-radius: 4px;
	}

	.detailstable tr td:nth-child(2) {
		text-align: right;
	}

	.column {
		margin: 15px;
	}

	.tablecellbuffer {
		margin: 10px 15px 0 15px;
	}

	#logonLeft {
		float: left;
		width: 49%;
	}

	#logonRight {
		float: left;
		width: 49%;
	}

	#divLeft {
		float: left;
		width: 30%;
		height: 300px;
		margin-left: 5px;
		margin-right: 5px;
		border-right: 1px solid black;
	}

	#divCenter {
		float: left;
		width: 30%;
		height: 300px;
		border-right: 1px solid black;
		margin-left: 5px;
		margin-right: 5px;
	}

	#divRight {
		float: left;
		width: 30%;
		height: 300px;
		margin-left: 5px;
		margin-right: 5px;
	}

	#footer {
		border-top: 1px solid #CCC;
		width: 890px;
		margin-top: 100px;
		height: 60px;
		text-align: left;
	}

	#footer td {
		padding: 5px 30px 0 0;
		color: #777;
	}
	/*Opera Fix*/
	body:before {
		content: "";
		height: 100%;
		float: left;
		width: 0;
		margin-top: -32767px;
	}

	.helplinks {
		margin-top: 100px;
	}

	.screened {
		opacity: 0.3;
	}

	.extrapad {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.setup-list li {
		@include subheader-text();
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.setup-list li p div span {
		@include secondary-body-text();
	}

	.field-validation-error {
		color: map-get($COLORS, danger) !important;
	}

	.field-validation-valid {
		display: none;
	}

	.validation-summary-errors {
		color: map-get($COLORS, danger) !important;
		padding-top: 5%;
	}

	.validationMessage {
		width: 100%;
		color: map-get($COLORS, danger) !important;
	}

	.border-right {
		border-right: 1px solid lightgray;
	}

	.footer-info {
		border-top: 1px solid #D9D9D9;
		padding-top: 20px;
	}

	#bottom-image-container {
		background-image: url(/content/images/Background-Aviation.jpg);
		background-position: bottom;
		background-repeat: no-repeat;
		position: relative;
		margin: auto;
		text-align: left;
		height: 392px;
	}

	#inreach-image {
		float: left;
	}

	.footer {
		margin-top: -20px;
		height: 20px;
		background-color: #eee;
		position: absolute;
		right: 0px;
		left: 0px;
		z-index: 1010;
	}

	.footer-image, .push {
		height: 392px;
		text-align: center;
		width: 950px;
		margin: auto;
		background-color: #FFFFFF;
	}

	.sub-heading {
		color: #005580;
	}

	#thread-container {
		padding-top: 10px;
		margin-right: 20px;
		padding-left: 10px;
	}

	.list-item {
		position: relative;
		cursor: pointer;
		border-bottom: 1px solid #d2d3d7;
		background-color: #e7ebee;
		padding: 1em;
	}

	.list-item:hover {
		background-color: #F2F2F2;
	}

	.list-item-active {
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#668099), to(#33414D));
		background-image: -moz-linear-gradient(0% 100% 90deg,#33414D,#668099 );
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#668099', endColorstr='#33414D');
		background: -ms-linear-gradient(top, #668099 0%,#33414D 100%); /* IE10+ */
		color: White;
	}

	.list-item-group-header {
		background: #bcbcbc; /* Old browsers */
		background: -moz-linear-gradient(top, #bcbcbc 0%, #4f4f4f 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#bcbcbc), color-stop(100%,#4f4f4f)); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top, #bcbcbc 0%,#4f4f4f 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top, #bcbcbc 0%,#4f4f4f 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top, #bcbcbc 0%,#4f4f4f 100%); /* IE10+ */
		background: linear-gradient(to bottom, #bcbcbc 0%,#4f4f4f 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bcbcbc', endColorstr='#4f4f4f',GradientType=0 ); /* IE6-9 */
		padding-top: 5px;
		padding-bottom: 5px;
		color: White;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding-left: 10px;
	}


	.list-item-clear {
		height: 2em;
		background-color: White;
		border-bottom: 1px solid #A8A8A8;
		padding: 0.3em;
		position: relative;
		vertical-align: middle;
	}

	.inbox-thread {
		cursor: pointer;
		border-bottom: 1px solid #A8A8A8;
		background-color: #e7ebee;
		padding: 5px;
		position: relative;
		background-image: url(/content/images/InboxArrow.png);
		background-position: 95% 50%;
		background-repeat: no-repeat;
	}

	.inbox-thread-first {
		border-top: 1px solid #A8A8A8;
	}

	.inbox-thread span {
		margin-left: 20px;
	}

	.inbox-thread h4 {
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 230px;
	}

	.inboxThreadStatus {
		line-height: 10px;
	}

	.inboxExpandSelect {
		position: absolute;
		right: 5px;
		height: 100%;
		@include title-text();
		color: White;
		top: 20px
	}

	.inboxThreadDate {
		@include small-body-text();
		display: inline-block;
		clear: both;
	}

	.inbox-thread:hover {
		background-color: #F2F2F2;
	}

	.inbox-thread-active {
		background-image: url(/content/images/InboxArrow.png), -webkit-gradient(linear, 0% 0%, 0%100%, from(#668099), to(#33414D));
		background-image: url(/content/images/InboxArrow.png), -moz-linear-gradient(0% 100% 90deg,#33414D,#668099 );
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#668099', endColorstr='#33414D');
		background: -ms-linear-gradient(top, #668099 0%,#33414D 100%); /* IE10+ */
		margin-right: -1px;
		color: White;
		background-position: 95% 50%;
	}

	.inbox-reply-instr {
		text-align: center;
		font-weight: bold;
	}

	.inbox-msg-bubble {
		padding: 10px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-o-border-radius: 4px;
		border-radius: 4px;
		margin-right: 20px;
		position: relative;
		min-height: 15px;
	}

	.inbox-thread-send {
		text-align: right;
	}

	.inbox-thread-receive {
		text-align: left;
	}

	.blue-rad {
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#abd2fd), to(#85b0de));
		background-image: -moz-linear-gradient(0% 100% 90deg,#abd2fd, #85b0de);
		background-image: linear-gradient(to bottom, #abd2fd 0%, #85b0de 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#abd2fd', endColorstr='#85b0de');
		word-wrap: break-word;
	}

	.green-rad {
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#b6de92), to(#a6d381));
		background-image: -moz-linear-gradient(0% 100% 90deg,#b6de92, #a6d381);
		background-image: linear-gradient(to bottom, #b6de92 0%, #a6d381 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b6de92', endColorstr='#a6d381');
		word-wrap: break-word;
	}

	.bubble-arrrow-left {
		position: absolute;
		top: 8px;
		left: 0px;
		margin-left: -9px;
	}

	.bubble-arrrow-right {
		position: absolute;
		top: 8px;
		right: 0px;
		margin-right: -9px;
	}

	.message-container {
		padding-bottom: 20px;
	}

	.inbox-functions {
		float: right;
		margin-right: 20px;
		margin-top: -25px;
	}

	.inbox-functions ul li {
		list-style-type: none;
		display: inline;
	}

	.inbox-functions li {
		height: 25px;
		border-left: 1px solid lightgray;
	}

	.inbox-functions li a {
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 3px;
		padding-bottom: 3px;
	}

	.loader-div {
		text-align: center;
		padding: 50px;
	}

	.popup {
		position: relative;
		text-align: left;
		overflow: hidden;
		z-index: 1 !important;
	}

	.popupHeader {
		display: block;
		float: left;
		width: 45%;
		font-weight: bold;
		@include subheader-text();
		margin-left: 5px;
		margin-top: 5px;
		margin-right: 5px;
	}

	.popupDate {
		color: GrayText;
		@include small-body-text();
		line-height: 15px;
		margin-right: 5px;
	}

	.popupText {
		display: block;
		clear: both;
		margin-bottom: 5px;
		padding-top: 10px;
		margin-left: 5px;
		overflow: hidden;
		max-width: 180px;
	}

	.popupLowerSection {
		float: left;
		padding-left: 5px;
		padding-right: 5px;
	}

	.popupSpeed {
		color: GrayText;
		display: block;
		float: left;
		width: 49%;
		margin-top: 5px;
		@include small-body-text();
	}

	.popupHeading {
		color: GrayText;
		display: block;
		float: left;
		width: 49%;
		text-align: right;
		margin-top: 5px;
		@include small-body-text();
	}

	.popupElevation {
		color: GrayText;
		display: block;
		float: left;
		width: 98%;
		@include small-body-text();
	}

	.popupLocation {
		color: GrayText;
		display: block;
		float: left;
		color: #005580;
		width: 100%;
		padding-bottom: 10px;
		@include small-body-text();
	}

	.popupLinks {
		clear: both;
		display: block;
		width: 100%;
		text-align: justify;
		padding-top: 10px;
	}

	.popupLinks a {
		float: left;
		display: block;
		width: 33.3%;
		text-align: center;
		vertical-align: text-bottom;
		height: 15px;
		padding-top: 20px;
	}

	#plTrack {
		background-image: url("/content/images/map/Popup-Track.png");
		background-position: center top;
		background-repeat: no-repeat;
		cursor: pointer;
	}

	#plLocate {
		background-image: url("/content/images/map/Popup-Locate.png");
		background-position: center top;
		background-repeat: no-repeat;
		cursor: pointer;
	}

	#plMsg {
		background-image: url("/content/images/map/Popup-Message.png");
		background-position: center top;
		background-repeat: no-repeat;
		cursor: pointer;
	}

	.olFramedCloudPopupContent {
		filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr='#EAEAEA', endColorstr='#D0D0D0'); /* for IE */
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#EAEAEA), to(#D0D0D0));
		background-image: -moz-linear-gradient(0% 100% 90deg,#D0D0D0,#EAEAEA );
		background: -o-linear-gradient(#EAEAEA,#D0D0D0);
		background: -ms-linear-gradient(#EAEAEA,#D0D0D0);
		background: linear-gradient(#EAEAEA,#D0D0D0);
		-webkit-border-radius: 7px;
		-moz-border-radius: 7px;
		-o-border-radius: 7px;
		border-radius: 7px;
		overflow: visible !important;
		top: 20px !important;
		padding: 0 !important;
		position: inherit;
		-moz-box-shadow: 0 0 5px 5px rgba(0,0,0,0.25);
		-webkit-box-shadow: 0 0 5px 5px rgba(0,0,0,0.25);
		box-shadow: 0 0 5px 5px rgba(0,0,0,0.25);
	}

	.olPopup {
		width: 185px;
		padding-right: 10px;
	}

	.ui-dialog-titlebar-close {
		display: none;
	}

	.ui-widget-overlay {
		background-image: url("/content/themes/base/images/ui-bg_flat_0_aaaaaa_40x100.png");
		background-repeat: repeat;
	}

	.clear {
		clear: both;
		display: block;
		height: 1px;
		overflow: hidden;
		margin: 0;
		padding: 0;
	}

	.device-message {
		position: relative;
		background-color: #e7ebee;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 15px;
		padding-right: 15px;
		min-height: 25px;
		border-bottom: 1px solid lightgray;
	}

	#puck-messages-holder, #canned-messages-holder {
		.device-message > div:first-child {
			overflow: auto;
		}
	}

	.pad-sides {
		padding: 20px;
	}

	.pad-contact-headings {
		padding: 1em;
	}

	.pad-contact-headings-left-right {
		padding-left: 1em;
		padding-right: 1em;
	}

	.map-header {
		text-align: left;
	}

	.map-title {
		font-weight: bold;
		@include body-text();
	}

	.map-desc {
		font-weight: normal;
		@include secondary-body-text();
	}

	.mapshare-title {
		background-color: #636363;
		color: #fff;
		border: 1px solid #3e4c61;
		border-top: none;
		padding: 8px;
		cursor: pointer;
	}

	#mapshare-password-content {
		flex-grow: 1;
		background-position: center top;
		background-size: 100vw auto;
		background-repeat: no-repeat;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		text-align: left;
	}

	#mapshare-password-pane {
		position: relative;
		background-color: rgba(0, 0, 0, 0.5);
		border: 1px solid rgba(0, 0, 0, 0.6);
		padding: 25px;
		margin: 10px;
	}

	#mapshare-password-background {
		position: absolute;
		top: 10px;
		left: 10px;
		right: 10px;
		bottom: 10px;
		background-position: center -50px;
		background-size: 100vw auto;
		background-repeat: no-repeat;
		filter: blur(3px);
	}

	@media (max-width: 950px) {
		#mapshare-password-background {
			background-size: auto 100vh;
		}

		#mapshare-password-content {
			background-size: auto 100vh;
		}
	}

	.olControlPanel {
		top: 10px;
		left: 10px;
		width: 250px;
		height: 33px;
		position: absolute;
	}

	.olControlButton {
		position: absolute;
		top: 18px;
		right: 0;
		width: 200px;
		height: 18px;
	}

	.valid-password {
		border-color: map-get($COLORS, buttonsuccess);
		background-color: map-get($COLORS, buttonsuccess) !important;
		color: map-get($COLORS, contentbackground) !important;
		width: 220px;
		background-image: none;
	}

	.invalid-password {
		border-color: map-get($COLORS, danger);
		background-color: map-get($COLORS, danger) !important;
		color: map-get($COLORS, contentbackground) !important;
		width: 220px;
		background-image: none;
	}

	#password-validator {
		width: 190px;
	}

	.onoffswitch {
		position: relative;
		width: 59px;
		max-width: 59px;
		word-wrap: break-word;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}

	.onoffswitch-checkbox {
		display: none;
	}

	.onoffswitch-label {
		position: relative;
		display: inline-block;
		overflow: hidden;
		cursor: pointer;
		width: fit-content;
		width: -moz-fit-content;
		width: -webkit-fit-cotent;
		border: 2px solid #999999;
		border-radius: 20px;
	}

	.onoffswitch-inner {
		display: block;
		width: 200%;
		margin-left: -100%;
		-moz-transition: margin 0.3s ease-in 0s;
		-webkit-transition: margin 0.3s ease-in 0s;
		-o-transition: margin 0.3s ease-in 0s;
		transition: margin 0.3s ease-in 0s;
	}

	.onoffswitch-inner:before, .onoffswitch-inner:after {
		display: block;
		float: left;
		width: 50%;
		height: 20px;
		padding: 0;
		line-height: 20px;
		@include secondary-body-text();
		color: white;
		font-weight: bold;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.onoffswitch-switch {
		display: block;
		width: 10px;
		margin: 3.5px;
		background: #FFFFFF;
		border: 2px solid #999999;
		border-radius: 20px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		-moz-transition: all 0.3s ease-in 0s;
		-webkit-transition: all 0.3s ease-in 0s;
		-o-transition: all 0.3s ease-in 0s;
		transition: all 0.3s ease-in 0s;
	}

	.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
		margin-left: 0;
	}

	.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
		left: calc(100% - 20px);
		left: -moz-calc(100% - 20px);
		left: -webkit-calc(100% - 20px);
	}

	.mapControlButton {
		padding: 4px 6px;
		margin: 5px;
		border-radius: 6px;
		color: #111111;
		background-color: #F8F8F8;
		box-shadow: 1px 1px 2px rgba(0,0,0,.5), inset 0 0px 1px rgba(255,255,255,.5);
		cursor: pointer;
		user-select: none;
		border: 1px solid lightgray;
		@include secondary-body-text();
	}

	body[theme='flatGreen'] .mapControlButton {
		padding: 4px 6px;
		margin: 5px;
		border-radius: 6px;
		color: #2B6709;
		background-color: #F8F8F8;
		box-shadow: 1px 1px 2px rgba(0,0,0,.5), inset 0 0px 1px rgba(255,255,255,.5);
		cursor: pointer;
		user-select: none;
		border: 1px solid lightgray;
		@include secondary-body-text();
	}

	.mapControlButton:hover {
		background-color: #CFF4FF;
		border: 1px solid #CFF4FF;
	}

	body[theme='flatGreen'] .mapControlButton:hover {
		background-color: #FFD68D;
		border: 1px solid #FFD68D;
	}

	.mapControlButton + .mapControlButton {
		margin-top: 8px !important;
	}

	.mapControlButton.disabled {
		cursor: not-allowed !important;
		opacity: .65;
	}

	.mapControlButton.disabled:hover {
		background-color: #F8F8F8;
		border: 1px solid lightgray;
	}

	body[theme='flatGreen'] .mapControlButton.disabled:hover {
		background-color: #F8F8F8;
		border: 1px solid lightgray;
	}

	#zoominbutton .glyphicon {
		left: .5px;
		top: 2px;
		font-family: 'Glyphicons Halflings' !important;
	}

	#zoomoutbutton .glyphicon {
		right: .5px;
		top: 2px;
		font-family: 'Glyphicons Halflings' !important;
	}

	.glyphicon.glyphicon-plus {
		font-family: 'Glyphicons Halflings' !important;
	}

	#centerHeadcrumbButton #centerHeadcrumbIcon {
		width: 20px;
		height: 20px;
		display: block;
		background-image: url(/Content/images/MapControls/Map-Center.png);
	}

	body[theme='flatGreen'] #centerHeadcrumbButton #centerHeadcrumbIcon {
		width: 20px;
		height: 20px;
		display: block;
		background-image: url(/Content/images/MapControls/Map-Center-Green.png);
	}

	#mapBaseLayerControl {
		margin-top: 3px !important;
		margin-left: 16px;
		box-shadow: 1px 1px 2px rgba(0,0,0,.5), inset 0 0px 1px rgba(255,255,255,.5);
		background-color: #F8F8F8;
		border: 1px solid lightgray;
		padding: 2px 0px;
	}

	#mapBaseLayerControl .leaflet-control-layers-toggle {
		width: 31px;
		height: 25px;
		background-size: 18px 16px;
		background-image: url(/Content/images/MapControls/Map-Layer-Control.png);
	}

	body[theme='flatGreen'] #mapBaseLayerControl .leaflet-control-layers-toggle {
		width: 31px;
		height: 25px;
		background-size: 18px 16px;
		background-image: url(/Content/images/MapControls/Map-Layer-Control-Green.png);
	}

	#mapBaseLayerControl .leaflet-control-layers-list {
		margin-bottom: 0px;
		width: auto;
		text-align: left;
		padding-bottom: 5px;
		padding-top: 10px;
	}

	#mapBaseLayerControl .leaflet-control-layers-base {
		display: inline-block;
	}

	#mapBaseLayerControl .leaflet-control-layers-base div {
		padding-right: 10px;
		padding-left: 5px;
		padding-bottom: 2px;
	}

	#mapBaseLayerControl .leaflet-control-layers-base div:hover {
		background-color: #CFF4FF;
		cursor: pointer;
	}

	body[theme='flatGreen'] #mapBaseLayerControl .leaflet-control-layers-base div:hover {
		background-color: #FFD68D;
	}

	#mapBaseLayerControl .leaflet-control-layers-base div span {
		top: 2px;
		position: relative;
	}

	#mapBaseLayerControl .leaflet-control-layers-selector {
		display: inline-block;
	}

	.olControlButtonTopoItemInactive, .olControlButtonAerialItemInactive, .olControlButtonRoadItemInactive {
		float: left;
		padding: 5px 10px;
		@include small-body-text();
		font-family: Helvetica, Arial, sans-serif;
		text-decoration: none;
		line-height: 1;
		color: #000;
		text-shadow: 0 -1px 1px rgba(255,255,255,.3);
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#fff), to(#cbcbcb));
		background-image: -moz-linear-gradient(0% 100% 90deg,#cbcbcb, #fff);
		background-image: -ms-linear-gradient(top, #fff, #cbcbcb);
		background-image: linear-gradient(top, #fff, #cbcbcb);
		background-color: #EFEFEF;
		-webkit-border-radius: 7px;
		-moz-border-radius: 7px;
		-o-border-radius: 7px;
		border-radius: 7px;
		border: 1px solid #666;
		-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
		-moz-box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
		-o-box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
		box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
		float: left;
		width: auto;
		margin: 5px;
		cursor: pointer;
	}

	.olControlButtonTopoItemActive, .olControlButtonAerialItemActive, .olControlButtonRoadItemActive {
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#cbcbcb), to(#878787));
		background-image: -moz-linear-gradient(0% 100% 90deg,#878787, #cbcbcb);
		background-image: -ms-linear-gradient(top, #878787, #cbcbcb);
		background-image: linear-gradient(top, #878787, #cbcbcb);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cbcbcb', endColorstr='#878787');
		filter: progid:dximagetransform.microsoft.gradient(enabled=false);
		background-color: #878787;
		-webkit-box-shadow: inset 0 0 10px rgba(0,0,0,.5), 0 1px 1px rgba(255,255,255,.7);
		-moz-box-shadow: 0 1px 2px rgba(0,0,0,.5), 0 1px 1px rgba(255,255,255,.5);
		-o-box-shadow: 0 1px 2px rgba(0,0,0,.5), 0 1px 1px rgba(255,255,255,.5);
		box-shadow: 0 1px 2px rgba(0,0,0,.5), 0 1px 1px rgba(255,255,255,.5);
		padding: 5px 10px;
		@include small-body-text();
		font-family: Helvetica, Arial, sans-serif;
		text-decoration: none;
		line-height: 1;
		color: #FFF;
		text-shadow: 0 -1px 1px rgba(255,255,255,.3);
		-webkit-border-radius: 7px;
		-moz-border-radius: 7px;
		-o-border-radius: 7px;
		border-radius: 7px;
		border: 1px solid #666;
		float: left;
		width: auto;
		margin: 5px;
		cursor: default;
	}

	.olControlButtonTopoItemInactive:hover, .olControlButtonTopoItemActive:hover, .olControlButtonRoadItemInactive:hover, .olControlButtonRoadItemActive:hover, .olControlButtonAerialItemInactive:hover, .olControlButtonAerialItemActive:hover {
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#ddd), to(#aaa));
		background-image: -moz-linear-gradient(0% 100% 90deg,#aaa, #ddd);
		background-image: -ms-linear-gradient(top, #aaa, #ddd);
		background-image: linear-gradient(top, #aaa, #ddd);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ddd', endColorstr='#aaa');
		filter: progid:dximagetransform.microsoft.gradient(enabled=false);
		background-color: #aaa;
	}

	.olControlButtonTopoItemInactive:after, .olControlButtonTopoItemActive:after {
		content: 'Topo';
		text-align: center;
	}

	.olControlButtonRoadItemInactive:after, .olControlButtonRoadItemActive:after {
		content: 'Road';
		text-align: center;
	}

	.olControlButtonAerialItemInactive:after, .olControlButtonAerialItemActive:after {
		content: 'Aerial';
		text-align: center;
	}

	.olControlScaleLine {
		-moz-border-radius: 5px;
		border-radius: 5px;
		width: 100px;
		padding: 5px;
		right: 10px !important;
		background: #eeeeee; /* Old browsers */
		/* IE9 SVG, needs conditional override of 'filter' to 'none' */
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjY2NjY2MiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-linear-gradient(top, #eeeeee 0%, #cccccc 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#eeeeee), color-stop(100%,#cccccc)); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top, #eeeeee 0%,#cccccc 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top, #eeeeee 0%,#cccccc 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top, #eeeeee 0%,#cccccc 100%); /* IE10+ */
		background: linear-gradient(top, #eeeeee 0%,#cccccc 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#cccccc',GradientType=0 ); /* IE6-8 */
	}

	.content-pad {
		padding-left: 10px;
		padding-right: 10px;
	}

	.sub-toolbar {
		border-bottom: 1px solid lightgray;
		height: 25px;
		font-weight: bold;
		padding-top: 5px;
		margin-left: 0px;
		padding-left: 10px;
		color: Gray;
	}

	.sub-toolbar A {
		color: gray;
		text-decoration: none;
		padding-left: 5px;
		padding-right: 5px;
	}

	.sub-toolbar I {
		opacity: 0.50;
	}

	.sub-toolbar A:Hover {
		color: Black;
	}

	.sub-toolbar-active {
		color: Black !important;
		font-weight: bold;
		border-bottom-width: 0px !important;
		margin-bottom: -1px !important;
		background: #FFFFFF !important;
		background-color: #FFFFFF !important;
		height: 27px !important;
	}

	.sub-toolbar2 {
		border-bottom-color: lightgray;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		height: 35px;
		margin: 0px !important;
		color: Gray;
	}

	.sub-toolbar2 a {
		text-decoration: none;
		color: black;
		padding-top: 9px;
		padding-left: 10px;
		padding-right: 10px;
		margin-left: 0px;
		margin-right: 0px;
		vertical-align: middle !important;
		float: left;
	}

	.sub-toolbar2 I {
		opacity: 0.50;
	}

	.sub-toolbar2 a:Hover {
		color: DarkSlateGray;
	}

	.rotate-down I {
		-webkit-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
		opacity: 1.0 !important;
		transform: rotate(90deg);
	}

	.rotate-up I {
		-webkit-transform: rotate(-90deg);
		-moz-transform: rotate(-90deg);
		filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-1);
		opacity: 1.0 !important;
		transform: rotate(-90deg);
	}

	.rotate-down {
		color: Black !important;
	}

	.olControlLoadingPanel {
		background-image: url("/content/images/ajax-loader-spin.gif");
		background-color: white;
		position: absolute;
		background-position: 90%;
		background-repeat: no-repeat;
		display: none;
		height: 25px;
		width: 100px;
		right: 10px;
		top: 10px;
		-moz-border-radius: 15px;
		border-radius: 15px;
		padding-left: 20px;
		padding-right: 10px;
		text-align: left;
		padding-top: 5px;
		vertical-align: bottom;
		font-weight: bold;
	}

	.olControlLoadingPanel:before {
		padding-top: 10px;
		content: "Loading";
	}

	.inReachWebZoomPan {
		position: absolute;
		left: 10px !important;
		top: 45px !important;
	}
	/*******************************************************************
 * Contact Specific Styles TODO: move these out of site.css..
 ******************************************************************/
	.contact_diplay_first_row {
		border-bottom: 1px solid #A8A8A8;
		justify-content: space-between;
	}

	.right-align {
		text-align: right;
	}

	.pad-left {
		padding-left: 1em;
	}

	.contact-detail {
		word-wrap: break-word;
	}

	.contact-detail-label {
		text-align: right;
	}

	.contact-detail-label label {
		color: #757575;
		font-size: 13px;
	}

	.contact-label {
		width: 0em;
		height: 0em;
		padding-right: 0.5em;
		width: 8.7em;
		height: 1em;
		text-align: right;
		vertical-align: middle;
		position: relative;
	}

	.contact-label label {
		color: #757575;
	}

	.contact-edit {
		width: 0em;
		height: 0em;
		width: 15em;
		height: 1.5em;
		vertical-align: middle;
		position: relative;
	}

	#SelectedCountryID {
		height: 2.5em;
	}

	.contact-last-column {
		width: 0em;
		height: 0em;
		width: 5em;
		height: 1em;
		vertical-align: middle;
	}

	.contact-data td label {
		display: inline;
	}

	.contact-data input[type="text"] {
		width: 100%;
	}

	.contact-data input[type="email"] {
		width: 100%;
	}

	.contact-data textarea {
		width: 100%;
		height: 3em;
	}

	.contact-data select {
		height: 2em;
		width: 100%;
	}


	.contact {
		min-height: 10em;
	}

	.contact-list {
		min-height: 10em;
	}

	.contact-list-add-button-row {
		height: 2em;
		background-color: White;
		padding: 1em;
		position: relative;
		vertical-align: middle;
	}

	.contact-sync-warning {
		padding-top: 1em;
		padding-bottom: 1.5em;
		margin: auto;
	}

	#contactListContents {
		margin-top: 10px;
		overflow: auto;
		/* IMPORTANT: This has to be in pixels as the list is resized based on
	   window height, and this is the minum size allowed */
		min-height: 75px;
		position: relative;
	}

	.contact-data-two-fields {
		display: grid;
		grid-template-columns: 1fr 4em;
		grid-column-gap: 4px;

		.validationMessage {
			grid-row: 2;
		}
	}

	#cList {
		@include selection-bar();
	}
	/***************************************
  * Overrides for jquery dialogs
  ***************************************/
	.ui-widget {
		font-family: inherit !important;
	}

	.ui-widget * {
		font-family: inherit !important;
	}

	.ui-dialog, .ui-widget, ui-widget-content, ui-widget-header {
		font-family: inherit !important;
	}

	.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
		font-family: inherit;
		@include body-text();
	}
	/****************************************************************
 * Sync Specific Styles // TODO: move these out of Site.css..
 ***************************************************************/
	.syncImage {
		float: left;
		width: 20%;
		display: inline-block;
	}

	.syncSteps {
		display: inline-block;
		float: left;
		text-align: left;
		width: 70%;
	}

	.syncStepTitle {
		float: left;
		clear: both;
	}

	.syncStep {
		float: left;
		clear: both;
		display: block;
		font-weight: bold;
		border-top: 1px solid gray;
		width: 90%;
		min-height: 40px;
	}

	.syncStep:last {
		border-bottom: 1px solid gray;
	}

	.syncStepStatus {
		@include small-body-text();
		width: 60%;
		float: left;
	}

	.statusImage {
		display: block;
		width: 40%;
		float: right;
		text-align: right;
	}

	.SetupChecklistItem {
		margin-top: 10px;
		@include body-text();
		line-height: 25px;
	}

	.StatsItem {
		margin-top: 10px;
		line-height: 25px;
	}

	.SetupChecklistItem a {
		text-decoration: none;
		color: Black;
	}

	.aok {
		display: inline-block;
		width: 20px;
		height: 20px;
		line-height: 30px;
		vertical-align: text-top;
		background-image: url("/Content/images/CheckMark_small.png");
		background-repeat: no-repeat;
		background-size: 100%;
		*margin-right: .3em;
	}

	.homeLeftColumn {
		margin-top: 10px;
		flex: 1;
	}

	.homeCenterColumn {
		flex: 1;
		margin-top: 10px;
		border-left: 1px solid lightgray;
		padding-left: 15px;
		padding-right: 15px;
		min-height: 300px;
	}

	.homeRightColumn {
		flex: 1;
		margin-top: 10px;
		border-left: 1px solid lightgray;
		padding-left: 15px;
		padding-right: 15px;
		min-height: 300px;
	}

	#betaSiteNotice {
		border: 1px solid black;
		background-color: lightgray;
		margin: 40px;
		padding: 20px;
	}

	#betaSiteNotice i {
		line-height: 40px;
	}

	.colorToggleBtnResting {
		color: #FFFFFF !important;
		text-shadow: none !important;
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#5b7599), to(#3e4c61));
		background-image: -moz-linear-gradient(0% 100% 90deg,#3e4c61, #5b7599);
		background: linear-gradient(0deg, #3e4c61, #5b7599);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3e4c61', endColorstr='#5b7599') !important;
		background: -ms-linear-gradient(top, #5b7599 0%,#3e4c61 100%);
		background-color: #4c5f7b !important;
		border: 1px solid #3e4c61 !important;
		transition: none !important;
	}

	.colorToggleBtnResting:hover {
		color: #FFFFFF;
		text-shadow: none;
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#9aadc7), to(#677388));
		background-image: -moz-linear-gradient(0% 100% 90deg,#677388, #9aadc7);
		background: linear-gradient(0deg, #677388, #9aadc7);
		background: -ms-linear-gradient(top,#9aadc7 100%, #677388 0%);
		background-color: #7f90ab;
		border: 1px solid #3e4c61;
		transition: none !important;
	}

	.sub-filterbar-collapsed {
		margin-left: 10px;
		margin-right: 10px;
	}

	.FilterControl {
		float: left;
	}

	.FilterControl span.title {
		float: left;
		display: block;
		clear: left;
	}

	.downAppend {
		margin-left: -5px !important;
		/*margin-top:-9px !important;*/
		height: 18px !important;
		margin-right: 15px;
		float: left;
		padding: 4px 5px;
		text-align: center;
	}

	ul.checkbox-select li.menu {
		background: none repeat scroll 0 0 #E6E6E6;
		border: 1px solid #CCCCCC;
		border-radius: 3px 3px 3px 3px;
		width: 100%;
	}

	.input-append input {
		background-color: #EEEEEE;
	}

	.FilterControl div.body {
		display: block;
		float: left;
		clear: left;
	}

	.sub-filterbar {
		text-align: left;
	}

	.menu-dropdown, .dropdown-menu-old {
		background-clip: padding-box;
		background-color: #FFFFFF;
		border-color: rgba(0, 0, 0, 0.2);
		border-radius: 0 0 6px 6px;
		border-style: solid;
		border-width: 0 1px 1px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
		display: none;
		float: left;
		margin-left: 0;
		margin-right: 0;
		max-width: 220px;
		min-width: 160px;
		padding: 6px 0;
		position: absolute;
		top: 40px;
		z-index: 900;
	}

	.menu-dropdown li, .dropdown-menu-old li {
		display: block;
		float: none;
	}

	.menu-dropdown .divider, .dropdown-menu-old .divider {
		background-color: #EEEEEE;
		border-bottom: 1px solid #FFFFFF;
		height: 1px;
		margin: 5px 0;
		overflow: hidden;
	}

	.topbar .dropdown-menu-old a, .dropdown-menu-old a {
		clear: both;
		color: #808080;
		display: block;
		font-weight: normal;
		line-height: 18px;
		padding: 4px 15px;
		text-shadow: 0 1px 0 #FFFFFF;
	}

	.topbar .dropdown-menu-old a:hover, .dropdown-menu-old a:hover, .topbar .dropdown-menu-old a.hover, .dropdown-menu-old a.hover {
		background-color: #DDDDDD;
		background-image: -moz-linear-gradient(center top, #EEEEEE, #DDDDDD);
		background-repeat: repeat-x;
		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.024) inset, 0 -1px rgba(0, 0, 0, 0.024) inset;
		color: #404040;
		text-decoration: none;
	}

	.open .menu, .dropdown.open .menu, .open .dropdown-toggle, .dropdown.open .dropdown-toggle {
		background: none repeat scroll 0 0 rgba(0, 0, 0, 0.3);
		color: #FFFFFF;
	}

	.open .menu-dropdown, .dropdown.open .menu-dropdown, .open .dropdown-menu-old, .dropdown.open .dropdown-menu-old {
		display: block;
		border-radius: 5px;
		z-index: 1005;
		background-color: #F2F2F2;
		margin-top: -5px;
		margin-left: 4px
	}

	.ui-timepicker {
		z-index: 7003 !important;
	}

	.ui-datepicker {
		z-index: 7003 !important;
	}

	tr.collapse {
		display: none;
		width: 100%;
	}

	tr.collapse.in {
		display: inherit;
	}

	.horizcollapse.in {
		height: 94% !important;
	}

	tr ul.nav {
		margin-bottom: 0px;
	}

	.nav-tabs li a {
		border: 1px solid gray;
	}

	.img-track {
		display: inline-block;
		width: 20px;
		height: 18px;
		line-height: 14px;
		vertical-align: text-top;
		background-image: url(themes/base/images/Track.png);
		background-repeat: no-repeat;
		*margin-right: .3em;
	}

	.img-locate {
		display: inline-block;
		width: 20px;
		height: 18px;
		line-height: 14px;
		vertical-align: text-top;
		background-image: url(themes/base/images/Locate.png);
		background-repeat: no-repeat;
		*margin-right: .3em;
	}

	.img-message {
		display: inline-block;
		width: 20px;
		height: 18px;
		line-height: 14px;
		vertical-align: text-top;
		background-image: url(themes/base/images/Message.png);
		background-repeat: no-repeat;
		*margin-right: .3em;
	}

	.img-reference {
		display: inline-block;
		width: 20px;
		height: 18px;
		line-height: 14px;
		vertical-align: text-top;
		background-image: url(themes/base/images/Map-ReceivedReference-Lowres.png);
		background-repeat: no-repeat;
		*margin-right: .3em;
	}

	.text-holder {
		word-wrap: break-word;
		word-break: hyphenate;

		textarea {
			margin-top: 40px;

			&.form-control {
				margin-top: 10px;
			}
		}

		.presetSOSWarning {
			color: rgb(215,0,0);
		}
	}

	.text-holder textarea {
	}

	.cannedTextCountSup {
	}

	.deviceGrid_btnContainer {
		float: left;
		padding-right: 50px;
	}

	#deviceGrid_btnRow fieldset {
		margin: 0px;
		color: gray;
		border-color: #CCCCCC;
		border: 0px solid #CCCCCC;
		text-align: left;
		margin-right: 50px;
		float: left;
		display: block;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	#deviceGrid_btnRow legend {
		@include small-body-text();
	}

	#deviceGrid {
		@include secondary-body-text();
	}

	.deviceGrid_col_data {
		text-align: center;
	}

	#deviceGrid_grid_container {
		margin-top: 10px;
		border: 1px solid #D4D4D4;
	}

	.cleaner {
		clear: both;
		font-size: 1px;
	}

	.deviceGrid_grid {
		width: 100%;
	}

	.deviceGrid_grid td {
		border: 0px solid #FFFFFF;
		padding: 3px;
		@include small-body-text();
	}

	.deviceGrid_grid tr:hover {
		background-color: #FFFF99;
	}

	.deviceGrid_grid_alt_row {
		background-color: #F2F2F2;
		text-align: center;
	}

	.deviceGrid_grid th {
		text-align: center;
		border-left: 1px solid #FFFFFF;
		border-right: 1px solid #D4D4D4;
		border-bottom: 1px solid #D4D4D4;
		padding-bottom: 3px;
		cursor: pointer;
	}

	#gridDevices {
		border: 0px solid #D4D4D4;
	}

	#deviceGrid_grid_container th {
		padding-left: 10px;
		border-left: 1px solid white;
		text-align: left;
		vertical-align: top;
		padding-top: 3px;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF', endColorstr='#efefef');
		background: -moz-linear-gradient(top, #FFFFFF, #efefef);
		background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#efefef));
	}

	.sorting_col {
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d9e5f3', endColorstr='#bbcee5') !important;
		background: -moz-linear-gradient(top, #d9e5f3, #bbcee5) !important;
		background: -webkit-gradient(linear, left top, left bottom, from(#d9e5f3), to(#bbcee5)) !important;
	}

	#deviceGrid_grid_container th a {
		color: Black;
		text-decoration: none;
	}

	#deviceGrid_grid_container td {
		vertical-align: top;
	}

	#deviceGrid_grid_container input[type=checkbox] {
		width: 30px;
		cursor: pointer;
	}

	#deviceGrid_grid_container INPUT, SELECT, OPTION {
		@include small-body-text();
		/* width: 120px; */
	}

	.deviceGrid_small_hdr {
		@include secondary-body-text();
	}

	.deviceGridFunctionBar {
		margin: 0px;
		margin-left: -5px;
		margin-right: -5px;
		padding-top: 5px;
		padding-bottom: 5px;
		background-color: #E6E6E6;
	}

	.ui-dialog-titlebar {
		background-color: transparent !important;
	}

	.ui-widget-header {
		background: transparent !important;
		color: Black !important;
		border: 0 solid black !important;
	}

	.ui-dialog {
		border: 2px solid #111 !important;
	}
	/* Overrides the cancel circle present on some Dijit items through Bootstraps */
	input[readonly] {
		border: none;
		cursor: pointer;
	}

	#mapLayout {
		height: 100%;
		overflow: hidden;
	}

	#mapControls {
		overflow: hidden;
	}

	#filterRow {
		background-color: #E6E6E6;
		border-bottom: 1px solid #BABABA;
		padding-bottom: 3px;
		min-width: 1100px;
		overflow: hidden;
	}

	#mapLeftPane {
		background-color: #E6E6E6;
		width: 300px;
		border-right: 1px solid #BABABA;
	}

	.dijitSplitter {
		background-color: #BABABA !important;
		border-right: 1px solid #7D7D7D !important;
	}

	#mapSubNav {
		border-bottom: 1px solid #BABABA;
		text-align: left;
		color: Gray;
	}

	.silver-grad {
		background: #DEDEDE;
	}

	.redux-dialog {
		border: 1px solid #7D7D7D !important;
		position: absolute;
		z-index: 7002;
		background-color: #DEDEDE;
		padding: 10px 20px;
		-moz-border-radius: 5px;
		border-radius: 5px;
	}

	.small {
		font-size: 10px;
		line-height: 12px;
	}

	.user_list_color_circle {
		@include headline-text();
		text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white, 2px 2px 0 white;
	}

	.user-list-main-buttons {
		width: 100%;
		padding: 20px;

		.ex-icon {
			width: 1em;
			height: 1em;
		}
	}

	.user-list-main-buttons td {
		padding-left: 7px;
		padding-right: 7px;
		padding-top: 10px;
		padding-bottom: 10px;
		text-align: center;
	}

	.user-list-main-button {
		cursor: pointer;
	}

	.user-list-main-button-disabled {
		opacity: 0.5 !important;
		cursor: not-allowed;
	}

	.user-list-main-button:hover {
		cursor: pointer;
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#9aadc7), to(#677388)) !important;
		background-image: -moz-linear-gradient(0% 100% 90deg,#677388, #9aadc7) !important;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9aadc7', endColorstr='#677388') !important;
	}

	div.mousescroll {
		overflow: hidden;
	}

	div.mousescroll:hover {
		overflow-y: scroll;
	}

	.slimScrollDiv {
		margin: 0px;
		padding: 0px;
		border-top: 1px solid white;
		border-bottom: 1px solid #808080;
	}

	.message-list-container-row td {
		margin: 0px;
		padding: 0px !important;
	}

	.message-list-container-row {
		border-bottom: 4px solid #3e4c61;
	}
	/* resets */
	.user-list-entry table td, .user-list-entry table tr, .user-list-entry, .user-list-entry table {
		margin: 0px;
		padding: 0px;
		border: 0px none;
		border-left: 0px none;
		border-right: 0px none;
	}

	div.track-summary > table {
		margin: 10px;
	}

	div.track-summary {
		cursor: pointer;
		position: relative;
		border-bottom: 1px solid #BFBFBF;
	}

	.bottom-tab-button {
		padding: 4px 10px;
		-webkit-border-radius: 10px 10px 0 0;
		-moz-border-radius: 10px 10px 0 0;
		-o-border-radius: z 10px 10px 0 0;
		border-radius: 10px 10px 0 0;
		background: white !important;
		cursor: pointer;
	}

	.point-expander {
		position: absolute;
		bottom: 0px;
		left: 5px;
	}

	.user-list-entry {
		padding: 5px;
	}

	.user-list-entry-selected {
		background-color: #A8EBFF !important;
	}

	body[theme='flatGreen'] .user-list-entry-selected {
		background-color: #FE9006 !important;
	}

	.message-list-container-row > td {
		border-bottom: 1px solid white;
		border-top: 1px solid #808080;
	}

	.lbl-time {
		color: Gray;
		@include small-body-text();
	}

	.no-select {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.select-text {
		-webkit-user-select: text;
		-khtml-user-select: text;
		-moz-user-select: text;
		-ms-user-select: text;
		user-select: text;
	}

	.view-point, .view-point > span {
		@include small-body-text();
		height: 25px;
		padding: 3px 3px 0px;
		padding-left: 20px;
		cursor: pointer;
	}

	.view-point:hover {
	}

	.view-point:active {
		background-color: #CCFFFF !important;
	}

	.view-point:nth-child(odd) {
		background-color: #E6E6E6;
	}

	.point-selected {
		background-color: #9FA7B7 !important;
	}

	.view-point-container {
	}

	.user-expand {
		position: absolute;
		bottom: 0px;
		right: 0px;
		background: #D9D9D9;
		padding: 1px;
		border-top: 1px solid #F2F2F2;
		border-left: 1px solid #F2F2F2;
		font-size: 10px;
		width: 53px;
		text-align: center;
	}

	.circle_container {
		position: relative;
		width: 1rem;
		height: 1rem;
	}

	.circle_gfx {
		z-index: 1;
		position: absolute;
		top: 0px;
		left: 0px;
	}

	.fade-div {
		background: -moz-linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255, 0));
		background: -webkit-linear-gradient(top, rgba(255,255,255,1), rgba(255,255,255, 0));
		filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr='#ffffffff', EndColorStr='#00ffffff');
		z-index: 2;
		pointer-events: none;
	}

	.fade-btm-div {
		background: -moz-linear-gradient(top, rgba(255,255,255,0), rgba(255,255,255, 1));
		background: -webkit-linear-gradient(top, rgba(255,255,255,0), rgba(255,255,255, 1));
		filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr='#00ffffff', EndColorStr='#ffffffff');
		z-index: 2;
		pointer-events: none;
	}

	.message-summary {
		cursor: pointer;
	}

	.msg-wrap {
		overflow: hidden;
		max-width: 270px;
		word-wrap: break-word;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		hyphens: auto;
		width: 100%;
	}

	.ui-dialog-buttonset {
		float: left !important;
		text-align: left !important;
	}

	ul.setup > li {
		padding: 8px 16px !important;
		cursor: default;
	}

	.vertical-centered-cell {
		vertical-align: middle;
	}

	.centered-cell {
		text-align: center;
		vertical-align: middle;
	}

	.notice-box {
		border: 1px solid grey;
		padding: 20px;
		margin-left: 50px;
		margin-right: 50px;
		margin-top: 10px;
		background-color: #E6E6E6;
	}

	.notice-box h2 {
		color: #990000;
		padding-top: 5px;
		padding-bottom: 10px;
		line-height: 10px;
	}

	.noscript-warning {
		color: map-get($COLORS, danger) !important;
	}

	#user-button-container {
		z-index: 1000;
		border-bottom: 1px solid darkgray;
		margin: 0px auto;
	}

	#table_user_list_header {
		position: fixed;
		z-index: 2000;
		display: block;
		border-bottom: 1px solid darkgray;
		display: none;
	}

	#userList > tr:first-child > td:first-child {
		padding-top: 25px;
	}

	#userList > tr:first-child {
		margin-top: 25px;
	}

	.innerUserListDiv {
		overflow-y: auto;
	}

	.outerUserListDiv {
		position: absolute;
		top: 67px;
		bottom: 0px;
		right: 0px;
		left: 0px;
		overflow-y: auto;
	}

	.ui-widget-content a {
		color: #005580 !important;
	}

	.dlg-no-title .ui-dialog-titlebar {
		display: none;
	}

	.layerButton {
		cursor: pointer !important;
	}

	.map-control-title {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.warn-bold {
		color: map-get($COLORS, danger) !important;
		font-weight: bold;
	}

	.tooltip {
		position: fixed;
	}


	.tooltip.in {
		opacity: 1;
		filter: alpha(opacity=100);
	}

	.tooltip.top .tooltip-arrow {
		bottom: 0;
		left: 50%;
		margin-left: -5px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid #ffffff;
	}

	.tooltip.left .tooltip-arrow {
		top: 50%;
		right: 0;
		margin-top: -5px;
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;
		border-left: 5px solid #ffffff;
	}

	.tooltip.bottom .tooltip-arrow {
		top: 0;
		left: 50%;
		margin-left: -5px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid #ffffff;
	}

	.tooltip.right .tooltip-arrow {
		top: 50%;
		left: 0;
		margin-top: -5px;
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;
		border-right: 5px solid #ffffff;
	}

	.tooltip-inner {
		max-width: 200px;
		padding: 3px 8px;
		color: #000000;
		text-align: center;
		text-decoration: none;
		background-color: #ffffff;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
	}

	.modal-half {
		width: 300px;
		margin: -260px 0 0 -140px;
	}

	.grey {
		color: #757575;
	}

	.dl-horizontal dt {
		float: left;
		clear: left;
		width: 130px;
		text-align: right;
	}

	.dl-horizontal dd {
		margin-left: 135px;
	}

	.hide-overflow {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.row-error {
		color: map-get($COLORS, danger) !important;
		background-color: #FFCCCC;
	}

	.row-error:nth-child(odd) td {
		background-color: #FFCCCC !important;
	}

	.row-error:hover td {
		background-color: #FFE5E5 !important;
	}

	.bordered {
		padding: 10px;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
		border: 1px solid #8A8A8A;
		margin-bottom: 10px;
	}

	.item-detail {
		color: black !important;
	}

	.item-label {
		color: #666666 !important;
	}

	.top-border a {
		border-top-color: lightgray;
		border-top-width: 1px;
		border-top-style: solid;
	}

	.error {
		padding: 3px;
		color: map-get($COLORS, danger) !important;
	}

	.ui-autocomplete.ui-widget {
		@include secondary-body-text();
		color: black;
		max-height: 150px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.logonPartialContainer {
		position: absolute;
		right: 0;
		top: 0;
		height: 60px;
	}

	#logon-icon-row {
		padding: 3px;
		height: 100%;
		display: flex;
		justify-content: flex-end;
		align-content: stretch;
	}

	#header-info {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
		text-align: right;
		margin-right: 5px;
	}

	#header-username {
		color: #636363;
		text-overflow: ellipsis;
		overflow: hidden !important;
		width: 200px;
	}

	#header-menu-username {
		display: none;
		color: #636363;
		text-overflow: ellipsis;
		overflow: hidden !important;
		width: 200px;
	}

	#menu-status-widget {
		display: none;
	}


	.logon-flyover {
		padding: 20px;
		position: absolute;
		display: none;
		right: 0px;
		top: 40px;
		background-color: gray;
		width: 240px;
		color: white;
		z-index: 5001;
	}

	.logon-flyover a, #language-chooser a {
		color: white;
		text-decoration: underline;
	}

	.logon-flyover {
		padding: 20px;
		position: absolute;
		display: none;
		right: 0px;
		top: 40px;
		background-color: gray;
		width: 240px;
		color: white;
		z-index: 5001;
	}

	.logon-flyover a, #language-chooser a {
		color: white;
		text-decoration: underline;
	}

	#language-chooser {
		padding: 20px;
		position: absolute;
		display: none;
		right: 0px;
		top: 160px;
		background-color: #a19c9c;
		width: 240px;
		color: white;
		z-index: 5001;
	}

	#profile-picture {
		height: 100%;
		border-radius: 50%;
		cursor: pointer;
	}

	.corner-help {
		padding: 5px;
		width: 50px;
		height: 50px !important;
	}

	.logon-flyover .row-fluid {
		margin-bottom: 5px;
	}
	/*StatusPage.io widget*/
	.status-page-summary-widget {
		color: #6a6a6a;
	}

	#status-page-component-widget {
		color: #6a6a6a;
	}

	.status-page-link {
		cursor: pointer;
	}

	.status-dot {
		border-radius: 99px;
		display: inline-block;
		width: .9em;
		height: .9em;
		margin-right: 5px;
	}

	.critical {
		background-color: #e74c3c;
	}

	.major {
		background-color: #e58100;
	}

	.minor {
		background-color: #ffec4e;
	}

	.maintenance {
		background-color: #3498db;
	}

	.none {
		background-color: #2ecc71;
	}

	.major_outage {
		background-color: #e74c3c;
	}

	.partial_outage {
		background-color: #e58100;
	}

	.degraded_performance {
		background-color: #ffec4e;
	}

	.under_maintenance {
		background-color: #3498db;
	}

	.operational {
		background-color: #2ecc71;
	}

	.garmin-user-device-button {
		max-width: 80px;
	}

	#DisplayPlanDialog p {
		margin: 0;
	}

	#lone-lang-picker-container {
		text-align: left;
		width: 200px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		margin-right: 5px;
		pointer-events: all;

		img {
			width: 16px;
			right: 28px;
			position: absolute;
			top: 28px;
		}
	}

	.custom-popover {
		position: absolute;
		z-index: 1000;
		background-color: black;
		color: #fff;
		opacity: 0.7;
		padding: 5px;
		transform: translateY(-50%);
		max-width: 200px;
		padding-right: 10px;
	}

	.custom-popover:after {
		right: 100%;
		top: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: transparent;
		border-right-color: black;
		border-width: 14px;
		margin-top: -14px;
	}

	.popover-close-btn {
		@include body-text();
		padding-right: 3px;
		cursor: pointer;
		color: #ccc;
		position: absolute;
		top: 0;
		right: 0;
	}

	.popover-close-btn:hover {
		color: #fff;
	}

	#change_password_confirm ul {
		@include secondary-body-text();
	}

	#edit-user-title {
		@include title-text();
	}

	.emergency-contact-label {
		@include small-body-text();
		margin-right: 0.5em;
		color: map-get($COLORS, danger) !important;
	}

	.provider-text {
		display: block;
		text-decoration: underline;
	}

	a.currenttab {
		border-bottom: 2px solid map-get($COLORS, explorange);
	}

	a.underline {
		text-decoration: underline !important;
	}
}
