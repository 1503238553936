﻿.ex-sortWidget {
	display: flex;
	cursor: pointer;
	@include subheader-text();
	white-space: nowrap;

	&_column {
		display: flex;
		flex-direction: column;
		padding: 3px 5px;
		vertical-align: middle;
		color: #aaa;
	}

	&_up-selected {
		color: #000;
	}

	&_down-selected {
		color: #000;
	}

	& .glyphicon {
		font-size: 9px;
		top: 4px;
	}
}