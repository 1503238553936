.addInReach {
    display: flex;
    align-items: baseline;
    padding: 1rem .25rem;

    @include bp('small') {
        flex-direction: column;
        padding: 1rem;
    }

    &-explanationContainer {
        flex-basis: 70%;
        padding-right: 1rem;
    }

    &-buttonContainer {
        flex-basis: 30%;
        border: 1px solid gray;
		border-radius: 10px;
		text-align: center;
		text-decoration: none;
		background-color: #F8F8F8;
		padding: .5rem;
    }

    &-footer {
        margin: auto auto 0 auto

        div {
            padding-bottom: 0px;
            overflow: hidden;
            margin-top: auto;
        }
    }

    &-footerImageEarthmate {
        height: 620px;
        background-image: url(/Content/images/AddInReach/inReach-Tab.png);

        @include bp('small') {
            display: none;
        }
    }

    &-footerImage {
        height:545px;
        background-image: url(/Content/images/AddInReach/inReach.png);

        @include bp('small') {
            display: none;
        }
    }
}
