.ex-homepage {
	padding: 0 1em 1em;
	display: flex;
	flex-wrap: wrap;

	@include bp('medium') {
		padding: 0 0.5em;
	}

	&_main {
		flex-basis: 70%;
		width: 70%;
		min-width: 70%;
		padding-right: 1em;
		display: flex;
		flex-direction: column;

		@include bp('medium') {
			padding-right: 0;
			flex-basis: 100%;
			width: 100%;
		}
	}

	&_side {
		flex-basis: 30%;
		width: 30%;
		padding-left: 1em;
		border-left: 2px solid var(--bordersdark);

		@include bp('medium') {
			padding-left: 0;
			flex-basis: 100%;
			width: 100%;
			border-left: none;
			display: flex;
			flex-wrap: wrap;
		}
	}

	.past-due-message {
		order: -3;
	}

	.subs-plan-stats-container {
		order: 2;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0.5em;

		#content {
			height: 35em;
		}

		.center {
			display: flex;
			flex-direction: row;
			justify-content: center;
		}
	}
}
