﻿.sar-plans {
	margin: 2rem 1rem 0;

	h1 {
		@include title-text();
		font-weight: 600;
	}

	.card {
		margin: 0 0 1rem 0 !important;

		#sar-image {
			width: 100%; // fixes image being vertically stretched on safari
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		#starting-price {
			font-size: 1.5rem;
		}

		#taxes-and-fees-text {
			@include secondary-body-text();
		}

		p {
			align-self: center;
			margin-bottom: 0.5rem;
		}
	}

	.sar-text {
		color: var(--secondarytext);

		#supported-device {
			color: var(--secondarytext);
		}
	}

	.sar-plan {

		&-label {
			color: var(--secondarytext);
		}

		&-container {

			&-embed {
				background-color: var(--bodybackground) !important;
				border: 1px solid var(--explorange);

				.switch {
					width: 2.8rem;
				}

				input:checked + .slider {
					background-color: #67C87A;
				}
			}
		}
	}
}
