.ex-homepageStatsMessaging {
	margin-bottom: 2em;
	padding: 0em, 0.5em, 0.5em, 0.5em;
	order: 3;

	&_title {
		font-size: 1.5rem;
		min-height: 3.5rem;

		@include bp('small') {
			font-size: 1.25rem;
		}
	}

	&_slider {

		&-wrapper {
			.ex-homepageStats_loading & {
				display: flex;
				justify-content: space-around;
			}
		}
	}
}
