﻿.ex-teamtracking {
	&_container {
		width: 100%;
		padding: 1em;
		position: relative;
	}

	&_main {
		padding-right: 20em;

		@include bp('small') {
			padding-right: 0;
			margin-top: 2em;
		}
	}

	&_sidebar {
		position: absolute;
		right: 2em;
		top: 2em;

		@include bp('small') {
			position: static;
		}
	}

	&_image {
		max-width: 100%;
	}
}
