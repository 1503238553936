﻿@mixin textMessagePage() {

    .ex-page_textMessage {

        @charset "UTF-8";
        /* CSS Document */
        html {
            height: 100%;
        }

        body {
            -webkit-text-size-adjust: 100%;
            margin: 0;
            height: 100%;
        }

        a {
            text-decoration: none;
            padding: 0;
            margin: 0;
        }

        img {
            border: 0px;
        }

        #menucontainer {
            position: relative;
            top: 0;
            width: 100%;
            height: auto;
            background-color: map-get($COLORS, contentbackground);
        }

        .menu {
            width: 100%;
            margin: auto;
            list-style: none;
            padding: 0;
        }

        .menu-icon {
            background-color: map-get($COLORS, explorange);
            width: 28px;
            height: 30px;
            float: left;
            margin: 0 -5% 0 5%;
        }

        #content {
            height: calc(100vh - #{$map-header-height-mobile} - 2em) !important;
            min-height: unset;

            @include bp('(max-height: 540px)') {
                height: auto !important;
            }

            > div {
                min-height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: stretch;
            }
        }

        .flex-grow-1 {
            flex-grow: 1;
        }

        .alignedright {
            float: right;
            margin-right: 15px;
        }

        .button {
            color: #000;
            margin: auto;
            padding: 5px;
            width: 60px;
            text-align: center;
            background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#fff), to(#cbcbcb));
            background-image: -moz-linear-gradient(0% 100% 90deg,#cbcbcb, #fff);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#cbcbcb');
            background-color: #EFEFEF;
            -webkit-border-radius: 7px;
            -moz-border-radius: 7px;
            -o-border-radius: 7px;
            border-radius: 7px;
            border: 1px solid #666;
        }

        &-menu {
            display: flex;
            justify-content: center;

            &_button {
                @include secondary-body-text();
                background-color: map-get($COLORS, contentbackground);
                padding: 0.75em 0;
                text-align: center;
                line-height: 1.25;
                color: map-get($COLORS, secondarytext);
                font-weight: 500;
                cursor: pointer;
                display: flex;
                justify-content: stretch;
                align-items: center;
                position: relative;
                display: flex;
                justify-content: center;
            }

            &_image {
                width: 1.75em;
                height: 2em;

                &-small {
                    width: 1.5em;
                }

                path {
                    fill: map-get($COLORS, explorange);
                }
            }

            &_title {
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: calc(100% - 3.75em);
                padding: 0 0.125em;
            }
        }

        .width33 {
            width: 33%;
        }

        .width50 {
            width: 49.9%;
        }

        #selector {
            height: 4px;
            background-color: map-get($COLORS, explorange);
            position: absolute;
            top: 36px;
            left: 0;
            transition: left ease-in-out 0.1s
        }

        .menubutton img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0.25em;
            width: 1.75em;

            &.ex-page_textMessage-menuImage {
                &-small {
                    width: 1.5em;
                    top: 55%;
                }
            }
        }

        .selectedmenubutton {
        }

        .lastmenubutton {
            margin-right: -3px;
            width: 34%;
        }


        .replyField {
            @include subheader-text();
            width: 92%;
            padding: 10px 0 10px 10px;
            margin-top: 5px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            border: 1px solid #636363;
        }

        .mobile-footer {
            padding: 10px 10px;
            @include small-body-text();
            z-index: 1000;
            background-color: #fff;
        }

        .listitem {
            background-color: #fff;
            border-top: 1px solid #DEDEDE;
            padding: 15px 5px 15px 15px;

            &:last-child {
                border-bottom: none;
                flex-grow: 1;
            }
        }

        .reverse-listitem {
            display: flex;
            justify-content: flex-end;
            background-color: #fff;
            border-top: 1px solid #DEDEDE;
            padding: 15px 15px 15px 5px;
        }

        #errorMsg {
            color: var(--danger);
        }

        #successMsg {
            color: var(--success);
        }

        .field-label {
            color: #636363;
            @include secondary-body-text();
            text-transform: uppercase;
        }

        .info {
            padding-left: 5px;
            color: #000;
        }

        #map {
            text-align: center;
            @include secondary-body-text();
        }

        #maptypecontrols {
            position: fixed;
            top: 50px;
            left: 8px;
            z-index: 1000;
        }

        #maptypecontrols ul {
            list-style: none;
            display: block;
            margin: 0;
            padding: 0;
        }

        #maptypecontrols ul li {
            display: block;
            float: left;
            width: 32px;
            padding: 6px 10px 7px 10px;
            @include small-body-text();
            text-decoration: none;
            line-height: 1;
            color: #000;
            text-shadow: 0 -1px 1px rgba(255,255,255,.3);
            background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#fff), to(#cbcbcb));
            background-image: -moz-linear-gradient(0% 100% 90deg,#cbcbcb, #fff);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#cbcbcb');
            background-color: #EFEFEF;
            -webkit-border-radius: 7px;
            -moz-border-radius: 7px;
            -o-border-radius: 7px;
            border-radius: 7px;
            border: 1px solid #666;
            -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
            -moz-box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
            -o-box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
            box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
            margin: 5px;
            cursor: pointer;
            text-align: center;
        }



        #maptypecontrols ul li.mapbuttonSelected {
            background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#cbcbcb), to(#878787));
            background-image: -moz-linear-gradient(0% 100% 90deg,#878787, #cbcbcb);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cbcbcb', endColorstr='#878787');
            background-color: #878787;
            color: #FFF;
            text-shadow: 0 -1px 1px rgba(255,255,255,.3);
        }

        .mapbutton {
            opacity: 0.65;
            -moz-opacity: .65;
            filter: alpha(opacity=65);
        }

        .validation-summary-errors {
            font-weight: bold;
            background-color: white;
            color: map-get($COLORS, danger);
            ul {
                list-style: none;
                padding-left: 5%;
                padding-right: 5%;
            }
        }

        .leaflet-popup-content-wrapper {
            color: black;
        }

        .layerButton {
            position: absolute;
            padding: 5px 10px;
            @include small-body-text();
            text-decoration: none;
            line-height: 1;
            color: #000;
            text-shadow: 0 -1px 1px rgba(255,255,255,.3);
            background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#fff), to(#cbcbcb));
            background-image: -moz-linear-gradient(0% 100% 90deg,#cbcbcb, #fff);
            background-image: -ms-linear-gradient(top, #fff, #cbcbcb);
            background-image: linear-gradient(top, #fff, #cbcbcb);
            background-color: #EFEFEF;
            -webkit-border-radius: 7px;
            -moz-border-radius: 7px;
            -o-border-radius: 7px;
            border-radius: 7px;
            border: 1px solid #666;
            -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
            -moz-box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
            -o-box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
            box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
            float: left;
            width: auto;
            margin: 5px;
            cursor: pointer;
        }

        .layerButtonActive {
            background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#cbcbcb), to(#878787));
            background-image: -moz-linear-gradient(0% 100% 90deg,#878787, #cbcbcb);
            background-image: -ms-linear-gradient(top, #878787, #cbcbcb);
            background-image: linear-gradient(top, #878787, #cbcbcb);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cbcbcb', endColorstr='#878787');
            filter: progid:dximagetransform.microsoft.gradient(enabled=false);
            background-color: #878787;
            -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,.5), 0 1px 1px rgba(255,255,255,.7);
            -moz-box-shadow: 0 1px 2px rgba(0,0,0,.5), 0 1px 1px rgba(255,255,255,.5);
            -o-box-shadow: 0 1px 2px rgba(0,0,0,.5), 0 1px 1px rgba(255,255,255,.5);
            box-shadow: 0 1px 2px rgba(0,0,0,.5), 0 1px 1px rgba(255,255,255,.5);
            padding: 5px 10px;
            @include small-body-text();
            text-decoration: none;
            line-height: 1;
            color: #FFF;
            text-shadow: 0 -1px 1px rgba(255,255,255,.3);
            -webkit-border-radius: 7px;
            -moz-border-radius: 7px;
            -o-border-radius: 7px;
            border-radius: 7px;
            border: 1px solid #666;
            float: left;
            width: auto;
            margin: 5px;
            cursor: default;
        }

        .red {
            color: red;
        }

        #nopoints {
			display: none;
            opacity: 0.7;
            background-color: black;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 4000;
            position: absolute;
            color: white;
            text-align: center;

            > div {
                width: 18.75em;
                height: 6em;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .text-muted {
            @include secondary-body-text();
        }

        &-map {
            display: none;
        }

        &-send {
            display: none;
        }

        &-message {
            display: none;
            flex-grow: 1;
        }

        &-form {
            flex-grow: 1;

            &_result {
                padding: 15px;
            }
        }

        &-footer {
            background: map-get($COLORS, contentbackground);
            position: relative;
            z-index: 99999;

            [data-activesection="map"] & {
                display: none;
            }

            &_form {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
            }
        }
    }
}
