#no-primary-collection-modal {
    text-align: left;
}

.missing-primary-collection-row + .missing-primary-collection-row {
    margin-top: .75rem;
}

.missing-primary-collection-device-name {
    width: 12rem;
}

.missing-primary-collection-select {
    margin-left: .5rem;
    margin-bottom: 0px;
    width: 15rem;
    cursor: pointer;
}

.missing-primary-collection-select-option {
    cursor: pointer;
}

.missing-primary-collection-default-option {
    display: none;
}

.missing-primary-collection-error {
    color: red;
}

.missing-primary-collection-mapshare-warning {
    margin-top: .5rem;
    padding: 0rem .75rem;
}

.missing-primary-collection-mapshare-warning-btns {
    margin-top: .5rem;
}

.ex-modal-slide {
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    min-height: 0;
    height: 0;

    &-active {
        height: auto;
        transition: max-height 0.3s ease-in-out;
    }
}
