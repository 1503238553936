﻿.inputAutoSuggestions {
    max-height: 10rem;
    overflow-y: auto;
}

.inputAutoSuggestions .list-group-item.active {
    background-color: #e9ecef;
    border-color: #e9ecef;
}

.inputAutoSuggestions .list-group-item.active:hover {
    background-color: #e9ecef;
    border-color: #e9ecef;
}
