@mixin sync() {
	#modal_how_to_install {
		.modal-header {
			display: flex;
			flex-flow: row wrap;

			ol {
				margin: 0.5em;
			}

			> div:first-child {
				flex: 1 0 60%;
			}

			> div:nth-child(2) {
				flex: 1 0 300px;
				padding: 0.5em;
			}

			.sync-browser-selection {
				display: flex;
				flex-flow: row wrap;

				> div {
					flex-basis: 50%;
					color: blue;
				}
			}

			.browserLink {
				padding: 5px;
				background-color: #E9F0F5;
				border: 1px solid #ffffff;
				cursor: pointer;

				> span {
					padding-right: 10px;
					padding-left: 10px;
				}
			}

			.browserLinkSelected {
				padding: 5px;
				background-color: #C8DDEF;
				border: 1px solid #ffffff;
				cursor: pointer;

				> span {
					padding-right: 10px;
					padding-left: 10px;
				}
			}
		}

		.modal-body {
			.sync-browser-selection-required {
				text-align: center;
				padding-bottom: 20px;
				padding-top: 20px;

				img {
					padding-left: 10px;
					padding-bottom: 10px;
				}
			}

			.browser-content {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				counter-reset: browser-content-counter;

				> div {
					padding: 0.5em;
					@include secondary-body-text();
					counter-increment: browser-content-counter 1;
					flex-basis: 50%;

					@include bp('small') {
						flex-basis: 100%;
					}

					&::before {
						@include subheader-text();
						font-weight: bold;
						content: counter(browser-content-counter) ".";
					}

					img {
						display: block;
						max-width: 100%;
						padding: 0.5em;
					}
				}
			}
		}
	}

	#profile-options-modal {
		.sync-additional-imeis {
			display: flex;
			flex-flow: row wrap;
			align-items: center;

			> div:first-child {
				padding-right: 0.5em;
			}

			> div:nth-child(2) {
				textarea {
					min-width: 12em;
					min-height: 7em;
				}
			}
		}

		.sync-additional-imeis-error {
			margin: 0.5em;
		}
	}

	#consumerSyncTable > tbody > tr > td {
		padding-left: 20px
	}

	.syncSubInfo {
		width: 310px;
		float: right;
		padding-top: 10px;
	}

	.launchButton {
		background-color: #4D9ED2;
		background-image: -webkit-linear-gradient(top, #4D9ED2, #2185BF);
		background-image: -moz-linear-gradient(0% 100% 90deg, #4D9ED2, #2185BF);
		background: -ms-linear-gradient(top, #4D9ED2 0%, #2185BF 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4D9ED2', endColorstr='#2185BF');
		color: white;
		text-shadow: none;
	}

	.launchButton:hover {
		background-image: -webkit-linear-gradient(top, #2185BF, #4D9ED2);
		background-image: -moz-linear-gradient(0% 100% 90deg, #2185BF, #4D9ED2);
		background: -ms-linear-gradient(top, #2185BF 0%, #4D9ED2 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2185BF', endColorstr='#4D9ED2');
		background-color: #4D9ED2;
		background-position: inherit;
		color: white;
	}

	.clickpic {
		float: left;
	}

	.syncTitle {
		background-color: #DDDDDD;
		padding: 5px;
		padding-left: 10px;
	}

	.borderStyleBottom {
		border-bottom: 1px solid;
		border-color: #D3D3D3;
	}

	.borderStyleRight {
		border-right: 1px solid;
		border-color: #D3D3D3;
	}

	.tableCell {
		display: table-cell;
		border-bottom: 1px solid lightgray;
		padding: 2px;
		vertical-align: middle;
	}

	.strong {
		font-weight: bold;
	}

	.masterTable {
		display: table;
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		border: 1px solid lightgray;
	}

	.tableRow {
		display: table-row;
		background-color: #FAFAFA;
	}

	.tableHeader {
		display: table-header-group;
		background-color: #EEEEEE;
	}

	.headerDivider {
		border-right: 1px solid lightgray;
	}

	.green {
		color: green;
	}

	.yellow {
		color: map-get($COLORS, explorange);
	}

	.red {
		color: map-get($COLORS, danger);
	}

	.syncTableRow {
		min-height: 50px;
		height: 50px;
		border-bottom: 1px solid #A8A8A8;
		border-top: 1px solid #A8A8A8;
		margin-top: -1px;
		background-color: #e7ebee;
		padding: 5px;
		background-position: 95% 50%;
		background-repeat: no-repeat;
	}

	.syncTableCell {
		padding-left: 8px;
	}

	.syncGroupHeaderRow {
		border-right: 1px solid #B1B3B2;
		padding-left: 8px;
		margin-bottom: -2px;
	}

	.syncTableHeader {
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#E5EAEE), to(#C5C6C8));
		background-image: -moz-linear-gradient(0% 100% 90deg, #C5C6C8, #E5EAEE);
		height: 20px;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#E5EAEE', endColorstr='#C5C6C8');
		background: -ms-linear-gradient(top, #E5EAEE 0%, #C5C6C8 100%); /* IE10+ */
		color: black;
		border-color: #A8ACAF;
		background-position: 95% 50%;
	}

	.enterpriseSyncText {
		padding: 20px 20px 0px 20px;
		width: 42em;
		max-width: 100%;

		@include bp('medium') {
			width: 100%;
		}
	}

	td#sync-rows {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 0px;
	}

	.checkboxCol {
		width: 0.5em;
	}

	.contentCol {
		width: 16.5%;
	}

	.notesContentCol {
		width: 17.5%;
	}

	.mobile-only {
		display: none;
	}

	.mobile-only-tap {
		display: none;
	}

	.desktop-textarea-input {
		width: 40%;
	}

	.desktop-textarea {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
	}

	table.borderless td, table.borderless th {
		border: none !important;
	}

	.bg-beige {
		background-color: #fbedd3 !important;
	}

	.pill {
		background-color: #0066CC;
		border: none;
		color: white;
		padding-left: 1em;
		padding-right: 1em;
		text-align: center;
		text-decoration: none;
		cursor: pointer;
		border-radius: 8em;
	}

	.step-paragraph {
		color: var(--dark-grey, #212529);
		font-family: Sarabun;
		font-size: 22px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.card-container {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 16px;
		justify-items: stretch;

		@media(max-width: 950px) {
			grid-template-columns: 1fr 1fr;
		}

		@media(max-width: 650px) {
			grid-template-columns: 1fr;
		}
	}

	.contact-card {
		padding: 1.4em;
		border-radius: 3px 12px 12px 12px;
		background: #FFF;
		container-type: inline-size;
	}

	@media(max-width: 950px) {
		.table {
			thead {
				display: none;
			}

			td, th {
				display: table;
				width: 100%;
				text-align: left;
			}

			tr {
				margin-bottom: 1em;
				display: block;
				padding: 1em 1em 1em 1em;
			}
		}

		.mobile-only {
			display: inline-block;
			width: 8em;
		}

		.mobile-only-tap {
			display: initial;
		}

		.desktop-only {
			display: none !important;
		}

		.mobile-button {
			display: flex;
			flex-direction: column;
		}

		.desktop-textarea {
			flex-direction: column;
		}

		.desktop-textarea-input {
			width: 100%;
		}

		.reset-buttons {
			align-self: flex-end;
		}
	}

	#step-content {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	#card-info {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.card-text-light-grey {
		color: #79747E;
	}

	.contact-image {
		width: 1.5em;
	}

	.ex-sync {
		max-width: $default-max-width;
		width: 80% !important;

		@media (max-width: 950px) {
			width: 100% !important;
		}

		h2 {
			font-size: 1.875rem;
		}

		h3 {
			font-size: 1.25rem;
		}

		input[type=color] {
			padding: 0.2em 0.5em;
		}

		.btn-large {
			width: 10em;
		}

		.info-box-img {
			vertical-align: text-bottom;
		}

		.btn-outline-danger, .text-danger {
			color: #d33e01 !important;
		}

		a.text-danger:hover {
			color: #d33e01 !important;
		}

		.border-danger {
			border-color: #d33e01 !important;
		}

		.btn-danger {
			background-color: #d33e01 !important;
			border-color: #d33e01 !important;
		}

		.btn-outline-danger:hover {
			background-color: #d33e01 !important;
			border-color: #d33e01 !important;
		}

		input[type="checkbox"] {
			accent-color: #d33e01;
			appearance: none;
			border: 1px solid #d33e01;
			height: 1em;
			width: 1em;
			border-radius: 0.2em;
			margin-top: 0.3em !important;
			vertical-align: top;
			cursor: pointer;

			&:disabled, &:checked {
				appearance: checkbox;
			}

			&.card-checkbox {
				width: 1.5em;
				height: 1.5em;
				border-radius: 50%;
				margin-top: 0em !important;

				&:checked {
					appearance: none;
					background-image: url('../Content/images/checkbox-enabled.svg');
					background-position: inherit;
					background-repeat: no-repeat;
					background-size: cover;
				}
			}
		}

		button.btn-outline-danger.active {
			color: #fff !important;
			background-color: #d33e01 !important;
			border-color: #d33e01 !important;
			box-shadow: none !important;
		}

		.spinner-border {
			width: 1em;
			height: 1em;
		}

		.small-img {
			width: 1em;
			height: 1em;
		}

		.spinner-border-large {
			width: 2em !important;
			height: 2em !important;
		}

		.span-2d {
			grid-column-end: span 2;

			@media (max-width: 940px) {
				grid-column-end: span 1;
			}
		}

		.col-2m {
			@media (max-width: 940px) {
				grid-column-start: 2;
			}
		}

		.d-none-m {
			@media (max-width: 940px) {
				display: none;
			}
		}

		.ex-form-grid {
			display: grid;
			grid-template-columns: 0.8fr 1.5fr;
			grid-row-gap: 1em;
			max-width: 50%;
		}
	}
}
