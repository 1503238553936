@mixin usersGroups() {
	.detail_label {
		text-align: right;
		color: map-get($COLORS, secondarytext);
		float: left;
		width: 12em;
		padding-right: 1em;
	}

	.detail_row {
		padding-bottom: 0.5em;
	}

	.detail_content {
		margin-left: 13em;
	}

	.ex-page_groupsAndUsers {
		#content {
			width: auto !important;
		}

		@include bp('medium') {
			#wrapper {
				padding: 0;
			}

			#orgWrapper {
				min-width: 220px;
				padding: 0;
				margin: 0;
				height: calc(100vh - 4rem);
			}

			#users-container {
				margin-top: 0 !important;
			}
		}

		@include bp('small') {
			#content {
				min-height: unset !important;
			}

			#contentwrapper {
				width: 100vw;
				overflow-x: hidden;
			}

			#orgWrapper {
				position: fixed;
				left: 0;
				width: auto;
				margin-top: 0;
				padding-left: 0;
				transition: left ease-in-out 0.35s;
			}

			#users-container {
				margin-top: 0 !important;
				margin-left: 220px;
				transition: margin-left ease-in-out 0.35s;

				.user-table-row:nth-child(2) {
					margin-top: 0.5rem;
					border-top: 1px solid #A8A8A8;
				}

				.user-table-row:nth-child(2n+1) {
					background-color: #f8f8f8;
				}
			}

			#pro-user-action-bar {
				padding-left: 0 !important;

				.users-title {
					max-width: none !important;
					width: 100%;
				}

				.action-buttons {
					float: none !important;

					button {
						margin-left: 0 !important;
					}
				}

				&-menu {
					left: 0;
					right: auto !important;
				}
			}

			.menu-ellipses-inner {
				transform: rotate( 90deg );
				margin-top: 0.5rem;
			}

			#inreach-footer {
				width: 100vw;
				transition: margin-left ease-in-out 0.35s;
			}

			&.drawer-closed {
				#orgWrapper {
					left: -220px;
				}

				#users-container {
					margin-left: 0;
				}

				.mobile-show-control-tab .glyphicon {
					transform: rotate(180deg);
				}
			}

			&:not(.drawer-closed) #inreach-footer {
				margin-left: 235px;
			}

			input[type=checkbox] {
				width: 1rem;
				height: 1rem;
			}
		}

		select {
			background-color: white;
			margin: 1em 0;
		}

		.message-limit-new {
			margin-top: 1em;
		}
	}

	#row_main_groups {
		width: 100%;
		margin: 0 auto 2rem auto !important;
		min-height: calc(100vh - 10em);
		box-sizing: content-box;
		display: flex;
		flex-wrap: nowrap;

		&::after {
			clear: both;
		}
	}

	.listing {
		cursor: pointer;
		background-color: #FFF;
		padding: 0.3rem 0 0.3rem 2rem;
		background-position: 95% 50%;
		background-repeat: no-repeat;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		.limited-name {
			overflow: hidden;
			text-overflow: ellipsis;
			max-height: 3em;
			white-space: nowrap;
			font-size: 14px;
		}

		&.selected .limited-name {
			color: map-get($COLORS, primarytext);
		}

		&.selected:before, &.selectedNoArrow:before {
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			width: 0.3em;
			height: 100%;
			content: "";
			background-color: map-get($COLORS, explorange);
		}
	}

	.listing:first-of-type {
		margin-top: 0px;
	}

	.listingNoArrow {
		cursor: pointer;
		border-bottom: 1px solid #A8A8A8;
		border-top: 1px solid #A8A8A8;
		margin-top: -1px;
		background-color: #FFFFFF;
		padding: 5px;
		background-position: 95% 50%;
		background-repeat: no-repeat;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.listingNoArrow:hover {
		background-color: #F2F2F2;
	}

	.listing:hover {
		background-color: #F2F2F2;
	}

	.inset1 {
		padding-left: 2rem;
	}

	.inset2 {
		padding-left: 2.5rem;
	}

	.inset3 {
		padding-left: 3rem;
	}

	.inset4 {
		padding-left: 3.5rem;
	}

	.inset5 {
		padding-left: 4rem;
	}

	.inset6 {
		padding-left: 4.5rem;
	}

	.inset7 {
		padding-left: 5rem;
	}

	.userTableHeader {
		background-color: #F8F8F8;
		color: black;
		border-color: #A8ACAF;
		background-position: 95% 50%;
	}

	.userGroupHeaderRow {
		border-right: 1px solid #B1B3B2;
		padding-left: 3px;
		margin-bottom: -2px;
		cursor: pointer;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.selected {
		position: relative;
		background: map-get($COLORS, explorangebackground);
		background-position: 95% 50%;
		z-index: 1;

		&:focus {
			background: map-get($COLORS, explorangebackground);
		}
	}



	.selectedNoArrow {
		position: relative;
		background: map-get($COLORS, explorangebackground);
		background-position: 95% 50%;
		z-index: 1;
	}

	.selected-hover {
		background-image: url(/content/images/InboxArrow.png), -webkit-gradient(linear, 0% 0%, 0%100%, from(#668099), to(#33414D));
		background-image: url(/content/images/InboxArrow.png), -moz-linear-gradient(0% 100% 90deg,#33414D,#668099 );
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#668099', endColorstr='#33414D');
		background: -ms-linear-gradient(top, #668099 0%,#33414D 100%); /* IE10+ */
		margin-right: -1px;
		color: White;
		background-position: 95% 50%;
	}

	.selectedHoverNoArrow {
		background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#668099), to(#33414D));
		background-image: -moz-linear-gradient(0% 100% 90deg,#33414D,#668099 );
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#668099', endColorstr='#33414D');
		background: -ms-linear-gradient(top, #668099 0%,#33414D 100%); /* IE10+ */
		margin-right: -1px;
		color: White;
		background-position: 95% 50%;
	}

	.selected .user-group-name {
		color: white;
	}

	.drop-hover {
		background-color: yellow !important;
	}

	.user-group-name {
		float: right;
		color: #757575;
		text-overflow: ellipsis;
		max-width: 80px;
		white-space: nowrap;
		overflow: hidden;
		margin-right: 30px;
	}

	.users-scroll {
		max-height: 300px;
		overflow: auto;
	}

	.users-scroll-item {
		cursor: pointer;
		padding: 3px;

		input[type=checkbox] {
			margin-right: 3px;
		}
	}

	.users-scroll-item:hover {
		background-color: #acb0c1;
	}

	.users-scroll-container {
		width: 100%;
		z-index: 0;
	}

	.user-drag-img {
		cursor: move;
		display: inline-block;
		padding-left: 2px;
		padding-right: 10px;
		margin-top: -4px;
	}

	.group-indent-img {
		margin-right: 4px;
		margin-left: 7px;
		padding-right: 5px;
		padding-bottom: 2px;
	}

	.item-detail-label {
		color: #757575;
		padding-bottom: 3px;
		margin-top: 10px;
	}

	.user-detail {
		margin-top: 20px;
	}

	.user-detail > div {
		padding: 2px;
	}

	.user-detail > hr {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.btn-left-arrow {
		border-top-left-radius: 70px;
		border-bottom-left-radius: 70px;
	}

	.group-name {
		display: inline-block;
		max-width: 93%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.inset2 .group-name {
		max-width: 160px;
	}

	.inset3 .group-name {
		max-width: 140px;
	}

	.limited-name {
		overflow: hidden;
		text-overflow: ellipsis;
		max-height: 3em;
		white-space: nowrap;
	}

	.user-name {
		display: inline-block;
		margin-top: -4px;
		margin-bottom: -4px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 230px;
	}

	.user-name-trim {
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 110px;
		white-space: nowrap;
	}

	.listing input[type=checkbox] {
		margin-top: -4px;
	}

	dl dt, dl dd {
		margin-bottom: 10px;
	}

	dl.edit dt {
		margin-bottom: 0px !important;
		padding-top: 5px;
		font-size: 15px;
	}

	dl dt {
		color: map-get($COLORS, primarytext) !important;
		font-weight: normal !important;
	}

	.validationMessage {
		display: inline-block;
		margin-top: -2px;
		font-size: smaller;
		color: red;
		margin-bottom: 3px;
		position: relative;
	}

	.error {
		border-color: red;
	}

	.user-detail dd {
		margin-left: 0px !important;
		margin-bottom: 12px;
	}

	.user-detail dt {
		color: #B1B1B1 !important;
		margin-bottom: 1px !important;
	}

	.bodyHeightCalc {
		height: inherit;
		height: -webkit-calc(100% - 20px);
	}

	.nameWidthCalc {
		width: 21%;
		width: -webkit-calc(21% - 49px);
		width: -moz-calc(21% - 49px);
		width: calc(21% - 49px);
	}

	.groupWidthCalc {
		width: 10.5%;
		width: -webkit-calc(10.5% - 23px);
		width: -moz-calc(10.5% - 23px);
		width: calc(10.5% - 23px);
	}

	.tableTD {
		display: block;
		float: left;
		padding-left: 3px;
	}

	.row-fluid .span2NoGutter {
		width: 16.66667%;
	}

	.row-fluid [class*="NoGutter"] {
		margin-left: 0;
	}

	.group-settings-title {
		@include title-text();
		display: inline-block;
	}

	.ex-userC-row {
		&-item {
			&_small {
				padding-top: 1em;
				margin-left: 0.325em;
			}
		}
	}

	.ex-dragHandle {
		padding: 0.25em;
	}

	#orgWrapper {
		margin: 1em 0 0 0;
		height: calc(100vh - 6.25rem);
		transition: margin-left ease-in-out 0.35s;
		display: flex;
		z-index: 1;

		.mobile-show-control {
			display: none;

			@include bp('small') {
				display: flex;
			}

			height: 100%;
			border-left: 1px solid #000;
			align-items: center;


			&-tab {
				margin: auto 0;
				width: 1rem;
				height: 2rem;
				background-color: #fff;
				border: 1px solid #000;
				border-bottom-right-radius: 0.5rem;
				border-top-right-radius: 0.5rem;
				border-left: none;
				font-size: 1rem;
				color: #000;

				.glyphicon {
					position: relative;
					top: 4px;
					right: 1px;
					transition: transform ease-in-out 0.35s;
				}
			}
		}

		& #org-name {
			/*padding: 5px 0 10px 0;*/
			padding: 1.5em 1.75em;

			& h6 {
				font-weight: bold;
			}

			& .btn {
				margin: 0.5em 0;
				width: 100%;
			}

			a.btn {
				margin-top: 1em;
			}
		}

		& .inner-org-wrapper {
			background-color: #FFF;
			border: 1px solid #f5f5f5;
			height: 100%;
			max-width: 220px;
			display: flex;
			flex-direction: column;
		}

		& .orgs-scroll-container {
			overflow-y: auto;
		}
	}

	.pro-user {

		@include bp('small') {
			width: 100vw;
		}

		#users-container {
			position: relative;
			margin-top: 1rem;
			display: inline-flex;
			flex-wrap: nowrap;
			flex: 1;

			@include bp('small') {
				min-width: 100vw;
			}

			& .inner-users-container {
				background-color: #FFF;
				padding: 1.5em 1.75em;
				border: 1px solid #f5f5f5;
				height: 100%;
				width: 100%;
				overflow: visible;
			}

			.ex-table_wrapper {
				border: none;
			}

			& .user-table {
				width: 100%;
				font-size: 14px;

				&-row {
					border-bottom: 1px solid #e4e4e4;
					position: relative;
					min-height: 3rem;

					&.disabledUserRow {
						opacity: 75%;
						cursor: not-allowed;
					}

					& > .user-table-cell {
						padding: 10px 5px;
						font-size: 14px;

						& > input[type="checkbox"] {
							vertical-align: middle;
							margin-right: 2em;
						}

						.editUserLoading {
							margin-left: 10px;
						}

						.proUserCheckbox {
							cursor: pointer;

							&.disabledProUserCheckbox {
								cursor: not-allowed;
							}
						}

						&.userStatusCell {
							text-align: center;

							& .glyphicon-remove {
								color: map-get($COLORS, danger);
							}
						}

						&.proUserExplanationOverlay {
							position: absolute;
							background: white;
							left: 1.7em;
							right: 1.7em;
							height: 2.99rem;
							text-align: center;
							border-top: 1px solid #e4e4e4;

							& button {
								margin-left: 1rem;
							}

							& span {
								opacity: 75%;
							}
						}
					}
				}

				&-header {
					color: map-get($COLORS, primarytext);
					text-transform: uppercase;
					font-weight: normal;
					font-size: 14px;
					padding: 10px 5px;

					&.checkboxHeader {
						min-width: 4rem;

						& span {
							margin-left: .15rem;
							position: relative;
							bottom: 1px;
						}
					}

					& > .ex-sortWidget {
						display: inline;
						font-size: 14px !important;

						& > .ex-sortWidget_column {
							display: inline-grid;
							vertical-align: top;
							padding-left: 0.5em;

							& > span.glyphicon-chevron-up {
								padding-top: 0.4em;
								grid-column: 1;
								grid-row: 1;
								cursor: pointer;
								top: -5px !important;
							}

							& > span.glyphicon-chevron-down {
								grid-column: 1;
								grid-row: 2;
								cursor: pointer;
								top: -5px !important;
							}
						}
					}
				}

				.menu-ellipses {
					padding: 4px 3px 8px 3px;
					cursor: pointer;
					position: relative;

					&-inner {
						font-weight: 900;
						@include title-text();
						line-height: 10px;
						position: relative;
						top: -8px;
						user-select: none;
						pointer-events: none;
					}

					&.menu-ellipses-disabled {
						cursor: not-allowed;
					}

					&:hover {
						color: #808080;

						&.menu-ellipses-disabled {
							color: unset;
						}
					}
				}

				.row-menu {
					display: flex;
					flex-direction: column;
					position: absolute;
					right: 0;
					top: 24px;
					min-width: 200px;
					border: 1px solid rgba(0, 0, 0, 0.125);
					background-color: white;
					z-index: 1;
				}

				.checkboxHeader {
					padding: 0 9px;

					& > * {
						vertical-align: middle;
					}
				}
			}

			.users-action-bar {
				padding: 15px 0px 15px 15px;
				position: relative;

				.users-title {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					max-width: 50%;
					display: inline-block;
					float: left;
					font-size: 1.5rem;
					min-height: 1.3em;
				}

				.action-buttons {
					display: inline-block;
					float: right;

					.glyphicon-chevron-down::before {
						font-size: .75em;
						vertical-align: text-bottom;
						margin-left: 0.25em;
					}
				}

				&:after {
					display: block;
					content: "";
					clear: both;
				}

				.btn {
					padding: 0.25rem 3rem;
					margin-left: 0.5rem;
					flex-grow: 0;
					min-width: 200px;
					white-space: nowrap;

					&:last-of-type {
						margin-right: 0;
					}

					& .small-chevron {
						font-size: 12px;
					}
				}

				#pro-user-action-bar-menu {
					display: flex;
					flex-direction: column;
					position: absolute;
					min-width: 200px;
					right: 0;
					border: 1px solid rgba(0, 0, 0, 0.125);
					background-color: white;
					z-index: 1;
				}
			}
		}
	}

	.pop-menu-button {
		width: 100%;
		text-align: start;
		padding: 5px 10px;
		cursor: pointer;
		transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s;
		background: none;
		border: none;

		&:hover {
			background-color: rgb(52, 58, 64);
			color: white;
		}

		&:disabled {
			cursor: not-allowed;

			&:hover {
				background: none;
				color: graytext;
			}
		}

		.glyphicon {
			margin-right: 5px;
			font-size: 14px;
		}
	}


	.pro-user #divDetails {
		display: none !important;
	}

	#row_org_settings {
		background-color: #fff;
		border: 1px solid #f5f5f5;
		margin-top: 1em;
		margin: 1em auto 0 auto;
		width: 925px;
		padding-bottom: 1.5em;

		@include bp('small') {
			width: 100vw;
		}

		.title-box {
			margin-bottom: 0.5em;

			.btn.btn-outline-success {
				vertical-align: text-bottom;
			}
		}

		.nav {
			margin: 0;
			min-height: unset !important;
		}

		.org_settings_TT {
			.table-responsive {
				.row {
					margin: 0;
				}
			}
		}
	}

	#modal_import {
		#fileChooser {
			width: 80%;
			display: table-cell;
			vertical-align: middle;
			float: left;

			.group-span-filestyle > .btn {
				color: #343a40;
				border-color: #343a40;
			}
		}
	}

	#module_edit_user {
		max-width: 920px;
		margin: 0 auto;

		.container {
			h2 {
				font-size: 1.5rem;
			}
		}
	}

	#AddServicesContainer {
		a {
			text-decoration: underline;

			&:hover {
				cursor: pointer;
			}
		}

		.contact_diplay_first_row {
			h5 {
				float: left;
				clear: right;
				margin-top: 10px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				margin-right: 0.5em;
			}

			h2 {
				float: left;
				clear: right;
				margin-top: 10px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				margin-right: 0.5em;
			}
		}

		.serviceContainer {
			.serviceName {
				float: right;
				display: inline-block;
				color: white;
				padding-left: 5px;
				padding-right: 5px;
				font-size: 11px;
			}

			.headingText {
				display: inline-block;
				color: white;
				padding-left: 5px;
				padding-right: 5px;
				font-size: 11px;
			}

			.serviceDescription {
				background-color: #F2F2F2;
				padding: 5px;
			}
		}
	}

	@media screen and (max-width: 1375px) {
		#row_main_groups {
			#orgWrapper {
				&.col-2 {
					max-width: none;
				}
			}
		}
	}
	/* Mobile Phones Portrait or Landscape */
	@media screen and (max-width: 767px) {

		#row_main_groups {

			#users-container {
				.ex-table_wrapper {
					overflow-x: visible;
				}

				&::after {
					clear: both;
				}

				&.col-10 {
					max-width: none;
				}

				.user-table {
					th {
						min-width: 6em;

						&:first-of-type, &:last-of-type {
							min-width: initial;
						}
					}

					.header-min-width {
						min-width: 10em;
					}
				}
			}
		}

		#modal_new_group, #modal_move_users, #modal_unlock_users, #modal_lock_users, #modal_reset_passwords, #modal_done_user_button,
		#modal_add_users, #modal_delete_confirm, #modal_done_org_button {
			.modal-dialog {
				width: 96vw;
				box-sizing: border-box;
			}
		}

		#module_edit_user {
			.detail_label {
				text-align: left;
			}

			.detail_row span {
				text-align: right;
			}
		}
	}

	.ex-page_groupsAndUsers {
		background-color: #F9F9FC;

		@include bp('small') {
			#wrapper.container {
				width: 100% !important;
				padding: 0;
			}
		}

		@include bp('tread') {
			#wrapper.container {
				width: 100% !important;
				padding: 0;
			}
		}
	}

	#row_main_groups.nonpro-user {
		margin-bottom: 1rem !important;
		min-height: calc(100vh - 13rem);

		#users-container {
			width: 100%;
			display: flex;
			justify-content: center;

			.card {
				width: 25%;
				height: calc(100vh - 13rem);
			}

			.card-body {
				display: flex;
				flex-direction: column;
				height: 100%;
			}

			.user-detail {
				&-wrapper {
					display: flex;
					flex: 1;
					min-height: 0;
				}

				flex: 1;
			}

			.consumerList {
				display: flex;
				flex: 1;
				min-height: 0;

				.users-scroll-container {
					flex: 1;
				}
			}
		}
	}

	.bulk-delete-user-list-container {
		max-height: 200px;
		overflow-x: hidden;
		overflow-y: auto;

		ul {
			list-style-type: none;
			padding-left: .5rem;
			margin-bottom: 0px;

			li {
				input {
					cursor: pointer;
					margin-right: .5rem;
				}
			}
		}
	}

	.bulk-delete-error-container {
		margin-top: 1rem;
	}

	.ex-pro_user_row-transformed {
		display: flex;
		flex-wrap: wrap;

		&-chunk {
			display: flex;
			margin-left: 2rem;

			@include bp('small') {
				margin-left: 0.25rem;
			}
		}

		&-label {
			font-weight: bold;
		}

		&-info {
			margin-left: 2rem;
			width: 10rem;

			div {
				width: 100%;
				overflow-x: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
}
