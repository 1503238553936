.ex-sarSelection {
	text-align: left;
	margin-bottom: 4rem;

	&_plans {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 1rem;

		@include bp('small') {
		}
	}

	&_super {
		font-weight: normal;
		font-size: 1.25rem;
		color: map-get($COLORS, secondarytext);
	}

	&_title {
		font-weight: bold;
		font-size: 1.5rem;
		color: map-get($COLORS, primarytext);
	}

	&_subtitle {
		font-weight: normal;
		font-size: 1.125rem;
		color: map-get($COLORS, secondarytext);
		margin-bottom: 1rem;

		p {
			margin-bottom: 0rem;
		}

		h5 {
			font-weight: bold;
			margin-bottom: 0rem;
			margin-top: 1.125rem;
		}
	}

	&_heading {
		a {
			margin-top: 1.125rem;
		}
	}

	&_plan {
		border: 1px solid map-get($COLORS, borders);
		border-radius: 10px;
		padding: 0.875rem;
		margin: 0 0.5rem;
		width: calc(100% / 3 - 1rem);
		background: none;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		@include bp('small') {
			max-width: none;
			width: calc((100%) / 3);
		}

		&-selected {
			border: 1px solid map-get($COLORS, explorange);
			background: map-get($COLORS, explorangebackground);
		}

		&:hover, &:focus {
			border: 1px solid map-get($COLORS, explorange);
		}

		&-title {
			font-size: 1.125rem;
			font-weight: bold;

			&-secondary {
				font-weight: normal;
			}
		}

		&-subtitle {
			font-size: 0.875rem;

			&-secondary {
				font-size: 1.125rem;
			}
		}
	}

	&_planData {
		border: 1px solid map-get($COLORS, explorange);
		padding: 1rem;
		max-width: 33rem;
		margin-bottom: 1rem;

		@include bp('small') {
			width: 100%;
			max-width: 100%;
		}
	}

	&_container {
		margin: auto;
		height: auto;

		ul {
			list-style: none;

			li {
				padding: 0.5em 0;

				span {
					color: map-get($COLORS, primarytext);
				}

				a {
					color: map-get($COLORS, explorange);
				}
			}
		}

		.ex-addDevice_terms {
			p {
				padding: 0.5em 0;
			}
		}
	}

	.recurringFee {
		align-self: center;
		font-size: 1rem;
		flex-basis: 100%;
	}

	.annualOrOneTimeFee {
		text-align: center;
	}

	.detailsLnk {
		text-align: center;
		padding-top: 2px;
		margin-top: auto;
		color: map-get($COLORS, explorange);
	}

	.detailstable {
		background-color: inherit;
		border: none;
		text-align: left;

		tr:first-child {
			background-color: inherit;
		}
	}

	.noPlanBox {
		border-left: 1px solid #808080;
		border-bottom: 1px solid #808080;
		border-right: 1px solid #808080;
		display: table;
		overflow: hidden;
		width: 100%;
	}

	.suspendPlanFirstColumn {
		display: table-cell;
		vertical-align: middle;
		padding-left: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
		width: 18%;
	}

	.suspendPlanRemainingColumn {
		display: table-cell;
		vertical-align: middle;
		padding-left: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
	}


	.metaBottom {
		clear: both;
		text-align: center;
		border-top: 1px solid black;
		margin-left: 25%;
		margin-right: 25%;
		padding-bottom: 10px;
	}

	.plansTop {
		text-align: center;
		padding-bottom: 5px;
	}
}
