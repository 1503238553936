.ex-homepageSubscriptions {
	width: 100%;

	&_container {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 3em;
		transition: all 0.3s ease-in-out;
		transform: translateX(0);

		.ex-homepageSubscriptions_loading & {
			display: none;
		}

		.manage-plan-card & {
			margin-top: 0;
		}

		@include bp('small') {
			transform: translateX(0) !important;
		}

		&-hidden {
			opacity: 0;
			z-index: -1;
			pointer-events: none;

			@include bp('small') {
				display: none;
			}
		}
	}

	&_selected {
		&-2 {
			transform: translateX(calc(-33.3% - 1em));
		}

		&-3 {
			transform: translateX(calc(-66.6% - 1em));
		}
	}

	&_section {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&_actions {
		width: 100%;
	}

	&_action {
		margin-bottom: 1em;
		text-align: center;

		&-button {
			font-weight: bold;
			font-size: 1rem;
			display: block;
			margin: auto auto 0.5em;
		}

		&-link {
			text-align: center;

			.manage-plan-card & {
				color: var(--bluelink);
			}
		}

		&-copy {
			font-size: 1rem;
			line-height: 1.25;
			text-align: center;
		}
	}
}

.ex-homepageContentArea_wrapper {
	&.subs {
		&-prioritize {
			order: -1;
		}

		&-normal {
			order: 2
		}
	}
}