.ex-planSelection, .ex-planTypeSelection {
	text-align: left;
	margin-bottom: 4rem;

	.image-grid {
		display: grid;
		grid-column-gap: 1em;
		grid-template-columns: 4em 1fr;
	}

	&_plans, &_planTypes {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 1rem;
		padding: 2em 0;

		@media screen and (max-width: 767px) {
			row-gap: 1em;
		}
	}

	&_heading {
		padding-left: 1rem;
	}

	&_super {
		font-weight: normal;
		font-size: 1.25rem;
		color: map-get($COLORS, secondarytext);
	}

	&_title {
		font-weight: bold;
		font-size: 1.5rem;
		color: map-get($COLORS, primarytext);
		margin-top:18px;
	}

	&_subtitle {
		font-weight: normal;
		font-size: 1.125rem;
		color: map-get($COLORS, secondarytext);
		margin-bottom: 1rem;
		max-width: 48rem;
	}

	&_description {
		display: flex;
		margin-bottom: 3em;

		div {
			flex: 100%;
		}

		img {
			width: 35%;
			margin-left: 4%;
			align-self: flex-start;

			@media screen and (max-width: 767px) {
				display: none;
			}
		}

		button {
			width: auto;
			margin-right: 1em;

			@media screen and (max-width: 860px) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 1em;
				max-width: none;
			}

			@media screen and (max-width: 767px) {
				width: auto;
				margin-right: 1em;
			}

			@media screen and (max-width: 500px) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 1em;
				max-width: none;
			}
		}
	}

	&_plan, &_planType {
		border: 1px solid map-get($COLORS, borders);
		border-radius: 10px;
		padding: 0.875rem;
		width: 32%;
		background: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		cursor: pointer;
		margin-bottom: 1em;
		margin-left: 0.5%;
		margin-right: 0.5%;

		@media screen and (max-width: 800px) {
			width: 49%;
		}

		@media screen and (max-width: 500px) {
			max-width: none;
			width: 100%;
			margin-bottom: 1em;
		}

		&-selected {
			border: 1px solid map-get($COLORS, explorange);
			background: map-get($COLORS, explorangebackground);
		}

		&:hover, &:focus {
			border: 1px solid map-get($COLORS, explorange);
		}

		&-title {
			font-size: 1.125rem;
			font-weight: bold;

			&-secondary {
				font-weight: normal;
			}
		}

		&-subtitle {
			font-size: 0.875rem;

			&-secondary {
				font-size: 1.125rem;
			}
		}
	}

	&_planData {
		border: 1px solid map-get($COLORS, explorange);
		padding: 1rem;
		max-width: 33rem;
		margin-bottom: 1rem;

		@media screen and (max-width: 767px) {
			width: 100%;
			max-width: 100%;
		}
	}

	&_container {
		margin: auto;
		height: auto;
	}

	.recurringFee {
		align-self: center;
		font-size: 1rem;
		flex-basis: 100%;
	}

	.annualOrOneTimeFee {
		text-align: center;
	}

	.detailsLnk {
		text-align: center;
		padding-top: 2px;
		margin-top: auto;
		color: map-get($COLORS, explorange);
	}

	.detailstable {
		background-color: inherit;
		border: none;
		text-align: left;

		tr:first-child {
			background-color: inherit;
		}
	}

	.noPlanBox {
		border-left: 1px solid #808080;
		border-bottom: 1px solid #808080;
		border-right: 1px solid #808080;
		display: table;
		overflow: hidden;
		width: 100%;
	}

	.suspendPlanFirstColumn {
		display: table-cell;
		vertical-align: middle;
		padding-left: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
		width: 18%;
	}

	.suspendPlanRemainingColumn {
		display: table-cell;
		vertical-align: middle;
		padding-left: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
	}


	.metaBottom {
		clear: both;
		text-align: center;
		border-top: 1px solid black;
		margin-left: 25%;
		margin-right: 25%;
		padding-bottom: 10px;
	}

	.plansTop {
		text-align: center;
		padding-bottom: 5px;
	}
}
