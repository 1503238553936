button {
}

.btn-explore {
	color: var(--contentbackground);
	background-color: var(--explorange);
	border-color: var(--explorange);

	&:hover {
		color: var(--contentbackground);
		background-color: var(--buttonexplorehover);
		border-color: var(--buttonexplorehover);
	}

	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(211,62,1, 0.5);
	}

	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: var(--contentbackground);
			background-color: var(--explorange);
			border-color: var(--explorange);
		}
	}

	&:disabled {
		color: #fff;
		background-color: var(--buttondisabled);
		border-color: var(--buttondisabled);
	}

	&-link {
		color: var(--explorange);
	}
}

.show > .btn-explore.dropdown-toggle {
	color: map-get($COLORS, contentbackground);
	background-color: map-get($COLORS, explorange);
	border-color: map-get($COLORS, explorange);
}

.btn-cancel {
	border: none;
	color: #000;
	background-color: transparent;

	&:hover {
		border: none;
		color: #000;
		text-decoration: underline;
		background-color: transparent;
	}

	&:focus {
		box-shadow: none;
		text-decoration: underline;
	}

	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: #000;
			background-color: transparent;
			border: none;
		}
	}
}

.show > .btn-cancel.dropdown-toggle {
	color: #000;
	background-color: transparent;
	border: none;
}

.btn-outline-explore {
	color: map-get($COLORS, explorange);
	background-color: transparent;
	background-image: none;
	border-color: map-get($COLORS, explorange);

	&:hover {
		color: map-get($COLORS, contentbackground);
		background-color: map-get($COLORS, explorange);
		border-color: map-get($COLORS, explorange);
	}

	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(211,62,1, 0.5);
	}

	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: map-get($COLORS, contentbackground);
			background-color: map-get($COLORS, explorange);
			border-color: map-get($COLORS, explorange);
		}
	}

	&:disabled {
		color: map-get($COLORS, explorange);
		background-color: transparent;
		border-color: map-get($COLORS, explorange);
	}
}

.show > .btn-outline-explore.dropdown-toggle {
	color: map-get($COLORS, contentbackground);
	background-color: map-get($COLORS, explorange);
	border-color: map-get($COLORS, explorange);
}

.button-link,
.ex-button_link {
	color: map-get($COLORS, explorange) !important;
	background: none;
	border: none;
	cursor: pointer;
	padding: 0 !important;
	display: block;
	font-size: 14px;

	&-lg {
		font-size: 1rem;
	}

	&:hover {
		text-decoration: underline;
	}
}

.ex-buttonStyle {
	background-color: transparent;
	border-radius: 10px;
	border: 1px solid map-get($COLORS, buttonprimary);
	color: map-get($COLORS, buttonprimary);
	font-size: 18px;
	font-weight: bold;
	padding: 18px 24px;
	text-decoration: none;
	margin: 10px 0 6px 0;
	align-self: flex-start;
	cursor: pointer;
	max-width: 100%;
	transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
	background-image: none;

	&:active {
		background-image: none;
		box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
		background-color: map-get($COLORS, buttonprimaryactive);
		color: map-get($COLORS, buttonprimary);
		outline: 0;
	}

	&:hover {
		background-image: none;
		box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
		background-color: map-get($COLORS, buttonprimaryactive);
		color: map-get($COLORS, buttonprimary);
		outline: 0;
	}
}

.ex-button_lg {
	font-size: 1.125rem;
	font-weight: bold;
	padding: 1.125rem 1.5rem;
	text-decoration: none;
	margin: 0.625rem 0 0.375rem 0;
}

#login-btn-signin,
.btn1 {
	border: 1px solid map-get($COLORS, buttonprimary) !important;
	color: map-get($COLORS, buttonprimary) !important;
}


.ex-button {
	&_container {
		margin-bottom: 0.5em;

		&-flex {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
		}

		&-full {
			width: 100%;
		}

		.ex-button {
			& + .ex-button {
				margin-left: 1rem;
			}
		}
	}

	&_absolute {
		&-topRight {
			position: absolute;
			top: 1em;
			right: 1em;
		}
	}
}

.modal {
	&-footer {
		.btn {
			& + .btn {
				margin-left: 0.5rem;
			}
		}

		[class*="buttonContainer"] {
			display: flex;
			flex-wrap: wrap;

			> *:not(.btn) {
				margin-left: 0.5rem;
			}
		}
	}
}

.btn:disabled, .btn.disabled {
	color: map-get($COLORS, contentbackground) !important;
	background-color: map-get($COLORS, buttondisabled) !important;
	border-color: map-get($COLORS, buttondisabled) !important;
	opacity: 1 !important;
	cursor: not-allowed;
}
