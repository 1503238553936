.ex-billinginfo {
	padding: 0 1em 1em;
	display: flex;
	flex-wrap: wrap;

	@include bp('medium') {
		padding: 0 0.5em;
	}

	&_container {
		flex-basis: 100%;
		display: flex;
		flex-wrap: wrap;

		.top-row {
			@include bp('medium') {
				text-align: center;
				flex-direction: column
			}
		}
	}

	&_main {
		flex-basis: 50%;
		width: 50%;
		min-width: 50%;
		padding-right: 1em;
		display: flex;
		flex-direction: column;

		@include bp('medium') {
			padding-right: 0;
			flex-basis: 100%;
			width: 100%;

			&.step2 {
				display: none;
			}
		}
	}

	&_side {
		flex-basis: 50%;
		width: 50%;
		padding-left: 1em;

		@include bp('medium') {
			padding-left: 0;
			flex-basis: 100%;
			width: 100%;
			border-left: none;
			display: flex;
			flex-direction: column;

			.step_header {
				margin-top: 1rem;
			}
		}
	}

	&_font {
		&-md {
			font-size: 1rem;
		}

		&-sm {
			font-size: 0.625rem;
		}
	}

	select:disabled, input:disabled {
		cursor: not-allowed
	}

	.card-info {
		font-weight: bold;
	}

	.step_header {
		@include subheader-text();
	}

	.button_controls {
		@include bp('medium') {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			.btn{
				width: calc(30% - 0.5rem);
				.spinner-border {
					margin-right: -1.25rem;
				}
			}
		}
	}

	.mobile {
		display: none;

		@include bp('medium') {
			display: inline-block;
		}
	}

	.desktop {
		@include bp('medium') {
			display: none;
		}
	}
}

#frameContainer {
	position: relative;
	height: 100%;

	@include bp('medium') {
		width: 100%;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		@include bp('medium') {
			position: initial;
		}
	}

	&_rp iframe {
		width: 451px !important;
		height: 600px !important;
	}
}