@mixin mobileMapStyle() {

	.ex-page_mobileMap {
		@include secondary-body-text();
		text-align: left;

		@include bp('medium') {
			#header {
				& > .header {
					display: flex;
					justify-content: center;
				}
			}
		}

		@include bp('(max-width: 560px)') {
			#header {
				height: 60px;
			}
		}

		p {
			@include secondary-body-text();
		}

		div > input {
			width: 97%;
		}

		div > textarea {
			width: 97%;
		}

		input[type=text],
		textarea {
			@include body-text();
		}

		#header {
			display: flex;
			justify-content: space-between;
			align-content: stretch;
			border-bottom: 1px solid #DEDEDE;
		}

		#selector {
			flex-grow: 0;
			height: 4px;
			width: 0px;
			background-color: map-get($COLORS, explorange);
			transition: left ease 0.25s;
			position: absolute;
			top: 56px;
			left: 0;
		}

		.header {
			cursor: pointer;
			padding-bottom: 7px;
			padding-top: 12px;
			width: 1px;
			flex-grow: 1;
		}

		.headerImage {
			height: 16px;
			width: 16px;
			margin-right: 6px;
			background-size: 16px 16px;
		}

		#mapImage {
			background-image: url(../Content/images/NewMap/mapHeader.png);
		}

		#msgImage {
			background-image: url(../Content/images/NewMap/messageHeader.png);
		}

		#locImage {
			background-image: url(../Content/images/NewMap/locateHeader.png);
		}

		.headerContainer {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.title {
			@include subheader-text();
			font-weight: bold;
		}

		.blueButton {
			background-color: map-get($COLORS, buttonprimary) !important;
			background-image: none !important;
			color: white !important;
		}

		.greenButton {
			background-color: #289a00 !important;
			background-image: none !important;
			color: white !important;
		}

		.grayButton {
			background-color: gray !important;
			background-image: none !important;
			color: white !important;
		}

		.redButton {
			background-color: #c20000 !important;
			background-image: none !important;
			color: white !important;
		}

		.wide-btn {
			width: 100%;
		}

		#msg, #locate {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.textTab {
			padding-left: 18px;
			padding-right: 18px;
		}

		.waypointCluster > span {
			position: absolute;
			top: 6px;
			left: 0px;
			width: 40px;
			text-align: center;

			b {
				background: map-get($COLORS, contentbackground);
				height: 11px;
				top: 0px;
				line-height: 0.675;
				position: relative;
				display: inline-block;
			}
		}

		.spinnerImageSize {
			height: 18px;
			width: 18px;
			position: relative;
			bottom: 2px;
		}

		.topBuffer {
			padding-top: 30px;
		}

		.bigText {
			@include subheader-text();
		}

		.nameLabel {
			-ms-text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
			text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
			border: 0;
			border-width: 0px !important;
			background: none !important;
			box-shadow: none !important;
			margin-top: 24px !important;
			font-weight: bold;
			color: #000 !important;
			@include body-text();
		}

		.nameLabel:before {
			border: 0px !important;
		}

		.visible {
			display: normal;
		}

		.buttonMap {
			height: 50px;
			width: 50px;
			border-radius: 25px;
			background-color: white;
			color: map-get($COLORS, buttonprimary);
			vertical-align: middle;
			text-align: center;
		}

		.garminBlock {
			padding: 10px;
		}

		.closeOverlayImg {
			width: 30px;
			height: 30px;
		}

		.closeOverlayStyle {
			float: right;
			cursor: pointer;
			margin-right: 8px;
		}

		.rowPadding {
			padding-top: 5px;
		}

		.overlay {
			position: relative;
			z-index: 10000;
			background-color: white;
			overflow-y: auto;
			box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.5);
		}

		.innerPad {
			padding: 5px;
		}

		#overlay {
			position: absolute;
			bottom: 0;
			z-index: 10000;
			transition: height ease 0.25s;
			height: 0;
			width: 100%;
			overflow: hidden;
		}

		#inner-overlay {
			min-height: 100%;
			position: absolute;
			top: 0;
			background-color: #FFFFFF;
			width: 100%;
			height: fit-content;
		}

		.overlay-panel {
			padding: 5px;
		}

		#panels {
			display: none;
		}


		#map {
			transition: height ease 0.25s;
		}

		.buttonImageTarget {
			height: 35px;
			width: 35px;
			margin: 7.5px;
		}

		.buttonImageI {
			height: 26px;
			width: 13px;
			margin: 12px 17px 12px 17px;
		}


		@media (max-width: 320px) {
			.headerImageVisible {
				display: none;
			}
		}


		.infoSlideInAnimation {
			-webkit-animation: infoSlideIn both ease 0.5s;
			animation: infoSlideIn both ease 0.5s;
		}

		.infoSlideOutAnimation {
			-webkit-animation: infoSlideIn both ease 0.5s reverse;
			animation: infoSlideIn both ease 0.5s reverse;
		}

		.waypointSlideInAnimation {
			-webkit-animation: waypointSlideIn both ease 0.5s;
			animation: waypointSlideIn both ease 0.5s;
		}

		.waypointSlideOutAnimation {
			-webkit-animation: waypointSlideIn 0.5s both ease reverse;
			animation: waypointSlideIn 0.5s both ease reverse;
		}

		.buttonSlideUpAnimation {
			-webkit-animation: buttonSlideUp 0.5s both ease;
			animation: buttonSlideUp 0.5s both ease;
		}

		.buttonSlideDownAnimation {
			-webkit-animation: buttonSlideUp 0.5s both ease reverse;
			animation: buttonSlideUp 0.5s both ease reverse;
		}

		.buttonsUp {
			margin-bottom: 150px !important;
		}

		.buttonSlideUpBigAnimation {
			-webkit-animation: buttonSlideUpBig 0.5s both ease;
			animation: buttonSlideUpBig 0.5s both ease;
		}

		.buttonSlideDownBigAnimation {
			-webkit-animation: buttonSlideUpBig 0.5s both ease reverse;
			animation: buttonSlideUpBig 0.5s both ease reverse;
		}

		.buttonsUpBig {
			margin-bottom: 400px !important;
		}

		.spinner {
			-webkit-animation: spin 1.2s infinite linear;
			animation: spin 1.2s infinite linear;
		}

		#menucontainer {
			position: fixed;
			top: 0;
			z-index: 9999;

			&:after {
				content: '';
				background: white;
				padding: 0 0 1.5em;
				position: absolute;
				bottom: -1.5em;
				left: 0;
				right: 0;
				z-index: -1;
			}
		}

		#content {
			padding-top: #{$map-header-height-mobile};
			background-color: map-get($COLORS, contentbackground) !important;
		}


		#footer {
			width: 95%;
		}

		.ex-content-light {
			background-color: map-get($COLORS, contentbackground);
			border-top: none;
			border-bottom: 1px solid map-get($COLORS, borders);
			padding: 1em;

			.label {
				background-color: map-get($COLORS, contentbackground) !important;
			}
		}

		#message {
			.listitem {
				background-color: map-get($COLORS, contentbackground);
				border-top: none;
				border-bottom: 1px solid map-get($COLORS, borders);
				padding: 1em;
			}
		}

		.popup {
			.popupHeader {
				width: 100%;
			}

			.popupText {
				max-width: 100%;
			}
		}

		.ex-overlayPanel {

			&_content {
				display: flex;
				justify-content: flex-start;
				align-items: stretch;

				&-section {
					padding: 2px;
				}

				&-header {
					height: 100%
				}

				&-image {
					width: 50px;
					height: 50px;
				}
			}
		}
	}
}
