.ex-homepageStat {
	height: 9em;
	box-shadow: 0px 3px 20px map-get($COLORS, statboxshadow);
	border-radius: 0.5em;
	padding: 0.5em;
	margin-top: 1em;
	margin-bottom: 1em;
	display: block;
	text-decoration: none !important;

	@include bp('(min-width: 1192px)') {
		max-width: 7em;
		margin: 0.25em 0.5em;
	}

	.ex-homepageStats_loading & {
		width: 10em;
		margin: 1em 0.5rem;
	}

	@include bp('small') {
		padding: 0.5rem;
	}

	&_centered {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;
		align-content: start;

		& > * {
			flex-basis: 100%;
		}
	}


	&_icon {
		height: 2.1rem;
		width: 2.1rem;
		border-radius: 50%;
		background-size: cover;
		flex-basis: 2.1rem;
		margin: 0 auto 0.5rem auto;

		.ex-homepageStats_loading & {
			@include loading();
			min-height: 2.1rem;
			min-width: 2.1rem;
		}
	}

	& > * {
		color: map-get($COLORS, contentbackground);
	}

	&_title {
		font-weight: normal;
		font-size: 1rem;
		line-height: 1rem;
		height: 3rem;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include bp('small') {
			font-size: 0.795rem;
		}

		.ex-homepageStats_loading & {
			@include loading();
			min-height: 2rem;
		}
	}

	&_value {
		font-weight: bold;
		font-size: 1.875em;
		line-height: 1;
		margin-bottom: 0.5rem;

		.ex-homepageStats_loading & {
			@include loading();
			min-height: 2.75rem;
		}

		.track-distance-smaller-font & {
			font-size: 1.45em;
		}
	}
}
