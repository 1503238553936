﻿@mixin plan-information-card() {
	.ex-plan_information_card {
		display: block;
		color: black;
		margin: .5rem 1rem .5rem 1rem !important;

		.plan-header {
			background-color: var(--cardHeaderNoTheme);
			padding: .25rem 0;
			border-radius: .5rem .5rem 0 0;
		}

		.card-body {
			background-color: var(--cardBodyNoTheme);
			border-radius: 0 0 0.5rem 0.5rem;
			display: flex;
			justify-content: space-between;
			padding: .2rem 1rem .2rem 1rem;
			column-gap: 2rem;
			align-items: center;
		}

		.price-header {
			font-size: .75rem;
			margin-bottom: -.375rem;
		}

		.price {
			display: flex;
			column-gap: .2rem;
			align-items: baseline;
		}

		.price-amount {
			font-size: 1.75rem;
			white-space: nowrap;
		}
		
		.price-currency-code {
			font-size: .9rem;
		}

		.plan-details-container {
			justify-content: start;
		}

		.plan-description {
			font-size: .875rem;
		}
	}
}
