.bluebutton {
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1;
	color: #FFF;
	background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#5B7599), to(#2E3542));
	background-image: -moz-linear-gradient(0% 100% 90deg,#2E3542, #5B7599);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5b7599', endColorstr='#2e3542');
	background-color: #E5E5E5;
	background: -ms-linear-gradient(top, #5b7599 0%,#2e3542 100%); /* IE10+ */
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
	border: 1px solid #a6a6a6;
	width: auto;
	cursor: pointer;
}

.bluebutton:hover {
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1;
	color: #FFF;
	background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#899CB8), to(#2E3542));
	background-image: -moz-linear-gradient(0% 100% 90deg,#2E3542, #899CB8);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5b7599', endColorstr='#2e3542');
	background: -ms-linear-gradient(top, #5b7599 0%,#2e3542 100%); /* IE10+ */
	background-color: #E5E5E5;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
	border: 1px solid #a6a6a6;
	width: auto;
	cursor: pointer;
}

.greengradient {
	background-image: -webkit-linear-gradient(left, #000 0%, #359500 100%);
	background-image: -moz-linear-gradient(0% 100% 0deg,#000, #359500);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000', endColorstr='#359500');
	background: -ms-linear-gradient(left, #000 0%,#359500 100%); /* IE10+ */
	background-color: #2b7700;
}

.redgradient {
	background-image: -webkit-linear-gradient(left, #000 0%, #b80000 100%);
	background-image: -moz-linear-gradient(0% 100% 0deg,#000, #b80000);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000', endColorstr='#b80000');
	background: -ms-linear-gradient(left, #000 0%,#b80000 100%); /* IE10+ */
	background-color: #b80000;
}

.orangegradient {
	background-image: -webkit-linear-gradient(left, #000 0%, #d33e01 100%);
	background-image: -moz-linear-gradient(0% 100% 0deg,#000, #d33e01);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000', endColorstr='#d33e01');
	background: -ms-linear-gradient(left, #000 0%,e06900 100%); /* IE10+ */
	background-color: map-get($COLORS, explorange);
}

.serviceContainer {
	margin-top: 10px;
	margin-bottom: 10px;
}

.sar-plans {
	.sar-plan {
		&-container {
			display: flex;
			flex-direction: column;
			border: 2px solid #e3e3e3;
			border-radius: 0.5rem;
			padding: 1rem;
			margin-bottom: 0.5rem;
		}

		&-name {
			@include subheader-text();
			font-weight: 700;
		}

		&-label {
			@include secondary-body-text();
			color: #757575;
			margin-top: 0.5rem;
		}

		&-value {
			@include body-text();
		}

		&-external-links {

			&-container {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
			}
		}

		&-renewal {
			display: flex;

			&-toggle-label {
				display: inline-block;
				margin-right: 0.25rem;
				margin-bottom: 0.4375rem;
			}

			&-toggle-container {
				display: flex;
				align-items: center;
				margin-left: 0.5rem;
				margin-top: 0.375rem;
			}
		}
	}
}

// custom slider - this should be somewhere else
.switch {
	position: relative;
	display: inline-block;
	width: 3rem;
	height: 2rem;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 1.25rem;
	width: 1.25rem;
	left: 0.125rem;
	bottom: 0.125rem;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .slider {
	background-color: #007bff;
}

input:focus + .slider {
	box-shadow: 0 0 1px #007bff;
}

input:checked + .slider:before {
	-webkit-transform: translateX(1.25rem);
	-ms-transform: translateX(1.25rem);
	transform: translateX(1.25rem);
}

/* Rounded sliders */
.slider.round {
	border-radius: 1.625rem;
}

.slider.round:before {
	border-radius: 50%;
}
