﻿@mixin collectBilling() {
	#CollectBillingForm hr {
		display: none;
		/* THIS SHOULD BE REMOVED POST VISUAL UPDATE, THE HR IS NOT NEEDED IN THE VIEW ANYMORE */
	}

	#billingForm {
		.contact_diplay_first_row {
			box-shadow: 0px 1px 0px 0px #75757559;
			display: flex;
			flex-wrap: wrap;

			& > h2 {
				display: inline-block;
			}

			#loadingSpinner {
				display: none;
				margin: 5px;
			}
		}
	}

	#CollectBillingForm {

		#cancelButton, #backButton {
			margin-right: 5px;
		}

		#buttonContainer {
			margin-bottom: 5px;
		}
	}

	.btn {
		&.btn-top {
			float: right;
			margin-right: 1em;
			background-image: none !important;
			background-color: #e7e7e7 !important;
			border: 1px #9a9a9a;
			border-radius: 0.25rem;
			transition: none;
		}

		&.btn.btn-green {
			float: right;
			background-color: #1E7D34 !important;
			color: white !important;
			background-image: none !important;
			transition: none;
			border: 1px #1E7D34;
			border-radius: 0.25rem;
		}
	}

	.editor-label label {
		font-size: 13px;
		color: rgb(97, 97, 97);
		height: 15px;
	}

	.editor-field input {
		margin: 2px 3px 5px 0 !important;
		padding: 2px 4px !important;
		-webkit-border-radius: 5px !important;
		-moz-border-radius: 5px !important;
		border-radius: 5px !important;
		outline: 0 !important;
		border: 1px solid #AAA !important;
		width: 250px !important;
		/*height: 20px !important;*/
		display: inline-block !important;
		box-shadow: none !important;
		color: #666;
	}

	.editor-field select {
		border-color: rgb(170, 170, 170);
		border-radius: 5px;
		color: rgb(102,102,102);
		display: inline-block;
		font-size: 16px;
		width: 250px;
		margin: 5px 0 3px 2px;
		padding: 0 3px 0 2px;
		box-shadow: none !important;
	}

	.field-validation-error {
		display: inline-block;
	}

	div.span5 {
		width: 60%;
		float: left;
	}

	#billingInfoForm {
		.editor-label.first {
			padding-top: 10px;
		}

		@include bp('small') {
			display: flex;
			flex-wrap: wrap;
			width: 100%;

			& > div {
				width: 100%;
			}
		}
	}

	#payframeModal {
		.modal-dialog {
			margin-top: 5%;
		}

		#worldpayErrorModalFooter, #worldpayModalFooter {
			display: none;
		}
	}

	#ccExpirationEditor {
		#ccExpirationSave:hover {
			cursor: pointer;
			color: #1E7D34;
		}

		#ccExpirationCancel:hover {
			cursor: pointer;
			color: #d60000;
		}
	}
}
