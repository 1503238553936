.ex-homepageSubscription {
	position: relative;
	flex-basis: calc(100% / 3 - 1em);
	border: 2px solid var(--bordersdark);
	border-radius: 5px;
	background: var(--primarybackground);
	padding: 1em;
	padding-bottom: 8em;
	min-height: 20em;
	transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
	opacity: 1;
	z-index: 1;

	&_isSingleCard {
		max-width: 25rem;
	}

	&_card-body {
		height: 30rem;
	}

	&_pending-change {
		color: var(--explorange);
		font-size: 0.75em;
		font-weight: normal;
	}

	&_header {
		font-size: 1.5rem;

		@include bp('small') {
			font-size: 1.25rem;
		}
	}

	&_header-container {
		min-height: 3.5rem;
	}

	.ex-homepageSubscriptions_loading & {
		display: none;
	}

	@include bp('(max-width: 1400px)') {
		flex-basis: calc(100% / 3 - 0.5em);
	}

	@include bp('medium') {
		flex-basis: calc(100% / 3 - 0.25em);
	}

	@include bp('small') {
		flex-basis: 100%;
		margin-bottom: 1em;
	}

	.manage-plan-card {
		background-color: var(--primarybackground);
		border-color: var(--formborder);
		color: var(--primarytext);
	}

	&_editing {
		opacity: 0;
		z-index: -1;
		visibility: hidden;

		@include bp('small') {
			display: none;
		}
	}

	&_isSelected {
		border: 2px solid var(--explorange);
		background: var(--explorangebackground);
		opacity: 1;
		visibility: visible;
	}

	&_section {
		display: flex;
		justify-content: space-between;
		padding: 0;

		.ex-homepageSubscriptions_loading & {
			margin-bottom: 0.5em;

			@include loading();
		}

		&-bottom {
			position: absolute;
			bottom: 1em;
			left: 0.25em;
			right: 0.25em;

			.ex-homepageSubscriptions_loading & {
				visibility: hidden;
			}

			.ex-homepageSubscription_section {
				margin-bottom: 1em;
			}
		}
	}

	&_title {
		font-size: 1.25rem;
		line-height: 1.25;
		font-weight: bold;

		.ex-homepageSubscriptions_loading & {
			min-height: 1.25rem;
			background-color: var(--tabbackground);

			@include loading();
		}
	}


	&_value {
		font-size: 1.25rem;
		line-height: 1.25;
		font-weight: bold;

		.ex-homepageSubscriptions_loading & {
			min-height: 1.25rem;
			background-color: var(--tabbackground);

			@include loading();
		}

		&-prefix {
			.ex-homepageSubscriptions_loading & {
				display: none;
			}
		}
	}

	&_label {
		font-size: 0.75rem;
		line-height: 1.25;

		.ex-homepageSubscriptions_loading & {
			min-height: 0.75rem;
			background-color: var(--tabbackground);

			@include loading();
		}
	}

	&_list {
		width: 100%;

		div {
			line-height: 1.25em;

			&:before {
				content: '';
				display: inline-block;
				height: 1.5em;
				background-image: url('/Content/images/stats/Subscriptions_Checkmark.svg');
				background-size: contain;
				background-repeat: no-repeat;
				background-position-y: 6px;
				padding-left: 1.5em;
			}
		}

		ul {
			padding-left: 1.25rem;
		}

		ul li {
			padding-left: 0.25rem;
		}

		ul:first-child {
			padding-left: 0;
			list-style-type: none;
		}

		ul:first-child li {
			padding-left: 1.5rem;
			background-image: url('/Content/images/stats/Subscriptions_Checkmark.svg');
			background-size: 1rem;
			background-repeat: no-repeat;
			line-height: 1.25rem;
		}

		li + li {
			margin-top: 0.3rem;
		}
	}

	&_datum {
		position: relative;
		padding: 0.25em 1em 0.25em 1.5em;
		font-size: 1rem;
		line-height: 1.25;

		.ex-homepageSubscriptions_loading & {
			min-height: 2rem;
			margin-bottom: 0.25em;
			background-color: var(--tabbackground);

			@include loading();
		}

		i {
			position: absolute;
			left: 0;
			top: 0.625em;
			color: var(--explorange);
			font-size: 0.75rem;

			.ex-homepageSubscriptions_loading & {
				display: none;
			}
		}
	}

	&_link {
		display: inline-block;
		margin: auto;

		.manage-plan-card & {
			color: var(--bluelink);
		}
	}

	&_button {
		font-weight: bold;
		font-size: 1em;
		min-width: 14em;
		margin: auto;
		transition: opacity 0.4s ease-in-out, color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

		@include bp('medium') {
			min-width: 10em;
		}

		@include bp('medium') {
			min-width: 0;
		}

		.ex-homepageSubscription_editing & {
			opacity: 0;
		}

		&.btn-current {
			margin-left: -0.25em;
			margin-right: -0.25em;
			width: calc(100% + 0.5em);
			max-width: none;
			border-right: none;
			border-left: none;
			border-radius: 0;
		}
	}
}
