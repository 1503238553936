@mixin hamburger() {
	.ex-hamburger {
		display: none;
		position: absolute;
		font-size: 0.75em;
		width: 2.75em;
		height: 3em;
		margin-bottom: 0;
		left: 1em;
		top: 1em;
		z-index: 10002;
		padding: 0;
		border: none;
		background: none;
		appearance: none;
		transition: transform 0.3s ease-in-out;
		box-shadow: none;

		@include bp('medium') {
			display: block;
		}

		.modal-open & {
			z-index: 0;
		}

		&:hover, &:active, &:focus {
			background: none;
			outline: none;
		}

		&_top,
		&_middle,
		&_bottom {
			position: absolute;
			left: 50%;
			width: 80%;
			height: 2px;
			background: map-get($COLORS, dark);
			transform: translateX(-50%);
			transition: top 0.3s ease-in-out, bottom 0.3s ease-in-out, transform 0.3s ease-in-out;
		}

		&_top {
			top: 0.75em;
		}

		&_middle {
			top: calc(50% - 1px);
		}

		&_bottom {
			bottom: 0.75em;
		}

		&.is-active {
			transform: rotate(45deg);
			transition: transform 0.3s ease-in-out;

			.ex-hamburger_top {
				top: calc(50% - 1px);
				transform: rotate(90deg) translateY(calc(1em + 2px));
			}

			.ex-hamburger_bottom {
				bottom: calc(50% - 1px);
			}
		}

		&:hover {
			background: none !important;
		}
	}
}
