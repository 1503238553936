.ex-activateDevice {
	&_container {
		display: flex;
		flex-direction: row;
		margin: 5rem 1rem 1rem;
	}

	&_image {
		margin-right: 2rem;
		@include bp("small") {
			display: none;
		}
	}

	&_content {
		display: flex;
		flex-direction: column;
		justify-content: start;

		.header {
			font-size: 2rem;
			margin-bottom: 1rem;

			padding: 0;

			display: flex;
			flex-direction: row;
			justify-content: center;
		}

		.body {
			padding: 1rem 0rem;
			border-top: 1px solid var(--borderslight);
			border-bottom: 1px solid var(--borderslight);
		}
	}

	&_link-container {
		display: flex;
		flex-direction: row;
		justify-content: end;
		margin: 2rem 0rem;
	}

	&_link {
		text-decoration: underline;
		color: var(--link);
	}

	&_button-container {
		display: flex;
		flex-direction: row;
		justify-content: end;

		@media (max-width: 406px) {
			button {
				height: 2rem;
				font-size: 0.75rem;
			}
		}
	}

	&_cancel-button {
		background-color: var(--primarybackground);
		color: var(--primarytext);
		margin-right: 1rem;
		border-color: var(--buttonoutline);
        width: 8rem;

		@media (max-width: 406px) {
			width: 4rem;
		}
	}

	&_disabled {
		pointer-events: none;
		cursor: default;
		opacity: 0.6;
	  }
}
