.ex-metaPlan {
	text-align: left;
	margin-bottom: 0;
	width: 100%;
	position: relative;

	&_plans {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 1rem;

		@include bp('small') {
		}
	}

	&_section {
		.ex-button_link {
			font-size: 1.125rem;
		}
	}

	&_heading {
		padding-left: 1rem;
	}

	&_super {
		font-weight: normal;
		font-size: 1.25rem;
		color: map-get($COLORS, secondarytext);
	}

	&_title {
		font-weight: bold;
		font-size: 1.5rem;
		color: map-get($COLORS, primarytext);
	}

	&_subtitle {
		font-weight: normal;
		font-size: 1.125rem;
		color: map-get($COLORS, secondarytext);
		margin-bottom: 1rem;
		max-width: 48rem;
	}

	&_billing_frequency {
		font-weight: normal;
		color: map-get($COLORS, secondarytext);
	}

	&_plan {
		border: 1px solid map-get($COLORS, borders);
		border-radius: 10px;
		padding: 0.875rem;
		width: 75%;
		background: none;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		cursor: pointer;
		margin-top: 3em;
		margin-bottom: 5rem;
		margin-left: auto;
		margin-right: auto;

		@include bp('small') {
			max-width: none;
			width: 100%;
			margin-bottom: 1em;
		}

		&-message {
			font-weight: bold;
			font-size: 1.25rem;
			margin-top: 1.25rem;
		}

		&-selected {
			border: 1px solid map-get($COLORS, explorange);
			background: map-get($COLORS, explorangebackground);
		}

		&:hover, &:focus {
			border: 1px solid map-get($COLORS, explorange);
		}

		&-title {
			font-size: 1.125rem;
			font-weight: bold;

			&-secondary {
				font-weight: normal;
			}
		}

		&-subtitle {
			font-size: 0.875rem;

			&-secondary {
				font-size: 1.125rem;
			}
		}
	}

	&_planData {
		border: 1px solid map-get($COLORS, explorange);
		padding: 1rem;
		max-width: 33rem;
		margin-bottom: 1rem;

		@include bp('small') {
			width: 100%;
			max-width: 100%;
		}
	}

	&_container {
		margin: auto;
		height: auto;
	}

	&_button {
		border-radius: 50%;
		border: 1px solid map-get($COLORS, primarytext);
		background: map-get($COLORS, light);
		width: 1.25rem;
		height: 1.25rem;
		position: absolute;
		top: 1.5rem;
		left: 1rem;
		z-index: 1;

		.ex-metaPlan_plan-selected & {
			background-color: map-get($COLORS, explorange) !important;
		}
	}

	&_list {
		margin-top: 1rem;

		b {
			display: none;
		}

		li {
			&::marker {
				color: #d33e01;
			}
		}

		&-item {
			display: flex;
			position: relative;

			&_icon {
				position: absolute;
				left: 0.25rem;
				top: 0;
				font-size: 2rem;
				line-height: 0.75;
				color: map-get($COLORS, secondarytext);

				&-secondary {
					color: map-get($COLORS, explorange);
				}
			}

			&_copy {
				font-size: 1.125rem;
				padding-left: 2rem;

				&-secondary {
					color: map-get($COLORS, secondarytext);
				}
			}
		}

		&-secondary {
			margin-top: 2rem;
			display: flex;
			justify-content: space-between;

			&_item {
				display: flex;
				position: relative;
				width: 100%;

				&-icon {
					font-size: 1.125rem;
					line-height: 1.25;
					color: map-get($COLORS, primarytext);
				}

				&-copy {
					font-size: 1.125rem;
					padding-left: 2rem;
					margin-left: auto;
				}
			}

			&_payment_box {
			display:flex;
			flex-wrap:wrap;

				&_text {
					margin-right: 10px;
					text-align: right;
				}
			}
		}
	}

	&_callout {
		display: flex;
		flex-wrap: wrap;
		margin-top: 2rem;
		width: 100%;

		&-icon {
			flex-basis: 3rem;
			padding-top: 0.25rem;

			svg {
				height: 2.675rem;
				width: 2.675rem;
			}
		}

		&-copy {
			flex-basis: calc(100% - 4rem);
			display: flex;
			flex-wrap: wrap;
			line-height: 1.25;
			padding-left: 1rem;

			&_secondary {
				flex-basis: auto;
			}
		}

		&-value {
			font-size: 2.5rem;
			font-weight: bold;
			color: map-get($COLORS, primarytext);
			flex-basis: 100%;
			line-height: 1;
		}

		&-key {
			font-size: 1.5rem;
			font-weight: bold;
			color: map-get($COLORS, primarytext);
			flex-basis: 100%;
		}

		&-subkey {
			font-size: 1rem;
			color: map-get($COLORS, secondarytext);
			flex-basis: 100%;
		}
	}
}
