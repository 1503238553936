.ex-syncNoty,
#SyncNotification {
	position: fixed;
	top: 45px;
	left: 0;
	right: 0;
	margin: 0 auto;
	z-index: -1;
	width: max-content;
	text-align: left;
	transition: opacity 0.3s ease-in-out, z-index 0.3s ease-in-out;

	@include bp('small') {
		opacity: 1 !important;
		z-index: 10010 !important;
		left: 100%;
		transition: left 0.3s ease-in-out !important;
	}

	&_open {
		z-index: 10010 !important;
		opacity: 1 !important;

		@include bp('small') {
			left: 0 !important;
		}
	}

	&_body {
		display: flex;

		@include bp('small') {
			margin-right: 12px; //This balances things out visually because of dead space in the image
		}
	}

	&_title {
		font-weight: bold;
	}

	&_copy {
		@include bp('small') {
			display: none;
		}
	}

	&_image {
		margin-right: 0.25rem;
		margin-top: 0.25rem;
	}

	&_button {
		margin-top: 0.25rem;
	}

	&_close {
		border: none;
		background: none;
		position: absolute;
		right: 6px;
		top: 0;
		font-size: 20px;
		font-weight: bold;
		color: #721c24; //text color from alert danger (doesn't cascade to buttons)
		&:hover {
			color: #000;
		}
	}
}
