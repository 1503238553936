﻿@mixin plan-change-details() {
	.ex_PlanChangeDetails {
		display: block;

		.floating-button-wrapper {
			padding: 1rem;
		}

		h3 {
			text-align: center;
			margin: 2rem 2rem .5rem 2rem;
		}

		p {
			text-align: center;
			margin: 0rem 1rem 1rem 1rem;
		}

		ul {
			margin: 1rem 1rem 1rem 1rem;
		}

		.img-wrapper {
			margin-right: 1rem;
			margin-left: 1rem;
		}

		li {
			margin-top: 0.5rem;
		}

		.btn-primary-nohover:hover {
			color: var(--contentbackground);
		}

		.btn-secondary-nohover:hover {
			color: var(--explorange);
		}

		.scroll-gradient-bottom {
			--scrollbar-width: 8px;
			/* Mask Size from the bottom of the div (also equal to the bottom padding) */
			--mask-height: 2rem;
			overflow-y: auto;
			/* Our height limit applied it to the whole div */
			height: 100%;
			/* Need to add padding to the bottom equal to the height of mask (without it bottom content could be stuck in the mask)*/
			padding-bottom: var(--mask-height);
			/* The content mask is a linear gradient from top to bottom setting 0 for 3rd param prevents mask from applying to the top as well */
			--mask-image-content: linear-gradient( to bottom, transparent, 0, var(--dark) calc(100% - var(--mask-height)), transparent );
			/* Width is equal to 100% of the div minus the scrollbar */
			--mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
			/* The scrollbar mask is a black pixel this variable is a dummy needed for below (we don't apply mask over the scrollbar) */
			--mask-image-scrollbar: linear-gradient(var(--dark), var(--dark));
			/* The width of our black pixel is the width of the scrollbar.*/
			--mask-size-scrollbar: var(--scrollbar-width) 100%;
			/* Apply the mask image and mask size variables */
			mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
			mask-size: var(--mask-size-content), var(--mask-size-scrollbar); /* Leave out the scroll bar */
			/* Positions gradient off top left for our size calculations (for leaving scrollbar alone) */
			mask-position: 0 0, 100% 0;
			/* Don't repeat mask */
			mask-repeat: no-repeat, no-repeat;
		}
	}
}
