﻿.ex-icon {
	&_normal {
		height: 1rem;
		width: 1rem;
	}

	&_tall {
		height: 1.125rem;
		width: 0.75rem;
	}

	&_large {
		height: 2rem;
		width: 2rem;
	}

	&_help {
		margin: 0 0.5rem;
		color: map-get($COLORS, explorange);
		transition: color 0.3s ease-in-out;

		&:hover {
			color: darken(map-get($COLORS, explorange), 20%);
		}

		&-sm {
			font-size: 1rem;
		}
	}
}
