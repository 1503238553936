@mixin login() {

	.ex-page_login {
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-position: bottom;
		background-size: cover;
		font-family: arial, helvetica, sans-serif !important;
		@include secondary-body-text();

		#content,
		#logonPageContent,
		.ex-content {
			ul, ol {
				-webkit-padding-start: 0;
				margin: 5px 0 0 15px;
			}

			ul {
				display: block;
				list-style-type: disc;
				-webkit-margin-before: 0em;
				-webkit-margin-after: 0em;
				-webkit-margin-start: 0px;
				-webkit-margin-end: 0px;
				-webkit-padding-start: 20px;
			}
		}

		#contentwrapper {
			text-align: center;
			min-height: 100%;
			height: auto !important;
			height: 100%;
			margin: 0 auto -392px;
			width: 100%;
			background-image: url(/content/images/whitebackground.jpg);
			background-position: center;
			background-repeat: repeat-y;
		}

		.logInComponentStepOne {
			min-height: 205px !important;
			color: #686868;

			.ex-label {
				font-weight: bold !important;
				margin-left: 0 !important;
				color: map-get($COLORS, secondarytext) !important;
				display: inline-block !important;

				&.ex-login_rememberMe {
					color: map-get($COLORS, tertiarytext) !important;
				}
			}
		}

		.logInComponentStepTwoSSO {
			min-height: 205px !important;
		}

		.logInComponentStepTwoNonSSO {
			min-height: 205px !important;
		}

		iframe.gauth-iframe {
			min-height: 205px !important;
		}
		/* This keeps the plan buttons centered in IE */
		.planButtons {
			text-align: center !important;
		}

		#NextBtn {
			margin-top: 25px;
		}

		#errorZone {
			background-color: #ffcece;
			-moz-border-radius: 4px;
			-webkit-border-radius: 4px;
			border-radius: 4px;
			background-image: url(https://sso.garmin.com/sso/images/cross_circle.gif);
			background-position: 6px 4px;
			background-repeat: no-repeat;
			padding: 5px 5px 5px 27px;
			margin: .5em 0 10px 0;
			vertical-align: top;
			height: 100%;
		}

		#logInStepTwoSSOForgotPassword {
			order: 2 !important;
		}

		#logInStepTwoSSOBackButton {
			padding-top: unset !important;
			order: 3 !important;
		}

		#SignUpBoxBottomBannerOne {
			background-color: #FFF;
			height: 45px;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			margin: 0px 15px 15px 15px;
		}

		#LogInBox h3 {
			line-height: 27px !important;
		}

		input, textarea, button {
			border-color: none !important;
			box-shadow: none !important;
			-webkit-box-shadow: none !important;
			outline: none !important;
		}

		#header {
			background-color: #FFF;
			background-image: none;
		}

		.ExploreLogo {
			padding: 10px;
			width: auto;
		}

		#GarminLogo {
			margin-top: auto;
			position: absolute;
			bottom: 0;
			height: 35px;
			right: 10px;
		}

		#header {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			background-color: #fff;
			min-height: 60px;
		}

		.grayText {
			color: #686868;
		}

		.boldText {
			font-weight: bold;
		}

		.innerLogOnContainer div, .innerLogOnContainer ul li {
			color: #686868;
		}



		.garminDeviceFamily {
			margin: 0;
			-webkit-padding-start: 0;
		}
		/*LogOnFlexContainer*/
		#logonwrapper {
			height: auto;
			align-self: flex-end;
			position: relative;
			width: 100%;
		}

		#LogOnBackground {
			flex: 1 1 auto;
			width: 100%;
			background-repeat: no-repeat;
			background-position: bottom;
			background-size: cover;
			min-height: 100px;
		}

		#logonPageContent {
			text-align: left;
			height: 100%;
			display: flex;
			flex-direction: column;
			min-height: 0; // fix for Chrome 73+ sort of breaking overflow
			/*overflow: auto;*/ /*this makes the header stay at the top but breaks IE*/
		}

		#LogOnFlexContainerReduxOuter {
			height: auto;
			position: relative;
			width: 100%;
			overflow: auto;
		}

		#LogOnFlexContainerReduxInner {
			width: 100%;
			flex-direction: column;
			position: relative;
		}

		#LogOnFlexStageContainerRedux, #LogOnFlexStageContainerSignIn {
			position: relative;
			display: flex;
			flex-direction: row;
		}

		#LogOnFlexStageContainerSignIn {
			padding: 1% 30% 0% 30%;
			background-color: #FFF;
		}

		#LogOnFlexStageContainerSignIn #LogOnFlexContainerLeft {
			align-items: stretch;
		}


		#LogOnFlexContainerLeft {
			display: flex;
			flex-direction: column;
			background-color: #FFF;
			padding: 50px 50px;
			align-items: center;
			flex-basis: auto;
			flex: 1;
		}

		#LogOnFlexContainerRight {
			display: flex;
			flex-direction: column;
			background-color: map-get($COLORS, tertiarybackground);
			padding: 50px 50px;
			align-items: center;
			flex-basis: auto;
			flex: 1;
		}

		.innerLogOnContainer {
			width: 50%;
			display: flex;
			flex-direction: column;
			padding-bottom: 20px;
		}

		#LogOnFlexContainerLeft input, #LogOnFlexContainerRight input {
			margin-top: auto;
			align-self: center;
			white-space: normal;
			flex: none;
		}

		#LogOnFlexContainerLeft #backLinkDiv {
			margin-bottom: auto;
			margin-right: auto;
			left: 10px;
			top: 0px;
			position: absolute;
		}

		#inreach-footer {
			position: relative;
			margin: 0;
			width: 50%;
			right: 0;
			height: 80px;
			padding: 30px 0px 0px 0px;
			background-color: #F5F5F5;
			float: right;
		}

		#inreach-footer p {
			margin: 0;
			padding: 0 10px 0 10px;
			position: relative;
			text-align: right;
		}

		#inreach-footer p a {
			white-space: nowrap;
		}

		#leftFooter {
			position: relative;
			margin: 0;
			width: 50%;
			left: 0;
			height: 61px;
			padding: 0px 0px 0px 0px;
			background-color: #FFF;
			float: left;
		}
		/*Logon page CSS screen greater than or equal to 1152px in width, three columns*/
		@media (min-width: 1152px) {
		}
		/*Logon page CSS screen less than or equal to 1152px in width, two columns*/
		@media (max-width: 1152px) {
			.innerLogOnContainer {
				width: 100%;
				display: flex;
				flex-direction: column;
				padding-bottom: 20px;
			}

			#LogOnFlexContainer {
				width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
			}
		}
		/*Logon page CSS screen less than or equal to 768px in width: one column*/
		@media screen and (max-width: 768px) {
			#header {
				display: flex;
				min-height: 80px;
				height: 80px;
				flex-direction: column;
				position: relative;
				width: 100%;
				align-items: center;
				justify-content: flex-start;
			}

			.ExploreLogo {
				position: relative;
				align-self: flex-start;
			}

			#LogOnFlexStageContainerRedux, #LogOnFlexStageContainerSignIn {
				flex-direction: column;
			}

			#LogOnFlexStageContainerSignIn {
				min-height: 300px;
				background-color: #fff;
			}

			#LogOnFlexContainerLeft {
				padding: 20px 0px 20px 0px;
				flex: 0 1 auto;
			}

			#LogOnFlexContainerRight {
				padding: 20px 0px 40px 0px;
				flex: 0 1 auto;
			}

			.innerLogOnContainer {
				width: 50%;
			}

			#leftFooter {
				visibility: hidden;
				position: absolute;
			}

			#inreach-footer {
				position: relative;
				width: 100%;
			}
		}

		@media (max-width: 560px) {
			#header {
				min-height: 80px;
				height: 80px;
				flex-direction: column;
				position: relative;
				width: 100%;
				align-items: center;
				justify-content: flex-start;
			}

			.ExploreLogo {
				position: relative;
				align-self: flex-start;
			}

			#WhatIsInReach {
				display: none;
			}

			.innerLogOnContainer {
				width: 80%;
			}

			#LogOnFlexStageContainerSignIn {
				padding: 0% 10% 0% 10%;
			}
		}

		.blueForm-basic:empty {
			background-image: url("/content/images/ajax-loader-spin.gif");
		}


		.logInStepOneTextBox {
			height: 2em;
			margin: 10px 0;
			width: 95%;
			border-top: 0px;
			border-left: 0px;
			border-right: 0px;
			border-bottom: 2px solid map-get($COLORS, buttonprimary);
			padding-top: 10px;
			box-shadow: none;
			padding: 4px;
		}

		.logInStepTwoTextBox {
			height: 20px;
			margin: 10px 0;
			width: 95%;
			border-top: 0px;
			border-left: 0px;
			border-right: 0px;
			border-bottom: 2px solid map-get($COLORS, buttonprimary);
			@include subheader-text();
			padding-top: 10px;
			padding-left: 10px;
			box-shadow: none;
		}

		.logInStepOneRememberBox {
			color: #999;
			margin: 0 0 16px 0;
		}

		.logInStepOneRememberBox input {
			margin-right: 5px;
		}

		.logInComponentStepOne {
			display: flex !important;
			flex-direction: column !important;
		}

		.logInComponentStepTwoNonSSO {
			display: flex !important;
			flex-direction: column !important;
		}

		.logInComponentStepTwoSSO {
			display: flex !important;
			flex-direction: column !important;
		}


		.buttonStyle {
			background-color: transparent;
			-webkit-border-radius: 10;
			-moz-border-radius: 10;
			border-radius: 10px;
			border: 1px solid map-get($COLORS, buttonprimary);
			color: map-get($COLORS, buttonprimary);
			@include subheader-text();
			font-weight: bold;
			padding: 18px 24px;
			text-decoration: none;
			margin: 10px 0 6px 0;
			align-self: flex-start;
			cursor: pointer;
			max-width: 100%;
		}

		.newButtonStyle {
			-webkit-border-radius: 10;
			-moz-border-radius: 10;
			border-radius: 10px;
			@include subheader-text();
			font-weight: bold;
			padding: 18px 24px;
			text-decoration: none;
			margin: 10px 0 6px 0;
			align-self: flex-start;
			cursor: pointer;
			max-width: 100%;
		}

		.buttonStyle:active {
			background-image: none;
			-webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
			-moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15),0 1px 2px rgba(0,0,0,.05);
			box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
			background-color: #e6e6e6;
			outline: 0;
		}

		.smallerText {
			@include small-body-text();
		}

		.subTitle {
			@include subheader-text();
			font-weight: bold;
			color: #FFF;
			margin: 20px 0 10px 0;
		}

		.whiteList {
			color: #FFF;
			line-height: 24px;
			margin: 0 10px 0 -10px;
		}

		.redText {
			color: red;
		}

		h1 {
			@include subheader-text();
			font-weight: normal;
		}

		h2 {
			@include subheader-text();
			font-weight: bold;
			margin: 0px 0 10px 0;
		}

		h3 {
			line-height: normal;
			@include secondary-body-text();
			font-weight: normal;
			color: #686868;
		}

		h4 {
			list-style-type: none;
			@include body-text();
			color: #686868;
		}

		h6 {
			color: #686868;
		}



		input {
			@include secondary-body-text();
		}


		.planDescriptionList {
			@include small-body-text();
			color: #FFF;
			font-weight: normal;
			display: block;
			list-style-type: disc;
			-webkit-margin-before: 1em;
			-webkit-margin-after: 1em;
			-webkit-margin-start: 0px;
			-webkit-margin-end: 0px;
			-webkit-padding-start: 40px;
		}

		.centerButton {
			margin: auto auto 1px auto;
			max-width: 95%;
			text-align: center; /*Fix IE*/
		}

		.smallDevices {
			float: left;
			margin: 0 20px 0 0;
		}

		.normalText {
			@include secondary-body-text();
		}

		.smallishText {
			@include small-body-text();
		}

		.alignBottom {
			vertical-align: bottom;
		}

		.blackText {
			color: black;
		}

		.whiteText {
			color: white;
		}

		.padBottom10 {
			padding-bottom: 10px;
		}

		.underline {
			text-decoration: underline;
		}

		.boldText {
			font-weight: bold;
		}

		.padTop10 {
			padding-top: 10px;
		}

		.padTop20 {
			padding-top: 30px;
		}

		.noDisplay {
			display: none;
		}

		.backLink {
			cursor: pointer;
			color: #315bb1;
			text-decoration: underline;
		}

		.backArrow {
			padding-right: 6px;
			vertical-align: middle;
		}

		a {
			cursor: pointer;
		}

		a {
			text-decoration: underline;
		}

		.ex-cultureCode {
			position: absolute;
			right: 101vw;
			display: flex;
			overflow: hidden;
		}

		.ex-contentWrapper {
			margin: 0 auto;
			height: 100%;
			display: flex;
			flex-direction: column;
		}

		.ex-content {
			background-color: transparent;
		}
	}
	//TreadOverviewPage styling

	.TreadOverviewLogOnPage {
		position: relative;
		width: 100vw;
		height: 100vh;
		overflow: scroll;
		background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), url(/content/images/background/logon/mountain-darker.jpeg);
		background-size: cover;
		background-position-x: center;
		color: whitesmoke;
	}

	.TreadOverviewTitleContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 30px 53px;
		gap: 20px;
		background: rgba(33, 37, 41, 0.9);
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
		border-radius: 9px;
		margin-top: 10em;
		margin-left: auto;
		margin-right: auto;
		width: 60%;

		@media screen and (max-width: 1200px) {
			width: 80%;
		}

		.ContactParagraph {
			background: rgba(255, 255, 255, 0.1);
			border-radius: 6px;

			p {
				padding: 8px;
			}
		}

		h2, h3, h5 {
			font-weight: bold;
		}

		h5 {
			margin-bottom: 25px;
		}

		p {
			color: lightgray;
			font-size: 14px;
			margin: 0px;
		}

		.StepsSubTitle {
			font-size: 16px;
			font-weight: bold;
			color: whitesmoke;
		}

		ul {
			margin: 0px;
		}

		.MainContentContainer {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			gap: 30px;
			width: 100%;

			.LeftStepsContainer {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 0px;
				width: 50%;
				gap: 10px;

				p {
					font-size: 14px;
				}
			}

			.RightGuidesContainer {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				gap: 5px;
				width: 50%;

				.LinkContainer {
					display: flex;
					flex-direction: column;
					gap: 20px;
					width: 100%;

					a {
						color: white;
						text-decoration: underline;
					}
				}

				.ExistingAccountContainer {
					width: 100%;
				}
			}
		}

		#GetStartedButton {
			color: white;
			width: 400px;
			height: 42px;
			background: #D33E02;
			border: 1px solid #D33E02;
			border-radius: 6px;
		}
	}

	@media screen and (max-width: 1024px) {

		#logo {
			display: block;
			height: 50px;
			width: 220px;
		}

		.TreadOverviewTitleContainer {
			width: 100%;
			top: 10%;
			padding: 15px;
			position: center;
			left: 0px;

			h2, h3, h5 {
				font-size: 25px;
			}

			p {
				font-size: 12px;
			}

			.MainContentContainer {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 30px;
				padding: 15px;

				.LeftStepsContainer {
					width: 100%;
					gap: 10px;

					p {
						font-size: 12px;
					}
				}

				.RightGuidesContainer {
					width: 100%;
				}
			}
		}
	}

	.Circle {
		border-radius: 50%;
		width: 28px;
		height: 28px;
		padding: 5px;
		background-color: #D33E01;
		color: white;
		text-align: center;
		font: 14px Arial, sans-serif;
	}

	.Line {
		border-left: 1px solid #D33E01;
		height: 90%;
		margin-left: 14px;
	}

	.CircleLine {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		gap: 10px;
	}
}
