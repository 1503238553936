﻿@mixin page-promo() {
	.ex-page_promo {
		display: block;

		hr {
			background-color: black;
		}

		td {
			padding: 0;
		}

		.promoTable {
			width: 100%;
			bgcolor: #F6F6F6;
			border: 0;
			cellpadding: 0;
			cellspacing: 0;
			border-spacing: 0;
			color: #777777;
			margin: 0;
			padding: 0;
			table-layout: fixed;
			-webkit-text-size-adjust: 100%;
			-ms-text-size-adjust: 100%;
			line-height: 100% !important;
			background-color: #F6F6F6 !important;

			.innerPromoTable {
				width: 100%;
				border-spacing: 0;
				color: #777777;
				max-width: 780px;
				margin: 0 auto;
				background-color: #FFFFFF !important;
			}

			.heroImage {
				max-width: 780px;
				max-height: 620px;
			}

			.heroTitle {
				color: #FFFFFF;
				font-size: 28px;
				font-weight: 200;
				line-height: 56px;
				background-color: #333333;

				div {
					width: 54%;
					text-align: center;
					margin: auto;
				}
			}

			td .promoContents {
				padding-top: 24px;
				padding-left: 16px;
				padding-right: 16px;
				padding-bottom: 24px;

				table {
					color: #777777;

					tbody tr {
						td {
							padding-top: 8px;
							padding-bottom: 8px;
						}
					}

					tbody tr:first-child {
						td {
							padding-top: 0;
							padding-bottom: 8px;
						}
					}

					tbody tr:last-child {
						td {
							padding-top: 16px;
						}
					}

					tbody tr.activate {
						text-align: center;
					}
				}
			}

			td .legalContents {
				color: #999999;
				font-size: 12px;
				padding: 0;
				padding-left: 16px;
				padding-right: 16px;
			}
		}
	}
}
