
#submitBtn, #login-btn-signin, #login-btn-reset, #login-btn-cancel {
    all: none;
    background: #3498db;
    background-image: -webkit-linear-gradient(top, #FFF, #EEE);
    background-image: -moz-linear-gradient(top, #FFF, #EEE);
    background-image: -ms-linear-gradient(top, #FFF, #EEE);
    background-image: -o-linear-gradient(top, #FFF, #EEE);
    background-image: linear-gradient(to bottom, #FFF, #EEE);
    -webkit-border-radius: 4;
    -moz-border-radius: 4;
    border-radius: 4px;
    border: 1px solid #DDDDDD;
    color: map-get($COLORS, buttonprimary);
    font-size: 16px !important;
    padding: 10px 24px;
    text-decoration: none;
    margin: 10px 0 6px 0;
    align-self: flex-start;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal !important;
    line-height: normal !important;
    height: auto;
    text-shadow: none;
}

#submitBtn:active, #login-btn-signin:active, #login-btn-reset:active, #login-btn-cancel:active {
    background-image: none;
    -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
    -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15),0 1px 2px rgba(0,0,0,.05);
    box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
    background-color: map-get($COLORS, buttonprimaryactive);
    outline: 0;
}


#password {
    all: initial;
    margin: 10px 0;
    width: 95% !important;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 2px solid map-get($COLORS, buttonprimary);
    font-size: 18px;
    padding-top: 10px;
    padding-left: 10px;
    box-shadow: none;
}

#username {
    all: initial;
    margin: 10px 0;
    width: 95% !important;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 2px solid map-get($COLORS, buttonprimary);
    font-size: 18px;
    padding-top: 10px;
    padding-left: 10px;
    box-shadow: none;
}

#login-remember-checkbox {
    margin-left: 20px;
    margin-right: -10px;
}

#login-state-default h2 {
    display: none !important;
}

input:focus {
    outline: 0;
}

#create-account-component h2 {
    font-family: Arial
}

#login-state-default h2 {
    all: initial;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
    font-weight: bold;
}

#login-state-default p {
    margin-top: 0px;
    margin-left: 3px
}

label {
    font-weight: normal !important;
    margin-left: 3px
}

.textfield p {
    color: #000000 !important;
    margin-left: 3px
}

.gauth-loading {
    display: none;
}

label[for=username] {
    font-weight: bold !important;
}

label[for=password] {
    font-weight: bold !important;
}

label[for=login-remember-checkbox] {
    position: relative;
    margin-left: 0px;
}

.blueForm-basic .textfield input {
    background: none;
}


#gauth-widget-frame-logInComponentStepTwoSSO {
    order: 1 !important;
}
