﻿.leaflet-draw-toolbar a {
    background-image: url('/scripts/images/spritesheet.png');
    background-image: linear-gradient(transparent,transparent),url('/scripts/leaflet13/images/spritesheet.svg');
}

.leaflet-retina .leaflet-draw-toolbar a {
    background-image: url('/scripts/images/spritesheet-2x.png');
    background-image: linear-gradient(transparent,transparent),url('/scripts/leaflet13/images/spritesheet.svg')
}

.leaflet-control-scale-line {
    background: map-get($COLORS, contentbackground) !important;
}

.leaflet-control-attribution {
    background: map-get($COLORS, contentbackground) !important;
}