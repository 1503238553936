﻿@mixin nomad-device-landing-page() {
	.ex-nomad-device-landing-page {
		#content {
			width: 100%;
		}

		.main-header {
			font-size: 2.2rem;
		}

		h2, h3 {
			line-height: 1;
		}

		.header-container {
			margin: .5rem 3rem .5rem 3rem;

			@media (max-width: 767px) {
				margin: .5rem 2rem .5rem 2rem;
			}
		}

		.card-container {
			display: flex;
			justify-content: center;
			flex-flow: wrap;
			column-gap: 5rem;
			margin: 2rem;
		}

		.trek-card {
			background-color: var(--cardheader);
			width: 25rem;
			box-shadow: -3px 4px 8px 0px #00000040;
		}

		.get-started-button {
			height: 2.875rem;
		}

		.tread-image {
			display: block;
			margin-left: auto;
			margin-right: auto;
			height: 8rem;
			max-width: 100%;
		}
	}
}
