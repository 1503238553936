﻿div.time-picker {
  position: absolute;
  height: 191px;
  width:4em; /* needed for IE */
  overflow: auto;
  background: #fff;
  border: 1px solid #aaa;
  z-index: 1010;
  margin: 0;
}
div.time-picker-12hours {
  width:6em; /* needed for IE */
}

div.time-picker ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
div.time-picker li {
  cursor: pointer;
  height: 10px;
  font: 12px/1 Helvetica, Arial, sans-serif;
  padding: 4px 3px;
}
div.time-picker li.selected {
  background: #0063CE;
  color: #fff;
}