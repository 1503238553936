@mixin map() {
	@media screen {

		.layerButton {
			position: absolute !important;
			padding: 5px 10px;
			@include small-body-text();
			font-family: inherit;
			text-decoration: none;
			line-height: 1;
			color: #000;
			text-shadow: 0 -1px 1px rgba(255,255,255,.3);
			background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#fff), to(#cbcbcb));
			background-image: -moz-linear-gradient(0% 100% 90deg,#cbcbcb, #fff);
			background-image: -ms-linear-gradient(top, #fff, #cbcbcb);
			background-image: linear-gradient(top, #fff, #cbcbcb);
			background-color: #EFEFEF;
			border-radius: 7px;
			border: 1px solid #666;
			box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
			float: left;
			width: auto;
			margin: 5px;
			cursor: pointer;
		}

		.layerButtonActive {
			background-image: linear-gradient(top, #878787, #cbcbcb);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cbcbcb', endColorstr='#878787');
			filter: progid:dximagetransform.microsoft.gradient(enabled=false);
			background-color: #878787;
			box-shadow: 0 1px 2px rgba(0,0,0,.5), 0 1px 1px rgba(255,255,255,.5);
			padding: 5px 10px;
			@include small-body-text();
			font-family: inherit;
			text-decoration: none;
			line-height: 1;
			color: #FFF;
			text-shadow: 0 -1px 1px rgba(255,255,255,.3);
			border-radius: 7px;
			border: 1px solid #666;
			float: left;
			width: auto;
			margin: 5px;
			cursor: default;
		}

		.map-control-title {
			background: #636363;
			color: #FFFFFF;
			text-align: left;
			padding: 8px 2px;
			border: 1px solid #DEDEDE;
		}

		.map-control-title:hover {
			background: #636363;
		}

		.map-control-title-active {
			color: black !important;
			background: white !important;
			border-bottom: 1px solid white;
		}

		.map-filter-title {
			background: #636363;
			color: #FFFFFF;
			text-align: left;
			border: 1px solid #3e4c61;
			cursor: pointer;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}

		.silver-grad {
			background: #DEDEDE;
		}

		#tab-container {
			overflow: hidden;
			position: relative;
		}

		#map-users {
			position: absolute !important;
			top: 0px;
			width: 100%;
		}

		.body_scroll {
			overflow-y: auto;
			overflow-x: hidden;
		}

		#userList > tr:first-child > td:first-child {
			padding: 0px !important;
		}

		.userContainer {
			flex-wrap: wrap;
			display: flex;
			background: map-get($COLORS, tabbackground);
			cursor: pointer;
			user-select: none;
			border-bottom: 1px solid #B0B0B0;

			&:hover {
				background: map-get($COLORS, tabbackgroundhover);
			}
		}

		#userScrollHeader {
			border-bottom: 1px solid #B0B0B0;
		}

		.userC-row-item {
			float: left;
			margin-left: 2px;
			margin-right: 2px;
			padding-top: 2px;
			white-space: nowrap;
			text-overflow: ellipsis;

			.ex-icon {
				height: 1em;
				width: 1em;
			}
		}

		.map-user-name {
			padding-left: 5px;
		}

		#mapfilters {
			padding: 5px;
			text-align: left;
			padding-bottom: 10px;
			position: relative;
		}

		#mapfilters select {
			margin-bottom: 1px;
			height: auto;
			width: auto;
		}

		#mapfilters hr {
			margin-top: 5px;
			margin-bottom: 5px;
		}

		.menu-dropdown li, .dropdown-menu li {
			float: none;
			margin-left: 10px;
		}

		#DateTimeRangePickerContainer {
			.modal-dialog {
				max-width: 19.25rem !important;
				margin-left: 0 !important;
			}
		}

		#selectDateRangeDialog {
			z-index: 7002;
		}

		#selectDateRangeDialog-alert {
			display: none;
		}

		div.time-picker {
			z-index: 3001;
		}

		.mapLabel {
			background-color: white;
			font-size: 10px;
			min-width: 20px;
			border: 1px solid gray;
			border-radius: 2px;
		}

		.small-controls input {
			height: 28px;
		}

		.small-controls label {
			height: 28px;
		}

		.map-list-item {
			position: relative;
			background: map-get($COLORS, tabbackground);
			padding: 2px;
			cursor: pointer;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			border-bottom: 1px solid #B0B0B0;
			text-align: left;
			height: 40px;

			&:hover {
				background-color: map-get($COLORS, tabbackgroundhover);
			}

			&-selected {
				background-color: map-get($COLORS, listitembackground) !important;

				&:hover {
					background: map-get($COLORS, listitembackground);
				}
			}

			&-alt-row {
				background-color: map-get($COLORS, contentbackground);

				&:hover {
					background-color: #ededed;
				}
			}
		}

		#icon-chooser-container {
			position: absolute;
			display: none;
			border-radius: 10px;
			box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.75);
			-webkit-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.75);
			-moz-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.75);
			z-index: 10002 !important;
			overflow: hidden;
			width: 250px;
			height: 300px;
			background-color: white;

			@include bp('small') {
				left: 50% !important;
				transform: translateX(-50%) !important;
			}
		}

		#icon-chooser {
			display: flex;
			justify-content: start;
			flex-direction: column;
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			padding: 9px;
		}

		.waypoint-category-section {
		}

		.waypoint-icon-chooser-row {
			display: flex;
			flex-direction: row;
			justify-content: start;
		}

		.waypoint-icon-chooser-item {
			width: 26px;
			height: 26px;
			cursor: pointer;
			padding: 2px;
			margin-right: 8px;
			margin-bottom: 3px;
			flex: none;
		}

		.waypoint-icon-chooser-item:hover {
			padding: 1px;
			border: 1px dashed gray;
		}

		.waypoint-icon-chooser-are-you-sure-image {
			width: 26px;
			height: 26px;
		}

		.waypoint-icon-section-header {
			width: 102%;
			position: relative;
			top: -9px;
			background-color: white;
			padding-bottom: 5px;
			padding-top: 5px;
		}

		#colorChooser {
			z-index: 1001;
			width: 153px;
			background-color: white;
			padding: 7px;
			padding-bottom: 9px;
			border-radius: 5px;
			-webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
			-moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
			box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
		}

		.color-chooser-square {
			float: left;
			height: 20px;
			width: 20px;
			margin-left: 3px;
			cursor: pointer;
			border: 1px solid gray;
			margin-top: 5px;
		}

		#route-edit-color-label {
			position: relative;
			bottom: 3px;
			margin-right: 2px;
		}

		#route-edit-color-square {
			height: 30px;
			width: 30px;
			display: inline-block;
			border: 1px solid black;
			cursor: pointer;
			position: relative;
			top: 10px;
		}

		#waypoint-popup-container {
			min-width: 210px;
		}

		.popup-waypoint-latlon {
			margin-top: 10px;
		}

		.iconBordered {
			border: solid 1px gray;
			border-radius: 3px;
			padding: 2px;
			height: 30px;
			width: 30px;
		}

		.popup-waypoint-editor {
			.row{
				div.col-2, div.col-4{
					input{
						width:80%;
						display:inline;
						margin-left: -0.5em;
					}
					span{
						display:inline;
					}
				}
				div.col-4{
					margin-left: 0.5em;
				}
			}
		}

		.popup-waypoint-icon {
			float: right;
			position: relative;
			top: 10px;
			width: 33px;
			height: 27px;
		}

		.popup-waypoint-icon-edit {
			position: relative;
			width: 33px;
			height: 27px;
			cursor: pointer;
			float: right;
		}

		.popup-time {
			@include small-body-text();
			color: #676767;
		}

		.waypoints-header select, .waypoints-header input {
			margin: 0px !important;
			margin-top: 2px !important;
			padding: 1px;
			height: auto !important;
			margin-left: 10px;
		}

		.nameLabel {
			-ms-text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
			text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
			border: 0;
			border-width: 0px !important;
			background: none !important;
			box-shadow: none !important;
			margin-top: 12px !important;
			font-weight: bold;
			color: #000 !important;
		}

		.nameLabel:before {
			border: 0px !important;
		}

		.draggable-popup-container {
			z-index: 1000;
			position: absolute;
			top: 50px;
			left: 50px;
			background-color: white;
			padding: 5px;
			border-radius: 5px;
			box-shadow: 0 3px 14px rgba(0,0,0,0.4);
			cursor: move;
		}

		#route-popup-container {
			width: 250px;
			text-align: left;
			padding: 0.25em 0.5em 1em;

			@include bp('small') {
				transform: translateX(-50%) !important;
				left: 50% !important;

				.modal-open & {
					z-index: 0;
				}
			}
		}

		#measure-popup-container {
			@include bp('small') {
				transform: translateX(-50%) !important;
				left: 50% !important;
			}
		}

		.measure-popup-text {
			padding: 0.5em;
			text-align: left;
			float: left;
		}

		.measure-popup-close {
			float: right;
			padding-top: 0.5em;
		}

		.route-editing input {
			padding: 1px !important;
			margin: 1px !important;
			width: 150px !important;
			@include small-body-text();
		}

		.drag {
			height: 20px;
			cursor: move;
		}

		.gray {
			color: #676767;
		}

		#drawCtrlContainer {
			position: absolute;
			margin-top: 20px;
			width: -moz-max-content;
		}

		#drawCtrlContainer ul {
			margin: 0px !important;
		}

		#importExportContainer {
			position: absolute;
			margin-top: 20px;
			width: -moz-max-content;
		}

		#importExportContainer ul {
			margin: 0px !important;
		}

		#MeasureCtrlContainer {
			position: absolute;
			margin-top: 20px;
			width: -moz-max-content;
		}

		#MeasureCtrlContainer ul {
			margin: 0px !important;
		}

		.map-button-group-item {
			padding: 5px 5px;
			@include small-body-text();
			font-family: Helvetica, Arial, sans-serif;
			text-decoration: none;
			line-height: 1;
			color: #000;
			text-shadow: 0 -1px 1px rgba(255,255,255,.3);
			background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#fff), to(#cbcbcb));
			background-image: -moz-linear-gradient(0% 100% 90deg,#cbcbcb, #fff);
			background-image: -ms-linear-gradient(top, #fff, #cbcbcb);
			background-image: linear-gradient(top, #fff, #cbcbcb);
			background-color: #EFEFEF;
			border: 1px solid #666;
			-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
			-moz-box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
			-o-box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
			box-shadow: 0 1px 2px rgba(0,0,0,.5), inset 0 1px 1px rgba(255,255,255,.5);
			cursor: pointer !important;
			user-select: none !important;
			display: inline;
			margin-left: -1px;
			min-width: 100px;
			max-width: none;
		}

		#drawingControls {
			order: 2
		}

		#controlContainer {
			border: 1px solid #464646;
			display: flex;
			margin-left: 0px;
			margin-top: 0px;
			position: absolute;
			min-width: 100%;
			line-height: 35px;
			height: 37px;
			background-color: #636363;
			align-content: center;
			z-index: 1001;
			justify-content: space-between;
			flex-wrap: nowrap;
			white-space: nowrap;
			padding-left: 5px;
		}

		.leaflet-bar {
			visibility: hidden;
		}

		#search-container {
			display: flex;
			order: 0;
			flex-direction: column;
			overflow: visible;
			font-family: $FONT-default, Arial, Helvetica, sans-serif;
			@include secondary-body-text();
			position: relative;
			right: 5px;
		}

		#search-top {
			display: flex;
			background: white;
			margin-left: 5px;
			margin-top: auto;
			margin-bottom: auto;
			border-radius: 5px;
			height: 26px;
			align-items: center;
			flex-direction: row;
		}

		#doSearchButton {
			padding-right: 7px;
			padding-left: 7px;
			cursor: pointer;
		}

		#doSearchButtonIcon {
			width: 16px;
			height: 16px;
			display: inline-block;
			background-image: url(/Content/images/MapBarIcons/DoSearch.png);
			background-repeat: no-repeat;
			vertical-align: text-top;
		}

		#search-input {
			border: none;
			margin-bottom: 0px;
			box-shadow: none;
			height: 18px;
		}

		#search-input::-ms-clear {
			display: none;
		}

		#clearSearchButton {
			padding-right: 10px;
			padding-left: 10px;
			cursor: pointer;
			opacity: 0.7;
		}

		#searchSuggestions {
			width: auto;
			min-width: 400px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			margin-left: 4px;
			margin-top: 1px;
			background: whitesmoke;
			cursor: pointer;
		}

		.searchNotice {
			background-color: white;
			height: 40px;
			margin-right: 1px;
			margin-bottom: 1px;
		}

		#searchBusyIcon {
			background-image: url(/Content/images/ajax-loader-spin.gif);
			background-repeat: no-repeat;
			background-position: center
		}

		#searchSuggestion {
			min-width: 400px;
			display: flex;
			flex-direction: row;
			background: white;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 1px;
			margin-right: 1px;
		}

		#searchSuggestion:hover {
			background: whitesmoke;
		}

		#suggestionCategoryHereIconContainer {
			height: 32px;
			width: 30px;
			overflow: hidden;
			flex-shrink: 0;
			margin-left: 4px;
			margin-right: 7px;
			margin-top: 4px;
			margin-bottom: 4px;
			border-radius: 22px;
		}

		#suggestionCategoryHereIcon {
			/*width: 45px;*/
			-webkit-filter: invert(100%);
			margin-left: -10px;
			margin-top: -9px;
			opacity: .6;
		}

		#suggestionTitle {
			text-overflow: ellipsis;
			overflow: hidden;
		}

		#suggestionVacinity {
			flex-shrink: 10000;
			color: gray;
			margin-left: 10px;
			text-align: left;
			margin-right: 5px;
		}

		#searchPopupDetails {
			display: flex;
			flex-direction: column
		}

		#searchPopupTitle {
			font-weight: bold;
			margin-bottom: 10px
		}

		#searchPopupControls {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-end;
			margin-left: 10px
		}

		#searchPopupControls img {
			cursor: pointer;
			/*margin-bottom: 10px*/
		}

		#searchPopupCloseButton {
			margin-bottom: 10px
		}

		#layerDropdown {
			order: 1;
			margin-top: auto;
			margin-bottom: auto;
			margin-left: 5px;
			cursor: pointer;
		}

		#importExportControls {
			order: 3;
			margin-right: 15px;
		}

		.map-control-item {
			list-style: none;
			margin: auto;
			padding-right: 30px;
		}

		#map {
			overflow: hidden;
			position: relative;

			.modal-open & {
				z-index: 0;
			}

			&-container {
				overflow: hidden;
			}
		}

		#inner-map-container {
			position: static;

			.leaflet-top .leaflet-control {
				margin-top: 40px;
			}			
		}

		#btn-Export {
			padding-right: 0px;
		}

		.map-button-group-item-active {
			background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#cbcbcb), to(#878787));
			background-image: -moz-linear-gradient(0% 100% 90deg,#878787, #cbcbcb);
			background-image: -ms-linear-gradient(top, #878787, #cbcbcb);
			background-image: linear-gradient(top, #878787, #cbcbcb);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cbcbcb', endColorstr='#878787');
			filter: progid:dximagetransform.microsoft.gradient(enabled=false);
			background-color: #878787;
			color: white;
		}

		.map-button-group-item-left {
			-webkit-border-top-left-radius: 7px;
			-webkit-border-bottom-left-radius: 7px;
			-moz-border-radius-topleft: 7px;
			-moz-border-radius-bottomleft: 7px;
			border-top-left-radius: 7px;
			border-bottom-left-radius: 7px;
		}

		.map-button-group-item-right {
			-webkit-border-top-right-radius: 7px;
			-webkit-border-bottom-right-radius: 7px;
			-moz-border-radius-topright: 7px;
			-moz-border-radius-bottomright: 7px;
			border-top-right-radius: 7px;
			border-bottom-right-radius: 7px;
		}

		.leaflet-top.leaflet-right {
			width: 101px !important;
		}

		.btn-terms {
			cursor: pointer !important;
			margin-bottom: -2px !important;
			margin-right: 94px !important;
			width: 73px;
			opacity: 0;
		}

		.btn-report {
			cursor: pointer !important;
			margin-right: -3px !important;
			margin-bottom: -19px !important;
			width: 94px;
			opacity: 0;
		}

		.btn-export {
			-moz-min-width: 0px;
			-ms-min-width: 0px;
			-o-min-width: 0px;
			-webkit-min-width: 0px;
			min-width: 0px;
			margin-top: -24px !important;
		}

		.option-Layers {
			margin-top: -227px !important;
			margin-left: 69px !important;
			line-height: 14px;
			width: auto;
			border-radius: 5px;
		}

		.map-button-group-item {
			min-width: 0;
			margin-top: -228px;
			line-height: 14px;
			white-space: nowrap;
		}

		.btn-import {
			float: left !important;
			-moz-min-width: 0px;
			-ms-min-width: 0px;
			-o-min-width: 0px;
			-webkit-min-width: 0px;
			min-width: 0px;
		}

		.expandedMessageListContainer {
			background-color: #F2F2F2;
			padding: 0px;
			max-height: 500px;
			overflow-x: hidden;
			overflow-y: auto;
			border-bottom: 1px solid #D4D4D4;
		}

		.load-more {
			border: solid 2px #E0E0E0;
			padding: 3px;
			margin-right: 2px;
			cursor: pointer;
		}

		.load-more:hover {
			background-image: -webkit-gradient(linear, 0% 0%, 0%100%, from(#cbcbcb), to(#878787));
			background-image: -moz-linear-gradient(0% 100% 90deg,#878787, #cbcbcb);
			background-image: -ms-linear-gradient(top, #878787, #cbcbcb);
			background-image: linear-gradient(top, #878787, #cbcbcb);
			background-color: #878787;
		}

		.leaflet-error-draw-tooltip {
			background-color: #B40000;
			background: rgba(180, 0, 0, 0.5);
			border: 1px solid #B40000;
			color: white;
		}

		.leaflet-tile {
			text-align: left !important
		}

		.leaflet-error-draw-tooltip:before {
			border-right-color: #B40000;
		}

		.drag-over-above-pad {
			margin-top: 5px;
		}

		.drag-over-below-pad {
			margin-bottom: 5px;
		}

		.padding-top-25 {
			padding-top: 25px;
		}

		.navigating {
			margin-right: 5px;
		}

		#control-pane-collections-container {
			min-height: 1px;
			flex: 1;
		}

		.group-header {
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			cursor: default;
		}

		.group-header > div > img {
			cursor: pointer;
		}

		.filterContainer {
			position: relative;
		}

		.input-x {
			position: absolute;
			right: 8px;
			top: 11px;
			cursor: pointer;
			opacity: 0.7;
		}

		.filter-search {
			padding-left: 3px !important;
			padding-right: 15px !important;
		}

		.filter-search::-ms-clear {
			display: none;
		}

		.group-name {
			display: inline-block;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			width: 220px;
			padding-top: 2px;
		}

		.group-expand {
			display: inline-block;
			margin-left: 5px;
			margin-right: 5px;
			margin-top: -10px;
		}

		.group-expand > img {
			margin-top: -10px;
			height: 0.75em;
			width: 0.75em;
		}

		.waypointCluster > span, .searchCluster > span {
			position: absolute;
			top: 9px;
			left: 0px;
			width: 40px;
			font-size: 10px;
		}

		.modal.fade.ui-draggable-dragging {
			-moz-transition: none;
			-o-transition: none;
			-webkit-transition: none;
			transition: none;
		}

		.modal-header > h3 {
			cursor: context-menu;
		}

		.tzLabel {
			color: map-get($COLORS, primarytext);
			background-color: map-get($COLORS, contentbackground);
			font-size: 10px;
			min-width: 20px;
			opacity: 1;
			white-space: nowrap;
			padding: 0px 5px;
		}

		.map-error {
			position: absolute;
			top: 10px;
			right: 5px;
			background-color: white;
			padding: 10px;
			border: 1px solid black;
			z-index: 5000;
			color: red;
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			border-radius: 2px;
		}

		.bold {
			font-weight: 700;
		}

		.airdropSort {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	@media print {
		#header, .nav, button, select, .noprint, .expand-user, .map-button-group-item,
		.waypoints-header, .toggleVisible, .listButton {
			display: none !important;
		}
	}

	#ICPFooterBottomBannerOneForMap {
		width: 100%;
		display: none;
		text-align: left;
		z-index: 1031;
		position: absolute;
		height: 35px;
		background-color: white;
		overflow-y: hidden;
		overflow-x: hidden;
		-webkit-transition: all 0.5s ease-out;
		-moz-transition: all 0.5s ease-out;
		-o-transition: all 0.5s ease-out;
		transition: all 0.5s ease-out;
	}

	#ICPFooterBottomBannerOneForMap > #inreach-footer {
		padding: 5px 0 5px 0 !important;
	}

	.time-picker {
		z-index: 7003 !important;
	}

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		/* IE10+ CSS styles go here */
		.gmnoprint {
			pointer-events: none !important;
		}
	}

	.control-pane-container {
		display: flex;
		flex-direction: column;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.control-pane-content-container {
		overflow-y: auto;
		flex: 1;
		min-height: 145px;
	}

	body[theme='flatGreen'] .control-pane-header {
		background: #2B6709;
		text-align: left;
		border: 1px solid #3e4c61;
		height: 34px;
		min-height: 34px;
		width: 100%;
		position: relative;
	}

	.control-pane-header {
		background: #DEDEDE;
		color: #000000;
		text-align: left;
		border: 1px solid #CECECE;
		height: 34px;
		min-height: 34px;
		width: 100%;
		position: relative;
		font-weight: bold;
	}

	.control-pane-header-spacer {
		width: 15%;
	}

	.control-pane-header-messaging-icon {
		width: 18px;
		height: 17px;
		display: flex;
		backface-visibility: hidden;
		position: relative;
		top: 1px;
		cursor: pointer;
	}

	body[theme='flatGreen'] .messages-control-pane-header {
		position: absolute;
		background: #2B6709;
		height: 34px;
		min-height: 34px;
		width: 100%;
		text-align: left;
		cursor: auto;
		border: 1px solid #3e4c61;
	}

	#collection-edit-pane-header {
		flex: 0 1 auto;
	}

	#unorganized-collection-edit-pane-header {
		flex: 0 1 auto;
	}

	.messages-control-pane-header {
		background: #DEDEDE;
		color: #000000;
		text-align: left;
		border: 1px solid #CECECE;
		position: absolute;
		height: 34px;
		min-height: 34px;
		width: 100%;
	}

	.messages-control-pane-header .eyeballContainer {
		color: white;
		line-height: 37px;
		padding-right: 7px;
	}

	.messages-control-pane-header .eyeballContainer img {
		padding-right: 0px;
	}

	.control-pane-header-text {
		line-height: 37px;
		padding-left: 7px;
		@include secondary-body-text();
		width: 85%;
	}

	.control-pane-header-text .glyphicon {
		padding-right: 7px;
		cursor: pointer;
	}

	.control-pane-header-text .glyphicon:hover {
		color: #F6A431;
	}

	#user-messages-container {
		overflow-y: auto;
		position: relative;
		height: calc(100% - 36px);
		top: 36px;
		background: white;

		@include selection-bar();
	}

	#user-messages-container .eyeballContainer img {
		padding-right: 0px;
	}

	.eyeballContainer {
		float: right;
		padding-right: 5px;
	}

	.eyeballContainer img {
		cursor: pointer;
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 3px;
		padding-bottom: 3px;
	}

	#control-container-slider {
		float: left;
		width: 1440px;
		-webkit-transition: all 0.75s ease-in-out;
		-moz-transition: all 0.75s ease-in-out;
		-o-transition: all 0.75s ease-in-out;
		transition: all 0.75s ease-in-out;
	}

	.slide-in-from-left {
		transform: translateX(-310px);
	}

	.slide-in-from-left-2 {
		transform: translateX(-620px);
	}

	.slide-in-from-left-3 {
		transform: translateX(-930px);
	}

	.sliding-control-container {
		float: left;
		padding: 0;
		margin: 0;
		width: 310px;
		overflow-y: hidden;
		overflow-x: hidden;
		display: inline-block;
		transform: translateX(0px);
	}

	.collection-editor-pane {
		width: 510px;

		@include bp('small') {
			max-width: 100vw;
		}
	}

	.control-pane-list-item {
		min-height: 8%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-bottom: 1px solid map-get($COLORS, borders);
		background: map-get($COLORS, contentbackground);
		cursor: pointer;
		padding: 5px 0px 5px 10px;

		&:hover {
			background-color: map-get($COLORS, tabbackgroundhover);
		}

		&-selected {
			background-color: map-get($COLORS, listitembackground) !important;
		}
	}

	body[theme='flatGreen'] .control-pane-list-item:hover {
		background: #FFD68D;
	}

	.user-collection-list-item {
		height: 60px; /* IE won't center content without a height */
	}

	body[theme='flatGreen'] .control-pane-list-item-selected {
		background-color: #FE9006 !important;
	}

	.message-recipient-line {
		font-weight: bold;
	}

	.user-message-top-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		margin-top: 5px;
		margin-left: 2px;
	}

	.user-message-top-row-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.user-message-icon-container {
		position: relative;
		width: 20px;
		height: 17px;
		margin-right: 5px;
	}

	.user-message-icon-image-container {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}

	.user-message-icon-color-container {
		position: absolute;
		// this is 1px off when using IE11 and we know it
		top: 5px;
		left: 1px;
		width: 18px;
		height: 13px;
	}

	.child-divs-flex div {
		display: flex;
	}

	.user-message-two-control-icons {
		width: 20%;
	}

	.user-message-content-container {
		margin-top: 10px;
		margin-bottom: 5px;
		padding-left: 5px;
		text-align: left;
	}

	.selectable-text {
		cursor: text;
	}

	.user-messages-none {
		position: relative;
		top: 5px;
	}

	.user-message-no-location-data {
		padding-top: 5px;
		padding-bottom: 0px;
	}



	.user-collections-none {
		position: relative;
		top: 10px;
		background: map-get($COLORS, contentbackground);
	}

	.user-row-bottom-half-container {
		display: flex;
		flex-direction: row;
		justify-content: start;
		align-items: flex-start;
		margin-left: 5px;
	}

	.view-user-messages-and-track-points {
		display: flex;
		flex-direction: row;
		justify-content: start;
		width: 260px;
		max-height: 17px;
	}

	.user-row-messages-and-track-summaries-text {
		@include small-body-text();
		margin-right: 2px;
		margin-top: 1px;
		overflow: hidden;
	}


	.user-row-click-into-single-users-view {
		@include subheader-text();
		right: -5px;
		top: -4px;
		height: 24px;
		padding-left: 8px;
	}

	.user-row-click-into-single-users-view:hover {
		color: #2B6709;
	}

	.user-row-messages-and-track-summaries-row {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding-left: 2px;
	}

	.user-row-messages-and-track-summaries-row.right-most {
		padding-left: 10px;
	}

	.user-row-bottom-left-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		overflow: hidden;
	}

	.collection-content-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		max-width: none;
		width: 98%;
	}
	/* Edge fix, position relative elements can show through elements on top of them */
	.collection-content-container .eyeballContainer {
		padding-right: 0px;
		position: static;
		height: 13px;
		border-radius: 15px;
	}

	.collection-content-container .eyeballContainer img {
		position: relative;
		bottom: 50%;
	}

	.collection-content-container .eyeballContainer:hover + .glyphicon-chevron-right {
		color: black;
	}
	/* Edge fix, position relative elements can show through elements on top of them */
	.collection-content-container .glyphicon-chevron-right {
		position: static;
	}

	.collection-content-container .glyphicon-chevron-right:hover {
		color: #2B6709;
		position: static;
	}

	.collection-content-container-left {
		width: 80%;
	}

	.control-pane-header-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0.375em 0.4375em;
	}

	.control-pane-header-row .glyphicon {
		top: 2px;
		cursor: pointer;
	}

	.control-pane-header-row .glyphicon-sort-by-alphabet,
	.control-pane-header-row .glyphicon-sort-by-alphabet-alt,
	.control-pane-header-row .glyphicon-sort-by-order,
	.control-pane-header-row .glyphicon-sort-by-order-alt,
	.control-pane-header-row .glyphicon-sort-by-attributes,
	.control-pane-header-row .glyphicon-sort-by-attributes-alt {
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 3px;
		padding-bottom: 3px;
	}

	.control-pane-header-row .glyphicon-plus {
		padding-left: 5px;
		padding-top: 3px;
		padding-bottom: 3px;
	}

	.selectable {
		cursor: pointer;
	}

	.selectable:hover .glyphicon {
		color: #FE9006;
	}

	.selectable.glyphicon:hover {
		color: #FE9006;
	}

	.selectable-green {
		cursor: pointer;
	}

	.selectable-green:hover .glyphicon {
		color: #2B6709;
	}

	.selectable-green.glyphicon:hover {
		color: #2B6709;
	}

	.selectable-no-color {
		cursor: pointer;
	}

	.track-summary-details-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 7%;
		padding: 10px 5px;
		text-align: left;
		overflow-wrap: break-word;
		overflow-y: hidden;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.track-summary-details-container.no-content {
		text-align: center;
		min-height: 2%;
	}

	.track-summary-details-container.track-summary-details-container-collection-list-item {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 10px;
	}

	.track-summary-details-container.no-overflow {
		overflow: hidden;
	}

	#control-pane-latest-message-container {
		max-height: 100px;
	}

	#control-pane-latest-message-content {
		margin-top: 10px;
	}

	.long-track-warning-icon {
		height: 2em;
		width: 2em;
		padding: 8px;
	}

	#track-summary-title-container {
		width: 80%;
	}

	#track-summary-title-latest-track {
		max-width: 80px;
	}

	#control-pane-header-back-to-users-container {
		max-width: 75px;
	}

	#control-pane-header-username {
		max-width: 140px;
	}

	#control-pane-header-username-more-space {
		max-width: 220px;
	}

	.control-pane-header-username-with-margin {
		margin-right: 35px;
	}

	#track-summary-content {
		overflow: hidden;
	}

	#track-summary-content:hover {
		background-color: #CFF4FF;
	}

	body[theme='flatGreen'] #track-summary-content:hover {
		background-color: #FFD68D;
	}

	#control-pane-header-one-control-icons {
		width: 6%;
		position: relative;
		right: 5px;
	}

	#control-pane-header-two-control-icons {
		width: 15.5%;
	}

	#control-pane-header-two-control-icons .eyeballContainer {
		padding-right: 0px;
		line-height: 0px;
	}

	#control-pane-header-two-control-icons .eyeballContainer img {
		padding-left: 0px;
	}

	.collections-header .eyeballContainer {
		position: relative;
		padding-right: 0px;
		top: 2px;
	}

	#collections-header-two-control-icons {
		width: 17%;
		position: relative;
		bottom: 3px;
	}

	#collections-header-two-control-icons .eyeballContainer {
		position: relative;
		top: 2px;
	}

	#collections-header-three-control-icons {
		width: 27.5%;
		position: relative;
		bottom: 3px;
		height: 19px;
		padding-top: 3px;
	}

	#collections-header-three-control-icons .eyeballContainer {
		position: relative;
		top: 1px;
		right: 1px;
	}
	/* Hack to get differently-sized hover images to line up */
	#collections-header-three-control-icons .eyeballContainer img[src="/Content/images/NotVisible-White.png"] {
		position: relative;
		top: 1px;
	}

	#collections-header-three-control-icons .eyeballContainer img[src="/Content/images/NotVisible-Hover.png"] {
		position: relative;
		top: 1px;
	}

	.track-summary-row {
		margin-bottom: 10px;
	}

	.track-summary-list-item {
		width: 42%;

		&:first-of-type {
			width: 58%;
			padding-right: .5rem;
		}
	}

	.collection-text-content {
		max-width: 265px;
		overflow-wrap: break-word;
		@include secondary-body-text();
	}

	#collections {
		&-pane {
			&-title {
				width: 65%;

				.glyphicon {
					margin-right: 7px;
				}
			}
		}

		&-control-icons {
			&-one {
				width: 9%;
			}

			&-two {
				width: 16%;
			}

			&-three {
				width: 26.1%;
			}

			&-four {
				width: 30.4%;
			}

			&-five {
				width: 38%;
			}
		}
	}

	.collections-control {
		&-icons {
			.glyphicon {
				top: 0px;
			}

			.eyeballContainer {
				padding-right: 0px;
			}

			&-mapshare-container {
				cursor: pointer;
				position: relative;
			}

			&-mapshare-warning {
				position: absolute;
				color: #ff0033;
				@include subheader-text();
				text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
				left: 11px;
				bottom: -3px;
			}

			body[theme='flatGreen'] &-mapshare-warning {
				color: orange;
			}
		}

		&-edit-icon {
			-moz-transform: scaleX(-1);
			-o-transform: scaleX(-1);
			-webkit-transform: scaleX(-1);
			transform: scaleX(-1);
		}
	}

	.control-pane-loading {
		width: 7%;
		margin: 15px auto;
	}

	.control-pane-list-item-loading {
		width: 5%;
		margin: 0px auto;
	}

	#collection-tabs-and-items-container {
		overflow: hidden;
		height: 100%;
		@include selection-bar();

		.collection-items-tab {
			&.selected {
				&:before {
					content: none !important;
				}
			}
		}
	}

	.collection-items-tab {
		width: 33%;
		height: 2.75em;
		border-top: 1px solid map-get($COLORS, borderslight);
		border-bottom: 1px solid map-get($COLORS, borderslight);
		cursor: pointer;
		background: #636363;
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b7599', endColorstr='#3e4c61',GradientType=0 ); /* IE6-9 */
		color: white;
	}

	.collection-control-tabs-container.two-tabs {
		.collection-items-tab {
			width: 50%;
			margin-right: 1px;
			height: 2.75em;
		}

		.collection-items-tab:last-child {
			width: 50%;
			margin-right: 0px;
		}
	}

	.collection-control-tabs-container.four-tabs {
		.collection-items-tab {
			width: 25%;
			margin-right: 1px;
		}

		.collection-items-tab:last-child {
			width: 25%;
			margin-right: 0px;
		}
	}

	.collection-items-tab:hover {
		background: #737373;
	}

	.collection-items-tab.selected {
		background: white;
		color: black;
	}

	body[theme='flatGreen'] .collection-items-tab {
		color: white;
		background: #244311;
	}

	body[theme='flatGreen'] .collection-items-tab:hover {
		background: #2B6709;
	}

	body[theme='flatGreen'] .collection-items-tab.selected {
		background: white;
		color: black;
	}

	.summary-loading {
		width: 5%;
		margin: 5px auto;
	}

	#user-summary-control-pane-go-back-icon {
		top: -1px;
		margin-right: 3px;
	}

	#collection-edit-pane-go-back-icon {
		top: -4px;
		margin-right: 7px;
	}

	#collection-edit-pane-title-container {
		width: 100%;
	}

	#collection-edit-pane-title {
		width: 95%;
		display: inline-block;
	}

	#collection-item-list {
		overflow-y: auto;
		flex: 1;
	}

	.collection-item {
		&-list-row {
			padding: 0.25rem;
			min-height: 35px;
			border-bottom: 1px solid map-get($COLORS, borders);
			cursor: pointer;
			user-select: none;

			@include bp('small') {
				padding-left: 0.75em;
			}

			@include bp('(min-width: 1025px)') {
				&:hover {
					background-color: map-get($COLORS, tabbackgroundhover);
				}
			}

			&.selected {
				background-color: map-get($COLORS, listitembackground);
			}

			body[theme='flatGreen'] &.selected {
				background: #FE9006;
			}

			body[theme='flatGreen'] &:hover {
				background: #FFD68D;
			}

			&-track {
				min-height: 3.5rem;
				padding-left: 0.5rem;
				padding-bottom: 0.5rem;

				.collection-icon-and-name-container {
					padding-left: 0;
				}

				&-expanded {
					border-bottom: 1px solid map-get($COLORS, borders);
					min-height: 14.75em;

					& :first-child {
						padding-left: 0 !important;
					}

					&:hover {
						background-color: map-get($COLORS, tabbackgroundhover) !important;
					}

					&.selected {
						background-color: map-get($COLORS, listitembackground) !important;
						color: black;

						.collection-item-list-row.selected {
							&:before {
								width: 0.125em;
							}

							background: none;
						}

						.track-summary-details-container {
							cursor: text;

							.track-summary-list-item {
								-moz-user-select: text;
								-khtml-user-select: text;
								-webkit-user-select: text;
								-ms-user-select: text;
								user-select: text;
							}
						}

						.ex-circle {
							@include bp('small') {
								vertical-align: baseline;
							}
						}
					}
				}
			}

			body[theme='flatGreen'] &-track {
				min-height: 45px;

				&-expanded {

					&:hover {
						background: #FFD68D;
					}

					&.selected {
						background: #FE9006;
					}
				}
			}

			&-activity, &-course {
				min-height: 45px;
			}

			&-no-border {
				border-bottom: none;
			}
		}

		&-adder {
			&-row {
				min-height: 1.5rem;
			}
		}
	}

	.collection-icon-and-name-container {
		width: 80%;
		margin-left: 2px;
		padding-left: 0.5rem;

		@include bp('small') {
			width: 13.25em;
		}

		img {
			flex: none;
		}
	}

	.collection-waypoint-icon {
		width: 25px;
		height: 25px;
		margin-left: 3px;
		margin-right: 5px;
	}

	.collection-item-name {
	}

	.collection-item-name.collection-item-name-line-object {
		margin-left: 13px;
		text-align: left;
	}

	.collection-item-name.collection-item-name-padding-top {
		padding-top: 5px;
	}

	.collection-item-activity-create-date {
		text-align: left;
		@include small-body-text();
	}

	.collection-item-activity-no-location {
		text-align: left;
		font-size: 10px;
		font-weight: bold;
		position: relative;
		bottom: 6px;
	}

	.collection-item-course-create-date {
		text-align: left;
		@include small-body-text();
	}

	.collection-item-course-no-location {
		text-align: left;
		font-size: 10px;
		font-weight: bold;
		position: relative;
		bottom: 6px;
	}

	.collection-item-name-track {
		margin-top: 5px;
		text-align: left;
		width: 170px;
	}

	.collection-item-name-activity {
		margin-top: 5px;
		text-align: left;
		width: 170px;
	}

	.collection-item-name-course {
		margin-top: 5px;
		text-align: left;
		width: 170px;
	}

	.collection-item-track-details {
		@include small-body-text();
		text-align: left;
		line-height: 15px;
		display: flex;
	}

	.collection-item-control-icons-container {
		margin-right: 7px;
		width: 14.5%;
	}

	.collection-item-control-icons-container .glyphicon:hover {
		color: #2B6709;
	}

	.collection-item-control-icons-container.one-item {
		width: auto;
	}

	.collection-item-control-icons-container.two-items {
		width: 18%;
	}

	.collection-item-control-icons-container.three-items {
		width: 22%;
	}

	.collection-item-control-icons-container.four-items {
		width: 30%;
	}

	.collection-add-items-row {
		width: 95%;
		margin-left: 11px;
	}

	.collection-add-items-row .collection-item-name {
		margin-left: 11px;
		position: relative;
		top: 5px;
	}

	.collection-add-items-row:hover .glyphicon {
		color: #2B6709;
	}

	.collection-add-items-row-sublabel {
		margin-top: 3px;
		@include small-body-text();
		text-align: left;
	}

	.collection-route-or-activity-color-hex {
		position: relative;
		right: .5px;
	}

	.collection-item-track-color-circle {
		position: relative;
		bottom: 2px;
		left: 7.5px;
		margin-right: 4px;
		flex: none;
	}

	.collection-item-activity-icon {
		height: 20px;
		width: 30px;
		margin-left: 1px;
		margin-top: 4px;
	}

	.collection-item-course-icon {
		height: 20px;
		width: 30px;
		margin-left: 1px;
		margin-top: 4px;
	}

	.add-collection-modal-title {
		text-align: left;
		flex: 0 1 auto;
		font-size: 1.375rem;
	}

	#add-synced-collection-devices-modal-title {
		text-align: left;
		flex-direction: column;
	}

	#edit-collection-pane-body {
		padding-top: 0px;
		overflow-y: hidden;
		padding: 10px;
		height: 100%;
		flex: 1 1 auto;
		background: map-get($COLORS, contentbackground);

		@include bp('small') {
			width: 100vw;
			overflow: hidden;
		}
	}

	#edit-collection-pane-body-header {
		flex: none;
	}

	#edit-collection-pane-import-from-map-icon {
		@include body-text();
	}

	#collection-name-input {
		margin-right: 5px;
		max-width: 75%;
		max-height: 30px;
	}

	.edit-collection-form-error {
		color: red;
		text-align: left;
		margin-top: 5px;
	}

	.delete-collection-modal-content {
		text-align: left;
	}

	.delete-collection-modal-warning-section {
		margin-top: 15px;
	}

	.delete-collection-modal-warning-icon {
		margin-right: 15px;
		@include title-text();
		color: red;
	}

	.collection-deletion-modal-collection-container {
		margin-top: 5px;
	}

	.collection-deletion-modal-collection-row-container {
		max-height: 100px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.collection-deletion-modal-collection-search {
		width: 94%;
	}

	.collection-deletion-modal-collection-checkbox {
		margin-right: 5px !important;
		margin-top: 5px !important;
	}

	.collection-deletion-modal-collection-checkbox.disabled {
		cursor: not-allowed;
	}

	.collection-deletion-modal-collection-row {
		min-height: 25px;
	}

	.collection-deletion-modal-collection-row.disabled {
		cursor: not-allowed;
	}

	.collection-deletion-modal-collection-row:hover {
		background: #CFF4FF;
	}

	body[theme='flatGreen'] .collection-deletion-modal-collection-row:hover {
		background: #FFD68D;
	}

	.collection-deletion-modal-collection-content-container {
		text-align: left;
		margin-top: 3px;
	}

	.collection-deletion-modal-select-new-collection-explanation {
		font-weight: bold;
		color: red;
	}

	.collection-deletion-modal-no-other-collections {
		margin-right: 3px;
		position: relative;
		bottom: 1px;
	}

	.collection-items-table-container {
		flex: 1 1 auto;
		overflow-y: hidden;
		user-select: none;

		@include bp('small') {
			width: 100%;
			overflow-x: scroll;
		}

		tbody {
			tr {
				&.selected {
					background-color: map-get($COLORS, listitembackground) !important;
					color: black;

					&:before {
						content: none;
					}

					td {
						background-color: map-get($COLORS, tabbackground) !important;
					}
				}

				&:hover {
					td {
						cursor: pointer;
					}

					.dataTables_empty {
						background-color: #f9f9f9 !important;
						cursor: auto;
					}
				}
			}
		}

		body[theme='flatGreen'] & {
			tbody {
				tr {
					.selected {
						background-color: #FE9006 !important;
						color: black;

						td {
							background-color: #FE9006 !important;
						}
					}

					&:hover {
						td {
							background-color: #FFD68D !important;
						}
					}
				}
			}
		}

		.dataTable {
			margin-bottom: 0px;
		}

		.dataTables {
			&_wrapper {
				height: 99%;
				display: flex;
				flex-direction: column;

				@include bp('small') {
					width: 34em;
				}
			}

			&_scroll {
				border: 1px solid lightgray;
				height: 100%;
				display: flex;
				flex-direction: column;

				&Head {
					flex: 0 1 auto;
					overflow: unset !important;

					th {
						border-bottom: 1px solid lightgray;
					}
				}

				&HeadInner {
					width: 100% !important;
					padding-right: 0px !important;
				}

				&Body {
					border: none !important;
					overflow-x: hidden !important;
				}
			}
		}

		.collection-items-table {
			width: 100% !important;
		}
	}

	#collection-items-table-filter-input {
		margin-bottom: 0px;
		margin-left: 10%;
		width: 75%;
		max-height: 25px;
		@include small-body-text();
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		border-radius: 20px;
		padding-left: 6px;

		@include bp('small') {
			margin: 1em 0;
			width: 30em;
			max-width: 100vw;
		}
	}

	#edit-collection-control-tabs-container {
		flex: none;

		@include bp('small') {
			flex-wrap: wrap;
		}

		.collection-control-tabs-container {
			width: 70%;

			@include bp('small') {
				width: 100%;
			}

			.collection-items-tab {
				border: 1px solid lightgray;
				height: 2.5rem;
				position: relative;
				top: 1px;

				&.selected {
					border-bottom: 1px solid white;
					z-index: 1;

					&:before {
						content: none !important;
					}
				}
			}
		}
	}

	.edit-collection-waypoint-icon {
		width: 25px;
		height: 25px;
	}

	.collection-syncable-device-primary-collection-icon {
		width: 14px;
		height: 20px;
	}

	.collection-syncable-device-row {
		min-height: 25px;
		cursor: pointer;
	}

	.collection-syncable-device-row:hover {
		background: #CFF4FF;
	}

	body[theme='flatGreen'] .collection-syncable-device-row:hover {
		background: #FFD68D;
	}

	.collection-syncable-device-name {
		margin-left: 5px;
	}

	.collection-sync-modal-name {
		margin-left: 10px;
		margin-bottom: 0px;
	}

	.collection-sync-left-align {
		text-align: left;
	}

	.mapshared-collection-modal-title {
		text-align: left;
		flex-direction: column;
	}

	.mapshared-collection-modal-title-icon {
		width: 14px;
	}

	.mapshared-collection-modal-title-text {
		margin-left: 10px;
	}

	#collection-edit-pane-name-section {
		border-bottom: 1px solid lightgray;
		padding: 10px;
		flex: none;
	}

	#edit-collection-name-header {
		margin-bottom: 8px;
	}

	#collection-edit-pane-previous-button {
		margin-left: 5px;
	}

	#collection-edit-pane-save-button, #collection-edit-pane-modify-button {
		margin-left: 5px;
	}

	body[theme='flatGreen'] #collection-edit-pane-save-button {
		background-color: #FE9006;
		background-image: linear-gradient(top, #FFD68D, #FE9006);
		background-image: -webkit-linear-gradient(top, #FFD68D, #FE9006);
		background-image: -moz-linear-gradient(top, #FFD68D, #FE9006);
		background-image: -ms-linear-gradient(top, #FFD68D, #FE9006);
		background-image: -o-linear-gradient(top, #FFD68D, #FE9006);
		color: black;
		text-shadow: none;
	}

	#collection-library-summary-header .eyeballContainer {
		padding-right: 0px;
	}

	#collection-library-summary-header .eyeballContainer img {
		padding-right: 0px;
	}

	.collection-library-summary-container {
		padding-left: 0px;
	}

	.generic-summary-row {
		padding: 0.5em;
		padding-left: 9px;
		padding-right: 7px;
		background: white;
		cursor: pointer;
	}

	.generic-summary-row:hover {
		background-color: map-get($COLORS, tabbackgroundhover);
	}

	body[theme='flatGreen'] .generic-summary-row:hover {
		background: #FFD68D;
	}

	.generic-summary-row-left {
		width: 82%;
	}

	.generic-summary-row-icon {
		margin-right: 10px;
		width: 21px;
		height: 20px;
		backface-visibility: hidden;
		display: flex;
	}

	.collection-library-count-loading {
		margin: auto;
	}

	#collection-item-list-none {
		margin-top: 5px;
	}

	.collection-item-count-loading {
		margin-top: 3px;
	}

	.collection-item-count-row {
		margin-top: 7px;
		position: relative;
		left: -2px;
	}

	.collection-item-count-container {
		width: 15%;
		display: flex;
		@include small-body-text();
		padding: 2px;
		position: relative;
		top: 1px;
	}

	#collection-edit-pane-remove-button {
		margin-right: 5px;
		margin-left: 5px;
	}

	.collection-item-count-icon {
		width: 17px;
		height: 17px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0px;
		margin-top: 1px;
		flex: none;
	}

	.collection-item-count-number {
		font-size: 10px;
		line-height: 90%;
		margin: auto;
		padding-top: 1px;
		padding-bottom: 2px;
	}

	#filter-container {
		height: 19px;
		position: relative;
	}

	#filter-select-list {
		padding-left: 0;
		position: absolute;
		right: 0px;
		top: 20px;
		z-index: 1000;
		color: black;
		cursor: pointer;
	}

	#collection-filter-select-list {
		padding-left: 0;
		position: absolute;
		right: -29px;
		top: 23px;
		z-index: 1000;
		color: black;
		cursor: pointer;
	}

	#collection-filter-select-list span {
		padding: 0px;
	}

	.filter-select-item {
		position: relative;
		padding: 10px 15px;
		margin-bottom: -1px;
		background-color: white;
		border: 1px solid #ddd;
	}

	.filter-select-item.selected {
		background-color: #A8EBFF;
	}

	body[theme='flatGreen'] .filter-select-item.selected {
		background-color: #FE9006;
	}

	.filter-select-item:hover {
		background-color: #CFF4FF;
	}

	body[theme='flatGreen'] .filter-select-item:hover {
		background-color: #FFD68D;
	}

	.filter-select-item-sort-icon {
		position: relative !important;
		top: -1px !important;
	}

	.filter-select-item-sort-name {
		margin-left: 15px;
		width: 105px;
	}

	.map-popup-add-to-collections-btn {
		cursor: pointer;
		top: 2px !important;
		border: 1px solid lightgray;
		padding: 2px;
		border-radius: 3px;
		padding-right: 4px;
		@include small-body-text();
		margin-right: .5px;
	}

	#map-popup-add-to-collections-btn-activity {
		margin-left: 3px;
		top: 0px !important;
	}

	#map-popup-add-to-collections-btn-course {
		margin-left: 3px;
		top: 0px !important;
	}

	.map-popup-edit-track-btn {
		cursor: pointer;
		position: relative;
		top: 1.5px;
		margin-left: 3px;
		-moz-transform: scaleX(-1);
		-o-transform: scaleX(-1);
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);
	}

	.map-popup-save-edit-track-btn {
		cursor: pointer;
		position: relative;
		top: 1.5px;
		margin-left: 3px;
	}

	.map-popup-cancel-edit-track-btn {
		cursor: pointer;
		position: relative;
		top: 1px;
	}

	.map-popup-track-name-input {
		width: 170px;
	}

	.activity-popup-edit {
		transform: scaleX(-1);
		margin-left: 3px;
		cursor: pointer;
	}

	.activity-popup-name-input {
		width: 170px;
		margin-bottom: auto;
	}

	.activity-popup-name-label {
		@include small-body-text();
	}

	.course-popup-edit {
		transform: scaleX(-1);
		margin-left: 3px;
		cursor: pointer;
	}

	.course-popup-name-input {
		width: 170px;
		margin-bottom: auto;
	}

	.course-popup-name-label {
		@include small-body-text();
	}

	.map-popup-waypoint-edit-btn {
		cursor: pointer;
		-moz-transform: scaleX(-1);
		-o-transform: scaleX(-1);
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);
	}

	#collection-item {

		&-adder {
			padding: 9px;
			overflow: hidden;
			height: 94%;

			&-container {
				position: absolute;
				display: none;
				border-radius: 10px;
				box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.75);
				-webkit-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.75);
				-moz-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.75);
				z-index: 9999;
				overflow: hidden;
				width: 300px;
				background-color: white;
				justify-content: space-around;

				@include bp('small') {
					transform: translate(-50%, -50%) !important;
					left: 50% !important;
					top: 50% !important;
				}
			}

			&-header {
				margin-bottom: 5px;
				text-align: left;
			}

			&-body {
				overflow-y: auto;
				max-height: 230px;
				margin-top: 0.5em;
			}

			&-footer {
				margin-left: auto;
				margin-top: 10px;
				padding-top: 10px;
				justify-content: flex-end;
			}

			#collection-item-adder-search {
				width: 97%;
				min-height: 20px;
				height: 30px;
			}

			&-create-collection {
				cursor: pointer;
				position: relative;
				bottom: 1px;
			}

			&-loading {
				height: 20px;
				width: 20px;
				margin: auto;
			}

			&-row {
				padding-right: 5px;
				padding-top: 8px;
				padding-bottom: 8px;
				cursor: pointer;
				flex: 0 0 auto;
				@include secondary-body-text();

				&:hover {
					background-color: #CFF4FF;
				}

				body[theme='flatGreen'] &:hover {
					background-color: #FFD68D;
				}

				&-checkbox {
					margin-right: 5px !important;
					margin-left: 3px !important;
				}
			}

			&-save-button {
				margin-left: 5px;

				body[theme='flatGreen'] & {
					background-color: #FE9006;
					background-image: linear-gradient(top, #FFD68D, #FE9006);
					background-image: -webkit-linear-gradient(top, #FFD68D, #FE9006);
					background-image: -moz-linear-gradient(top, #FFD68D, #FE9006);
					background-image: -ms-linear-gradient(top, #FFD68D, #FE9006);
					background-image: -o-linear-gradient(top, #FFD68D, #FE9006);
					color: black;
					text-shadow: none;
				}
			}
		}
	}

	.map-popup-check-in-collection-button {
		display: flex;
		justify-content: center;
		margin-top: 5px;
	}

	.map-popup-check-in-collection-button button {
		width: 50%;
	}

	#conversion-modal .modal-header {
		padding-bottom: 0px;
		border-bottom: 0px;
	}

	#conversion-modal h2 {
		margin-left: 10px;
	}

	#conversion-modal .modal-body {
		text-align: left;
		padding-bottom: 0px;
	}

	#conversion-modal .modal-error {
		color: red;
		position: relative;
		top: -15px;
		padding-bottom: 0px;
	}

	#user-messaging-controls-container {
		-webkit-transition: max-height 0.5s ease-in;
		-moz-transition: max-height 0.5s ease-in;
		-o-transition: max-height 0.5s ease-in;
		transition: max-height 0.5s ease-in;
		max-height: 100px;
		overflow: hidden;
	}

	#user-messaging-controls-container.hidden {
		max-height: 0;
		transition: max-height 0.5s ease-out;
	}

	.user-messaging-toggle {
		height: 18px;
		position: relative;
	}

	.user-messaging-toggle.expanded {
		top: 2.5px;
	}

	.user-messaging-carrot {
		width: 0px;
		height: 0px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid white;
		position: relative;
		top: 3.5px;
		left: 5px;
	}

	#user-messaging-controls {
		border-bottom: 1px solid lightgray;
		background: map-get($COLORS, contentbackground);
	}

	.user-messaging-control-container {
		flex: 1 0 auto;
		padding-top: 6px;
		padding-bottom: 4px;
		cursor: pointer;
	}

	.user-messaging-control-container:hover {
		background-color: #CFF4FF;
	}

	body[theme='flatGreen'] .user-messaging-control-container:hover {
		background-color: #FFD68D;
	}

	.user-messaging-control-container.disabled {
		cursor: not-allowed;
		opacity: .65;
	}

	.user-messaging-control-container.disabled:hover {
		background-color: white;
	}

	.user-messaging-control-icon {
		margin: auto;
		cursor: default;
	}

	.user-messaging-control-icon-track {
		background-image: url(/Content/images/icons/UsersList-TrackIcon.svg);
		height: 16px;
		width: 16px;
	}

	.user-messaging-control-container:hover:not(.disabled) {
		.user-messaging-control-icon {
			cursor: pointer;
		}

		.user-messaging-control-icon-track {
			background-image: url(/Content/images/icons/UsersList-TrackIcon-Green.svg);
		}

		.user-messaging-control-icon-locate {
			background-image: url(/Content/images/icons/UsersList-LocateIcon-Green.svg);
		}

		.user-messaging-control-icon-message {
			background-image: url(/Content/images/SendMessage-Hover.png);
		}

		.user-messaging-control-icon-send {
			background-image: url(/Content/images/SendIcon-Green.png);
		}
	}

	.user-messaging-control-icon-locate {
		background-image: url(/Content/images/icons/UsersList-LocateIcon.svg);
		height: 16px;
		width: 16px;
	}

	.user-messaging-control-icon-message {
		background-image: url(/Content/images/UsersList-MessageIcon.png);
		height: 17px;
		width: 20px;
	}

	.user-messaging-control-icon-send {
		background-image: url(/Content/images/UsersList-SendLocationIcon.png);
		height: 19px;
		width: 13px;
	}

	.user-message-cotrol-label {
		max-width: 77px;
	}

	#activity-map-popup-container {
		position: absolute;
		display: none;
		border-radius: 12px;
		box-shadow: 0 3px 14px rgba(0,0,0,0.4);
		-webkit-box-shadow: 0 3px 14px rgba(0,0,0,0.4);
		-moz-box-shadow: 0 3px 14px rgba(0,0,0,0.4);
		z-index: 1000;
		overflow: hidden;
		width: 280px;
		min-height: 110px;
		padding: 10px;
		background-color: white;
		cursor: move;

		@include bp('small') {
			transform: translateX(-50%) !important;
			left: 50% !important;
		}
	}

	.activity-popup {
		height: 100%;
	}

	.activity-popup-name {
		@include body-text();
		max-width: 175px;
	}

	.activity-popup-delete {
		cursor: pointer;
	}

	.activity-popup-create-date {
		@include small-body-text();
		color: #676767;
	}

	.activity-popup-icon {
		height: 70px;
		max-width: 90px;
	}

	.activity-popup-metadata-list {
		text-align: left;
		margin-top: 5px;
		@include small-body-text();
		height: 65px;
	}

	.activity-popup-metadata-list span {
		max-width: 140px;
	}

	#course-map-popup-container {
		position: absolute;
		display: none;
		border-radius: 12px;
		box-shadow: 0 3px 14px rgba(0,0,0,0.4);
		-webkit-box-shadow: 0 3px 14px rgba(0,0,0,0.4);
		-moz-box-shadow: 0 3px 14px rgba(0,0,0,0.4);
		z-index: 1000;
		overflow: hidden;
		width: 280px;
		min-height: 110px;
		padding: 10px;
		background-color: white;
		cursor: move;

		@include bp('small') {
			transform: translateX(-50%) !important;
			left: 50% !important;
		}
	}

	.course-popup {
		height: 100%;
	}

	.course-popup-name {
		@include body-text();
		max-width: 175px;
	}

	.course-popup-delete {
		cursor: pointer;
	}

	.course-popup-create-date {
		@include small-body-text();
		color: #676767;
	}

	.course-popup-icon {
		height: 70px;
		max-width: 90px;
	}

	.course-popup-metadata-list {
		text-align: left;
		margin-top: 5px;
		@include small-body-text();
		height: 65px;
	}

	.course-popup-metadata-list span {
		max-width: 140px;
	}

	.map-popup-track-details-p {
		margin: 0 0 !important;
		margin-bottom: 5px !important;
		padding-bottom: 0px;
		@include small-body-text();
		width: 170px;
	}

	#collection-item-adder-body {
		.collection-item-adder-row {
			cursor: pointer;

			&:hover {
				background: map-get($COLORS, listitemhover);
			}

			&-checkbox {
				margin-right: .3rem;
				cursor: pointer;
			}
		}
	}

	.collection-import-item {
		&-selector {
			&-header-row {
				cursor: pointer;
				padding: 10px 3px;
				border-top: 1px solid #eee;
				margin-top: 10px;

				body[theme='flatGreen'] &:hover {
					background: #FFD68D;
				}

				~ .collection-import-item-selector-header-row {
					margin-top: 0px;
				}
			}

			&-toggle {
				margin-right: 5px;
				height: 24px;
			}

			&-chevron {
				top: 0px !important;
				color: lightgray;
				transition: transform .4s;
				-webkit-transition: transform .4s;

				&-expanded {
					-moz-transform: rotate(90deg);
					-o-transform: rotate(90deg);
					-webkit-transform: rotate(90deg);
					transform: rotate(90deg);
				}
			}

			&-body {
				position: relative;
				max-height: 300px;
				overflow: hidden;
				-webkit-transition: max-height 0.4s ease-in;
				-moz-transition: max-height 0.4s ease-in;
				-o-transition: max-height 0.4s ease-in;
				transition: max-height 0.4s ease-in;

				&.hidden {
					max-height: 0px;
					transition: max-height 0.4s ease-out;
				}

				&-padding {
					padding: 5px 15px;
				}

				&-header {
					max-width: 450px;
					margin-left: 3px;
				}

				.collection-item-adder-row {
					cursor: pointer;

					&-checkbox {
						margin-top: .1rem;
						cursor: pointer;
					}
				}
			}

			&-collection-row-container {
				overflow-y: auto;
				max-height: 150px;
				margin: 0.5rem auto;
			}

			&-new-collection {
				&-name {
					&-body {
						padding-right: 14px;
						padding-left: 3px;
					}

					&-container {
						max-height: 150px;
						overflow-y: auto;
						overflow-x: hidden;
					}

					&-label {
						margin-right: 5px;
						margin-left: 3px;
					}

					&-input {
						margin-right: 5px;
						width: 280px;
					}

					&-limit {
						@include small-body-text();
					}
				}

				&-row {
					margin-bottom: .5rem;
					min-height: 30px;

					@include bp('small') {
						flex-wrap: wrap;
					}
				}

				&-row-button {
					button {
						min-width: 3.5rem;
					}
				}
			}

			&-new-collections {
				&-mapshared-container {
					flex: none;
					margin-bottom: 5px;
					margin-left: 2px;
				}

				&-devices-sync {
					margin-bottom: 15px;
					cursor: pointer;
					margin-left: 2px;
				}
			}

			&-mapshare-disabled-warning {
				color: red;
				position: relative;
				bottom: 10px;
				@include small-body-text();
				margin-left: 2px;
			}
		}

		&-loading {
			height: 20px;
			width: 20px;
			margin: 5px auto;
			display: block;
		}
	}

	#control-pane-collections-list {
		flex: 1;
		min-height: 180px;
	}

	#control-pane-track-summary-container {
		flex: 1;
		min-height: 184px;
		overflow: hidden;
	}

	.control-pane-track-summary-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 7%;
		max-height: 230px;
		padding: 10px;
		padding-right: 0px;
		text-align: left;
		overflow-wrap: break-word;
		overflow-y: hidden;
		border-bottom: 1px solid lightgray;
	}

	.control-pane-track-summary-content.selected {
		background-color: #CFF4FF;

		.track-summary-details-container {
			cursor: text;

			.track-summary-list-item {
				-moz-user-select: text;
				-khtml-user-select: text;
				-webkit-user-select: text;
				-ms-user-select: text;
				user-select: text;
			}
		}
	}

	body[theme='flatGreen'] .control-pane-track-summary-content.selected {
		background-color: #FFD68D;
	}

	.control-pane-track-summary-content:hover {
		background-color: #CFF4FF;
	}

	body[theme='flatGreen'] .control-pane-track-summary-content:hover {
		background-color: #FFD68D;
	}

	.control-pane-track-summary-loading {
		margin: auto;
		width: 7%;
	}

	.control-pane-track-summary-header-row {
		padding-right: 0px;
	}

	.control-pane-track-summary-header-icons {
		position: relative;
		left: 3px;
	}

	.toggle-switch {
		position: relative;
		display: inline-block;
		width: 40px;
		height: 23px;
	}

	.toggle-switch input {
		display: none;
	}

	.toggle-switch-slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: transform .4s;
		transition: transform .4s;
		border-radius: 34px;
	}

	.toggle-switch-slider:before {
		position: absolute;
		content: "";
		height: 17px;
		width: 17px;
		left: 4px;
		bottom: 3px;
		background-color: white;
		-webkit-transition: transform .4s;
		transition: transform .4s;
		border-radius: 50%;
	}

	input:checked + .toggle-switch-slider {
		background-color: #2B6709;
	}

	input:focus + .toggle-switch-slider {
		box-shadow: 0 0 1px #2B6709;
	}

	input:checked + .toggle-switch-slider:before {
		-webkit-transform: translateX(15px);
		-ms-transform: translateX(15px);
		transform: translateX(15px);
	}

	.map-collection-error {
		margin: .5rem 1.25rem;
		text-align: left;
		color: red;
	}
	/* No IE support for position:sticky */
	@supports(position:sticky) {
		.waypoint-icon-section-header {
			width: 102%;
			position: sticky;
			top: -9px;
			background-color: white;
			padding-bottom: 5px;
			padding-top: 5px;
		}
	}

	.collection-settings-configurator-container {
		flex: 1 1 auto;
		overflow: hidden;
		height: 100%;
	}

	.collection-settings-configurator-mapshare-container {
		flex: none;
		padding: 10px;
		border-bottom: 1px solid lightgray;
	}

	.collection-settings-configurator-sync-container {
		flex: 1 1 auto;
		padding: 10px;
		height: 100%;
	}

	.collection-settings-configurator-sync-container-no-padding {
		flex: 1 1 auto;
		height: 100%;
	}

	.collection-settings-configurator-device-row-container {
		@include secondary-body-text();
		flex: 1 1 auto;
		overflow-y: auto;
		height: 100%;
		padding-top: .5rem;
	}

	.collection-settings-configurator-device-row {
		min-height: 1.5rem;
		cursor: pointer;
		padding: .25rem 0;
	}

	.collection-settings-configurator-device-row:hover {
		background: #CFF4FF;
	}

	body[theme='flatGreen'] .collection-settings-configurator-device-row:hover {
		background: #FFD68D;
	}

	.collection-settings-configurator-mapshare-disabled-warning {
		color: map-get($COLORS, danger);
		text-align: left;
	}

	.collections-mapshared-toggle {
		top: 6px;
		position: relative;
		margin-left: 10px;
	}

	.generic-collection {
		&-chooser {
			flex: 1 1 auto;
			width: 100%;
			height: 90%;
		}

		&-row {
			min-height: 25px;

			&-container {
				flex: 1 1 auto;
				overflow-x: hidden;
				overflow-y: auto;
			}

			&.disabled {
				cursor: not-allowed;
			}

			&:hover {
				background: #CFF4FF;
			}

			body[theme='flatGreen'] &:hover {
				background: #FFD68D;
			}
		}

		&-search {
			width: 98%;
		}

		&-checkbox {
			margin-right: 5px !important;
			margin-top: 5px !important;

			&.disabled {
				cursor: not-allowed;
			}
		}

		&-content-container {
			text-align: left;
			margin-top: 3px;
			width: 97%;
		}

		&-name {
			@include body-text();
			cursor: pointer;
		}
	}

	#collection-edit-explanation-icon {
		top: -3px;
		margin-left: 7px;
	}

	#collection-edit-mapshare-explanation-icon {
		margin-left: 7px;
	}

	.togglemostrecentleaflet {
		margin-top: 5px !important;
	}

	#most-recent-track-toggle {
		border-radius: 4px;
		padding: 4px 12px;
		font-weight: bold;
		background-color: #F8F8F8;
		color: #111111;

		&:hover {
			background-color: #CFF4FF;
			border: 1px solid #CFF4FF;
		}
	}

	#inreach-right-ad-container {
		z-index: 10001;
		position: absolute;
		bottom: 30px;
		right: 0;
		overflow: hidden;
		width: 0;
		height: 300px;
		transition: width ease-in 0.75s;
		box-shadow: -3px 3px 3px 1px rgba(1, 1, 1, 0.2);

		@include bp('small') {
			z-index: 10010;
		}
	}

	#inreach-right-ad {
		position: absolute;
		top: 0;
		left: 0;
		background-color: map-get($COLORS, ads);
		width: 245px;
		height: 300px;
	}

	#inreach-right-ad-color-splash {
		opacity: 0.3;
		background-color: #fff;
		transform-origin: 0% 100%;
		transform: rotate(60deg);
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	#inreach-right-ad-close {
		position: absolute;
		top: 0;
		right: 0;
		border: none;
		background-color: transparent;
		color: map-get($COLORS, primarytext);
		font-size: 1.5rem;
	}

	#inreach-right-ad-close:hover {
		color: #fff;
	}

	#inreach-right-ad-img {
		position: absolute;
		top: 30px;
		height: 240px;

		&.mini {
			right: -165px;
		}

		&.sixtySixi {
			right: -100px;
		}
	}

	#inreach-right-ad-column {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		position: absolute;
		top: 40px;
		left: 30px;
		bottom: 40px;
		z-index: 1;
	}

	#inreach-right-ad-text {
		color: map-get($COLORS, primarytext);
		background-color: map-get($COLORS, ads);
		font-size: 1.15rem;
		font-weight: 500;
		width: 130px;
		text-align: left;
	}

	#inreach-right-ad-learn-more {
		width: 95%;
		box-shadow: -2px 2px 3px 1px rgba(1, 1, 1, 0.2);
	}

	.map-route-popup-label {
		@include body-text();
		height: 25px;
		width: 120px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		@include bp('small') {
			white-space: normal;
			height: auto;
			line-height: 1.25;
			width: 11rem;
		}
	}

	.map-waypoint-popup-name {
		@include body-text();
		width: 145px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		@include bp('small') {
			white-space: normal;
			height: auto;
			line-height: 1.25;
			width: 11rem;
		}
	}

	.map-waypoint-popup-remaining {
		float: left;
		padding-left: 4px;
		font-size: 12px;
	}

	.map-user-popup-displayname {
		@include secondary-body-text();
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap
	}

	.map-message-popup-date {
		float: left;
		@include small-body-text();
	}

	.map-message-popup-time {
		float: right;
		@include small-body-text();
	}

	.map-message-popup-content {
		@include small-body-text();
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
	}

	.map-track-popup-name-remaining {
		float: right;
		padding-right: 4px;
		@include small-body-text();
		display: inline-block;
		position: relative
	}

	.map-track-popup-more-label {
		color: map-get($COLORS, secondarytext);
		@include small-body-text();
	}

	.map-track-popup-more-data {
		white-space: nowrap;
		@include small-body-text();
		color: black
	}

	.RandomColor.btn {
		@include small-body-text();
		margin-top: 1px;
		padding: 1px 3px 1px 3px;
	}

	.line-name-save-icon {
		@include body-text();
	}

	.line-name-cancel-icon {
		@include body-text();
	}

	#mapshare-password-label {
		margin-top: 10px;
		@include secondary-body-text();
		font-weight: bold;
		color: #fff;
	}

	.lastSeenTime {
		color: map-get($COLORS, dark);
	}

	.ex-message {
		&_date {
			color: map-get($COLORS, secondarytext);
		}
	}

	.ex-page_map {
		#control-container-slider {
			.ex-dragHandle {
				// this functionality will be discussed, for now we hide
				display: none !important;
			}
		}
	}

	.ex-controlExpander,
	#control-expander {
		display: inline-block;
		float: left;
		position: relative;
		background-color: black;
		width: 5px;
		opacity: .4;
		z-index: 1000;
		overflow: visible;

		&_closed {
			transform: rotate(180deg);
		}

		@include bp('small') {
			z-index: 10001;
			transition: opacity .3s ease-in-out;
			opacity: 0.7;
		}

		.modal-open &,
		.ex-nav_active & {
			@include bp('small') {
				z-index: 1000;
			}
		}
	}

	#divModalSendLocation {
		max-width: 30em;
		left: 1em;

		@include bp('small') {
			left: auto;
		}
	}


	.ex-map {
		&_popup {
			@include bp('small') {
				width: 18em;
			}
		}
	}

	.expand-collapse-control:hover {
		cursor: pointer !important;
	}

	#expand-collapse-controls {
		position: absolute;
		top: 50%;
		padding: 2px;
		z-index: 700;
		background-color: black;
		border-radius: 3px 3px 3px 3px;
		transform: rotate(180deg);

		@include bp('small') {
			width: 1.5em;
			height: 2.5em;
			top: 50% !important;
		}

		.ex-controlContainer_open ~ #map & {
			transform: rotate(0deg);
		}
	}

	.inline-icon {
		height: 12px;
		vertical-align: baseline;
	}

	.leaflet-hidden-div-icon {
		display: none;
	}
}
