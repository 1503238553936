@mixin selectableIcon() {
	.ex-selectable_icon {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&-image_container {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: var(--secondarybackground);
			border-radius: 50%;
			width: 4.5rem;
			height: 4.5rem;
			margin: 0 0.5rem 0.25rem 0.5rem;
			position: relative;
			user-select: none;

			&:not(.ex-selectable_icon-disabled) {
				cursor: pointer;

				&:hover {
					filter: brightness(80%);
				}
			}

			&:before {
				content: url(/Content/images/ActivitySurvey/checkmark.svg);
				color: white;
				display: flex;
				justify-content: center;
				align-items: end;
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: rgba(211,62,1,0.65);
				border-radius: 50%;
				opacity: 0;
				transition: opacity linear 0.15s;
			}

			svg {
				color: var(--filledIcon);
				width: 3.375rem;
				height: 3.375rem;
			}
		}

		&-title {
			text-align: center;
			width: 5.5rem;
			line-height: 1;
			@include small-body-text();
		}

		&-selected:before {
			opacity: 1;
		}
	}
}
