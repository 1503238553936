﻿@mixin welcome-to-explore-get-started() {
	.ex-welcome-to-explore-get-started {
		#content {
			width: 100%;
		}

		#welcome-to-explore-container {
			margin-left: auto;
			margin-right: auto;
			@media (min-width: 787px) {
				width: 60%;
			}
		}

		.welcome-text-size {
			@include subheader-text();
		}

		.get-started-button {
			padding: 1rem 4rem 1rem 4rem;
		}
	}
}
