.ex-homepageContentArea {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	border: 2px solid var(--bordersdark);
	border-radius: 5px;
	padding: 1.2em 2em;
	min-height: 4em;
	overflow: hidden;

	@include bp('(max-width: 1400px)') {
		padding: 1em;
	}

	@include bp('medium') {
		padding: 1em;
	}

	.card & {
		border: none;
	}

	&_content {
		width: 100%;
		transition: max-height 0.3s ease-in-out;
		transition: height 0.3s ease-in-out;

		.ex-homepageContentArea_expandable & {
			max-height: 0;
		}

		.ex-homepageContentArea_expanded & {
			max-height: 44em;

			@include bp('small') {
				max-height: 80em;
			}
		}
	}

	&_wrapper {
		width: 100%;
		padding: 0.5em;
		margin-bottom: 2em;
		overflow: hidden;

		.ex-homepage_side & {
			padding: 0 1em 1em 1em;
			margin-bottom: 0;

			& + .ex-homepageContentArea_wrapper {
				margin-top: 0;
			}

			.ex-homepageContentArea {
				padding: 0.25rem 0.5rem;
			}

			@include bp('medium') {
				flex-basis: calc(50% - 0.5rem);
				margin-top: 0;
			}

			@include bp('small') {
				flex-basis: 100%;
				margin-bottom: 0;
			}
		}
	}

	&_heading {
		font-size: 1.5rem;
		margin-bottom: 0.2em;

		@include bp('small') {
			font-size: 1.25rem;
		}

		.pending-change-link {
			color: var(--explorange);
			text-decoration: underline;
			cursor: pointer;
			font-size: 0.75em;
			font-weight: normal;
		}
	}

	&_subheading {
		color: var(--explorange);
		font-size: 0.75em;
		font-weight: bolder;
		margin-bottom: 0.2em;
	}

	&_expander {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: 100%;
		max-height: 4em;
		padding: 1.2rem 2rem;
		width: 100%;
		text-align: left;
		border: none;
		background: none;

		@include bp('small') {
			padding: 0.5rem;
		}

		img {
			transition: all 0.3s ease-in-out;
			margin-left: 0.5rem;
			vertical-align: baseline;

			.ex-homepageContentArea_expanded & {
				transform: rotate(180deg);
			}
		}
	}

	&_title {
		font-size: 1.125rem;
		line-height: 1.125;

		@include bp('small') {
			font-size: 0.925rem;
		}

		.flex-baseline {
			display: flex;
			align-items: baseline;
		}
	}
}
