.ex-sarInsurance {
	&_header {
		font-size: 1.5rem;

		@include bp("small") {
			font-size: 1.25rem;
		}
	}

	&_header-container {
		min-height: 3.5rem;
	}

	&_card-container {
		max-width: 25rem;
		height: 30rem;
	}

	&_card {
		border-radius: 5px;
		border: 2px solid var(--bordersdark);
		height: 100%;
	}

	&_card-header {
		background-color: var(--sarimagebackgroundcolor);
		color: var(--contentbackground);
		display: flex;
		justify-content: center;
		border-radius: 5px 5px 0px 0px;
		padding: 0.25rem;
		text-align: center;
		font-weight: bold;
		font-size: 1.25em;
	}

	&_card-body {
		display: flex;
		flex-direction: column;
		align-content: space-between;
		justify-content: space-between;
		flex-wrap: nowrap;
		padding-bottom: 0.5rem;
	}

	&_card-image {
		height: 14rem;
		object-fit: cover;
	}

	.additional-info {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 100%;
	}

	&_link {
		color: var(--bluelink);
	}

	&_plan-container {
		padding-left: 1.25rem;
	}

	.plan-label {
		color: var(--gray);
		margin-top: 0.5rem;
		font-weight: map-get($WEIGHTS, light);
	}

	.plan-value {
		font-weight: map-get($WEIGHTS, medium);
	}

	.body-text {
		@include body-text();
	}

	.secondary-body-text {
		@include secondary-body-text();
		padding-top: 0.25rem;
	}
}
