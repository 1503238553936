﻿@mixin subnav() {
	.ex-subnav {
		display: none;
		position: absolute;
		top: 3.75em;
		left: 0;
		width: 14em !important;
		height: auto !important;
		flex-wrap: wrap;
		z-index: 10000;
		background: map-get($COLORS, contentbackground);
		text-align: left;
		border: 1px solid map-get($COLORS, borders);
		border-top: none;
		padding: 0;
		margin: 0;

		&_active & {
			display: flex;
		}

		@include bp('medium') {
			position: static;
			border: none;
			width: auto !important;
			display: flex;
		}

		&_item {
			flex-basis: 100%;
			padding: 0 !important;
			display: block !important;

			a {
				display: block;
				text-transform: none !important;
				padding: 0.75em 0.875em !important;
			}

			@include bp('medium') {
				border-bottom: none;
				padding-left: 1.5em !important;

				a {
					text-transform: none;
				}
			}
		}
	}
}
