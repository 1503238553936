#modal_pending_change {
	.modal-content {
		background-color: var(--primarybackground);
		color: var(--primarytext);
	}

	.modal-footer {
		flex-wrap: wrap;

		.btn {
			margin-top: 0.5em;
		}

		.btn-group {
			.btn {
				border-radius: .25rem !important;
			}

			.dropdown-item {
				&:hover {
					background: var(--dropdownhover);
					color: var(--light);
				}
			}
		}
	}

	.ex-actionButtons {
		margin-right: 0;
		text-align: right;
	}
}
