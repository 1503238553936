@mixin account() {

	#testinReach h1 {
		font-size: 1.75em !important;
	}

	.ex-account {
		&_row {
			display: flex;
			flex-direction: row;
			margin-bottom: 1.25rem;

			&-padded {
				padding: 1em;
			}

			@include bp('small') {
				flex-wrap: wrap;
				margin: auto;
			}
		}

		&_col {
			@include bp('small') {
				width: 100%;
				max-width: 100%;
				flex-basis: 100% !important;
				height: auto !important;
				padding: 1em;

				& > div {
					margin: auto auto 1.5em !important;
				}
			}
		}
	}
}
