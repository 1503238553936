.ex-hero {
	position: absolute;
	top: 0;
	bottom: 0;
	// padding on outer homepage container is 1em, offset hero to fill the width
	left: -0.5em;
	right: -0.5em;
	background-repeat: no-repeat;
	background-position: center;


	&_container {
		position: relative;
		height: 9em;
		width: 100%;
		flex-basis: 100%;
		margin-bottom: 2em;

		@include bp('small') {
			height: 7em;
		}
	}

	&_copy {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		&-title {
			color: map-get($COLORS, contentbackground);
			font-size: 1.5rem;
			text-transform: uppercase;
			z-index: 100;
			position: relative;
			text-align: center;

			@include bp('small') {
				font-size: 1.5em;
			}
		}
	}

	&_blur {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 99;
		/* Pull the background 106px higher to the same place as #bg's */
		background-position: right 50% bottom -106px;
		-webkit-filter: blur(1px);
		filter: blur(2px);
		min-width: 30rem;

		@include bp('small') {
			min-width: 18rem;
			/* Pull the background 107px higher to the same place as #bg's */
			background-position: right 50% bottom -107px;
		}
	}

	&_box {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 100;
		min-width: 30rem;
		background-color: rgba(0,0,0,0.4);

		@include bp('small') {
			min-width: 18rem;
		}
	}
}
