@mixin how-to-activate-landing-page() {
	.ex-how-to-activate-landing-page {
		#content {
			width: 100%;
		}

		$one-card-per-row-breakpoint: 991px;

		.header-container {
			@media (max-width: $one-card-per-row-breakpoint) {
				margin-left: auto;
				margin-right: auto;
				width: 21rem;
			}
		}

		.card-container {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			column-gap: 2rem;

			@media (max-width: $one-card-per-row-breakpoint) {
				justify-content: center;
			}
		}


		.activation-card {
			background-color: var(--cardheader);
			width: 21rem;
			box-shadow: -3px 4px 8px 0px #00000040;
		}

		.device-list {
			padding-left: .5rem;
		}

		.btn-card {
			padding: 1rem 0.75rem;
		}

		.professional-account-description {
			font-size: .8rem;
			line-height: 1.25;
		}

		.scan-qr-code {
			font-size: .8rem;
			margin-bottom: 0;
		}

		.no-grow {
			flex-grow: 0;
		}

		.tread-img-container {
			width: 13rem;
			margin-right: -2rem;
		}

		.tread-img {
			max-width: 100%;
		}

		.activation-link-container {
			display: flex;
			align-items: center;
			column-gap: 0.5rem;

			@media (max-width: $one-card-per-row-breakpoint) {
				margin-left: auto;
				margin-right: auto;
				width: 21rem;
				justify-content: center;
			}
		}

		.activation-link-image {
			width: 1.5rem;
		}

		.activation-link {
			font-size: 1.4rem;
			text-decoration: underline
		}
	}
}
