.ex-deviceForm {
	text-align: left;

	&_fields {
		display: flex;
		flex-wrap: wrap;

		@include bp('small') {
			display: block;
		}
	}

	&_field {
		margin-right: 1.25em;
		margin-bottom: 1em;
		text-align: left;

		@include bp('small') {
			margin-right: 0;
		}
	}

	&_complete {
		display: flex;
		flex-wrap: wrap;

		&-section {
			padding: 0.5rem;

			&:last-child {
				margin-left: auto;
				line-height: 2;
			}
		}

		&-label {
			font-size: 1.25rem;
		}

		&-value {
			font-size: 1.25rem;
			font-weight: bold;
			margin-left: 0.5rem;
		}
	}
}
