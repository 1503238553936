﻿.ex-addDevice {
	.ex-deviceImeiFinder {
		&_content {
		}

		&_title {
			text-align: left;
		}

		&_section {
			margin: 0.5rem auto 1rem 1rem;
			text-align: left;
		}

		&_list {
		}

		&_image {
			height: 11.7em;
		}

		&_button {
		}

		&_device {
			&Description {
				flex-wrap: wrap;
			}

			&Section {
			}

			&Title {
			}

			&Content {
				margin-left: 1rem !important;
				flex-basis: 100%;

				&_inner {
					margin-left: 0 !important;
					flex-basis: 100%;
				}
			}

			&Image {
				flex: 0;
				max-width: 8rem;
			}
		}
	}
}
