.ex-layout {
	&_noImageFooter {
		#content {
			display: flex;
			flex-direction: column;
			min-height: calc(100vh - 121px);
			max-width: none;
		}
	}

	&_fullWidth {
		.ex-content {
			@include bp('small') {
				margin: auto !important;
			}
		}

		#content {
			width: 100% !important;
		}
	}
}

.ex-page_home-wide {
	#content {
		width: 100%;
		max-width: 1600px;
	}
}

.ex-normal-title {
	font-size: 1.5rem;
	margin-top: 0.5em;
}

@media(max-width:950px) {
	.ex-layout {
		&_noImageFooter {
			#content {
				min-height: 100vh;
			}
		}
	}
}

@media(max-width:800px) {
	.ex-layout {
		&_noImageFooter {
			#content {
				min-height: calc(100vh + 4em);
			}
		}
	}
}

@media(max-width:700px) {
	.ex-layout {
		&_noImageFooter {
			#content {
				min-height: calc(100vh + 8em);
			}
		}
	}
}

@media(max-width:600px) {
	.ex-layout {
		&_noImageFooter {
			#content {
				min-height: calc(100vh + 16em);
			}
		}
	}
}