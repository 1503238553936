﻿.dojoxGridRowTable th
{
	height: 25px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.dojoxGridSortNode
{
	cursor: pointer !important;
}
.dojoxGridCellFocus
{
	border: none !important;
}

th.dojoxGridCellFocus
{
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d9e5f3', endColorstr='#bbcee5') !important;
	background: -moz-linear-gradient(top,  #d9e5f3,  #bbcee5) !important;
	background: -webkit-gradient(linear, left top, left bottom, from(#d9e5f3), to(#bbcee5)) !important;
	border-style: solid !important;
	border-width: 1px !important;
} 
.tundra .dojoxGridCellFocus
{
	border: 1px solid #8C8C8C !important;
}
.tundra .dojoxGridRowOver {
	border-top-color: #d0e4c3;
	border-bottom-color: #d0e4c3;
	background-color: 	#d0e4c3;
}
.tundra .dojoxGridRowOver .dojoxGridCell {
	border-top-color: #d0e4c3;
	border-bottom-color: #d0e4c3;
	background-color: 	#d0e4c3;
	color: Black;
}
.tundra .dojoxGridCellOver {
	border-top-color: #d0e4c3;
	border-bottom-color: #d0e4c3;
	background-color: 	#d0e4c3;
}
div.dojoxGridSortNode
{
	font-weight:bold;
}
