.ex-siteNotification {
	position: relative;
	width: 100%;
	max-width: none;
	margin: 0 !important;
	padding: 1rem 1rem 0.5rem 1rem;
	transition: all 0.3s;
	background-color: map-get($COLORS, bannerBackground);

	&_body {
		margin: auto;
		text-align: left;
		width: fit-content;
		max-width: 60rem;
		background: map-get($COLORS, bannerBackground) !important;
		color: map-get($COLORS, light) !important;
		padding: 0 1rem;

		@include bp('(max-width: 560px)') {
			margin: 0;
			font-size: 0.7rem;
			padding: 0;
		}

		@include bp('(max-width: 350px)') {
			font-size: 0.525rem;
		}

		.failtext {
			color: map-get($COLORS, light) !important;
		}

		ul, ol {
			list-style: initial !important;
			padding: 0 0 0 2rem !important;
			margin-bottom: 0;

			li {
				padding: 0 !important;
			}
		}

		p {
			margin-bottom: 0;
		}
	}

	&_close {
		border: none;
		background: none;
		position: absolute;
		right: 2px;
		top: 0;
		font-size: 20px;
		font-weight: bold;
		color: map-get($COLORS, light);
		transition: all 0.3s;

		&:hover {
			color: darken(map-get($COLORS, light), 20%);
		}
	}

	&_header {
		text-align: left;
		margin: auto;
		width: max-content;
		max-width: 100%;
		padding: 0 1rem;
		font-size: 1.1rem !important;
		border-radius: 0 !important;
		background-color: map-get($COLORS, bannerBackground);
		border: none !important;
		color: map-get($COLORS, light) !important;

		@include bp('(max-width: 560px)') {
			margin: 0;
			padding: 0;
		}

		@include bp('(max-width: 350px)') {
			font-size: 0.8rem !important;
		}


		&.alert-danger {
			background: map-get($COLORS, bannerBackground);
		}
	}

	nav {
		display: grid;
		grid-template-columns: max-content;
		justify-content: right;
		align-self: end;
	}

	&_link {
		font-size: 0.875rem;
		text-decoration: underline;
		color: map-get($COLORS, light) !important;
		transition: all 0.3s;
		top: 2rem;
		right: 0.5rem;
		text-align: right;

		@include bp('(max-width: 560px)') {
			font-size: 0.7rem;
		}

		@include bp('(max-width: 350px)') {
			font-size: 0.6125rem;
		}

		&:hover {
			color: darken(map-get($COLORS, light), 20%);
		}
	}
}
