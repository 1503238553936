@mixin landingPage() {
	.ex-landing-page {
		$landingPageMobileBP: 58.25rem;

		font-family: Sarabun;
		line-height: 1.3;
		font-weight: 400;

		h2 {
			line-height: 1.3;
			font-weight: 700;
			font-size: 1.875rem;

			@media (min-width: $landingPageMobileBP) {
				font-size: 2.375rem;
			}
		}

		&-activation {

			&-content-container {
				display: flex;
				justify-content: center;

				@media (min-width: $landingPageMobileBP) {
					justify-content: end;
				}

				&-column {
					flex-direction: column;

					@media (min-width: $landingPageMobileBP) {
						width: max(50%, 630px);
						flex-direction: column;
					}
				}
			}

			&-small-group-hike-img {
				img {
					width: 100%;
					mask-image: linear-gradient(180deg,rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 255) 42%);
				}

				@media (min-width: $landingPageMobileBP) {
					display: none;
				}
			}

			&-container {
				width: auto;
				display: flex;
				flex-direction: column;
				padding: 1.25rem;

				@media (min-width: $landingPageMobileBP) {
					height: 26.25rem;
					flex-direction: column;
					justify-content: flex-start;
					background-image: url("/Content/images/LandingPage/group-hike-big-hr-cropped-left.jpg");
					background-size: cover;
					background-position-x: 25%;
					padding: 0;
				}
			}

			&-title-container {
				margin-bottom: 1rem;

				@media (min-width: $landingPageMobileBP) {
					margin: 2rem 0 0 1%;
					padding-right: 1rem;
				}
			}

			&-header {
				margin-bottom: 1rem;

				@media (min-width: $landingPageMobileBP) {
					font-size: 2.5rem;
				}
			}

			&-image-button-container-group {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: flex-end;
				gap: 2.5rem;
				flex-wrap: wrap; /*Let it wrap on unrealistically tiny displays*/

				@media (min-width: $landingPageMobileBP) {
					flex-direction: row;
					justify-content: normal;
					margin: 2rem 0rem 0rem 0rem;
					row-gap: 0;
					column-gap: min(6rem, 2vw);
					flex-wrap: nowrap;
					align-items: center;
				}
			}
			/*This class is expected to contain one device image and one button that takes you to activate said device*/
			&-image-button-container {
				/*On smaller screens the img is stacked above the button*/
				display: flex;
				flex-direction: column;
				gap: 0.5625rem;
				align-self: stretch;

				#mini2_img {
					height: 7.5rem;
					width: auto;
					margin: 0 auto 0 auto;
				}

				#tread_img {
					height: 5rem;
					width: auto;
					margin: 2.5rem auto 0 auto; /*The margin top value is to give it the same height as mini2_img*/
				}

				a {
					width: 8.75rem;
					border-radius: 0.375rem;
					padding: 0.25rem 0.625rem 0.25rem 0.625rem;
					gap: 0.625rem;
					word-wrap: break-word;
					background: #0072EC;
					height: 100%;
					display: flex;
					align-items: center;
				}
				/*On bigger screens the img is next to and overlapping the button and the button color changes some*/
				@media (min-width: $landingPageMobileBP) {
					display: block;
					width: fit-content;
					gap: normal;
					align-self: inherit;
					height: fit-content;
					text-wrap: nowrap;


					a {
						width: 15.625rem;
						padding: 1.25rem 1.25rem 1.25rem 2.6rem; /*Extra padding on left to keep images from obscuring any translated text that may be longer than the english text*/
						border-radius: 0.375rem;
						height: fit-content;
						display: inline-block;
					}
					/*rotate and shadow*/
					#mini2_img {
						transform: rotate(-8deg);
						filter: drop-shadow(0rem 0.25rem 0.25rem rgb(0,0,0,0.25));
						margin: 0 -2.25rem 1.75rem 0;
					}

					#tread_img {
						transform: rotate(-8deg);
						filter: drop-shadow(0rem 0.25rem 0.25rem rgb(0,0,0,0.25));
						margin: 0 -2rem 1rem 0;
					}
				}
			}
		}

		&-adventures {
			$featuresMobileBP: 37.5rem;

			&-container {
				order: 2; /*Go after explore mobile on mobile*/
				padding: 1.25rem;
				background-color: var(--landingpagesecondarybackground);

				@media (min-width: $landingPageMobileBP) {
					order: 1; /*Go before explore mobile on desktop*/
					padding: 2.875rem 1.25rem;
				}
			}

			&-header {
				margin-bottom: 1.25rem;

				@media (min-width: $featuresMobileBP) {
					text-align: center;
					margin-bottom: 2.5rem;
				}
			}

			&-features-container {
				display: flex;
				flex-direction: column;
				gap: 1.25rem;
				align-items: center;
				/*Slightly smaller breakpoint to keep it from going to a single vertical column until it looks okay*/
				@media (min-width: $featuresMobileBP) {
					align-items: normal;
					flex-direction: row;
					justify-content: center;
					flex-wrap: wrap;
				}
			}

			&-feature {
				width: 12.5rem;
				padding-right: 1.25rem;

				img {
					width: 3.125rem;
					height: 3.125rem;
					margin: 0 auto .6rem auto;
					display: block;
				}

				p {
					font-size: 1rem;
				}
			}
		}

		&-explore-mobile {
			&-container {
				order: 1; /*Go before off grid adventures on mobile*/
				width: auto;
				display: flex;
				flex-direction: column;
				padding: 1.25rem 1.25rem 2.5rem 1.25rem;

				@media (min-width: $landingPageMobileBP) {
					order: 2; /*Go after off grid adventrue on desktop*/
					height: 40.625rem;
					flex-direction: row;
					background-image: url("/Content/images/LandingPage/PS-Silhouette-big-hr.jpg");
					background-size: cover;
					background-position: right;
					padding: 3rem 0 0 5rem;
					gap: 6rem;
				}
			}

			&-content {
				display: flex;
				flex-direction: column;
				gap: 1.25rem;

				@media (min-width: $landingPageMobileBP) {
					width: 51.125rem;
					padding: 0.625rem;

					h2 {
						font-size: 2.5rem;
					}
				}

				p {
					font-size: 1rem;

					@media (min-width: $landingPageMobileBP) {
						font-size: 1.5rem;
					}
				}
			}

			&-app-store-button-wrapper {
				display: flex;
				flex-direction: row;
				gap: 1.25rem;
				justify-content: center;
				flex-wrap: wrap; /*Let it wrap on super tiny displays*/

				img {
					width: 9.375rem;
				}

				@media (min-width: $landingPageMobileBP) {
					padding-top: 1.25rem;
					justify-content: normal;
					flex-wrap: nowrap;

					img {
						width: 12.5rem;
					}
				}
			}


			&-app-img {
				$modernIphoneScreenRatio: 9/19.5; // iPhone 11 - 15
				display: none;

				@media (min-width: $landingPageMobileBP) {
					display: block;
					max-width: 16.4375rem;
					min-width: 0rem;
					flex-grow: 1;
					flex-shrink: 1;
					height: fit-content;
					aspect-ratio: $modernIphoneScreenRatio;
					margin-right: 1rem;
				}
			}
		}

		&-explore-web {
			&-container {
				width: auto;
				padding: 1.25rem 1.25rem 2.5rem 1.25rem;

				#web_big_img {
					display: none;
				}

				@media (min-width: $landingPageMobileBP) {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					align-items: center;
					column-gap: 4rem;
					height: 63.0625rem;
					padding: 0 1rem 0 9.0625rem;


					#web_big_img {
						display: block;
						max-width: 57.4375rem;
						flex-shrink: 1;
						flex-grow: 1;
						min-width: 28.125rem;
						flex-basis: 0rem;
					}
				}
			}

			&-content {

				h2 {
					text-align: center;
					margin-bottom: 1.25rem;
				}

				p {
					font-size: 0.875rem;
					text-align: left;
				}

				li {
					font-size: 0.875rem;
					text-align: left;
					margin-top: 0.625rem;
				}

				#app_route_sync_img {
					display: block;
					width: 8.21875rem;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 1.25rem;
					padding-top: 1.25rem;
				}

				@media (min-width: $landingPageMobileBP) {
					display: block;
					max-width: 42.125rem;

					h2 {
						text-align: left;
					}

					p {
						font-size: 1.3125rem;
						margin-bottom: 0.625rem;
					}

					li {
						font-size: 1rem;
					}

					#app_route_sync_img {
						display: none;
					}
				}
			}
		}

		&-apps {

			$appsMobileBP: 43.75rem;

			&-container {
				padding: 1.25rem;
				background-color: var(--landingpagesecondarybackground);

				@media (min-width: $landingPageMobileBP) {
					padding: 2.875rem 1.25rem;
				}
			}

			&-header {
				margin-bottom: 1.25rem;

				@media (min-width: $appsMobileBP) {
					text-align: center;
				}
			}

			&-more-link {
				text-align: center;
				font-size: 1rem;
				margin: 2.5rem 0;

				a {
					text-decoration: underline;
					color: var(--primarytext);
				}

				@media (min-width: $landingPageMobileBP) {
					font-size: 1.375rem;
					margin: 1rem 0 0 0;
				}
			}

			&-icon-container {
				display: flex;
				flex-direction: column;
				gap: 2.5rem;
				align-items: center;

				a {
					padding-top: 1.25rem;
				}
				//smaller breakpoint to prevent single column until it looks better
				@media (min-width: $appsMobileBP) {
					align-items: normal;
					flex-direction: row;
					justify-content: center;
					flex-wrap: wrap;
				}
			}

			&-icon {
				img {
					width: 7.8125rem;
					height: 7.8125rem;
					margin: 0 auto .6rem auto;
					display: block;
				}



				p {
					font-family: Arial;
					font-size: 1.25rem;
					text-align: center;
					margin-bottom: 0rem; /*Override default*/
				}
			}

			&-additional-info {
				font-size: 0.6875rem;

				@media (min-width: $landingPageMobileBP) {
					padding: 1.25rem 9.0625rem 1.25rem 9.0625rem;
					font-size: 1.25rem;
				}
			}
		}

		&-service-and-trade-mark-section {
			font-size: 0.6875rem;
			text-align: left;
			padding: 1.25rem;

			@media (min-width: $landingPageMobileBP) {
				font-size: 1.25rem;
				text-align: center;
				padding: 6.25rem 1.25rem;
			}
		}

		&-footer {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			#inreach-footer {
				position: relative;
				margin: 0;
				right: 0;
				padding: 1.875rem 0 0.625rem 0;
				background: var(--light); /*Override default gray color*/
			}

			#inreach-footer p {
				margin: 0;
				padding: 0 0.625rem 0 0.625rem;
				position: relative;
				/*Here instead of in the a tag so it applies to the pipes between links as well. Existing color from other footer classes*/
				font-size: 0.625rem;
				font-weight: 700;
				text-align: right;
			}

			#inreach-footer p a {
				white-space: nowrap;
				text-decoration: underline;
			}
		}

		&-garmin-logo-footer {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			img {
				width: 7.875rem;
				margin-right: 1.25rem;
				display: block;
			}
		}
	}
}
