.too-much-data-alert-container {
    min-width: 25vw;
}

.too-much-data-alert-body {
    :not(:last-child) {
        margin-top: 0;
        margin-bottom: .5rem;
    }

    :last-child {
        margin-top: 0;
        margin-bottom: 0;
    }
    
    span {
        display: block;
    }

    ul {
        max-height: 10vh;
        overflow-x: hidden;
        overflow-y: auto;
    }
}