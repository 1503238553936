
.ex-hide {
	display: none !important;
}

.ex-block {
	display: block !important;
}

.ex-inlineBlock {
	display: inline-block !important;
}

.ex-margin {
	&_left10 {
		margin-left: 0.625em;
	}

	&_leftAuto {
		margin-left: auto;
	}

	&_bottom10 {
		margin-bottom: 0.625em;
	}

	&_bottom24 {
		margin-bottom: 24px;
	}

	&_top24 {
		margin-top: 24px;
	}
}

.ex-text {
	&_center {
		text-align: center;
	}

	&_right {
		text-align: right;
	}

	&_left {
		text-align: left;
	}

	&_underline {
		text-decoration: underline !important;
	}

	&_bold {
		font-weight: bold !important;
	}
}

.ex-float {
	&_rt {
		float: right;
	}

	&_lt {
		float: left;
	}
}

.ex-cursor {
	&_pointer {
		cursor: pointer;
	}
}


.ex-link {
	color: map-get($COLORS, explorange);

	.ex-footer & {
		color: map-get($COLORS, link);
	}
}

.ex-circle {
	border-radius: 50%;
}

.ex-flex {
	display: flex;

	&_grow {
		flex-grow: 1;
	}

	&_100 {
		flex-basis: 100%;
	}

	&_justifyEnd {
		justify-content: flex-end;
	}

	&_justifyCenter {
		justify-content: center;
	}
}

.ex-overflow {
	&_hidden {
		overflow: hidden;
	}

	&_scrollHidden {
		overflow: scroll hidden;
	}
}

.ex-show {
	&_sm {
		display: none;

		@include bp('small') {
			display: block;
		}
	}

	&_tread {
		display: none;

		@include bp('tread') {
			display: block;
		}
	}

	&_inlinesm {
		display: none;

		@include bp('small') {
			display: inline-block;
		}
	}
}

.ex-hide {
	&_sm {
		@include bp('small') {
			display: none;
		}
	}

	&_tread {
		@include bp('tread') {
			display: none;
		}
	}
}

.ex-error {
	color: map-get($COLORS, danger) !important;
}

.ex-error-input {
	border-color: map-get($COLORS, danger) !important;
}

.invalid-field-tag:after {
    content: " *";
    color: var(--danger);
}

.ex-visibility {
	&_hidden {
		visibility: hidden;
	}
}

.ex-border {
	&_none {
		border: none !important;
	}
}

.ex-eq-triangle-up {
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
	border-bottom: 14px solid;
}

.ex-width {
	&_100 {
		width: 100%;
	}

	&_65 {
		width: 65%;
	}

	&_30 {
		width: 30%;
	}
}

.ex-pe-none {
	pointer-events: none;
}

.ex-pad {
	&_left {
		&-16 {
			padding-left: 16px;
		}

		&-24 {
			padding-left: 24px;
		}
	}
}

/* Invert img div when in a dark theme when css class is used */
body:where([data-theme=dark], [data-theme=earthmatedark]) .ex-themed-icon {
    -webkit-filter: invert(1);
    filter: invert(1);
}
