.ex-profile {
	background-image: none !important;

	&_title {
		vertical-align: middle;
		padding-left: 0.375em;
	}

	&_warning {
		float: right;
		padding-right: 2em;
		color: map-get($COLORS, error);
		padding-top: 0.25em;
		font-weight: bold;
	}

	&_tab {
		text-align: right;
		cursor: pointer;
		padding: 5px;
		font-weight: bold;

		&-disabled {
			pointer-events: none;
		}

		&-selected {
			position: relative;
			background-color: map-get($COLORS, tabbackground);
			z-index: 1;
		}

		&-selected:after, &-selectedNoArrow:before {
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			width: 0.3em;
			height: 100%;
			content: "";
			background-color: map-get($COLORS, explorange);
		}

		&:first-of-type {
			border-top: 1px solid #A8A8A8;
		}

		&:last-of-type {
			border-bottom: 1px solid #A8A8A8;
		}
	}

	&_goBack {
		font-size: 1em;
	}

	&_deviceTab {
		cursor: pointer;
		padding: 0.625rem 0.5rem;
		font-size: 0.875rem;
		display: inline-block;
		background-color: #FAFAFA;
	}

	&_home_screen_settings_tab_selected {
		background-color: rgba(0, 0, 0, 0);
		border-bottom: 1px solid map-get($COLORS, bodybackground) !important;
		margin-bottom: -1px;
	}

	&_device_settings_tab_selected {
		background-color: #EEEEEE;
		border-bottom: 1px solid #EEEEEE !important;
		margin-bottom: -1px;
	}

	&_content {
		&-title {
			font-size: 1.25em;
		}

		&-edit {
			padding-left: 1rem;
			text-decoration: underline;
			padding: 0 !important;
			color: map-get($COLORS, link);
		}

		&-divider {
			margin-top: 0.75rem;
			margin-bottom: 0.75rem;
		}
	}

	&_data {
		border: 1px solid map-get($COLORS, borders);
		padding: 1em;

		&-header {
			padding: 0;
			border: 1px solid map-get($COLORS, borders);
			display: flex;

			&#homeScreenTitleDiv {
				padding: 0.5em;
			}
		}

		&-tabs {
			display: flex;
			flex-wrap: wrap;
		}

		&-formWrapper {
			padding: 0.375em;
			position: relative;
		}

		&-formField {
			width: 93%;
			resize: none;
		}

		&-formImage {
			position: absolute;
			right: 1rem;
			top: 0.5em;
			margin-top: 1em;
			cursor: pointer;

			@include bp('small') {
				right: 0;
			}
		}
	}
}

.ex-profiles {
	min-height: 100%;
	position: relative;

	@include bp('small') {
		padding: 0.5em;
	}

	&_header {
		padding-left: 10px;
		padding-top: 10px;
		overflow: hidden;

		&-title {
			font-size: 1.75rem;
		}

		&_subtitle {
			font-size: 0.75rem;
		}
	}

	&_content {
		@include bp('small') {
			overflow: hidden;
		}

		&-body {
			padding-top: 1.875em;
			height: 100%;
			min-height: 100%;
			display: flex;
			flex-wrap: wrap;

			@include bp('small') {
				width: 200vw;
				transform: translateX(0);
				transition: all 0.3s ease-in-out;

				&_active {
					transform: translateX(calc(-50% + 0.5em));
				}
			}
		}

		&-main {
			border-left: 1px solid;
			border-color: map-get($COLORS, borders);
			padding: 1.25em;
			flex-basis: calc(100% - 18em);

			@include bp('small') {
				flex-basis: calc(100vw - 1em);
				padding: 0.5em;
				overflow: hidden;
			}
		}

		&-sidebar {
			width: 18em;
			overflow-y: auto;
			overflow-x: hidden;

			@include bp('small') {
				flex-basis: calc(100vw - 1em);
			}
		}

		&-buttonContainer {
			padding: 0.5em;
		}

		&-title {
			color: map-get($COLORS, light);
			padding-top: 0.125em 0.25em;
		}
	}

	#title {
		background: map-get($COLORS, secondarytext);
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 5px;
	}

	#content {
		min-height: 100% !important
	}

	#contentwrapper {
		height: 100% !important;
		min-height: 100% !important
	}

	.table {
		display: table;
		border-collapse: collapse;
		border-spacing: 0px;
	}

	.droppableTableCell {
		display: table-cell;
		width: 58px;
		height: 40px;
		padding: 1px;
	}

	.droppableTableCellPlus {
		height: 58px;
	}

	.screenBlock {
		display: inline-block;
		text-align: center;
		padding: 5px;
		margin: auto;
	}

	.screenBlockRemove {
		width: 85%;
		display: inline-block;
		text-align: center;
		padding: 5px;
		margin: auto;
	}

	.borderStyle {
		border: 1px solid;
		border-color: #D3D3D3;
	}

	.borderStyleBottom {
		border-bottom: 1px solid;
		border-color: #D3D3D3;
	}

	.borderStyleRight {
		border-right: 1px solid;
		border-color: #D3D3D3;
	}

	.tableHeaderColor {
		background-color: #EEEEEE;
	}

	.rowHeaderColor {
		background-color: #FAFAFA;
	}

	.differentFromDefaultColor {
		background-color: #FAFDE3;
	}

	.grayItalicWriting {
		font-style: italic;
		color: map-get($COLORS, secondarytext);
	}

	.disablePage {
		opacity: .5;
		pointer-events: none;
	}

	.disable img {
		display: none;
	}

	.removeLinkStyle a {
		color: inherit;
		text-decoration: inherit;
	}

	.iconContainer {
		font-size: .9em;
		text-align: center;
		cursor: pointer;
	}

	.firstRadioButtonLabel {
		margin-right: 30px;
	}

	html[dir=rtl] .firstRadioButtonLabel {
		margin-left: 30px;
		margin-right: 0;
	}
}
